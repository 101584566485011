export interface ICallRate {
	id: number;
	name: string;
	monthly: number;
	starting_rate: number;
}

export const defaultCallRate: ICallRate = {
	id: 0,
	name: "",
	monthly: 0,
	starting_rate: 0,
};
