import React from "react";
import { KTSVG } from "../../../../../../_start/helpers";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import {
  defaultFourRowData,
  TableFourColumns,
} from "./TableFourColumns";

interface Props {
  data: TableFourColumns;
  update: (to: Partial<TableFourColumns>) => void;
}

export const TableFourColumnsTable: React.FC<Props> = ({ data, update }) => {
  const addRow = () => {
    const dataTable = [...data.dataTable];
    dataTable.push([...defaultFourRowData]);

    update({ dataTable });
  };

  const updateTableHeads = (to: string, index: number) => {
    const tableHeads = [...data.tableHeads];
    tableHeads[index] = to;
    update({ tableHeads });
  };

  const updateRowData = (value: string, index: number, row: number) => {
    const dataTable = [...data.dataTable];
    dataTable[row][index] = value;

    update({ dataTable });
  };

  const removeRow = (index: number) => {
    const newTable: string[][] = [...data.dataTable];

    newTable.splice(index, 1);

    update({ dataTable: newTable });
  };

  return (
    <div className="table-two">
      <div className="tablehead tablecontent">
        <ol className="tablerow">
          {data.tableHeads.map((cell, index) => (
            <li key={index}>
              <TextAreaEdit
                className={`text headtext ${index !== 0 ? "text-right" : ""}`}
                name={"dat"}
                value={cell}
                setValue={(val) => updateTableHeads(val.dat, index)}
              />
            </li>
          ))}
        </ol>
      </div>
      <div className="tablebody tablecontent">
        {data.dataTable.map((rowData, row) => (
          <dl className="tablerow" key={row}>
            <span
              onClick={() => removeRow(row)}
              className="position-absolute z-index-2 top-0 end-0 me-2 mt-0 right-0 opacity-75-hover"
            >
              <KTSVG
                path="/media/icons/duotone/Interface/Minus-Square.svg"
                className="svg-icon-danger svg-icon-1"
              />
            </span>

            {rowData.map((cell, index) => (
              <dd key={index}>
                <TextAreaEdit
                  className={`text ${index !== 0 ? "text-right" : ""}`}
                  name={"dat"}
                  value={cell}
                  setValue={(val) => updateRowData(val.dat, index, row)}
                />
              </dd>
            ))}
          </dl>
        ))}
      </div>
      <div className="tablecontent">
        <div onClick={addRow} className="add-item">
          <div className="m-auto d-flex flex-column">
            <KTSVG
              path="/media/icons/duotone/Interface/Plus-Square.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
