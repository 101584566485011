import { PageLink } from "../../../_start/layout/core";

export const pageManagementSubmenu: Array<PageLink> = [
  {
    title: "Pagina's",
    path: "/page-management/pages",
    isActive: true,
  },
  {
    title: "FAQs",
    path: "/page-management/faqs",
    isActive: true,
  },
  {
    title: "FAQ Categories",
    path: "/page-management/faq-categories",
    isActive: true,
  },
];
