import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeImageRightDescriptionFloatingWindowIconsContent } from './HomeImageRightDescriptionFloatingWindowIconsContent';
import { HomeImageRightDescriptionFloatingWindowIconsElement } from './HomeImageRightDescriptionFloatingWindowIconsElement';
import { HomeImageRightDescriptionFloatingWindowIconsFigure } from './HomeImageRightDescriptionFloatingWindowIconsFigure';

interface Props {
  index: number;
  data: HomeImageRightDescriptionFloatingWindowIconsElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeImageRightDescriptionFloatingWindowIconsFC: React.FC<
  Props
> = ({ index, data, updateData }) => {
  const update = (
    fieldsToUpdate: Partial<HomeImageRightDescriptionFloatingWindowIconsElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-image-floating-right-description-icons">
          <div className="showcase">
            <HomeImageRightDescriptionFloatingWindowIconsContent
              data={data}
              update={update}
            />
            <HomeImageRightDescriptionFloatingWindowIconsFigure
              data={data}
              update={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
