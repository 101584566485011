import { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../_start/helpers';

type CardListHeaderProps = {
  toolbar?: ReactNode;
  create?: string;
  search: string;
  paginate?: number;
  setPaginate?: (value: any) => void;
  setSearch: (search: string) => void;
};

const CardListHeader = ({
  toolbar,
  create,
  search,
  setSearch,
  paginate,
  setPaginate,
  children,
}: PropsWithChildren<CardListHeaderProps>) => {
  return (
    <div className="pt-8 card-header">
      <div className="d-flex">
        {paginate ? (
          <select
            value={paginate}
            onChange={(e) => setPaginate!(e.target.value)}
            className="form-select border border-right-0 rounded-top-0 rounded-bottom-0"
            aria-label="Paginate"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        ) : null}

        {children}

        <div className="d-flex flex-center border py-1 px-4 bg-white">
          <KTSVG
            path="/media/icons/duotone/General/Search.svg"
            className="svg-icon-1 svg-icon-primary"
          />
          <input
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            type="text"
            className={`form-control border-0 fw-bold ps-2 ${
              '' ? 'w-xxl-600px' : 'w-xxl-200px'
            }`}
            placeholder="Zoeken"
          />
        </div>
      </div>

      {create ? (
        <div className="card-toolbar">
          <Link className="btn btn-sm btn-light" to={create}>
            Create
          </Link>
        </div>
      ) : null}

      {toolbar ? toolbar : null}
    </div>
  );
};

export default CardListHeader;
