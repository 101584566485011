/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../../../_start/helpers";
import {
	handleFileChange,
	handleFileDelete,
} from "../../../../support/helpers";
import { failureToast, successToast } from "../../../../support/utils";
import { IDepartment } from "../../models/Department";
import { defaultEmployee, IEmployee } from "../../models/Employee";
import { getAllDepartment } from "../../redux/DepartmentCRUD";
import { updateEmployee, viewEmployee } from "../../redux/EmployeeCRUD";

export const UpdateEmployee = () => {
	const { id } = useParams<{ id: string }>();

	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<IEmployee>(defaultEmployee);

	const updateData = (fieldsToUpdate: Partial<IEmployee>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		setData(updatedData);
	};

	const [departments, setDepartments] = useState<Array<IDepartment>>([]);

	const handleFetch = async (id: string) => {
		setLoading(true);
		try {
			const [departments, employee] = await Promise.all([
				getAllDepartment(),
				viewEmployee(id),
			]);

			setDepartments(departments);

			setData({
				...data,
				...employee,
			});

			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			handleFetch(id);
		}
	}, [id]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);
		try {
			await updateEmployee(data);
			successToast("Employee has been updated.");
			setLoading(false);
			setData(defaultEmployee);
			history.goBack();
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	return (
		<div className="card">
			<div className="card-header">
				<h3 className="card-title">Update Employee</h3>
			</div>

			{/* begin::Form */}
			<form onSubmit={handleSubmit} className="form d-flex flex-center">
				<div className="card-body mw-800px py-20">
					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Name</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter name"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.name}
									onChange={(e) =>
										updateData({ name: e.target.value })
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Department
						</label>
						<div className="col-lg-9">
							<select
								className="form-select form-select-lg form-select-solid"
								data-control="select2"
								data-placeholder="Select Status..."
								value={data.department_id}
								onChange={(e) =>
									updateData({
										department_id: parseInt(e.target.value),
									})
								}
							>
								{departments.map((department, idx) => (
									<option value={department.id} key={idx}>
										{department.name}
									</option>
								))}
							</select>
						</div>
					</div>
					{/* end::Form row */}

					<div className="row mb-20">
						<label className="col-lg-3 col-form-label">
							Profile Image
						</label>
						<div className="col-lg-9">
							{data.profile_image && (
								<div className="overlay">
									<div className="w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover overlay-wrapper">
										<img
											alt=""
											className="m-auto"
											src={data.profile_image}
											style={{
												width: "100%",
												height: "100px",
												objectFit: "contain",
											}}
										/>
									</div>
									<div className="overlay-layer bg-dark bg-opacity-10">
										<button
											type="button"
											name="profile_image"
											className="btn btn-primary btn-shadow"
											onClick={(event) =>
												handleFileDelete(
													event,
													data.profile_image,
													updateData
												)
											}
										>
											Remove
										</button>
										<label
											htmlFor={`person-image-preview-input`}
											className="btn btn-light-primary btn-shadow ms-2"
										>
											Change
										</label>
									</div>
								</div>
							)}
							<label
								htmlFor={`person-image-preview-input`}
								className={`w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover ${
									data.profile_image ? "d-none" : ""
								}`}
							>
								<div className="m-auto d-flex flex-column">
									<KTSVG
										path="/media/icons/duotone/Interface/Image.svg"
										className="svg-icon-muted svg-icon-3hx text-center"
									/>
									<span className="mt-4 text-gray-500">
										Upload profile image
									</span>
								</div>
							</label>
							<div className="form-text">
								The profile image for the employee that will be
								used on components that require employee
								selection.
							</div>
						</div>
						<input
							id={`person-image-preview-input`}
							type="file"
							accept="image/*"
							name="profile_image"
							style={{ display: "none" }}
							onChange={(event) =>
								handleFileChange(
									event,
									updateData,
									data.profile_image
								)
							}
						/>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-20">
						<label className="col-lg-3 col-form-label">
							GIF Image
						</label>
						<div className="col-lg-9">
							{data.gif && (
								<div className="overlay">
									<div className="w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover overlay-wrapper">
										<img
											alt=""
											className="m-auto"
											src={data.gif}
											style={{
												width: "400px",
												height: "500px",
												objectFit: "cover",
											}}
										/>
									</div>
									<div className="overlay-layer bg-dark bg-opacity-10">
										<button
											type="button"
											name="gif"
											className="btn btn-primary btn-shadow"
											onClick={(event) =>
												handleFileDelete(
													event,
													data.gif,
													updateData
												)
											}
										>
											Remove
										</button>
										<label
											htmlFor={`employee-gif-image`}
											className="btn btn-light-primary btn-shadow ms-2"
										>
											Change
										</label>
									</div>
								</div>
							)}
							<label
								htmlFor={`employee-gif-image`}
								className={`w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover ${
									data.gif ? "d-none" : ""
								}`}
							>
								<img
									className="m-auto"
									src={toAbsoluteUrl(
										"/assets/images/400x500.png"
									)}
									style={{
										objectFit: "cover",
									}}
									alt=""
								/>
							</label>
							<div className="form-text">
								This image preview will be used as a preview
								page on featured-other-cases when this page is
								selected as other case.
							</div>
						</div>
						<input
							id={`employee-gif-image`}
							type="file"
							accept="image/*"
							name="gif"
							style={{ display: "none" }}
							onChange={(event) =>
								handleFileChange(event, updateData, data.gif)
							}
						/>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row">
						<label className="col-lg-3 col-form-label"></label>
						<div className="col-lg-9">
							<button
								type="submit"
								disabled={loading}
								className="btn btn-primary fw-bolder px-6 py-3 me-3"
							>
								{!loading && (
									<span className="indicator-label">
										Save
									</span>
								)}
								{loading && (
									<span
										className="indicator-progress"
										style={{ display: "block" }}
									>
										Please wait...{" "}
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
							<Link
								className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
								to="/employee-management/employees"
							>
								Cancel
							</Link>
						</div>
					</div>
					{/* end::Form row */}
				</div>
			</form>
			{/* end::Form */}
		</div>
	);
};
