import React from "react";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { IElement } from "../../../models/Element";

import { FAQ } from "./FAQ";
import { FAQContent } from "./FAQContent";
import { FAQList } from "./FAQList";

interface FAQProps {
  index: number;
  data: FAQ;
  updateData: (body: IElement, index: number) => void;
}

export const FAQFC: React.FC<FAQProps> = ({ index, data, updateData }) => {
  const update = (fieldsToUpdate: Partial<FAQ>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="specializations-faq body-my-8">
        <div className="specializations-grid">
          <div className="grid__inner">
            <FAQContent data={data} update={update} />
            <FAQList data={data} update={update} />
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
