import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export interface IconAndTitle {
  id: number;
  icon: string;
  icon_url?: string;
  title: string;
}

const heart_icon = `<svg data-src="assets/icons/icon-heart.svg" focusable="false" aria-hidden="true" data-cache="604800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 27" data-id="svg-loader_12"><path d="M15.08 27h-.16c-.5 0-.98-.2-1.34-.55L2.67 15.53C.95 13.81 0 11.53 0 9.1s.95-4.72 2.67-6.43C4.39.95 6.67 0 9.1 0c2.19 0 4.25.76 5.9 2.17 3.57-3.04 8.96-2.88 12.33.5C29.05 4.39 30 6.67 30 9.1s-.95 4.71-2.67 6.43L16.41 26.44c-.36.36-.84.55-1.34.55ZM9.1 3.79c-1.42 0-2.75.55-3.76 1.56-1 1-1.56 2.34-1.56 3.76s.55 2.75 1.56 3.76L15 22.52l9.65-9.65c1-1 1.56-2.34 1.56-3.76s-.55-2.75-1.56-3.76c-1-1-2.34-1.56-3.76-1.56s-2.75.55-3.76 1.56l-.54.54c-.85.85-2.34.85-3.2 0l-.54-.54c-1-1-2.34-1.56-3.76-1.56ZM4 4.01Z"></path></svg>`;
const slider_icon = `<svg data-src="assets/icons/icon-configure.svg" focusable="false" aria-hidden="true" data-cache="604800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" data-id="svg-loader_16"><path d="M15 10c-1.87 0-3.43 1.29-3.87 3.03-.05 0-.09-.03-.13-.03H1c-.55 0-1 .45-1 1s.45 1 1 1h10l.13-.03c.44 1.73 2 3.03 3.87 3.03 2.21 0 4-1.79 4-4s-1.79-4-4-4Zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2ZM18 3H8l-.13.03C7.43 1.3 5.87 0 4 0 1.79 0 0 1.79 0 4s1.79 4 4 4c1.87 0 3.43-1.29 3.87-3.03.05 0 .09.03.13.03h10c.55 0 1-.45 1-1s-.45-1-1-1ZM4 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Z"></path></svg>`;

export const defaultIconAndTitle1: IconAndTitle = {
  id: 0,
  icon: heart_icon,
  icon_url: '',
  title: 'Gratis iOS App',
};

export const defaultIconAndTitle2: IconAndTitle = {
  id: 0,
  icon: slider_icon,
  title: '24/7 beheer dashboard',
};

export type HomeImageLeftDescriptionFloatingWindowIconsElement = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  image_preview: string;
  image_preview_alt: string;
  floating_text: string;
  floating_button_title: string;
  floating_button_url: string;
  floating_button_url_is_external: boolean;
  icons_titles: IconAndTitle[];
};

export const defaultHomeImageLeftDescriptionFloatingWindowIconsElement: HomeImageLeftDescriptionFloatingWindowIconsElement =
  {
    id: 0,
    name: ElementType.HOME_IMAGE_LEFT_DESCRIPTION_FLOATING_WITH_ICONS,
    heading: 'Regel het zelf',
    description:
      'Deze kennis en enthousiasme brengen we dan ook graag over op onze klanten.',
    floating_text:
      'Bij Verbonden regel je zelf jouw telefonie zaken, 24/7. Dat is telefonie anno 2022.',
    floating_button_title: 'Contact',
    floating_button_url: '',
    floating_button_url_is_external: false,
    image_preview: '',
    image_preview_alt: '',
    icons_titles: [defaultIconAndTitle1, defaultIconAndTitle2],
  };
