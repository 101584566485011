import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { CreateFAQPayload, IFAQ, UpdateFAQPayload } from "../models/FAQ";

export const BASE_FAQ_URL = `${API_URL}/v1/faq-management`;
export const GET_ALL_FAQ_URL = `${API_URL}/v1/faq-management/get-all`;
export const CREATE_FAQ_URL = `${API_URL}/v1/faq-management/create`;

export const listFAQ = async (search = "", direction = "asc") => {
  const URL = `${BASE_FAQ_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IFAQ> }>(URL);

  return value;
};

export const createFAQ = async (payload: CreateFAQPayload) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFAQ }>(CREATE_FAQ_URL, payload);

  return value;
};

export const viewFAQ = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IFAQ }>(`${BASE_FAQ_URL}/${id}/get`);

  return value;
};

export const updateFAQ = async (
  id: number | string,
  payload: UpdateFAQPayload
) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFAQ }>(
    `${BASE_FAQ_URL}/${id}/update`,
    payload
  );

  return value;
};

export const deleteFAQ = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFAQ }>(`${BASE_FAQ_URL}/${id}/delete`);

  return value;
};

// unpaginated faq
export const getAllFAQ = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IFAQ> }>(GET_ALL_FAQ_URL);

  return value;
};
