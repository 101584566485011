import React, { PropsWithChildren } from 'react';

interface QueryFilterProps<T, A> {
  filterValue: T;
  getQueryFilters: (value: T) => A;
  setFilters: (filters: A) => void;
}

const QueryFilter = <T extends unknown, A extends unknown>({
  filterValue,
  getQueryFilters,
  setFilters,
  children,
}: PropsWithChildren<QueryFilterProps<T, A>>) => {
  const [value, setValue] = React.useState<T>(filterValue);

  return (
    <select
      value={value as string}
      onChange={(e) => {
        setValue(e.target.value as T);
        setFilters(getQueryFilters(e.target.value as T));
      }}
      className="w-100px form-select border border-right-0 rounded-top-0 rounded-bottom-0"
      aria-label="Query Filter"
    >
      {children}
    </select>
  );
};

export default QueryFilter;
