import { IPartner } from '../../../../client-management/models/Partner';
import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

// export interface IPartner {
//   id: number;
//   image: string;
//   image_alt: string;
// }

// export const defaultPartner: IPartner = {
//   id: 0,
//   image: '',
//   image_alt: '',
// };

export type PluginPartners = IElement & {
  heading: string;
  heading_type: ElementHeaderType;
  partners: Array<IPartner>;
  featured_partners: Array<IPartner>;
};

export const defaultPluginPartners: PluginPartners = {
  id: 0,
  name: ElementType.PARTNERS,
  heading: 'Deze bedrijven gingen u voor',
  heading_type: ElementHeaderType.H2,
  partners: [],
  featured_partners: [],
};
