import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { CreateVoipGrid } from "./pages/CreateVoipGrid";
import { QuotationsPage } from "./pages/QuotationsPage";
import { SendQuote } from "./pages/SendQuote";
import { UpdateQuotation } from "./pages/UpdateQuotation";
import { ViewQuote } from "./pages/ViewQuote";

export const QuotationManagementPage = () => {
  return (
    <Switch>
      <Route exact={true} path="/quotation-management/quotations">
        <>
          <PageTitle>Quotations</PageTitle>
          <QuotationsPage />
        </>
      </Route>

      <Route exact={true} path="/quotation-management/quotations/send-quote">
        <>
          <PageTitle>Quotations</PageTitle>
          <SendQuote />
        </>
      </Route>

      <Route exact={true} path="/quotation-management/quotations/:id/update">
        <>
          <PageTitle>Quotations</PageTitle>
          <UpdateQuotation />
        </>
      </Route>

      <Route exact={true} path="/quotation-management/quotations/:id/voipgrid">
        <>
          <PageTitle>Quotations</PageTitle>
          <CreateVoipGrid />
        </>
      </Route>

      <Route
        exact={true}
        path="/quotation-management/quotations/:id/bekijk-offerte"
      >
        <>
          <PageTitle>Quotations</PageTitle>
          <ViewQuote></ViewQuote>
        </>
      </Route>

      <Redirect
        from="/quotation-management"
        exact={true}
        to="/quotation-management/quotations"
      />
      <Redirect to="/quotation-management/quotations" />
    </Switch>
  );
};
