import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { XYCoord, Identifier } from 'dnd-core';

import { EditContentItemType, ElementType, IElement } from '../models/Element';
import { MarginTopElementFC } from './utility/margin-top-element/MarginTopElementFC';
import { MarginTopElement } from './utility/margin-top-element/MarginTopElement';
import { MarginBottomElementFC } from './utility/margin-bottom-element/MarginBottomFC';
import { MarginBottomElement } from './utility/margin-bottom-element/MarginBottomElement';
import { HomeImageRightDescriptionElement } from './body/home/image-with-right-heading-and-description/HomeImageRightDescriptionElement';
import { HomeImageRightDescriptionFC } from './body/home/image-with-right-heading-and-description/HomeImageRightDescriptionFC';
import { BlogHeaderFC } from './blog/blog-header/BlogHeaderFC';
import { BlogHeader } from './blog/blog-header/BlogHeader';
import { HomeImageLeftDescriptionFC } from './body/home/image-with-left-heading-and-description/HomeImageLeftDescriptionFC';
import { HomeImageLeftDescriptionElement } from './body/home/image-with-left-heading-and-description/HomeImageLeftDescriptionElement';
import { BlogFeaturedFC } from './blog/blog-featured/BlogFeaturedFC';
import { BlogFeatured } from './blog/blog-featured/BlogFeatured';
import { BlogContentHeadingFC } from './blog/blog-content-heading/BlogContentHeadingFC';
import { BlogContentHeading } from './blog/blog-content-heading/BlogContentHeading';
import { BlogContentImageFC } from './blog/blog-content-image/BlogContentImageFC';
import { BlogContentImage } from './blog/blog-content-image/BlogContentImage';
import { BlogContentDescriptionFC } from './blog/blog-content-description/BlogContentDescriptionFC';
import { BlogContentDescription } from './blog/blog-content-description/BlogContentDescription';
import { BlogContentDescriptionWithInfoFC } from './blog/blog-content-description-with-info/BlogContentDescriptionWithInfoFC';
import { BlogContentDescriptionWithInfo } from './blog/blog-content-description-with-info/BlogContentDescriptionWithInfo';
import { HomeRightDescriptionFloatingWindowFC } from './body/home/image-with-right-description-and-floating-window/HomeRightDescriptionFloatingWindowFC';
import { HomeRightDescriptionWithFloatingWindowElement } from './body/home/image-with-right-description-and-floating-window/HomeRightDescriptionFloatingWindowElement';
import { BlogContentWideDescriptionTwoColumnFC } from './blog/blog-content-wide-description-two-column/BlogContentWideDescriptionTwoColumnFC';
import { BlogContentWideDescriptionTwoColumn } from './blog/blog-content-wide-description-two-column/BlogContentWideDescriptionTwoColumn';
import { BlogContentInfoPanelFC } from './blog/blog-content-info-panel/BlogContentInfoPanelFC';
import { BlogContentInfoPanel } from './blog/blog-content-info-panel/BlogContentInfoPanel';
import { BlogContentWideDescriptionFC } from './blog/blog-content-wide-description/BlogContentWideDescriptionFC';
import { BlogContentWideDescription } from './blog/blog-content-wide-description/BlogContentWideDescription';
import { HomeImageRightDescriptionFloatingWindowIconsFC } from './body/home/image-with-right-description-floating-window-and-icons/HomeImageRightDescriptionFloatingWindowIconsFC';
import { HomeImageRightDescriptionFloatingWindowIconsElement } from './body/home/image-with-right-description-floating-window-and-icons/HomeImageRightDescriptionFloatingWindowIconsElement';
import { HomeLeftDescriptionFloatingWindowFC } from './body/home/image-with-left-description-and-floating-window/HomeLeftDescriptionFloatingWindowFC';
import { HomeLeftDescriptionWithFloatingWindowElement } from './body/home/image-with-left-description-and-floating-window/HomeLeftDescriptionFloatingWindowElement';
import { HomeImageLeftDescriptionFloatingWindowIconsFC } from './body/home/image-with-left-description-floating-window-and-icons/HomeImageLeftDescriptionFloatingWindowIconsFC';
import { HomeImageLeftDescriptionFloatingWindowIconsElement } from './body/home/image-with-left-description-floating-window-and-icons/HomeImageLeftDescriptionFloatingWindowIconsElement';
import { BlogAuthorHeaderFC } from './blog/blog-author-header/BlogAuthorHeaderFC';
import { BlogAuthorHeader } from './blog/blog-author-header/BlogAuthorHeader';
import { BlogAuthorFooterFC } from './blog/blog-author-footer/BlogAuthorFooterFC';
import { BlogAuthorFooter } from './blog/blog-author-footer/BlogAuthorFooter';
import { HomeImageRightDescriptionBlueFC } from './body/home/image-with-right-description-and-blue-theme/HomeImageRightDescriptionBlueThemeFC';
import { HomeImageRightDescriptionBlueElement } from './body/home/image-with-right-description-and-blue-theme/HomeImageRightDescriptionBlueThemeElement';
import { HomeImageTwoRightDescriptionFloatingWindowFC } from './body/home/image-with-two-right-description-and-floating-comp/HomeImageTwoRightDescriptionFloatingWindowFC';
import { HomeImageTwoRightDescriptionFloatingWindowElement } from './body/home/image-with-two-right-description-and-floating-comp/HomeImageTwoRightDescriptionFloatingWindowElement';
import { HomeImageLeftDescriptionBlueFC } from './body/home/image-with-left-description-and-blue-theme/HomeImageLeftDescriptionBlueThemeFC';
import { HomeImageLeftDescriptionBlueElement } from './body/home/image-with-left-description-and-blue-theme/HomeImageLeftDescriptionBlueThemeElement';
import { HomeImageTwoLeftDescriptionFloatingWindowFC } from './body/home/image-with-two-left-description-and-floating-comp/HomeImageTwoLeftDescriptionFloatingWindowFC';
import { HomeImageTwoLeftDescriptionFloatingWindowElement } from './body/home/image-with-two-left-description-and-floating-comp/HomeImageTwoLeftDescriptionFloatingWindowElement';
import { HomeThreeIconsHeadingsAndDescriptionsFC } from './body/home/three-icons-headings-and-descriptions/HomeThreeIconsHeadingsAndDescriptionsFC';
import { HomeThreeIconsHeadingsAndDescriptionsElement } from './body/home/three-icons-headings-and-descriptions/HomeThreeIconsHeadingsAndDescriptionsElement';
import { HomeSixIconsWithTitlesFC } from './body/home/six-icons-with-titles/HomeSixIconsWithTitlesFC';
import { HomeSixIconsWithTitlesElement } from './body/home/six-icons-with-titles/HomeSixIconsWithTitlesElement';
import { HomeThreeIconsHeadingsAndDescriptionsNarrowFC } from './body/home/three-icons-headings-and-descriptions-narrow/HomeThreeIconsHeadingsAndDescriptionsNarrowFC';
import { HomeThreeIconsHeadingsAndDescriptionsNarrowElement } from './body/home/three-icons-headings-and-descriptions-narrow/HomeThreeIconsHeadingsAndDescriptionsNarrowElement';
import { HomeFAQFC } from './body/home/text-with-frequently-asked-questions/HomeFAQFC';
import { HomeFAQ } from './body/home/text-with-frequently-asked-questions/HomeFAQ';
import { HomeHeroWideThreeIconsFC } from './body/home/hero-wide-three-icons/HomeHeroWideThreeIconsFC';
import { HomeHeroWideThreeIconsElement } from './body/home/hero-wide-three-icons/HomeHeroWideThreeIconsElement';
import { HomeHeroWideButtonsFC } from './body/home/hero-wide-buttons/HomeHeroWideButtonsFC';
import { HomeHeroWideButtonsElement } from './body/home/hero-wide-buttons/HomeHeroWideButtonsElement';
import { ContactsImageRightDescriptionFC } from './contacts/contacts-image-with-right-heading-and-description/ContactsImageRightDescriptionFC';
import { ContactsImageRightDescriptionElement } from './contacts/contacts-image-with-right-heading-and-description/ContactsImageRightDescriptionElement';
import { ContactsImageLeftDescriptionFC } from './contacts/contacts-image-with-left-heading-and-description/ContactsImageLeftDescriptionFC';
import { ContactsImageLeftDescriptionElement } from './contacts/contacts-image-with-left-heading-and-description/ContactsImageLeftDescriptionElement';
import { ContactsImageLeftDescriptionFloatingWindowIconsFC } from './contacts/contacts-image-with-left-description-floating-window-and-icons/ContactsImageLeftDescriptionFloatingWindowIconsFC';
import { ContactsImageLeftDescriptionFloatingWindowIconsElement } from './contacts/contacts-image-with-left-description-floating-window-and-icons/ContactsImageLeftDescriptionFloatingWindowIconsElement';
// import { ContactsOtherInfoWithIconsContent } from "./contacts/contacts-other-info-with-icons/ContactsOtherInfoWithIconsContent";
import { ContactsOtherInfoWithIconsElement } from './contacts/contacts-other-info-with-icons/ContactsOtherInfoWithIconsElement';
import { ContactsOtherInfoWithIconsFC } from './contacts/contacts-other-info-with-icons/ContactsOtherInfoWithIconsFC';
import { FAQFC } from './faq/faq-text-with-frequently-asked-questions/FAQFC';
import { FAQ } from './faq/faq-text-with-frequently-asked-questions/FAQ';
import { FAQ2FC } from './faq/faq-built-in/FAQ2FC';
import { FAQ2 } from './faq/faq-built-in/FAQ2';
import { FAQSelect } from './faq/faq-select/FAQSelect';
import { FAQSelectFC } from './faq/faq-select/FAQSelectFC';
import { HomeCustomerReviewsFC } from './body/home/home-customer-reviews/HomeCustomerReviewsFC';
import { HomeCustomerReviews } from './body/home/home-customer-reviews/HomeCustomerReviews';
import { HomePackagesFC } from './body/home/home-packages/HomePackagesFC';
import { HomePackages } from './body/home/home-packages/HomePackages';
import { ProcessHighlightFC } from './page-elements/process-highlight/ProcessHighlightFC';
import { ProcessHighlight } from './page-elements/process-highlight/ProcessHighlight';
import { WebsiteScreenshotFC } from './page-elements/website-screenshot/WebsiteScreenshotFC';
import { WebsiteScreenshot } from './page-elements/website-screenshot/WebsiteScreenshot';
import { InfoBlockBlueFC } from './page-elements/info-block-blue/InfoBlockBlueFC';
import { InfoBlockBlue } from './page-elements/info-block-blue/InfoBlockBlue';
import { InfoBlockBorderedFC } from './page-elements/info-block-bordered/InfoBlockBorderedFC';
import { InfoBlockBordered } from './page-elements/info-block-bordered/InfoBlockBordered';
import { InfoBlockExpandFC } from './page-elements/info-block-expand/InfoBlockExpandFC';
import { InfoBlockExpand } from './page-elements/info-block-expand/InfoBlockExpand';
import { HeadingDescriptionFC } from './page-elements/heading-description/HeadingDescriptionFC';
import { HeadingDescription } from './page-elements/heading-description/HeadingDescription';
import { FourColumnContentFC } from './page-elements/four-column-content/FourColumnContentFC';
import { FourColumnContent } from './page-elements/four-column-content/FourColumnContent';
import { TitleThreeColumnContentFC } from './page-elements/title-three-column-content/TitleThreeColumnContentFC';
import { TitleThreeColumnContent } from './page-elements/title-three-column-content/TitleThreeColumnContent';
import { QuoteBlockFC } from './page-elements/quote-block/QuoteBlockFC';
import { QuoteBlock } from './page-elements/quote-block/QuoteBlock';
import { TableTwoColumnsFC } from './page-elements/table-two-columns/TableTwoColumnsFC';
import { TableTwoColumns } from './page-elements/table-two-columns/TableTwoColumns';
import { TableThreeColumnsFC } from './page-elements/table-three-columns/TableThreeColumnsFC';
import { TableThreeColumns } from './page-elements/table-three-columns/TableThreeColumns';
import { TableFourColumnsFC } from './page-elements/table-four-columns/TableFourColumnsFC';
import { TableFourColumns } from './page-elements/table-four-columns/TableFourColumns';
import { TableFiveColumnsFC } from './page-elements/table-five-columns/TableFiveColumnsFC';
import { TableFiveColumns } from './page-elements/table-five-columns/TableFiveColumns';
import { FormPackageFC } from './forms/form-package/FormPackageFC';
import { FormPackage } from './forms/form-package/FormPackage';
import { TeamEmployeesFC } from './page-elements/team-employees/TeamEmployeesFC';
import { TeamEmployees } from './page-elements/team-employees/TeamEmployees';
import { MediaCarouselFC } from './media/media-carousel/MediaCarouselFC';
import { MediaSliderFC } from './media/media-slider/MediaSliderFC';
import { MediaGalleryFC } from './media/media-gallery/MediaGalleryFC';
import { MediaVideoFC } from './media/media-video/MediaVideoFC';
import { MediaCarousel } from './media/media-carousel/MediaCarousel';
import { MediaSlider } from './media/media-slider/MediaSlider';
import { MediaGallery } from './media/media-gallery/MediaGallery';
import { MediaVideo } from './media/media-video/MediaVideo';
import { PluginExtraFeaturesFC } from './page-elements/extra-features/PluginExtraFeaturesFC';
import { PluginExtraFeatures } from './page-elements/extra-features/PluginExtraFeatures';
import { PluginPartnersFC } from './page-elements/partners/PluginPartnersFC';
import { PluginPartners } from './page-elements/partners/PluginPartners';
import { FormInterestedInFC } from './forms/form-interested-in/FormInterestedInFC';
import { FormInterestedIn } from './forms/form-interested-in/FormInterestedIn';
import { FormCallMeFC } from './forms/form-call-me/FormCallMeFC';
import { FormCallMe } from './forms/form-call-me/FormCallMe';
import { FormNewsLetterFC } from './forms/form-news-letter/FormNewsLetterFC';
import { FormNewsLetter } from './forms/form-news-letter/FormNewsLetter';
import { FormContactFormulierFC } from './forms/form-contact-formulier/FormContactFormulierFC';
import { FormContactFormulier } from './forms/form-contact-formulier/FormContactFormulier';
import { ProjectHighlightsFC } from './page-elements/project-highlights/ProjectHighlightsFC';
import { ProjectHighlights } from './page-elements/project-highlights/ProjectHighlights';
import { FeaturedProductsFC } from './page-elements/featured_products/FeaturedProductsFC';
import { FeaturedProducts } from './page-elements/featured_products/FeaturedProducts';
import { HomeShowcaseBoxHighlightLeftFC } from './body/home/home-showcase-box-highlight-left-image/HomeShowcaseBoxHighlightLeftFC';
import { HomeShowcaseBoxHighlightLeft } from './body/home/home-showcase-box-highlight-left-image/HomeShowcaseBoxHighlightLeft';
import { HomeShowcaseBoxHighlightRightFC } from './body/home/home-showcase-box-highlight-right-image/HomeShowcaseBoxHighlightRightFC';
import { HomeShowcaseBoxHighlightRight } from './body/home/home-showcase-box-highlight-right-image/HomeShowcaseBoxHighlightRight';
import { ArchiveReviewFC } from './page-elements/archive-review/ArchiveReviewFC';
import { ArchiveReview } from './page-elements/archive-review/ArchiveReview';
import { HomeAdvantagesFC } from './body/home/home-advantages/HomeAdvantagesFC';
import { HomeAdvantage } from './body/home/home-advantages/HomeAdvantages';
import { FormContactWithRemarkFC } from './forms/form-contact-with-remark/FormContactWithRemarkFC';
import { FormContactWithRemark } from './forms/form-contact-with-remark/FormContactWithRemark';
import { HomeReviewsSingleFC } from './body/home/home-reviews-single/HomeReviewsSingleFC';
import { HomeReviewsSingle } from './body/home/home-reviews-single/HomeReviewsSingle';
import { HomeReviewsSliderFC } from './body/home/home-reviews-slider/HomeReviewsSliderFC';
import { HomeReviewsSlider } from './body/home/home-reviews-slider/HomeReviewsSlider';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

type ElementDragProps = {
  component: IElement;
  index: number;
  move: (dragIndex: number, hoverIndex: number) => void;
  remove: (index: number) => void;
  updateData: (body: IElement, index: number) => void;
};

export const ElementDrag = ({
  component,
  index,
  move,
  remove,
  updateData,
}: ElementDragProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: EditContentItemType.ELEMENT_MOVE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: EditContentItemType.ELEMENT_MOVE,
    item: () => {
      return { id: component.id, index: index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  const renderElement = (element: IElement) => {
    switch (element.name) {
      // UTILITY
      case ElementType.UTILITY_MARGIN_TOP:
        return (
          <MarginTopElementFC
            index={index}
            data={component as MarginTopElement}
            updateData={updateData}
          />
        );

      case ElementType.UTILITY_MARGIN_BOTTOM:
        return (
          <MarginBottomElementFC
            index={index}
            data={component as MarginBottomElement}
            updateData={updateData}
          />
        );

      // FORM
      case ElementType.FORM_INTERESTED_IN:
        return (
          <FormInterestedInFC
            index={index}
            data={component as FormInterestedIn}
            updateData={updateData}
          />
        );

      case ElementType.FORM_CALL_ME:
        return (
          <FormCallMeFC
            index={index}
            data={component as FormCallMe}
            updateData={updateData}
          />
        );

      case ElementType.FORM_NEWS_LETTER:
        return (
          <FormNewsLetterFC
            index={index}
            data={component as FormNewsLetter}
            updateData={updateData}
          />
        );

      case ElementType.FORM_CONTACT_FORMULIER:
        return (
          <FormContactFormulierFC
            index={index}
            data={component as FormContactFormulier}
            updateData={updateData}
          />
        );

      case ElementType.FORM_CONTACT_WITH_REMARK:
        return (
          <FormContactWithRemarkFC
            index={index}
            data={component as FormContactWithRemark}
            updateData={updateData}
          />
        );

      case ElementType.FORM_PACKAGE:
        return (
          <FormPackageFC
            index={index}
            data={component as FormPackage}
            updateData={updateData}
          />
        );

      // BLOG
      case ElementType.BLOG_HEADER:
        return (
          <BlogHeaderFC
            index={index}
            data={component as BlogHeader}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_AUTHOR_HEADER:
        return (
          <BlogAuthorHeaderFC
            index={index}
            data={component as BlogAuthorHeader}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_HEADING:
        return (
          <BlogContentHeadingFC
            index={index}
            data={component as BlogContentHeading}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_DESCRIPTION:
        return (
          <BlogContentDescriptionFC
            index={index}
            data={component as BlogContentDescription}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_IMAGE:
        return (
          <BlogContentImageFC
            index={index}
            data={component as BlogContentImage}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_DESCRIPTION_WITH_INFO:
        return (
          <BlogContentDescriptionWithInfoFC
            index={index}
            data={component as BlogContentDescriptionWithInfo}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_WIDE_DESCRIPTION_TWO_COLUMN:
        return (
          <BlogContentWideDescriptionTwoColumnFC
            index={index}
            data={component as BlogContentWideDescriptionTwoColumn}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_INFO_PANEL:
        return (
          <BlogContentInfoPanelFC
            index={index}
            data={component as BlogContentInfoPanel}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_CONTENT_WIDE_DESCRIPTION:
        return (
          <BlogContentWideDescriptionFC
            index={index}
            data={component as BlogContentWideDescription}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_AUTHOR_FOOTER:
        return (
          <BlogAuthorFooterFC
            index={index}
            data={component as BlogAuthorFooter}
            updateData={updateData}
          />
        );

      case ElementType.BLOG_FEATURED:
        return (
          <BlogFeaturedFC
            index={index}
            data={component as BlogFeatured}
            updateData={updateData}
          />
        );

      // HOME
      case ElementType.HOME_IMAGE_RIGHT_DESCRIPTION:
        return (
          <HomeImageRightDescriptionFC
            index={index}
            data={component as HomeImageRightDescriptionElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_LEFT_DESCRIPTION:
        return (
          <HomeImageLeftDescriptionFC
            index={index}
            data={component as HomeImageLeftDescriptionElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_RIGHT_DESCRIPTION_WITH_FLOATING:
        return (
          <HomeRightDescriptionFloatingWindowFC
            index={index}
            data={component as HomeRightDescriptionWithFloatingWindowElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_LEFT_DESCRIPTION_WITH_FLOATING:
        return (
          <HomeLeftDescriptionFloatingWindowFC
            index={index}
            data={component as HomeLeftDescriptionWithFloatingWindowElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_RIGHT_DESCRIPTION_FLOATING_WITH_ICONS:
        return (
          <HomeImageRightDescriptionFloatingWindowIconsFC
            index={index}
            data={
              component as HomeImageRightDescriptionFloatingWindowIconsElement
            }
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_LEFT_DESCRIPTION_FLOATING_WITH_ICONS:
        return (
          <HomeImageLeftDescriptionFloatingWindowIconsFC
            index={index}
            data={
              component as HomeImageLeftDescriptionFloatingWindowIconsElement
            }
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_RIGHT_DESCRIPTION_BLUE:
        return (
          <HomeImageRightDescriptionBlueFC
            index={index}
            data={component as HomeImageRightDescriptionBlueElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_LEFT_DESCRIPTION_BLUE:
        return (
          <HomeImageLeftDescriptionBlueFC
            index={index}
            data={component as HomeImageLeftDescriptionBlueElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_TWO_RIGHT_DESCRIPTION_WITH_FLOATING:
        return (
          <HomeImageTwoRightDescriptionFloatingWindowFC
            index={index}
            data={
              component as HomeImageTwoRightDescriptionFloatingWindowElement
            }
            updateData={updateData}
          />
        );

      case ElementType.HOME_IMAGE_TWO_LEFT_DESCRIPTION_WITH_FLOATING:
        return (
          <HomeImageTwoLeftDescriptionFloatingWindowFC
            index={index}
            data={component as HomeImageTwoLeftDescriptionFloatingWindowElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_THREE_ICONS_HEADINGS_AND_DESCRIPTIONS:
        return (
          <HomeThreeIconsHeadingsAndDescriptionsFC
            index={index}
            data={component as HomeThreeIconsHeadingsAndDescriptionsElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_THREE_ICONS_HEADINGS_AND_DESCRIPTIONS_NARROW:
        return (
          <HomeThreeIconsHeadingsAndDescriptionsNarrowFC
            index={index}
            data={
              component as HomeThreeIconsHeadingsAndDescriptionsNarrowElement
            }
            updateData={updateData}
          />
        );

      case ElementType.HOME_SIX_ICONS_WITH_TITLES:
        return (
          <HomeSixIconsWithTitlesFC
            index={index}
            data={component as HomeSixIconsWithTitlesElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_FAQ:
        return (
          <HomeFAQFC
            index={index}
            data={component as HomeFAQ}
            updateData={updateData}
          />
        );

      case ElementType.HOME_HERO_WIDE_THREE_ICONS:
        return (
          <HomeHeroWideThreeIconsFC
            index={index}
            data={component as HomeHeroWideThreeIconsElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_HERO_WIDE_BUTTONS:
        return (
          <HomeHeroWideButtonsFC
            index={index}
            data={component as HomeHeroWideButtonsElement}
            updateData={updateData}
          />
        );

      case ElementType.HOME_CUSTOMER_REVIEWS:
        return (
          <HomeCustomerReviewsFC
            index={index}
            data={component as HomeCustomerReviews}
            updateData={updateData}
          />
        );

      case ElementType.HOME_REVIEWS_SINGLE:
        return (
          <HomeReviewsSingleFC
            index={index}
            data={component as HomeReviewsSingle}
            updateData={updateData}
          />
        );

      case ElementType.HOME_REVIEWS_SLIDER:
        return (
          <HomeReviewsSliderFC
            index={index}
            data={component as HomeReviewsSlider}
            updateData={updateData}
          />
        );

      case ElementType.HOME_PACKAGES:
        return (
          <HomePackagesFC
            index={index}
            data={component as HomePackages}
            updateData={updateData}
          />
        );

      case ElementType.HOME_ADVANTAGES:
        return (
          <HomeAdvantagesFC
            index={index}
            data={component as HomeAdvantage}
            updateData={updateData}
          />
        );

      // Contacts
      case ElementType.CONTACTS_IMAGE_RIGHT_DESCRIPTION:
        return (
          <ContactsImageRightDescriptionFC
            index={index}
            data={component as ContactsImageRightDescriptionElement}
            updateData={updateData}
          />
        );

      case ElementType.CONTACTS_IMAGE_LEFT_DESCRIPTION:
        return (
          <ContactsImageLeftDescriptionFC
            index={index}
            data={component as ContactsImageLeftDescriptionElement}
            updateData={updateData}
          />
        );

      case ElementType.CONTACTS_IMAGE_LEFT_DESCRIPTION_FLOATING_WITH_ICONS:
        return (
          <ContactsImageLeftDescriptionFloatingWindowIconsFC
            index={index}
            data={
              component as ContactsImageLeftDescriptionFloatingWindowIconsElement
            }
            updateData={updateData}
          />
        );

      case ElementType.CONTACTS_OTHER_INFO_WITH_ICONS:
        return (
          <ContactsOtherInfoWithIconsFC
            index={index}
            data={component as ContactsOtherInfoWithIconsElement}
            updateData={updateData}
          />
        );

      // FAQ
      case ElementType.FAQ:
        return (
          <FAQFC
            index={index}
            data={component as FAQ}
            updateData={updateData}
          />
        );

      case ElementType.FAQ2:
        return (
          <FAQ2FC
            index={index}
            data={component as FAQ2}
            updateData={updateData}
          />
        );

      case ElementType.FAQSELECT:
        return (
          <FAQSelectFC
            index={index}
            data={component as FAQSelect}
            updateData={updateData}
          />
        );

      case ElementType.PROCESS_HIGHLIGHT:
        return (
          <ProcessHighlightFC
            index={index}
            data={component as ProcessHighlight}
            updateData={updateData}
          />
        );

      case ElementType.WEBSITE_SCREENSHOT:
        return (
          <WebsiteScreenshotFC
            index={index}
            data={component as WebsiteScreenshot}
            updateData={updateData}
          />
        );

      case ElementType.INFO_BLOCK_BLUE:
        return (
          <InfoBlockBlueFC
            index={index}
            data={component as InfoBlockBlue}
            updateData={updateData}
          />
        );

      case ElementType.INFO_BLOCK_BORDERED:
        return (
          <InfoBlockBorderedFC
            index={index}
            data={component as InfoBlockBordered}
            updateData={updateData}
          />
        );

      case ElementType.INFO_BLOCK_EXPAND:
        return (
          <InfoBlockExpandFC
            index={index}
            data={component as InfoBlockExpand}
            updateData={updateData}
          />
        );

      case ElementType.HEADING_DESCRIPTION:
        return (
          <HeadingDescriptionFC
            index={index}
            data={component as HeadingDescription}
            updateData={updateData}
          />
        );

      case ElementType.FOUR_COLUMN_CONTENT:
        return (
          <FourColumnContentFC
            index={index}
            data={component as FourColumnContent}
            updateData={updateData}
          />
        );

      case ElementType.TITLE_THREE_COLUMN_CONTENT:
        return (
          <TitleThreeColumnContentFC
            index={index}
            data={component as TitleThreeColumnContent}
            updateData={updateData}
          />
        );

      case ElementType.QUOTE_BLOCK:
        return (
          <QuoteBlockFC
            index={index}
            data={component as QuoteBlock}
            updateData={updateData}
          />
        );

      case ElementType.TABLE_TWO_COLUMNS:
        return (
          <TableTwoColumnsFC
            index={index}
            data={component as TableTwoColumns}
            updateData={updateData}
          />
        );

      case ElementType.TABLE_THREE_COLUMNS:
        return (
          <TableThreeColumnsFC
            index={index}
            data={component as TableThreeColumns}
            updateData={updateData}
          />
        );

      case ElementType.TABLE_FOUR_COLUMNS:
        return (
          <TableFourColumnsFC
            index={index}
            data={component as TableFourColumns}
            updateData={updateData}
          />
        );

      case ElementType.TABLE_FIVE_COLUMNS:
        return (
          <TableFiveColumnsFC
            index={index}
            data={component as TableFiveColumns}
            updateData={updateData}
          />
        );

      case ElementType.TEAM_EMPLOYEES:
        return (
          <TeamEmployeesFC
            index={index}
            data={component as TeamEmployees}
            updateData={updateData}
          />
        );

      case ElementType.MEDIA_CAROUSEL:
        return (
          <MediaCarouselFC
            index={index}
            data={component as MediaCarousel}
            updateData={updateData}
          />
        );

      case ElementType.MEDIA_SLIDER:
        return (
          <MediaSliderFC
            index={index}
            data={component as MediaSlider}
            updateData={updateData}
          />
        );

      case ElementType.MEDIA_GALLERY:
        return (
          <MediaGalleryFC
            index={index}
            data={component as MediaGallery}
            updateData={updateData}
          />
        );

      case ElementType.MEDIA_VIDEO:
        return (
          <MediaVideoFC
            index={index}
            data={component as MediaVideo}
            updateData={updateData}
          />
        );

      case ElementType.EXTRA_FEATURES:
        return (
          <PluginExtraFeaturesFC
            index={index}
            data={component as PluginExtraFeatures}
            updateData={updateData}
          />
        );

      case ElementType.PARTNERS:
        return (
          <PluginPartnersFC
            index={index}
            data={component as PluginPartners}
            updateData={updateData}
          />
        );

      case ElementType.PROJECT_HIGHLIGHTS:
        return (
          <ProjectHighlightsFC
            index={index}
            data={component as ProjectHighlights}
            updateData={updateData}
          />
        );

      case ElementType.FEATURED_PRODUCTS:
        return (
          <FeaturedProductsFC
            index={index}
            data={component as FeaturedProducts}
            updateData={updateData}
          />
        );

      case ElementType.HOME_SHOWCASE_BOX_HIGHLIGHT_LEFT:
        return (
          <HomeShowcaseBoxHighlightLeftFC
            index={index}
            data={component as HomeShowcaseBoxHighlightLeft}
            updateData={updateData}
          />
        );

      case ElementType.HOME_SHOWCASE_BOX_HIGHLIGHT_RIGHT:
        return (
          <HomeShowcaseBoxHighlightRightFC
            index={index}
            data={component as HomeShowcaseBoxHighlightRight}
            updateData={updateData}
          />
        );

      case ElementType.ARCHIVE_REVIEW:
        return (
          <ArchiveReviewFC
            index={index}
            data={component as ArchiveReview}
            updateData={updateData}
          />
        );

      default:
        return <div>Something went wrong.</div>;
    }
  };

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {renderElement(component)}
    </div>
  );
};
