import React, { useRef } from "react";
import { KTSVG } from "../../../../../../../_start/helpers";
import { defaultHomeQuestionItem, HomeFAQ, HomeQuestionItem } from "./HomeFAQ";
import { HomeFAQListItem } from "./HomeFAQListItem";

interface FAQListProps {
  data: HomeFAQ;
  update: (fieldsToUpdate: Partial<HomeFAQ>) => void;
}

export const HomeFAQList: React.FC<FAQListProps> = ({ data, update }) => {
  const updateData = (
    fieldsToUpdate: Partial<HomeQuestionItem>,
    index: number
  ) => {
    const items = [...data.item_list];
    items[index] = { ...items[index], ...fieldsToUpdate };
    update({ ...data, item_list: items });
  };

  const addItemRef = useRef<HTMLDivElement>(null);

  const addListItem = () => {
    const items = [...data.item_list];
    items.push(defaultHomeQuestionItem);
    update({ ...data, item_list: items });
  };

  const remove = (index: number) => {
    const items = [...data.item_list];
    items.splice(index, 1);
    update({ ...data, item_list: items });
  };

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    // removeButtonRef.current?.classList.remove('d-none');
    addItemRef.current?.classList.remove("d-none");

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = "pointer";
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    // removeButtonRef.current?.classList.add('d-none');
    addItemRef.current?.classList.add("d-none");
    event.currentTarget.style.cursor = "auto";
  };
  return (
    <div
      className="specializations-faq__list"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data.item_list.map((item, index) => (
        <div>
          <HomeFAQListItem
            index={index}
            data={item}
            update={updateData}
            remove={remove}
          />
        </div>
      ))}
      <div
        ref={addItemRef}
        onClick={addListItem}
        style={{
          minHeight: "68px",
        }}
        className="w-100 h-100 d-flex rounded border-gray-400 border-dashed border-2 opacity-75-hover"
      >
        <div className="m-auto d-flex flex-column">
          <KTSVG
            path="/media/icons/duotone/Interface/Plus-Square.svg"
            className="svg-icon-muted svg-icon-3hx text-center"
          />
        </div>
      </div>
    </div>
  );
};
