import React from 'react';
import {
  HomeSixIconsWithTitlesElement,
  IconWithTitleItem,
} from './HomeSixIconsWithTitlesElement';
import { HomeSixIconsWithTitlesItem } from './HomeSixIconsWithTitlesItem';

interface Props {
  data: HomeSixIconsWithTitlesElement;
  update: (fieldsToUpdate: Partial<HomeSixIconsWithTitlesElement>) => void;
}

export const HomeSixIconsWithTitlesContent: React.FC<Props> = ({
  data,
  update,
}) => {
  const updateItem = (toUpdate: Partial<IconWithTitleItem>, idx: number) => {
    const item_list = [...data.item_list];
    item_list[idx] = { ...item_list[idx], ...toUpdate };
    update({ ...data, item_list });
  };
  return (
    <div className="usp">
      {data.item_list.map((item, idx) => (
        <HomeSixIconsWithTitlesItem
          key={idx}
          index={idx}
          data={data}
          item={item}
          update={updateItem}
        />
      ))}
    </div>
  );
};
