import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export interface ButtonTitle {
  id: number;
  title: string;
  url: string;
  url_is_external: boolean;
}

export type HomeHeroWideButtonsElement = IElement & {
  title: string;
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  image_preview: string;
  image_preview_alt: string;
  button_titles: Array<ButtonTitle>;
};

export const defaultButtonTitle: ButtonTitle = {
  id: 0,
  title: 'Bedrijven',
  url: '',
  url_is_external: false,
};

export const defaultHomeHeroWideButtonsElement: HomeHeroWideButtonsElement = {
  id: 0,
  name: ElementType.HOME_HERO_WIDE_BUTTONS,
  title: 'Dé VoIP aanbieder',
  heading: 'Telefonie zonder gedoe',
  description: 'Wel verbonden, niet gebonden. Geen langlopende contracten.',
  button_title: 'Ja dat wil ik!',
  button_url: '',
  button_url_is_external: false,
  image_preview: '',
  image_preview_alt: '',
  button_titles: [defaultButtonTitle, defaultButtonTitle],
};
