/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  getConfig,
  IThemeConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from '../../../_start/layout/core';
import { RedirectLinkManagementPage } from './RedirectLinkManagementPage';
import { redirectLinkManagementSubmenu } from './RedirectLinkManagementPageData';

const redirectLinkManagementBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: 'Redirect Link Management',
    path: '/redirect-link-management',
    isActive: false,
  },
];

const defaultPageConfig = getConfig();
const redirectLinkManagementPageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
    content: 'general',
    bgColor: 'bg-info',
  },
};

export const RedirectLinkManagementWrapper = () => {
  const { setTheme } = useTheme();

  // Refresh UI after config updates
  useEffect(() => {
    setTheme(redirectLinkManagementPageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={redirectLinkManagementBreadCrumbs}
        submenu={redirectLinkManagementSubmenu}
      />
      <RedirectLinkManagementPage />
    </>
  );
};
