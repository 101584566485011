import { ElementWrapper } from '../../../components/ElementWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { BlogContentHeading } from './BlogContentHeading';

import { TextEditor } from '../../../../../components/TextEditor';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { ElementHeaderType } from '../../utils';

type BlogContentHeadingFCProps = {
  index: number;
  data: BlogContentHeading;
  updateData: (body: IElement, index: number) => void;
};

export const BlogContentHeadingFC = ({
  index,
  data,
  updateData,
}: BlogContentHeadingFCProps) => {
  const update = (fieldsToUpdate: Partial<BlogContentHeading>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="blog-content-heading my-10">
        <div className="blog-content">
          {/* begin::Title */}
          <HeaderSelectWrapper
            name={`title_type`}
            value={data.title_type ?? ElementHeaderType.H1}
            setValue={update}
          >
            <TextAreaEdit
              name="title"
              className={`blog-content__title`}
              value={data.title}
              setValue={update}
            />
          </HeaderSelectWrapper>
          {/* end::Title */}

          {/* begin::Description */}
          <HeaderSelectWrapper
            name={`description_type`}
            value={data.description_type ?? ElementHeaderType.H2}
            setValue={update}
          >
            <TextAreaEdit
              name="description"
              className={`blog-content__description`}
              value={data.description}
              setValue={update}
            />
          </HeaderSelectWrapper>
          {/* end::Description */}

          <div className="blog-content__editor">
            <TextEditor
              content={data.content}
              updateContent={(content) => update({ content })}
            />
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
