import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { CreateFAQCategory } from "../content-management/pages/faq-category/CreateFAQCategory";
import { FAQCategoriesPage } from "../content-management/pages/faq-category/FAQCategoriesPage";
import { UpdateFAQCategory } from "../content-management/pages/faq-category/UpdateFAQCategory";
import { CreateFAQ } from "../content-management/pages/faq/CreateFAQ";
import { FAQsPage } from "../content-management/pages/faq/FAQsPage";
import { UpdateFAQ } from "../content-management/pages/faq/UpdateFAQ";
import { CreatePage } from "./pages/CreatePage";
import { PageEditContent } from "./pages/PageEditContent";
import { PagesPage } from "./pages/PagesPage";
import { UpdatePage } from "./pages/UpdatePage";

export const PageManagementPage = () => {
  return (
    <Switch>
      {/* begin::Page Routes */}
      <Route exact={true} path="/page-management/pages">
        <>
          <PageTitle>Pages</PageTitle>
          <PagesPage />
        </>
      </Route>

      <Route exact={true} path="/page-management/pages/create">
        <>
          <PageTitle>Pages</PageTitle>
          <CreatePage />
        </>
      </Route>
      <Route exact={true} path="/page-management/pages/:id/update">
        <>
          <PageTitle>Pages</PageTitle>
          <UpdatePage />
        </>
      </Route>
      <Route exact={true} path="/page-management/pages/:id/edit-content">
        <>
          <PageTitle>Pages</PageTitle>
          <PageEditContent />
        </>
      </Route>
      {/* end::Page Routes */}

      {/* FAQs */}
      <Route exact={true} path="/page-management/faqs">
        <>
          <PageTitle>FAQs</PageTitle>
          <FAQsPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/faqs/create">
        <>
          <PageTitle>FAQs</PageTitle>
          <CreateFAQ cancelUrl={`/page-management/faqs`} />
        </>
      </Route>
      <Route exact={true} path="/page-management/faqs/:id/update">
        <>
          <PageTitle>FAQs</PageTitle>
          <UpdateFAQ cancelUrl={`/page-management/faqs`} />
        </>
      </Route>
      {/* FAQs */}

      {/* FAQ Categories */}
      <Route exact={true} path="/page-management/faq-categories">
        <>
          <PageTitle>FAQ Categories</PageTitle>
          <FAQCategoriesPage />
        </>
      </Route>
      <Route exact={true} path="/page-management/faq-categories/create">
        <>
          <PageTitle>FAQ Categories</PageTitle>
          <CreateFAQCategory cancelUrl="/page-management/faq-categories" />
        </>
      </Route>
      <Route exact={true} path="/page-management/faq-categories/:id/update">
        <>
          <PageTitle>FAQ Categories</PageTitle>
          <UpdateFAQCategory cancelUrl="/page-management/faq-categories" />
        </>
      </Route>
      {/* FAQ Categories */}

      <Redirect
        from="/page-management"
        exact={true}
        to="/page-management/pages"
      />
      <Redirect to="/page-management/pages" />
    </Switch>
  );
};
