import React, { useRef } from 'react';
import { KTSVG } from '../../../../../../../_start/helpers';
import { TextEditor } from '../../../../../../components/TextEditor';
import { ButtonTooltip } from '../../../../components/ButtonTooltip';
import { InlineEdit } from '../../../../components/InlineEdit';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { AdvantageInfo, HomeAdvantage } from './HomeAdvantages';
import { HomeAdvantagesFigure } from './HomeAdvantagesFigure';
import { HomeAdvantagesIcons } from './HomeAdvantagesIcons';

interface Props {
  index: number;
  elementIndex: number;
  advantage: AdvantageInfo;
  data: HomeAdvantage;
  updateAdvantage: (idx: number, item: AdvantageInfo) => void;
  removeAdvantage: (idx: number) => void;
}

export const HomeAdvantagesCard: React.FC<Props> = ({
  index,
  elementIndex,
  advantage,
  data,
  updateAdvantage,
  removeAdvantage,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    removeItemRef.current?.classList.remove('d-none');
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    removeItemRef.current?.classList.add('d-none');
  };

  const update = (to: Partial<AdvantageInfo>) => {
    const updatedData: AdvantageInfo = { ...advantage, ...to };
    updateAdvantage(index, updatedData);
  };

  const updateTags = (idx: number, tag: string) => {
    const newAdvantage = { ...advantage };
    newAdvantage.tags[idx] = tag;
    update(newAdvantage);
  };

  return (
    <div
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`card-advantage`}
    >
      <span
        ref={removeItemRef}
        onClick={() => removeAdvantage(index)}
        className="position-absolute top-0 end-0 d-none mt-n3 me-n3 opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>

      <HomeAdvantagesIcons
        idx={index}
        data={data}
        item={advantage}
        updateData={update}
      />

      <TextAreaEdit
        className="card-heading"
        name={'heading'}
        value={advantage.heading}
        setValue={update}
      />
      <div className="tags">
        <InlineEdit
          name="tag"
          className="tag"
          value={advantage.tags[0]}
          /** @ts-ignore */
          setValue={(v) => updateTags(0, v.tag)}
          activeVerticalPadding={'py-1'}
          dynamicWidth
        />
        <InlineEdit
          name="tag"
          className="tag"
          value={advantage.tags[1]}
          /** @ts-ignore */
          setValue={(v) => updateTags(1, v.tag)}
          activeVerticalPadding={'py-1'}
          dynamicWidth
        />
        <InlineEdit
          name="tag"
          className="tag"
          value={advantage.tags[2]}
          /** @ts-ignore */
          setValue={(v) => updateTags(2, v.tag)}
          activeVerticalPadding={'py-1'}
          dynamicWidth
        />
      </div>

      <div className="description">
        <TextEditor
          content={advantage.description}
          updateContent={(description) => update({ description })}
        />
      </div>

      {/* begin::Action Button */}
      <div className="btn-container">
        <ButtonTooltip
          name={`button_url`}
          value={advantage.button_url}
          setValue={update}
          isCheckboxChecked={advantage.button_url_is_external}
          checkboxChanged={(v) => update({ button_url_is_external: v })}
        >
          <button className="btn rounded-pill content-button__inner">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center text-primary-color"
              value={advantage.button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                fontWeight: 600,
                width: '200px',
              }}
            />
          </button>
        </ButtonTooltip>
      </div>
      {/* end::Action Button */}

      <HomeAdvantagesFigure
        index={index}
        data={advantage}
        elementData={data}
        update={update}
      />
    </div>
  );
};
