import React from 'react';
import { HomeThreeIconsHeadingsAndDescriptionNarrowItem } from './HomeThreeIconsHeadingsAndDescriptionNarrowItem';
import {
  HeadingsAndDescriptionsItem,
  HomeThreeIconsHeadingsAndDescriptionsNarrowElement,
} from './HomeThreeIconsHeadingsAndDescriptionsNarrowElement';

interface Props {
  data: HomeThreeIconsHeadingsAndDescriptionsNarrowElement;
  update: (
    fieldsToUpdate: Partial<HomeThreeIconsHeadingsAndDescriptionsNarrowElement>
  ) => void;
}

export const HomeThreeIconsHeadingsAndDescriptionNarrowContent: React.FC<
  Props
> = ({ data, update }) => {
  const updateItem = (
    toUpdate: Partial<HeadingsAndDescriptionsItem>,
    idx: number
  ) => {
    const item_list = [...data.item_list];
    item_list[idx] = { ...item_list[idx], ...toUpdate };
    update({ ...data, item_list });
  };
  return (
    <div className="usp">
      {data.item_list.map((item, idx) => (
        <HomeThreeIconsHeadingsAndDescriptionNarrowItem
          key={idx}
          index={idx}
          data={data}
          item={item}
          update={updateItem}
        />
      ))}
    </div>
  );
};
