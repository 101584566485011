/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../_start/helpers';
import { ActionsDropdown } from '../../../components/ActionsDropdown';
import { Pagination } from '../../../components/Pagination';
import { AppTable } from '../../../components/table/AppTable';
import { Cell } from '../../../components/table/Cell';
import { Head } from '../../../components/table/Head';
import { Row } from '../../../components/table/Row';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from '../../../support/utils';
import { IMenu } from '../models/Menu';
import { listMenu, moveMenu } from '../redux/MenusCRUD';

export const MenusPage = () => {
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const [paginatedValue, setPaginatedValue] = useState<PaginatedValue<IMenu>>(
    new PaginatedValue()
  );

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IMenu>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listMenu();
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();

      setInitialized(true);
    }
  }, []);

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce<string>(search, 750);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listMenu(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  // const [selected, setSelected] = useState<IMenu>(defaultMenu);

  // const handleDelete = async (item: IMenu) => {
  // 	setLoading(true);
  // 	try {
  // 		await deleteMenu(item);
  // 		successToast("Menu has been deleted.");
  // 		setLoading(false);
  // 		handleFetchList();
  // 	} catch (error) {
  // 		failureToast(error);
  // 		setLoading(false);
  // 	}
  // };

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    let sourceData = paginatedValue.data[dragIndex];
    let destinationData = paginatedValue.data[hoverIndex];

    setLoading(true)

    try {
      const result = await moveMenu(sourceData, destinationData)
      setPaginatedValue(result)
      successToast("Menu order has been updated.");
  		setLoading(false);
    } catch (error) {
      failureToast(error);
  		setLoading(false);
    }
  };

  return (
    <>
      {/* <DeleteModal
				headerTitle="Delete Menu"
				item={selected}
				handleDelete={handleDelete}
			/> */}
      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                '' ? 'w-xxl-600px' : 'w-xxl-350px'
              }`}
              placeholder="Zoeken"
            />
          </div>
          {/* <div className="card-toolbar">
						<Link
							className="btn btn-sm btn-light"
							to={`menus/create`}
						>
							Create Menu
						</Link>
					</div> */}
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <AppTable
            head={
              <thead>
                <Row className="fw-bolder fs-6 text-gray-800">
                  <Head>Name</Head>
                  <Head>Expand</Head>
                  <Head></Head>
                </Row>
              </thead>
            }
            body={
              <tbody>
                {paginatedValue.data.map((element, idx) => (
                  <DraggableRow
                    key={idx}
                    idx={idx}
                    element={element}
                    moveRow={moveRow}
                  />
                ))}
              </tbody>
            }
          />
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};

type DraggableRowProps = {
  idx: number;
  element: IMenu;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
};

const DraggableRow = ({ idx, element, moveRow }: DraggableRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: `table-row`,
    item: () => ({ index: idx }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: `table-row`,
    drop: (item: { index: number }) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = idx;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  drop(drag(ref));

  const opacity = isDragging ? 0.3 : 1;

  return (
    <Row
      rowRef={ref}
      style={{ opacity }}
      className={`align-middle ${canDrop ? 'can-drop-highlight' : ''} ${
        isOver ? 'is-over-highlight' : ''
      }`}
    >
      <Cell>{element.name}</Cell>
      <Cell className="text-uppercase">{element.expand ? 'YES' : 'NO'}</Cell>
      <Cell className="text-end">
        {/* begin::Dropdown */}
        <ActionsDropdown>
          <div className="menu-item px-3">
            <Link to={`menus/${element.id}/update`} className="menu-link px-3">
              Update
            </Link>
          </div>
          <div className="separator mt-3 opacity-75"></div>
        </ActionsDropdown>
        {/* end::Dropdown */}
      </Cell>
    </Row>
  );
};
