import React from "react";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { FAQ } from "./FAQ";

interface FAQContentProps {
  data: FAQ;
  update: (fieldsToUpdate: Partial<FAQ>) => void;
}

export const FAQContent: React.FC<FAQContentProps> = ({ data, update }) => {
  return (
    <div className="specializations-faq__content">
      <TextAreaEdit
        name="heading"
        value={data.heading}
        setValue={update}
        className="content-heading"
      />

      <TextAreaEdit
        name="description"
        value={data.description}
        setValue={update}
        className="content-description"
      />
    </div>
  );
};
