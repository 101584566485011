import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export interface IconAndDescription {
  id: number;
  icon: string;
  icon_url?: string;
  description: string;
}

const calendar_icon = `<svg data-src="assets/icons/icon-calendar.svg" aria-hidden="true" data-cache="604800" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 23" data-id="svg-loader_20"><path d="M18 2h-1V1c0-.55-.45-1-1-1s-1 .45-1 1v1H7V1c0-.55-.45-1-1-1S5 .45 5 1v1H4C1.79 2 0 3.79 0 6v13c0 2.21 1.79 4 4 4h14c2.21 0 4-1.79 4-4V6c0-2.21-1.79-4-4-4ZM4 4h14c1.1 0 2 .9 2 2v1H2V6c0-1.1.9-2 2-2Zm14 17H4c-1.1 0-2-.9-2-2V9h18v10c0 1.1-.9 2-2 2Z"></path><path d="M6.5 14h-1c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1ZM11.5 14h-1c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1ZM16.5 14h-1c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1ZM6.5 18h-1c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1ZM11.5 18h-1c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1ZM16.5 18h-1c-.55 0-1-.45-1-1s.45-1 1-1h1c.55 0 1 .45 1 1s-.45 1-1 1Z"></path></svg>`;

export type ContactsOtherInfoWithIconsElement = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  content: string;
  icons_descriptions: IconAndDescription[];
};

export const defaultIconAndDescription: IconAndDescription = {
  id: 0,
  icon: calendar_icon,
  icon_url: '',
  description:
    "Onze diensten kan je altijd per dag aanvragen maar ook stopzetten.",
};

export const defaultContactsOtherInfoWithIconsElement: ContactsOtherInfoWithIconsElement =
  {
    id: 0,
    name: ElementType.CONTACTS_OTHER_INFO_WITH_ICONS,
    heading: "Overige gegevens",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus dapibus, felis vitae tempor malesuada.",
    content: "<p><strong>Bankrekeningnummer:</strong> 8590707 (ING Bank)</p>",
    icons_descriptions: [
      defaultIconAndDescription,
      defaultIconAndDescription,
      defaultIconAndDescription,
      defaultIconAndDescription,
    ],
  };
