import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../setup";
import { KTSVG } from "../../../../../../_start/helpers";
import { IEmployee } from "../../../../employee-management/models/Employee";

type SelectAuthorWrapperProps = {
	author: IEmployee;
	updateAuthor: (author: IEmployee) => void;
};

export const SelectAuthorWrapper: React.FC<SelectAuthorWrapperProps> = ({
	author,
	updateAuthor,
	children,
}) => {
	const reduxEmployees = useSelector(
		(state: RootState) => state.editContent.allEmployees
	);

	const [allEmployees, setAllEmployees] = useState<Array<IEmployee>>([]);
	useEffect(() => {
		setAllEmployees(reduxEmployees);
	}, [reduxEmployees]);

	const [active, setActive] = useState(false);

	const sectionRef = useRef<HTMLLIElement>(null);

	useEffect(() => {
		if (active) {
			sectionRef.current?.focus();
		}
	}, [active]);

	const handleSelect = (author: IEmployee) => {
		updateAuthor(author);
		setActive(false);
	};

	const onBlur = (event: React.FocusEvent<HTMLLIElement>) => {
		if (!event.currentTarget.contains(event.relatedTarget)) {
			setActive(false);
		}
	};

	const handleLocalSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const keyword = event.target.value.toLowerCase();

		const filtered_items = reduxEmployees.filter((employee) => {
			const employeeName = employee.name.toLowerCase();
			return employeeName.indexOf(keyword) > -1;
		});

		setAllEmployees(filtered_items);
	};

	const handleActivate = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		event.preventDefault();

		let dataValue = (event.target as HTMLElement).getAttribute(
			"data-value"
		);

		if (dataValue !== "child-li") {
			setActive(true);
		}
	};

	return (
		<div
			className="section-outline-none"
			style={{
				position: "relative",
			}}
			onClick={handleActivate}
		>
			{active && (
				<section
					className="position-absolute z-index-3"
					tabIndex={1}
					ref={sectionRef}
					onBlur={onBlur}
					style={{
						minWidth: "160px",
						left: "50%",
						transform: "translateX(-50%)",
						marginTop: `${6}rem`,
					}}
				>
					<div className="d-flex flex-column align-items-center">
						{/* begin::Arrow Up Element*/}
						<div
							style={{
								width: "0",
								height: "0",
								borderLeft: "12px solid transparent",
								borderRight: "12px solid transparent",
								borderBottom: "12px solid white",
							}}
						></div>
						{/* end::Arrow Up Element*/}

						{/* begin::Case Page List*/}
						<div
							className="rounded"
							style={{
								background: "white",
							}}
						>
							{/* begin::Search */}
							<div className="position-relative px-3 pt-3">
								<span
									className="position-absolute"
									style={{
										top: "60%",
										transform: "translateY(-50%)",
									}}
								>
									<KTSVG
										path="/media/icons/duotone/General/Search.svg"
										className="ps-1 svg-icon-1 svg-icon-primary"
									/>
								</span>
								<input
									onChange={handleLocalSearch}
									className="ps-10 form-control form-control-solid"
									type="text"
									placeholder="Zoeken"
								/>
							</div>
							{/* end::Search */}

							<div className="separator mt-2"></div>

							{/* begin::OtherCases */}
							<ul
								className="my-1 me-2 p-1 list-unstyled overflow-auto select-other-case"
								style={{
									maxHeight: "128px",
								}}
							>
								{allEmployees.map((employee, idx) => (
									<li
										key={idx}
										data-value="child-li"
										onClick={() => handleSelect(employee)}
										className={`mb-1 px-2 py-1 ${
											employee.name === author.name
												? "bg-success"
												: ""
										}`}
									>
										{employee.name}
									</li>
								))}
								{allEmployees.length === 0 && (
									<div className="mb-1 px-2 py-1 text-center text-gray-500">
										No data available
									</div>
								)}
							</ul>
							{/* end::OtherCases */}

							<div className="separator mb-2"></div>
						</div>
						{/* end::Case Page List*/}
					</div>
				</section>
			)}

			{children}
		</div>
	);
};
