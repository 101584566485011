import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeShowcaseBoxHighlightRightContent } from './HomeShowcaseBoxHighlightRightContent';
import { HomeShowcaseBoxHighlightRight } from './HomeShowcaseBoxHighlightRight';
import { HomeShowcaseBoxHighlightRightFigure } from './HomeShowcaseBoxHighlightRightFigure';

interface HomeImageRightDescriptionProps {
  index: number;
  data: HomeShowcaseBoxHighlightRight;
  updateData: (element: IElement, index: number) => void;
}

export const HomeShowcaseBoxHighlightRightFC: React.FC<
  HomeImageRightDescriptionProps
> = ({ index, data, updateData }) => {
  const update = (fieldsToUpdate: Partial<HomeShowcaseBoxHighlightRight>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-showcase-box-highlight-right">
          <div className="position-relative align-items-center showcase">
            <HomeShowcaseBoxHighlightRightContent data={data} update={update} />
            <HomeShowcaseBoxHighlightRightFigure data={data} update={update} />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
