import React from 'react';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import {
  HomeHeroWideThreeIconsElement,
  IconTitle,
} from './HomeHeroWideThreeIconsElement';
import { HomeHeroWideThreeIconsIcon } from './HomeHeroWideThreeIconsIcon';

interface Props {
  data: HomeHeroWideThreeIconsElement;
  update: (to: Partial<HomeHeroWideThreeIconsElement>) => void;
}

export const HomeHeroWideThreeIconsItems: React.FC<Props> = ({
  data,
  update,
}) => {
  const updateData = (to: Partial<IconTitle>, idx: number) => {
    const titles = [...data.icon_titles];
    titles[idx] = { ...titles[idx], ...to };
    update({ ...data, icon_titles: titles });
  };
  return (
    <div className="hero__usps">
      {data.icon_titles.map((title, idx) => (
        <div key={idx} className="hero__usp">
          <HomeHeroWideThreeIconsIcon
            idx={idx}
            data={data}
            item={title}
            updateData={updateData}
          />
          <TextAreaEdit
            className="title"
            name="title"
            value={title.title}
            setValue={(val) => updateData(val, idx)}
          />
        </div>
      ))}
    </div>
  );
};
