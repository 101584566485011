import React from 'react';
import { KTSVG } from '../../../../_start/helpers';
import { BulletType, MenuPackageBulletPoint } from '../models/Menu';

interface Props {
  index: number;
  point: MenuPackageBulletPoint;
  update: (index: number, point: MenuPackageBulletPoint) => void;
  remove: (index: number) => void;
}

export const BulletPoint: React.FC<Props> = ({
  index,
  point,
  update,
  remove,
}) => {
  return (
    <div className="row mb-8 position-relative">
      <span
        onClick={() => remove(index)}
        className="position-absolute top-0 end-0 opacity-75-hover cursor-pointer"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>
      <label className="col-lg-3 col-form-label"></label>
      <div className="col">
        <div className="spinner spinner-sm spinner-primary spinner-right">
          <input
            placeholder="Enter name"
            className="form-control form-control-lg form-control-solid"
            type="text"
            value={point.content}
            onChange={(e) =>
              update(index, {
                ...point,
                content: e.target.value,
              })
            }
            required
          />
        </div>
      </div>
      <div className="col">
        <div className="spinner spinner-sm spinner-primary spinner-right">
          <select
            className="form-select form-select-lg form-select-solid text-capitalize"
            data-control="select2"
            data-placeholder="Select..."
            value={point.bullet_type}
            onChange={(e) =>
              update(index, {
                ...point,
                bullet_type: e.target.value as BulletType,
              })
            }
          >
            <option value={BulletType.CROSS}>CROSS</option>
            <option value={BulletType.CHECK}>CHECK</option>
          </select>
        </div>
      </div>
    </div>
  );
};
