import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
import { useDebounce } from '../../../hooks/useDebounce';
import { failureToast, handlePaginate } from '../../../support/utils';
import { defaultMedia, IMedia } from '../../media-management/models/Media';
import { listMedia } from '../../media-management/redux/MediaLibraryCRUD';
import { setMediaLibrary } from '../redux/EditContentActions';

import SVG from 'react-inlinesvg';
import { KTSVG, toAbsoluteUrl } from '../../../../_start/helpers';
import { UploadMedia } from '../../media-management/components/form-image/UploadMedia';
import { Pagination } from '../../../components/Pagination';

type AttachMediaModalProps = {
  modalId: string;
  updateCallback: (
    image_url: string,
    image_alt: string,
    image_caption: string
  ) => void;
};

export const AttachMediaModal = ({
  modalId,
  updateCallback,
}: AttachMediaModalProps) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  /**
   * SEARCH
   */
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listMedia(search);
      dispatch(setMediaLibrary(result));
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    // to prevent running search on initial component rendner
    setInitialized(true);
  }, []);

  /**
   * PAGINATION
   */
  const paginatedValue = useSelector(
    (state: RootState) => state.editContent.media_library
  );

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IMedia>(page_url);
      dispatch(setMediaLibrary(result));
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  /**
   * INITIALIZATION
   */
  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listMedia();
      dispatch(setMediaLibrary(result));
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const closeRef = useRef<HTMLButtonElement>(null);

  const [selected, setSelected] = useState<IMedia>(defaultMedia);

  const handleConfirm = () => {
    updateCallback(
      selected.original_url as string,
      selected.custom_properties.alt_text ?? '',
      selected.custom_properties.caption ?? ''
    );
    closeRef.current?.click();
  };

  const handleClose = () => {
    setSearch('');
  };

  return (
    <div className="modal bg-white fade" tabIndex={-1} id={modalId}>
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content shadow-none">
          <div className="modal-header">
            <h5 className="modal-title">Select Media</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span className="svg-icon svg-icon-2x" onClick={handleClose}>
                <SVG
                  src={toAbsoluteUrl(
                    '/media/icons/duotone/Navigation/Close.svg'
                  )}
                />
              </span>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-4">
                <UploadMedia
                  loading={loading}
                  handleFetchList={handleFetchList}
                />
              </div>

              <div className="col-lg-8">
                <div
                  className={`card card-custom card-flush ${
                    loading ? 'overlay overlay-block' : ''
                  }`}
                >
                  <div className="pt-8 card-header">
                    <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
                      <KTSVG
                        path="/media/icons/duotone/General/Search.svg"
                        className="svg-icon-1 svg-icon-primary"
                      />
                      <input
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        type="text"
                        className={`form-control border-0 fw-bold ps-2 ${
                          '' ? 'w-xxl-600px' : 'w-xxl-350px'
                        }`}
                        placeholder="Zoeken"
                      />
                    </div>
                  </div>
                  <div
                    className={`card-body py-5 ${
                      loading ? 'overlay-wrapper' : ''
                    }`}
                  >
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                        gap: '2rem',
                      }}
                    >
                      {paginatedValue.data.map((element, idx) => (
                        <MediaGridItem
                          key={idx}
                          element={element}
                          selected={selected}
                          setSelected={setSelected}
                        />
                      ))}
                    </div>
                    {loading && (
                      <div className="overlay-layer rounded bg-dark bg-opacity-5">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="card-footer">
                    <Pagination
                      loading={loading}
                      pagination={paginatedValue}
                      handlePaginateState={handlePaginateState}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <div className="d-block">
              <p>
                <span>Selected: </span>
                <span className="fw-bold underline">
                  {selected.id !== 0 ? selected.name : 'None'}
                </span>
              </p>
            </div>
            <div className="d-flex gap-5">
              <button
                ref={closeRef}
                onClick={handleClose}
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                disabled={selected.id === 0}
                onClick={handleConfirm}
                type="button"
                className="btn btn-primary"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

type MediaGridItemProps = {
  element: IMedia;
  selected: IMedia;
  setSelected: (value: React.SetStateAction<IMedia>) => void;
};
export const MediaGridItem = ({
  element,
  selected,
  setSelected,
}: MediaGridItemProps) => {
  const [dimension, setDimension] = useState({
    width: 0,
    height: 0,
  });

  return (
    <a
      className={`rounded text-center ${
        element.id === selected.id ? 'border border-4 border-gray-400' : ''
      }`}
      onClick={() => setSelected(element)}
    >
      <img
        alt={element.name}
        src={element.original_url as string}
        loading={`lazy`}
        onLoad={(e) =>
          setDimension({
            width: e.currentTarget.naturalWidth,
            height: e.currentTarget.naturalHeight,
          })
        }
        style={{
          width: '150px',
          height: '150px',
          objectFit: 'cover',
        }}
      />
      <div className="mt-2 text-center">
        <span className="d-block">{element.name}</span>
        <span className="d-block text-gray-600">
          {dimension.width}x{dimension.height}
        </span>
      </div>
    </a>
  );
};
