import {
  IActionUnion,
  makeBaseAction,
  makeParamsAction,
  makePayloadAction,
} from '../../../../setup/redux/utils';
import { PaginatedValue } from '../../../support/utils';
import { IManageRedirectLink, IRedirectLink } from '../models/RedirectLink';
import { RedirectLinkEvents } from './RedirectLinkEvents';
// CREATE --
export const createRedirectLinkRequest = makeParamsAction<
  RedirectLinkEvents.CREATE_REDIRECT_LINK_REQUESTED,
  { redirect_link: IRedirectLink }
>(RedirectLinkEvents.CREATE_REDIRECT_LINK_REQUESTED);

export const createRedirectLinkLoad = makePayloadAction<
  RedirectLinkEvents.CREATE_REDIRECT_LINK_LOADED,
  IRedirectLink
>(RedirectLinkEvents.CREATE_REDIRECT_LINK_LOADED);

export const createRedirectLinkFailed =
  makeBaseAction<RedirectLinkEvents.CREATE_REDIRECT_LINK_FAILED>(
    RedirectLinkEvents.CREATE_REDIRECT_LINK_FAILED
  );

// MANAGE --
export const manageRedirectLinkRequest = makeParamsAction<
  RedirectLinkEvents.MANAGE_REDIRECT_LINK_REQUESTED,
  { payload: Array<IManageRedirectLink> }
>(RedirectLinkEvents.MANAGE_REDIRECT_LINK_REQUESTED);

export const manageRedirectLinkLoad = makePayloadAction<
  RedirectLinkEvents.MANAGE_REDIRECT_LINK_LOADED,
  IRedirectLink
>(RedirectLinkEvents.MANAGE_REDIRECT_LINK_LOADED);

export const manageRedirectLinkFailed =
  makeBaseAction<RedirectLinkEvents.MANAGE_REDIRECT_LINK_FAILED>(
    RedirectLinkEvents.MANAGE_REDIRECT_LINK_FAILED
  );

// VIEW --
export const viewRedirectLinkRequest = makeParamsAction<
  RedirectLinkEvents.VIEW_REDIRECT_LINK_REQUESTED,
  { redirect_link_id: string | number }
>(RedirectLinkEvents.VIEW_REDIRECT_LINK_REQUESTED);

export const viewRedirectLinkLoad = makePayloadAction<
  RedirectLinkEvents.VIEW_REDIRECT_LINK_LOADED,
  IRedirectLink
>(RedirectLinkEvents.VIEW_REDIRECT_LINK_LOADED);

export const viewRedirectLinkFailed =
  makeBaseAction<RedirectLinkEvents.VIEW_REDIRECT_LINK_FAILED>(
    RedirectLinkEvents.VIEW_REDIRECT_LINK_FAILED
  );

// UPDATE --
export const updateRedirectLinkRequest = makeParamsAction<
  RedirectLinkEvents.UPDATE_REDIRECT_LINK_REQUESTED,
  { redirect_link: IRedirectLink }
>(RedirectLinkEvents.UPDATE_REDIRECT_LINK_REQUESTED);

export const updateRedirectLinkLoad = makePayloadAction<
  RedirectLinkEvents.UPDATE_REDIRECT_LINK_LOADED,
  IRedirectLink
>(RedirectLinkEvents.UPDATE_REDIRECT_LINK_LOADED);

export const updateRedirectLinkFailed =
  makeBaseAction<RedirectLinkEvents.UPDATE_REDIRECT_LINK_FAILED>(
    RedirectLinkEvents.UPDATE_REDIRECT_LINK_FAILED
  );

// DELETE --
export const deleteRedirectLinkRequest = makeParamsAction<
  RedirectLinkEvents.DELETE_REDIRECT_LINK_REQUESTED,
  { redirect_link: IRedirectLink }
>(RedirectLinkEvents.DELETE_REDIRECT_LINK_REQUESTED);

export const deleteRedirectLinkLoad = makePayloadAction<
  RedirectLinkEvents.DELETE_REDIRECT_LINK_LOADED,
  IRedirectLink
>(RedirectLinkEvents.DELETE_REDIRECT_LINK_LOADED);

export const deleteRedirectLinkFailed =
  makeBaseAction<RedirectLinkEvents.DELETE_REDIRECT_LINK_FAILED>(
    RedirectLinkEvents.DELETE_REDIRECT_LINK_FAILED
  );

// LIST --
export const listRedirectLinkRequest =
  makeBaseAction<RedirectLinkEvents.LIST_REDIRECT_LINK_REQUESTED>(
    RedirectLinkEvents.LIST_REDIRECT_LINK_REQUESTED
  );

export const listRedirectLinkLoad = makePayloadAction<
  RedirectLinkEvents.LIST_REDIRECT_LINK_LOADED,
  PaginatedValue<IRedirectLink>
>(RedirectLinkEvents.LIST_REDIRECT_LINK_LOADED);

export const listRedirectLinkFailed =
  makeBaseAction<RedirectLinkEvents.LIST_REDIRECT_LINK_FAILED>(
    RedirectLinkEvents.LIST_REDIRECT_LINK_FAILED
  );

export const resetCreateRedirectLink =
  makeBaseAction<RedirectLinkEvents.RESET_CREATE_REDIRECT_LINK>(
    RedirectLinkEvents.RESET_CREATE_REDIRECT_LINK
  );

// SEARCH
export const searchRedirectLinkRequest = makeParamsAction<
  RedirectLinkEvents.SEARCH_REDIRECT_LINK_REQUESTED,
  { search: string }
>(RedirectLinkEvents.SEARCH_REDIRECT_LINK_REQUESTED);

export const searchRedirectLinkLoad = makePayloadAction<
  RedirectLinkEvents.SEARCH_REDIRECT_LINK_LOADED,
  PaginatedValue<IRedirectLink>
>(RedirectLinkEvents.SEARCH_REDIRECT_LINK_LOADED);

export const searchRedirectLinkFailed =
  makeBaseAction<RedirectLinkEvents.SEARCH_REDIRECT_LINK_FAILED>(
    RedirectLinkEvents.SEARCH_REDIRECT_LINK_FAILED
  );

export const actions = {
  createRedirectLinkRequest,
  createRedirectLinkLoad,
  createRedirectLinkFailed,
  manageRedirectLinkRequest,
  manageRedirectLinkLoad,
  manageRedirectLinkFailed,
  viewRedirectLinkRequest,
  viewRedirectLinkLoad,
  viewRedirectLinkFailed,
  updateRedirectLinkRequest,
  updateRedirectLinkLoad,
  updateRedirectLinkFailed,
  deleteRedirectLinkRequest,
  deleteRedirectLinkLoad,
  deleteRedirectLinkFailed,
  listRedirectLinkRequest,
  listRedirectLinkLoad,
  listRedirectLinkFailed,
  resetCreateRedirectLink,
  searchRedirectLinkRequest,
  searchRedirectLinkLoad,
  searchRedirectLinkFailed,
};

export type RedirectLinkActions = IActionUnion<typeof actions>;
