import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as userManagement from '../../app/modules/user-management/redux/UserManagementRedux';

import * as auth from '../../app/modules/auth';

import * as editContent from '../../app/modules/edit-content/redux/EditContentRedux';
import * as mediaLibrary from '../../app/modules/media-management/redux/MediaLibraryRedux';
import * as svgLibrary from '../../app/modules/media-management/redux/SvgLibraryRedux';
import * as redirectLinkManagement from '../../app/modules/redirect-link-management/redux/RedirectLinkRedux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  userManagement: userManagement.reducer,
  editContent: editContent.reducer,
  mediaLibrary: mediaLibrary.reducer,
  svgLibrary: svgLibrary.reducer,
  redirectLinkManagement: redirectLinkManagement.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([
    auth.saga(),
    editContent.saga(),
    userManagement.saga(),
    redirectLinkManagement.saga(),
  ]);
}
