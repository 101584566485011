import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

type CKEditorProps = {
  className?: string;
  content: string;
  updateContent: (content: string) => void;
};

export const TextEditor = ({
  className = '',
  content,
  updateContent,
}: CKEditorProps) => {
  return (
    <CKEditor
      className={className}
      editor={Editor}
      data={content ?? ''}
      // @ts-ignore
      onChange={(event, editor) => {
        console.log('Updated event: ', event);
        const editorData = editor.getData();
        updateContent(editorData);
      }}
    />
  );
};
