import {
  IActionUnion,
  makeParamsAction,
  makePayloadAction,
} from '../../../../setup/redux/utils';
import { PaginatedValue } from '../../../support/utils';
import { IPartner } from '../../client-management/models/Partner';
import { IPackage } from '../../content-management/models/Package';
import { IProduct } from '../../content-management/models/Product';
import { IEmployee } from '../../employee-management/models/Employee';
import { IMedia } from '../../media-management/models/Media';
import { IPage } from '../../page-management/models/Page';
import { IPost } from '../../post-management/models/Post';
import { IElement } from '../models/Element';
import { EditContentEvents } from './EditContentEvents';

export const insertElement = makeParamsAction<
  EditContentEvents.INSERT_ELEMENT,
  { element: IElement }
>(EditContentEvents.INSERT_ELEMENT);

export const moveElement = makeParamsAction<
  EditContentEvents.MOVE_ELEMENT,
  { dragIndex: number; hoverIndex: number }
>(EditContentEvents.MOVE_ELEMENT);

export const updateElement = makeParamsAction<
  EditContentEvents.UPDATE_ELEMENT,
  { element: IElement; index: number }
>(EditContentEvents.UPDATE_ELEMENT);

export const removeElement = makeParamsAction<
  EditContentEvents.REMOVE_ELEMENT,
  { index: number }
>(EditContentEvents.REMOVE_ELEMENT);

export const setContent = makePayloadAction<
  EditContentEvents.SET_CONTENT,
  Array<IElement>
>(EditContentEvents.SET_CONTENT);

export const setPost = makePayloadAction<EditContentEvents.SET_POST, IPost>(
  EditContentEvents.SET_POST
);

export const setPage = makePayloadAction<EditContentEvents.SET_PAGE, IPage>(
  EditContentEvents.SET_PAGE
);

export const setAllPosts = makePayloadAction<
  EditContentEvents.SET_ALL_POSTS,
  Array<IPost>
>(EditContentEvents.SET_ALL_POSTS);

export const setAllEmployees = makePayloadAction<
  EditContentEvents.SET_ALL_EMPLOYEES,
  Array<IEmployee>
>(EditContentEvents.SET_ALL_EMPLOYEES);

export const setAllPackages = makePayloadAction<
  EditContentEvents.SET_ALL_PACKAGES,
  Array<IPackage>
>(EditContentEvents.SET_ALL_PACKAGES);

export const setAllProducts = makePayloadAction<
  EditContentEvents.SET_ALL_PRODUCTS,
  Array<IProduct>
>(EditContentEvents.SET_ALL_PRODUCTS);

export const setAllPartners = makePayloadAction<
  EditContentEvents.SET_ALL_PARTNERS,
  Array<IPartner>
>(EditContentEvents.SET_ALL_PARTNERS);

export const setAuthor = makePayloadAction<
  EditContentEvents.SET_AUTHOR,
  IEmployee
>(EditContentEvents.SET_AUTHOR);

export const setFeaturedPackages = makePayloadAction<
  EditContentEvents.SET_FEATURED_PACKAGES,
  Array<IPackage>
>(EditContentEvents.SET_FEATURED_PACKAGES);

export const setFeaturedProducts = makePayloadAction<
  EditContentEvents.SET_FEATURED_PRODUCTS,
  Array<IProduct>
>(EditContentEvents.SET_FEATURED_PRODUCTS);

export const setMediaLibrary = makePayloadAction<
  EditContentEvents.SET_MEDIA_LIBRARY,
  PaginatedValue<IMedia>
>(EditContentEvents.SET_MEDIA_LIBRARY);

export const setFileLibrary = makePayloadAction<
  EditContentEvents.SET_FILE_LIBRARY,
  PaginatedValue<IMedia>
>(EditContentEvents.SET_FILE_LIBRARY);

export const setSvgLibrary = makePayloadAction<
  EditContentEvents.SET_SVG_LIBRARY,
  PaginatedValue<IMedia>
>(EditContentEvents.SET_SVG_LIBRARY);

export const setPartners = makePayloadAction<
  EditContentEvents.SET_PARTNERS,
  Array<IPartner>
>(EditContentEvents.SET_PARTNERS);

export const editContentActions = {
  insertElement,
  moveElement,
  updateElement,
  removeElement,
  setContent,
  setPost,
  setPage,

  setAllPosts,
  setAllEmployees,
  setAllPackages,
  setAllProducts,
  setAllPartners,

  setAuthor,
  setFeaturedPackages,
  setFeaturedProducts,
  setMediaLibrary,
  setFileLibrary,
  setSvgLibrary,
  setPartners,
};

export type EditContentActions = IActionUnion<typeof editContentActions>;
