import React from 'react';
import { KTSVG } from '../../../../../../../_start/helpers';
import {
  HomeHeroWideThreeIconsElement,
  IconTitle,
} from './HomeHeroWideThreeIconsElement';

interface Props {
  idx: number;
  data: HomeHeroWideThreeIconsElement;
  item: IconTitle;
  updateData: (to: Partial<IconTitle>, idx: number) => void;
}

export const HomeHeroWideThreeIconsIcon: React.FC<Props> = ({
  idx,
  data,
  item,
//   updateData,
}) => {
  return (
    <>
      <div className="icon__outer">
        <div className="overlay">
          <div className="overlay-layer bg-dark bg-opacity-10">
            <label
              className="px-1 btn btn-sm btn-icon btn-color-primary btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target={`#home-hero-wide-three-icons-${idx}-${data.id}`}
            >
              <KTSVG
                path="/media/icons/duotone/General/Edit.svg"
                className="svg-icon-1"
              />
            </label>
          </div>
          <div>
            {item.icon_url ? (
              <img className="icon" src={item.icon_url} alt="Uploaded" />
            ) : (
              <KTSVG svgClassName="icon" path={item.icon ?? ''} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
