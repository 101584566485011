import { PropsWithChildren } from 'react';
import { ElementHeaderType } from '../elements/utils';

type HeaderSelectWrapperProps = {
  name: string;
  value: string;
  marginLeft?: string;
  className?: string;
  setValue: (fieldsToUpdate: Partial<any>) => void;
  zIndex?: number;
};

export const HeaderSelectWrapper = ({
  name,
  value,
  setValue,
  className = '',
  marginLeft = '-7rem',
  zIndex = 1,
  children,
}: PropsWithChildren<HeaderSelectWrapperProps>) => {
  const allClassNames = `position-relative ${className}`;

  return (
    <div className={allClassNames}>
      {children}
      <div
        className="position-absolute start-0"
        style={{
          marginLeft: marginLeft,
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: `${zIndex}`,
        }}
      >
        <select
          className="form-select form-select-sm form-select-solid text-capitalize"
          data-control="select2"
          data-placeholder="Select Status..."
          name={name}
          value={value}
          onChange={(event) =>
            setValue({ [event.target.name]: event.target.value })
          }
        >
          <option value={ElementHeaderType.H1}>{ElementHeaderType.H1}</option>
          <option value={ElementHeaderType.H2}>{ElementHeaderType.H2}</option>
          <option value={ElementHeaderType.H3}>{ElementHeaderType.H3}</option>
          <option value={ElementHeaderType.H4}>{ElementHeaderType.H4}</option>
          <option value={ElementHeaderType.P}>{ElementHeaderType.P}</option>
        </select>
      </div>
    </div>
  );
};
