import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TextEditor } from '../../../../components/TextEditor';
import { failureToast, successToast } from '../../../../support/utils';
import { defaultICookieBanner, ICookieBanner } from '../../models/CookieBanner';
import {
  updateCookieBanner,
  viewCookieBanner,
} from '../../redux/CookieBannersCRUD';

export const UpdateCookieBanner = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ICookieBanner>(defaultICookieBanner);

  const updateData = (fieldsToUpdate: Partial<ICookieBanner>) => {
    if (data.id === 0) return;
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const [menu] = await Promise.all([viewCookieBanner(id)]);
      setData({ ...data, ...menu });
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await updateCookieBanner(data);
      successToast('Cookie Banner has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Cookie Banner</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) =>
                    updateData({
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Message</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <div className="form-control form-control-lg form-control-solid">
                  <TextEditor
                    content={data.message}
                    updateContent={(message) => updateData({ message })}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Button Text</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter text"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.button_text}
                  onChange={(e) =>
                    updateData({
                      button_text: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Button Settings Text
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter text"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.button_settings_text}
                  onChange={(e) =>
                    updateData({
                      button_settings_text: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Settings Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter text"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.settings_title}
                  onChange={(e) =>
                    updateData({
                      settings_title: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Settings Text</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <div className="form-control form-control-lg form-control-solid">
                  <TextEditor
                    content={data.settings_text}
                    updateContent={(settings_text) =>
                      updateData({ settings_text })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Settings Essential Text
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <div className="form-control form-control-lg form-control-solid">
                  <TextEditor
                    content={data.settings_essential_text}
                    updateContent={(settings_essential_text) =>
                      updateData({ settings_essential_text })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Settings Functional Text
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <div className="form-control form-control-lg form-control-solid">
                  <TextEditor
                    content={data.settings_functional_text}
                    updateContent={(settings_functional_text) =>
                      updateData({ settings_functional_text })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Settings Analytics Text
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <div className="form-control form-control-lg form-control-solid">
                  <TextEditor
                    content={data.settings_analytics_text}
                    updateContent={(settings_analytics_text) =>
                      updateData({ settings_analytics_text })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Settings Marketing Text
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <div className="form-control form-control-lg form-control-solid">
                  <TextEditor
                    content={data.settings_marketing_text}
                    updateContent={(settings_marketing_text) =>
                      updateData({ settings_marketing_text })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/layout-management/cookie-banner`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
