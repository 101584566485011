import React from "react";
import { TextEditor } from "../../../../../components/TextEditor";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { HeaderSelectWrapper } from "../../../components/HeaderSelectWrapper";
import { InlineEdit } from "../../../components/InlineEdit";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { IElement } from "../../../models/Element";
import { HeadingDescription } from "./HeadingDescription";

interface Props {
  index: number;
  data: HeadingDescription;
  updateData: (body: IElement, index: number) => void;
}

export const HeadingDescriptionFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<HeadingDescription>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="heading-description">
        <InlineEdit
          className="title"
          name={"title"}
          value={data.title}
          setValue={update}
          dynamicWidth
        />

        <HeaderSelectWrapper
          name={"heading_type"}
          value={data.heading_type}
          setValue={update}
        >
          <TextAreaEdit
            className="heading"
            name={"heading"}
            value={data.heading}
            setValue={update}
          />
        </HeaderSelectWrapper>

        <div className="description">
          <TextEditor
            content={data.description}
            updateContent={(description) => update({ description })}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
