/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { failureToast, successToast } from "../../../../support/utils";
import { defaultCallRate, ICallRate } from "../../models/CallRate";
import { updateCallRate, viewCallRate } from "../../redux/CallRateCRUD";

export const UpdateCallRate = () => {
	const { id } = useParams<{ id: string }>();

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<ICallRate>(defaultCallRate);

	const updateData = (fieldsToUpdate: Partial<ICallRate>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		setData(updatedData);
	};

	const handleFetch = async (id: string) => {
		setLoading(true);

		try {
			const result = await viewCallRate(id);
			setData(result);
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			handleFetch(id);
		}
	}, [id]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);

		try {
			await updateCallRate(data);
			successToast("Category has been updated.");
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	return (
		<div className="card">
			<div className="card-header">
				<h3 className="card-title">Update Call Rate</h3>
			</div>
			{/* begin::Form */}
			<form onSubmit={handleSubmit} className="form d-flex flex-center">
				<div className="card-body mw-800px py-20">
					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Name</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter name"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.name}
									onChange={(e) =>
										updateData({
											name: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Tafief p/m
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter rate"
									className="form-control form-control-lg form-control-solid"
									type="number"
                  step="0.01"
									value={data.monthly}
									onChange={(e) =>
										updateData({
											monthly: parseFloat(e.target.value),
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Starttarief
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter rate"
									className="form-control form-control-lg form-control-solid"
									type="number"
                  step="0.01"
									value={data.starting_rate}
									onChange={(e) =>
										updateData({
											starting_rate: parseFloat(
												e.target.value
											),
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row">
						<label className="col-lg-3 col-form-label"></label>
						<div className="col-lg-9">
							<button
								type="submit"
								disabled={loading}
								className="btn btn-primary fw-bolder px-6 py-3 me-3"
							>
								{!loading && (
									<span className="indicator-label">
										Save
									</span>
								)}
								{loading && (
									<span
										className="indicator-progress"
										style={{ display: "block" }}
									>
										Please wait...{" "}
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
							<Link
								className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
								to={`/content-management/call-rates`}
							>
								Cancel
							</Link>
						</div>
					</div>
					{/* end::Form row */}
				</div>
			</form>
			{/* end::Form */}
		</div>
	);
};
