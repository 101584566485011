import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { InquiriesPage } from "./pages/InquiriesPage";
import { UpdateInquiry } from "./pages/UpdateInquiry";

export const InquiryManagementPage = () => {
	return (
		<Switch>
			{/* begin::Page Routes */}
			<Route exact={true} path="/inquiry-management/inquiries">
				<>
					<PageTitle>Inquiries</PageTitle>
					<InquiriesPage />
				</>
			</Route>

			<Route exact={true} path="/inquiry-management/inquiries/:id/update">
				<>
					<PageTitle>Inquiries</PageTitle>
					<UpdateInquiry />
				</>
			</Route>

			{/* end::Page Routes */}

			<Redirect
				from="/inquiry-management"
				exact={true}
				to="/inquiry-management/inquiries"
			/>

			<Redirect to="/inquiry-management/inquiries" />
		</Switch>
	);
};
