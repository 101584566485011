import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { ITag } from "../models/Tag";

export const BASE_TAG_URL = `${API_URL}/v1/page-management/tags`;
export const GET_ALL_TAG_URL = `${API_URL}/v1/page-management/tags/get-all`;
export const CREATE_TAG_URL = `${API_URL}/v1/page-management/tags/create`;

export const listTag = async (search = "", direction = "asc") => {
  const URL = `${BASE_TAG_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<ITag> }>(URL);

  return value;
};

export const createTag = async (payload: ITag) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITag }>(CREATE_TAG_URL, payload);

  return value;
};

export const viewTag = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: ITag }>(`${BASE_TAG_URL}/${id}/get`);

  return value;
};

export const updateTag = async (payload: ITag) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITag }>(
    `${BASE_TAG_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteTag = async (payload: ITag) => {
  const {
    data: { value },
  } = await axios.post<{ value: ITag }>(
    `${BASE_TAG_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllTag = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<ITag> }>(GET_ALL_TAG_URL);

  return value;
};
