import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { IElement } from '../../../models/Element';
import { FormContactWithRemark } from './FormContactWithRemark';

type FormContactWithRemarkFCProps = {
  index: number;
  data: FormContactWithRemark;
  updateData: (element: IElement, index: number) => void;
};

export const FormContactWithRemarkFC = ({
  index,
  data,
  updateData,
}: FormContactWithRemarkFCProps) => {
  const update = (fieldsToUpdate: Partial<FormContactWithRemark>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="form-interested-in">
        <div className="form-interested-in__content">
          <StaticWidthInlineEdit
            name="title"
            className="title"
            value={data.title}
            setValue={update}
          />

          <div className="form-field-group">
            <StaticWidthInlineEdit
              name="heading_primary"
              className="heading-text"
              value={data.heading_primary}
              setValue={update}
            />

            <div className="d-flex justify-content-between gap-4">
              <div className="form-field w-100">
                <a>
                  <StaticWidthInlineEdit
                    name={`name_label`}
                    value={data.name_label}
                    setValue={update}
                    style={{
                      maxWidth: '22rem',
                    }}
                  />
                </a>
              </div>

              <div className="form-field w-100">
                <a>
                  <StaticWidthInlineEdit
                    name={`email_label`}
                    value={data.email_label}
                    setValue={update}
                    style={{
                      maxWidth: '22rem',
                    }}
                  />
                </a>
              </div>
            </div>

            <div className="form-field">
              <a>
                <StaticWidthInlineEdit
                  name={`subject_label`}
                  value={data.subject_label}
                  setValue={update}
                  style={{
                    maxWidth: '22rem',
                  }}
                />
              </a>
            </div>
          </div>

          <div className="form-field-group">
            <StaticWidthInlineEdit
              name="heading_secondary"
              className="heading-text"
              value={data.heading_secondary}
              setValue={update}
            />

            <div className="form-field">
              <a className="form-field__textarea">
                <StaticWidthInlineEdit
                  name={`message_label`}
                  value={data.message_label}
                  setValue={update}
                  className="w-100"
                />
              </a>
            </div>
          </div>

          {/* begin::Action Button */}
          <div className="action">
            <a>
              <InlineEdit
                className="action-text"
                name={`button_title`}
                value={data.button_title}
                setValue={update}
                dynamicWidth
                style={{
                  maxWidth: '30rem',
                }}
              />
            </a>
          </div>
          {/* end::Action Button */}

          <StaticWidthInlineEdit
            name="success_message"
            className="text-white"
            value={
              data.success_message ??
              'We hebben je aanmelding ontvangen. Bevestig je inschrijving.'
            }
            setValue={update}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
