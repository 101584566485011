/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { failureToast, successToast } from '../../../../support/utils';
import { ICategory } from '../../models/Category';
import {
  defaultProduct,
  IProduct,
  ProductPriceType,
} from '../../models/Product';
import { BASE_PRODUCT_URL, updateProduct } from '../../redux/ProductCRUD';

export const UpdateProduct = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IProduct>(defaultProduct);

  const updateData = (fieldsToUpdate: Partial<IProduct>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      await updateProduct(data);
      successToast('Product has been updated.');
      setLoading(false);
      setData(defaultProduct);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const toggleCheckbox = () => {
    const is_bulk_purchase = data.is_bulk_purchase;
    updateData({
      is_bulk_purchase: !is_bulk_purchase,
    });
  };

  // removing unnecessary re-render during initialization
  // and calling the same api/s
  const [initialized, setInitialized] = useState(false);

  const [categories, setCategories] = useState<Array<ICategory>>([]);

  const handleInitialization = async () => {
    setLoading(true);

    try {
      const response = await axios.get<{
        value: {
          product: IProduct;
          categories: Array<ICategory>;
        };
      }>(`${BASE_PRODUCT_URL}/${id}/update-initialization`);

      const { value } = response.data;
      setCategories(value.categories);
      setData({ ...data, ...value.product });
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleInitialization();
      setInitialized(true);
    }
  }, [id]);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Product</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) => updateData({ name: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Category</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.category_id}
                onChange={(e) =>
                  updateData({
                    category_id: parseInt(e.target.value),
                  })
                }
              >
                {categories.map((item, idx) => (
                  <option value={item.id} key={idx}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Show</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.show}
                onChange={(e) =>
                  updateData({
                    show: parseInt(e.target.value),
                  })
                }
              >
                <option value={1}>YES</option>
                <option value={0}>NO</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          {/* <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Discount Code</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter code"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.discount_code}
                  onChange={(e) =>
                    updateData({ discount_code: e.target.value })
                  }
                  required
                />
              </div>
            </div>
          </div> */}
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Price Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.price_type}
                onChange={(e) =>
                  updateData({
                    price_type: e.target.value as ProductPriceType,
                  })
                }
              >
                <option value={ProductPriceType.ONCE}>ONCE</option>
                <option value={ProductPriceType.MONTHLY}>MONTHLY</option>
                <option value={ProductPriceType.BOTH}>BOTH</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Quantity</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter quantity"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.quantity}
                  onChange={(e) => updateData({ quantity: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">One Time Payment</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter one time cost"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.one_time_payment}
                  onChange={(e) =>
                    updateData({
                      one_time_payment: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Monthly Payment</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter monthly cost"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.monthly_payment}
                  onChange={(e) =>
                    updateData({
                      monthly_payment: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <div className="row form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckChecked"
                    checked={data.is_bulk_purchase}
                    onChange={toggleCheckbox}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckChecked"
                  >
                    Is bulk purchase
                  </label>
                </div>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/content-management/products`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
