import { PageLink } from '../../../_start/layout/core';

export const layoutManagementSubmenu: Array<PageLink> = [
  {
    title: 'Menus',
    path: '/layout-management/menus',
    isActive: true,
  },
  {
    title: 'Footers',
    path: '/layout-management/footers',
    isActive: true,
  },
  {
    title: 'Cookie Banner',
    path: '/layout-management/cookie-banner',
    isActive: true,
  },
];
