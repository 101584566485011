import CardListBody from '../../../../components/card/CardListBody';
import CardListFooter from '../../../../components/card/CardListFooter';
import CardListHeader from '../../../../components/card/CardListHeader';
import QueryFilter from '../../../../components/QueryFilter';
import { useRepositoryList } from '../../../../hooks/useRepositoryList';
import { POST_URL } from '../../../../support/api';
import {
  getPostQueryFilters,
  IPost,
  PostQueryFilterValue,
} from '../../models/Post';
import PostsTable from './PostsTable';

export const PostsPage = () => {
  const {
    paginatedValue,
    isPending,
    setIsPending,
    paginate,
    setPaginate,
    search,
    setSearch,
    handlePaginateState,
    sort,
    handleSort,
    selected,
    setSelected,
    handleDelete,
    handleFetchList,
    setFilters,
  } = useRepositoryList<IPost>(POST_URL);

  return (
    <div
      className={`card card-custom card-flush ${
        isPending ? 'overlay overlay-block' : ''
      }`}
    >
      <CardListHeader
        create={`posts/create`}
        paginate={paginate}
        setPaginate={setPaginate}
        search={search}
        setSearch={setSearch}
      >
        <QueryFilter
          filterValue={PostQueryFilterValue.ALL}
          getQueryFilters={getPostQueryFilters}
          setFilters={setFilters}
        >
          <option value="">All</option>
          <option value={PostQueryFilterValue.DRAFT}>Draft</option>
          <option value={PostQueryFilterValue.FUTURE}>Future</option>
          <option value={PostQueryFilterValue.PUBLISHED}>Published</option>
        </QueryFilter>
      </CardListHeader>

      <CardListBody isPending={isPending}>
        <PostsTable
          setIsPending={setIsPending}
          data={paginatedValue.data}
          sort={sort}
          handleSort={handleSort}
          selected={selected}
          setSelected={setSelected}
          handleDelete={handleDelete}
          handleFetchList={handleFetchList}
        />
      </CardListBody>

      <CardListFooter
        isPending={isPending}
        paginatedValue={paginatedValue}
        handlePaginateState={handlePaginateState}
      />
    </div>
  );
};
