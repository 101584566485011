import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IClient } from '../models/Client';

const CUSTOMER_URL = `${API_URL}/v1/client-management`;

export const createClient = async (payload: IClient) => {
  const response = await axios.post<{ value: IClient }>(
    `${CUSTOMER_URL}/create`,
    payload
  );

  return response.data.value;
};

export const viewClient = async (customer_id: string | number) => {
  const response = await axios.get<{ value: IClient }>(
    `${CUSTOMER_URL}/${customer_id}/get`
  );

  return response.data.value;
};

export const updateClient = async (payload: IClient) => {
  const response = await axios.post<{ value: IClient }>(
    `${CUSTOMER_URL}/${payload.id}/update`,
    payload
  );

  return response.data.value;
};

export const deleteClient = async (payload: IClient) => {
  const response = await axios.post<{ value: IClient }>(
    `${CUSTOMER_URL}/${payload.id}/delete`,
    payload
  );

  return response.data.value;
};

export const listClient = async (search = '', direction = 'asc') => {
  const URL = `${CUSTOMER_URL}/list?search=${search}&direction=${direction}`;
  const response = await axios.get<{ value: PaginatedValue<IClient> }>(URL);

  return response.data.value;
};

export const getAllClient = async () => {
  const response = await axios.get<{ value: Array<IClient> }>(
    `${CUSTOMER_URL}/get-all`
  );

  return response.data.value;
};
