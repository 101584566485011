/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { DeleteModal } from '../../components/modals/DeleteModal';
import { IGoogleReview } from './GoogleReview';

type Props = {
  data: Array<IGoogleReview>;
  selected?: IGoogleReview;
  setSelected: (item: IGoogleReview) => void;
  handleDelete: (item: IGoogleReview) => void;
};

const GoogleReviewsTable = ({
  data,
  selected,
  setSelected,
  handleDelete,
}: Props) => {
  return (
    <React.Fragment>
      <DeleteModal item={selected} handleDelete={handleDelete}>
        <div>
          <p>{`Are you sure you want to delete this item? This action is irreversible.`}</p>
          <p className="block fw-bolder">{selected?.review_id}</p>
        </div>
      </DeleteModal>

      <table className="table table-row-gray-300 gy-7">
        <thead>
          <tr className="fw-bolder fs-6 text-gray-800">
            <th>Reviewer</th>
            <th>Comment</th>
            <th>Star Rating</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, idx) => (
            <tr key={idx} className="align-middle">
              <td>{el.reviewer['displayName']}</td>
              <td>{el.comment}</td>
              <td>{el.star_rating}</td>
              <td className="text-end">
                {/* begin::Dropdown */}
                {/* <ActionsDropdown>
                  <div className="separator mt-3 opacity-75"></div>
                  <div className="menu-item px-3">
                    <Link
                      to={`feedbacks/${el.id}/update`}
                      className="menu-link px-3"
                    >
                      Update
                    </Link>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      onClick={() => setSelected(el)}
                      href="#"
                      className="menu-link px-3 text-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#custom_delete_kt_modal_1"
                    >
                      Delete
                    </a>
                  </div>
                </ActionsDropdown> */}
                {/* end::Dropdown */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default GoogleReviewsTable;
