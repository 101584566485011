import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeLeftDescriptionFloatingWindowContent } from './HomeLeftDescriptionFloatingWindowContent';
import { HomeLeftDescriptionFloatingWindowFigure } from './HomeLeftDescriptionFloatingWindowContentFigure';
import { HomeLeftDescriptionWithFloatingWindowElement } from './HomeLeftDescriptionFloatingWindowElement';

interface Props {
  index: number;
  data: HomeLeftDescriptionWithFloatingWindowElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeLeftDescriptionFloatingWindowFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (
    fieldsToUpdate: Partial<HomeLeftDescriptionWithFloatingWindowElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-image-floating-left-description">
          <div className="showcase">
            <HomeLeftDescriptionFloatingWindowContent
              data={data}
              update={update}
            />
            <HomeLeftDescriptionFloatingWindowFigure
              data={data}
              update={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
