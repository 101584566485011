import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IPackageType } from '../models/PackageType';

export const BASE_PACKAGE_TYPE_URL = `${API_URL}/v1/content/package-types`;
export const GET_ALL_PACKAGE_TYPE_URL = `${API_URL}/v1/content/package-types/get-all`;
export const CREATE_PACKAGE_TYPE_URL = `${API_URL}/v1/content/package-types/create`;

export const listPackageType = async (search = '', direction = 'desc') => {
  const URL = `${BASE_PACKAGE_TYPE_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPackageType> }>(URL);

  return value;
};

export const createPackageType = async (payload: IPackageType) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPackageType }>(
    CREATE_PACKAGE_TYPE_URL,
    payload
  );

  return value;
};

export const viewPackageType = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPackageType }>(
    `${BASE_PACKAGE_TYPE_URL}/${id}/get`
  );

  return value;
};

export const updatePackageType = async (payload: IPackageType) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPackageType }>(
    `${BASE_PACKAGE_TYPE_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deletePackageType = async (payload: IPackageType) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPackageType }>(
    `${BASE_PACKAGE_TYPE_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated Package types
export const getAllPackageType = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IPackageType> }>(GET_ALL_PACKAGE_TYPE_URL);

  return value;
};
