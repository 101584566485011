import React from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { MediaVideo } from './MediaVideo';
import { MediaVideoContent } from './MediaVideoContent';
import { MediaVideoFigure } from './MediaVideoFigure';

interface Props {
  index: number;
  data: MediaVideo;
  updateData: (body: IElement, index: number) => void;
}

export const MediaVideoFC: React.FC<Props> = ({ index, data, updateData }) => {
  const update = (to: Partial<MediaVideo>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="media-video">
        <MediaVideoContent data={data} update={update} />
        <MediaVideoFigure data={data} update={update} />
      </div>
    </ElementWrapper>
  );
};
