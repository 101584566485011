import React from "react";
import { ElementWrapper } from "../../../../components/ElementWrapper";
import { IElement } from "../../../../models/Element";
import { HomeThreeIconsHeadingsAndDescriptionNarrowContent } from "./HomeThreeIconsHeadingsAndDescriptionNarrowContent";
import { HomeThreeIconsHeadingsAndDescriptionsNarrowElement } from "./HomeThreeIconsHeadingsAndDescriptionsNarrowElement";

interface Props {
  index: number;
  data: HomeThreeIconsHeadingsAndDescriptionsNarrowElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeThreeIconsHeadingsAndDescriptionsNarrowFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (
    fieldsToUpdate: Partial<HomeThreeIconsHeadingsAndDescriptionsNarrowElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-three-icons-headings-and-descriptions-narrow">
        <div className="inner">
          <HomeThreeIconsHeadingsAndDescriptionNarrowContent
            data={data}
            update={update}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
