import React from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { HomeRightDescriptionWithFloatingWindowElement } from './HomeRightDescriptionFloatingWindowElement';

interface Props {
  data: HomeRightDescriptionWithFloatingWindowElement;
  update: (
    fieldsToUpdate: Partial<HomeRightDescriptionWithFloatingWindowElement>
  ) => void;
}

export const HomeRightDescriptionFloatingWindowFloatingText: React.FC<
  Props
> = ({ data, update }) => {
  return (
    <div className="showcase__cta">
      <div className="heading__cta">
        <TextEditor
          content={data.floating_text}
          updateContent={(floating_text) => update({ floating_text })}
        />
      </div>
    </div>
  );
};
