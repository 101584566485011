/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import { KTSVG } from '../../../../_start/helpers';
import { useDebounce } from '../../../hooks/useDebounce';

import { failureToast, handlePaginate, PaginatedValue, successToast } from '../../../support/utils';
import { defaultMedia, IMedia } from '../models/Media';
import { Pagination } from '../../../components/Pagination';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { deleteSvg, listSvg, updateSvg } from '../redux/SvgLibraryCRUD';
import { UpdateMediaPayload } from '../redux/MediaLibraryCRUD';
import { UploadSvgModal } from '../components/svg/UploadSvgModal';
import { MediaInlineCustomPropertyInput } from '../components/MediaCustomPropertyInput';
import { ActionsDropdown } from '../../../components/ActionsDropdown';

export const SvgLibraryPage = () => {
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  /**
   * SEARCH
   */
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listSvg(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  /**
   * DELETE
   */
  const [selected, setSelected] = useState<IMedia>(defaultMedia);

  const handleDelete = async (item: IMedia) => {
    setLoading(true);
    try {
      await deleteSvg(item);
      successToast('SVG has been deleted.');
      setLoading(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  /**
   * PAGINATION
   */
  const [paginatedValue, setPaginatedValue] = useState<PaginatedValue<IMedia>>(
    new PaginatedValue()
  );

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IMedia>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  /**
   * INITIALIZATION
   */
  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listSvg();
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();
      setInitialized(true);
    }
  }, []);

  const handleUpdate = async (media: IMedia) => {
    const payload: UpdateMediaPayload = {
      id: media.id,
      name: media.name,
      alt_text: media.custom_properties.alt_text ?? '',
      caption: media.custom_properties.caption ?? '',
    };

    try {
      await updateSvg(payload);
      successToast('SVG has been updated.');
    } catch (error) {
      failureToast(error);
    }
  };

  return (
    <>
      <UploadSvgModal handleFetchList={handleFetchList} />
      <DeleteModal headerTitle="Delete File" item={selected} handleDelete={handleDelete} />
      <div className={`card card-custom card-flush ${loading ? 'overlay overlay-block' : ''}`}>
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${'' ? 'w-xxl-600px' : 'w-xxl-350px'}`}
              placeholder="Zoeken"
            />
          </div>
          <div className="card-toolbar">
            <a
              className="btn btn-sm btn-success"
              data-bs-toggle="modal"
              data-bs-target="#custom_upload_kt_modal_1"
            >
              Add new
            </a>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Preview</th>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedValue.data.map((element, idx) => (
                <tr key={idx} className="align-middle">
                  <td>
                    {element.mime_type === 'application/pdf' ||
                    element.mime_type === 'application/zip' ? (
                      <KTSVG path="/media/icons/duotone/Files/File.svg" className="svg-icon-5tx" />
                    ) : (
                      <img
                        alt={element.name}
                        src={element.original_url as string}
                        style={{
                          width: '150px',
                          height: '150px',
                          objectFit: 'cover',
                        }}
                      />
                    )}
                  </td>
                  <td>
                    <MediaInlineCustomPropertyInput
                      name={`name`}
                      value={element.name}
                      media={element}
                      handleUpdate={handleUpdate}
                    />
                  </td>
                  <td className="text-end">
                    {/* begin::Dropdown */}
                    <ActionsDropdown>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(element)}
                          href="#"
                          className="menu-link px-3 text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_delete_kt_modal_1"
                        >
                          Delete
                        </a>
                      </div>
                      <div className="separator mt-3 opacity-75"></div>
                    </ActionsDropdown>
                    {/* end::Dropdown */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};
