import React, { useRef } from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextEditor } from '../../../../../components/TextEditor';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';
import {
  defaultFeature,
  Feature,
  PluginExtraFeatures,
} from './PluginExtraFeatures';
import { PluginExtraFeaturesFigure } from './PluginExtraFeaturesFigure';

interface Props {
  index: number;
  data: PluginExtraFeatures;
  updateData: (element: IElement, index: number) => void;
}

export const PluginExtraFeaturesFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<PluginExtraFeatures>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const updateFeature = (index: number, feature: Partial<Feature>) => {
    const features = [...data.features];
    features[index] = { ...features[index], ...feature };
    update({ features });
  };

  const addFeature = () => {
    const features = [...data.features];
    features.push(defaultFeature);
    update({ features });
  };

  const removeFeature = (idx: number) => {
    const features = [...data.features];
    features.splice(1, 1);
    update({ features });
  };

  const addItemRef = useRef<HTMLLIElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = 'pointer';
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLUListElement>) => {
    addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = 'auto';
  };

  const removeItemRef = useRef<HTMLSpanElement>(null);

  return (
    <ElementWrapper elementIndex={index}>
      <div className="plugin-extra-features">
        <HeaderSelectWrapper
          name={`heading_type`}
          value={data.heading_type ?? ElementHeaderType.H2}
          setValue={update}
        >
          <TextAreaEdit
            className="heading"
            name="heading"
            value={data.heading}
            setValue={update}
          />
        </HeaderSelectWrapper>

        <ul
          className="cards"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {data.features.map((feature, idx) => (
            <li className="card" key={idx}>
              <span
                ref={removeItemRef}
                onClick={() => removeFeature(idx)}
                className="position-absolute top-0 end-0 mt-n3 me-n3 opacity-75-hover"
              >
                <KTSVG
                  path="/media/icons/duotone/Interface/Minus-Square.svg"
                  className="svg-icon-danger svg-icon-1"
                />
              </span>
              <PluginExtraFeaturesFigure
                index={idx}
                data={feature}
                plugin={data}
                update={(to) => updateFeature(idx, to)}
              />

              <HeaderSelectWrapper
                name={`heading_type`}
                value={feature.heading_type ?? ElementHeaderType.H3}
                setValue={(newVal) => updateFeature(idx, newVal)}
              >
                <TextAreaEdit
                  className="card__heading"
                  name={'heading'}
                  value={feature.heading}
                  setValue={(newVal) => updateFeature(idx, newVal)}
                />
              </HeaderSelectWrapper>

              <div className="description">
                <TextEditor
                  content={feature.description}
                  updateContent={(description) =>
                    updateFeature(idx, { description })
                  }
                />
              </div>

              <div className="content-action-container">
                <div className="content-action">
                  {/*  start::Primary Button  */}
                  <button className="btn btn-success rounded-pill">
                    <InlineEdit
                      name="button_primary_title"
                      className="px-1 text-white text-center"
                      value={feature.button_primary_title}
                      setValue={(val) => updateFeature(idx, val)}
                      activeVerticalPadding={'py-1'}
                      dynamicWidth
                      style={{
                        maxWidth: '22rem',
                      }}
                    />
                  </button>

                  {/*  end::Primary Button  */}
                </div>

                <div className="content-action">
                  {/*  start::Secondary Button  */}

                  <button className="btn btn-success rounded-pill">
                    <InlineEdit
                      name="button_secondary_title"
                      className="px-1 text-white text-center"
                      value={feature.button_secondary_title}
                      setValue={(val) => updateFeature(idx, val)}
                      activeVerticalPadding={'py-1'}
                      dynamicWidth
                      style={{
                        maxWidth: '22rem',
                      }}
                    />
                  </button>

                  {/*  end::Secondary Button  */}
                </div>
              </div>
            </li>
          ))}
          <li
            ref={addItemRef}
            onClick={addFeature}
            style={{
              minHeight: '263px',
            }}
            className="d-none w-100 h-100 d-flex rounded border-light-400 border-dashed border-2 opacity-75-hover"
          >
            <div className="m-auto d-flex flex-column" style={{ zIndex: 1 }}>
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-3hx text-center"
              />
              <span className="mt-4">Add new item</span>
            </div>
          </li>
        </ul>
      </div>
    </ElementWrapper>
  );
};
