import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export interface AdvantageInfo {
  icon: string;
  icon_url: string;
  heading: string;
  tags: string[];
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  image: string;
  image_alt: string;
}

export const defaultAdvantageInfo: AdvantageInfo = {
  icon: `<svg class="rotate-90" data-src="assets/icons/icon-phone.svg" aria-hidden="true" data-cache="604800" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-id="svg-loader_75"><path d="m493.4 24.6-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>`,
  icon_url: '',
  heading: 'VoIP telefonie',
  tags: ['OP MAAT', 'PER DAG OPZEGBAAR', 'VANAF € 2,- P/M'],
  description: `<p>Bellen via VoIP zonder gedoe. Volledig op maat en bespaar ook tot 40% op reguliere zakelijke KPN tarieven.</p`,
  button_title: 'Lees meer',
  button_url: '',
  button_url_is_external: false,
  image: '',
  image_alt: '',
};

export type HomeAdvantage = IElement & {
  heading: string;
  heading_type: ElementHeaderType;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  advantages: AdvantageInfo[];
};

export const defaultHomeAdvantage: HomeAdvantage = {
  id: 0,
  name: ElementType.HOME_ADVANTAGES,
  heading: 'Check onze voordelen.',
  heading_type: ElementHeaderType.H2,
  description: `<p>Wel verbonden, niet gebonden.</p>`,
  button_title: 'Offerte vragen',
  button_url: '',
  button_url_is_external: false,
  advantages: [],
};
