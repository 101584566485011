import React from 'react';
import { KTSVG } from '../../../../../../../_start/helpers';
import { AttachSvgModal } from '../../../../components/AttachSvgModal';
import { AdvantageInfo, HomeAdvantage } from './HomeAdvantages';

interface Props {
  idx: number;
  data: HomeAdvantage;
  item: AdvantageInfo;
  updateData: (to: Partial<AdvantageInfo>) => void;
}

export const HomeAdvantagesIcons: React.FC<Props> = ({
  idx,
  data,
  item,
  updateData,
}) => {
  return (
    <>
      <AttachSvgModal
        modalId={`home-advantages-${idx}-${data.id}-icon`}
        updateCallback={(icon_url) => updateData({ icon_url })}
      />
      <div className="icon-holder">
        <div className="overlay">
          <div className="overlay-layer bg-dark bg-opacity-10 edit-icon">
            <label
              className="px-1 btn btn-sm btn-icon btn-color-primary btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target={`#home-advantages-${idx}-${data.id}-icon`}
            >
              <KTSVG
                path="/media/icons/duotone/General/Edit.svg"
                className="svg-icon-1"
              />
            </label>
          </div>
          <div>
            {item.icon_url ? (
              <img className="icon" src={item.icon_url} alt="Uploaded" />
            ) : (
              <KTSVG path={item.icon ?? ''} svgClassName="icon" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
