export interface ISendQuote {
  package_id: number;

  free_products: Array<number>;
  products: Array<number>;

  voip_accounts: number;
  user_accounts: number;

  new_phone_numbers: number;
  existing_phone_numbers: number;

  company_name: string;
  company_email: string;

  firstname: string;
  email: string;
  phone_number: string;
}

export const defaultSendQuote: ISendQuote = {
  package_id: 0,

  free_products: [],
  products: [],

  voip_accounts: 0,
  user_accounts: 0,

  new_phone_numbers: 0,
  existing_phone_numbers: 0,

  company_name: '',
  company_email: '',

  firstname: '',
  email: '',
  phone_number: '',
};
