import React from "react";
import { TextEditor } from "../../../../../components/TextEditor";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { HeaderSelectWrapper } from "../../../components/HeaderSelectWrapper";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { IElement } from "../../../models/Element";
import { TableThreeColumns } from "./TableThreeColumns";
import { TableThreeColumnsTable } from "./TableThreeColumnsTable";

interface Props {
  index: number;
  data: TableThreeColumns;
  updateData: (body: IElement, index: number) => void;
}

export const TableThreeColumnsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<TableThreeColumns>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="table-two-columns">
        <HeaderSelectWrapper
          name={"header_type"}
          value={data.header_type}
          setValue={update}
        >
          <TextAreaEdit
            className="heading"
            name={"header"}
            value={data.header}
            setValue={update}
          />
        </HeaderSelectWrapper>

        <TableThreeColumnsTable data={data} update={update} />

        <div className="content">
          <TextEditor
            content={data.content}
            updateContent={(content) => update({ content })}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
