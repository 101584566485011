import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export type ProjectHighlights = IElement & {
  title: string;
  title_type: ElementHeaderType;
  highlights: Array<IHighlight>;
};

export interface IHighlight {
  id: number;
  label_start: string;
  label_end: string;
  title: string;
  description: string;
}

export const defaultHighlight: IHighlight = {
  id: 0,
  label_start: 'Strategie',
  label_end: 'Marketing',
  title: 'Benchmark',
  description:
    '<p>5.8 procent van de bezoekers bestelt direct een product op de site. De benchmark ligt op 3.1 procent.</p>',
};

export const defaultProjectHighlights: ProjectHighlights = {
  id: 0,
  name: ElementType.PROJECT_HIGHLIGHTS,
  title: 'Project highlights',
  title_type: ElementHeaderType.H3,
  highlights: [defaultHighlight],
};

export enum ProjectHighlightsItemTypes {
  HIGHLIGHT = 'highlight',
}
