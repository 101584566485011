import React from 'react';
import { AttachSvgModal } from '../../../components/AttachSvgModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { ContactsOtherInfoWithIconsContent } from './ContactsOtherInfoWithIconsContent';
import {
  ContactsOtherInfoWithIconsElement,
  IconAndDescription,
} from './ContactsOtherInfoWithIconsElement';
import { ContactsOtherInfoWithIconsUSP } from './ContactsOtherInfoWithIconsUSP';

interface Props {
  index: number;
  data: ContactsOtherInfoWithIconsElement;
  updateData: (element: IElement, index: number) => void;
}

export const ContactsOtherInfoWithIconsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<ContactsOtherInfoWithIconsElement>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const updateIcon = (to: Partial<IconAndDescription>, idx: number) => {
    const items = [...data.icons_descriptions];
    items[idx] = { ...items[idx], ...to };
    update({ ...data, icons_descriptions: items });
  };
  return (
    <>
      {
        // different approach since having this element inside icon component,
        // styling issue arises and modal attach feature is not possible
        data.icons_descriptions.map((item, idx) => (
          <AttachSvgModal
            key={idx}
            modalId={`contacts-other-info-with-icons-${idx}-${data.id}`}
            updateCallback={(icon_url) => updateIcon({ icon_url }, idx)}
          />
        ))
      }

      <ElementWrapper elementIndex={index}>
        <div className="contacts-other-info-with-icons">
          <div className="grid">
            <ContactsOtherInfoWithIconsContent data={data} update={update} />
            <ContactsOtherInfoWithIconsUSP data={data} update={update} />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
