import {
  defaultPostPlaceholder,
  IPost,
} from '../../../../post-management/models/Post';
import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export type BlogFeatured = IElement & {
  title: string;
  title_type: ElementHeaderType;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  featured_posts: Array<IPost>;
};

export const defaultBlogFeatured: BlogFeatured = {
  id: 0,
  name: ElementType.BLOG_FEATURED,
  title: 'Bekijk ons laatste nieuws',
  title_type: ElementHeaderType.H2,
  button_title: 'Meer artikelen',
  button_url: '',
  button_url_is_external: false,
  featured_posts: [defaultPostPlaceholder],
};
