/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { IMedia } from "../models/Media";

type MediaInlineCustomPropertyInputProps = {
	name: string;
	value: string;
	media: IMedia;
	handleUpdate: (media: IMedia) => void;
	className?: string;
	style?: React.CSSProperties;
	activeVerticalPadding?: string;
};

export const MediaInlineCustomPropertyInput: React.FC<
	MediaInlineCustomPropertyInputProps
> = ({
	name,
	value,
	media,
	handleUpdate,
	className = "",
	style,
	activeVerticalPadding = "py-2",
	...rest
}) => {
	const allClassNames = `inline-edit-input ${className}`;

	const inputRef = useRef<HTMLInputElement>(null);

	const [editingValue, setEditingValue] = useState(value);

	useEffect(() => {
		if (value !== editingValue) {
			setEditingValue(value);
		}
	}, [value]);

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEditingValue(event.target.value);
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		if (event.key === "Enter" || event.key === "Escape") {
			target.blur();
		}
	};

	const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		if (event.target.value === value) {
			setEditingValue(value);
		} else {
			// setValue({ [event.target.name]: event.target.value });
			handleUpdate({
				...media,
				custom_properties: {
					...media.custom_properties,
					[event.target.name]: event.target.value,
				},
			});
		}
		inputRef.current?.classList.remove(activeVerticalPadding);
	};

	const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		inputRef.current?.classList.add(activeVerticalPadding);
	};

	return (
		<input
            placeholder="Enter text"
			name={name}
			ref={inputRef}
			className={allClassNames}
			style={style}
			{...rest}
			type="text"
			aria-label="Field name"
			value={editingValue}
			onChange={onChange}
			onKeyDown={onKeyDown}
			onBlur={onBlur}
			onFocus={onFocus}
		/>
	);
};
