import axios from "axios";
import { API_URL } from "../../../../../support/api";
import { IFAQ } from "../../../../content-management/models/FAQ";
import { IFAQCategory } from "../../../../content-management/models/FAQCategory";
import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export const GET_ALL_FAQ_URL = `${API_URL}/v1/faq-management/get-all`;

export type FAQSelect = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  category?: IFAQCategory;
  item_list: Array<IFAQ>;
};

export const defaultFAQItem: IFAQ = {
  id: -1,
  title: "",
  title_description: "",
  description: "",
  url: "",
  button_title: "Insert title",
};

export const defaultFAQSelect: FAQSelect = {
  id: 0,
  name: ElementType.FAQSELECT,
  heading: "Veel gestelde vragen over SEO",
  description: "Bellen en gebeld worden",
  item_list: [],
};

export const getAllFAQs = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IFAQ> }>(GET_ALL_FAQ_URL);

  return value;
};
