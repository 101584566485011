import { useState, useEffect } from 'react';
import { PluginPartners } from './PluginPartners';
import { PluginPartnersCarousel } from './PluginPartnersCarousel';
import { PluginPartnersContent } from './PluginPartnersContent';

import immutabilityHelper from 'immutability-helper';
import { IElement } from '../../../models/Element';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { IPartner } from '../../../../client-management/models/Partner';
import { setPartners } from '../../../redux/EditContentActions';

type HomeEmployeesFCProps = {
  index: number;
  data: PluginPartners;
  updateData: (body: IElement, index: number) => void;
};

export const PluginPartnersFC = ({
  index,
  data,
  updateData,
}: HomeEmployeesFCProps) => {
  const dispatch = useDispatch();

  /**
   * Initialization
   */
  const allModels = useSelector(
    (state: RootState) => state.editContent.allPartners
  );
  const partners = useSelector(
    (state: RootState) => state.editContent.partners
  );

  const [selectedMap, setSelectedMap] = useState<Map<number, IPartner>>(
    new Map()
  );

  useEffect(() => {
    if (allModels.length) {
      const iterable: Array<[number, IPartner]> = partners.map((element) => [
        element.id,
        element,
      ]);

      setSelectedMap(new Map(iterable));
    }
  }, [allModels, partners]);

  const update = (fieldsToUpdate: Partial<PluginPartners>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  const addPartner = (selected: IPartner) => {
    // since previous implementation does not include "featured_partner" property then this value is undefined
    const partners = immutabilityHelper(data.featured_partners ?? [], {
      $push: [selected],
    });

    const updatedData = { ...data, featured_partners: partners };
    updateData(updatedData, index);

    dispatch(setPartners(updatedData.featured_partners));
  };

  const removePartner = (itemIndex: number) => {
    let partners = immutabilityHelper(data.featured_partners, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, featured_partners: partners };
    updateData(updatedData, index);

    dispatch(setPartners(updatedData.featured_partners));
  };

  const updatePartner = (itemIndex: number, partner: IPartner) => {
    let partners = [...data.featured_partners];

    partners[itemIndex] = partner;

    const updatedData: PluginPartners = {
      ...data,
      featured_partners: partners,
    };

    updateData(updatedData, index);

    dispatch(setPartners(updatedData.featured_partners));
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="plugin-partners body-my-8">
        <PluginPartnersContent data={data} update={update} />
        <PluginPartnersCarousel
          index={index}
          data={data}
          updateData={updateData}
          addPartner={addPartner}
          removePartner={removePartner}
          updatePartner={updatePartner}
          allModels={allModels}
          selectedMap={selectedMap}
        />
      </div>
    </ElementWrapper>
  );
};
