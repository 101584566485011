import axios from "axios";
import { ElementType, IElement } from "../../../models/Element";
import { API_URL } from "../../../../../support/api";

export const GET_ALL_FAQ_URL = `${API_URL}/v1/faq-management/get-all`;

export interface QuestionItem {
  id: number;
  title: string;
  title_description: string;
  description: string;
  url: string;
  slug: string;
}

export type FAQ2 = IElement & {
  heading: string;
  description: string;
  item_list: Array<QuestionItem>;
};

export const defaultQuestionItem: QuestionItem = {
  id: 0,
  title: "Loading...",
  title_description: "Loading...",
  description: "Loading...",
  url: "",
  slug: "",
};

export const defaultFAQ2: FAQ2 = {
  id: 0,
  name: ElementType.FAQ2,
  heading: "Veel gestelde vragen over SEO",
  description: "Bellen en gebeld worden",
  item_list: [defaultQuestionItem],
};

export const getAllFAQs = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<QuestionItem> }>(GET_ALL_FAQ_URL);

  return value;
};
