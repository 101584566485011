import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { CreateDepartment } from "./pages/department/CreateDepartment";
import { DepartmentsPage } from "./pages/department/DepartmentsPage";
import { UpdateDepartment } from "./pages/department/UpdateDepartment";
import { CreateEmployee } from "./pages/employee/CreateEmployee";
import { EmployeesPage } from "./pages/employee/EmployeesPage";
import { UpdateEmployee } from "./pages/employee/UpdateEmployee";

export const EmployeeManagementPage = () => {
	return (
		<Switch>
			{/* begin::Employee Routes */}
			<Route exact={true} path="/employee-management/employees">
				<>
					<PageTitle>Personeel</PageTitle>
					<EmployeesPage />
				</>
			</Route>
			<Route exact={true} path="/employee-management/employees/create">
				<>
					<PageTitle>Personeel</PageTitle>
					<CreateEmployee />
				</>
			</Route>
			<Route
				exact={true}
				path="/employee-management/employees/:id/update"
			>
				<>
					<PageTitle>Personeel</PageTitle>
					<UpdateEmployee />
				</>
			</Route>
			{/* end::Employee Routes */}

			{/* begin::Employee Routes */}
			<Route exact={true} path="/employee-management/departments">
				<>
					<PageTitle>Afdelingen</PageTitle>
					<DepartmentsPage />
				</>
			</Route>
			<Route exact={true} path="/employee-management/departments/create">
				<>
					<PageTitle>Afdelingen</PageTitle>
					<CreateDepartment />
				</>
			</Route>
			<Route
				exact={true}
				path="/employee-management/departments/:id/update"
			>
				<>
					<PageTitle>Afdelingen</PageTitle>
					<UpdateDepartment />
				</>
			</Route>
			{/* end::Employee Routes */}

			<Redirect
				from="/employee-management"
				exact={true}
				to="/employee-management/employees"
			/>
			<Redirect to="/employee-management/employees" />
		</Switch>
	);
};
