import immutabilityHelper from 'immutability-helper';
import React from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { defaultImagePreview, MediaSlider } from './MediaSlider';
import { MediaSliderContent } from './MediaSliderContent';
import { MediaSliderFigure } from './MediaSliderFigure';

interface Props {
  index: number;
  data: MediaSlider;
  updateData: (body: IElement, index: number) => void;
}

export const MediaSliderFC: React.FC<Props> = ({ index, data, updateData }) => {
  const update = (to: Partial<MediaSlider>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const addImage = () => {
    const image_previews = immutabilityHelper(data.image_previews, {
      $push: [defaultImagePreview],
    });

    const updatedData = { ...data, image_previews };
    updateData(updatedData, index);
  };

  const removeImage = (itemIndex: number) => {
    let image_previews = immutabilityHelper(data.image_previews, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, image_previews };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="media-carousel">
        <MediaSliderContent data={data} update={update} />
        <MediaSliderFigure
          data={data}
          update={update}
          addImage={addImage}
          removeImage={removeImage}
        />
      </div>
    </ElementWrapper>
  );
};
