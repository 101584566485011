import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../../_start/helpers';
import { TextEditor } from '../../../../../../components/TextEditor';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { HeaderSelectWrapper } from '../../../../components/HeaderSelectWrapper';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';
import { HomeReviewsSingle } from './HomeReviewsSingle';
import { HomeReviewsSingleFigure } from './HomeReviewsSingleFigure';
import { HomeReviewsSingleList } from './HomeReviewsSingleList';

interface Props {
  index: number;
  data: HomeReviewsSingle;
  updateData: (element: IElement, index: number) => void;
}

export const HomeReviewsSingleFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<HomeReviewsSingle>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-reviews-single">
          <div className="reviews__header">
            <div className="reviews__content">
              <HeaderSelectWrapper
                name={`heading_type`}
                value={data.heading_type ?? ElementHeaderType.H2}
                setValue={update}
              >
                <TextAreaEdit
                  className="reviews__heading"
                  name={'heading'}
                  value={data.heading}
                  setValue={update}
                />
              </HeaderSelectWrapper>

              <div className="reviews__description">
                <TextEditor
                  content={data.description}
                  updateContent={(description) => update({ description })}
                />
              </div>
              <div className="reviews__rating">
                <div className="rating__stars">
                  {[...Array(5)].map((x, i) => (
                    <div key={i}>
                      <KTSVG
                        path={toAbsoluteUrl(
                          '/assets/icons/icon-star-solid.svg'
                        )}
                        className="icon"
                      />
                    </div>
                  ))}
                  <div className="rating__score">{`(* Total Reviews *)`}</div>
                </div>
              </div>
            </div>
            <HomeReviewsSingleFigure data={data} update={update} />
          </div>
          <div className="reviews__container">
            <HomeReviewsSingleList data={data} update={update} />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
