import immutabilityHelper from 'immutability-helper';
import React from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextEditor } from '../../../../../components/TextEditor';
import {
  defaultEmployee,
  IEmployee,
} from '../../../../employee-management/models/Employee';
import { AttachSvgModal } from '../../../components/AttachSvgModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { TeamDescription, TeamEmployees } from './TeamEmployees';
import { TeamEmployeesIcon } from './TeamEmployeesIcon';
import { TeamEmployeesItem } from './TeamEmployeesItem';
import { TeamEmployeesItemWrapper } from './TeamEmployeesItemWrapper';

interface Props {
  index: number;
  data: TeamEmployees;
  updateData: (body: IElement, index: number) => void;
}

export const TeamEmployeesFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<TeamEmployees>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const updateIcon = (icon: string) => {
    const team_description = { ...data.team_description, icon };
    update({ team_description });
  };

  const updateDescription = (to: Partial<TeamDescription>) => {
    const team_description = { ...data.team_description, ...to };
    update({ team_description });
  };

  const updateEmployee = (employee: IEmployee, idx: number) => {
    let employees = [...data.employees];

    const prevEmployee = employees[idx];
    employees[idx] = {
      ...prevEmployee,
      name: employee.name,
      gif: employee.gif,
      department: employee.department,
    };

    const updatedData: TeamEmployees = {
      ...data,
      employees,
    };

    update(updatedData);
  };

  const addEmployee = () => {
    const employees = immutabilityHelper(data.employees, {
      $push: [defaultEmployee],
    });

    const updatedData = { ...data, employees };
    updateData(updatedData, index);
  };

  const removeEmployee = (itemIndex: number) => {
    let employees = immutabilityHelper(data.employees, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, employees };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <AttachSvgModal
        modalId={`team-employees-${data.id}`}
        updateCallback={(icon) => updateIcon(icon)}
      />
      <div className="team-employees">
        {/** Content */}
        <div className="content">
          <InlineEdit
            className="title"
            name={'title'}
            value={data.title}
            setValue={update}
          />

          <HeaderSelectWrapper
            name={'heading_type'}
            value={data.heading_type}
            setValue={update}
          >
            <TextAreaEdit
              className="heading"
              name={'heading'}
              value={data.heading}
              setValue={update}
            />
          </HeaderSelectWrapper>
        </div>

        {/** Employees */}
        <div className="employees">
          {data.employees.map((employee, idx) => (
            <TeamEmployeesItemWrapper
              employee={employee}
              index={idx}
              updateEmployee={updateEmployee}
            >
              <TeamEmployeesItem
                teamEmployees={data}
                employee={employee}
                index={idx}
                removeEmployee={removeEmployee}
              />
            </TeamEmployeesItemWrapper>
          ))}

          <div onClick={addEmployee} className="add-item">
            <div className="m-auto d-flex flex-column">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-muted svg-icon-3hx text-center"
              />
              <span className="mt-4 text-gray-500">Add</span>
            </div>
          </div>
        </div>

        {/** Department */}
        <div className="department">
          <TeamEmployeesIcon data={data} update={update} />
          <div className="content">
            <TextAreaEdit
              className="heading"
              name={'title'}
              value={data.team_description.title}
              setValue={(val) => updateDescription({ title: val.title })}
            />

            <div className="description">
              <TextEditor
                content={data.team_description.description}
                updateContent={(description) =>
                  updateDescription({ description })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
