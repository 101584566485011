import { PageDataContainer, PageLink } from "../../../_start/layout/core";
import { noticeManagementSubmenu } from "./NoticeManagementData";
import { NoticeManagementPage } from "./NoticeManagementPage";

const breadcrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/",
    isActive: false,
  },
  {
    title: "Notice Management",
    path: "/notice-management",
    isActive: false,
  },
];

export const NoticeManagementWrapper = () => {
  return (
    <>
      <PageDataContainer
        breadcrumbs={breadcrumbs}
        submenu={noticeManagementSubmenu}
      />
      <NoticeManagementPage />
    </>
  );
};
