import React from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { HeaderSelectWrapper } from '../../../../components/HeaderSelectWrapper';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { ElementHeaderType } from '../../../utils';
import {
  HomeImageLeftDescriptionFloatingWindowIconsElement,
  IconAndTitle,
} from './HomeImageLeftDescriptionFloatingWindowIconsElement';
import { HomeImageLeftDescriptionFloatingWindowIconsIcon } from './HomeImageLeftDescriptionFloatingWindowIconsIcon';

interface Props {
  data: HomeImageLeftDescriptionFloatingWindowIconsElement;
  update: (
    fieldsToUpdate: Partial<HomeImageLeftDescriptionFloatingWindowIconsElement>
  ) => void;
}

export const HomeImageLeftDescriptionFloatingWindowIconsContent: React.FC<
  Props
> = ({ data, update }) => {
  const updateData = (to: Partial<IconAndTitle>, idx: number) => {
    const icons_titles = [...data.icons_titles];
    icons_titles[idx] = { ...icons_titles[idx], ...to };
    update({ ...data, icons_titles: icons_titles });
  };
  return (
    <div className="showcase__content">
      {/*begin::Heading*/}
      <HeaderSelectWrapper
        name={`heading_type`}
        value={data.heading_type ?? ElementHeaderType.H2}
        setValue={update}
      >
        <TextAreaEdit
          name="heading"
          className="heading"
          value={data.heading}
          setValue={update}
        />
      </HeaderSelectWrapper>
      {/*end::Heading*/}

      {/*begin::Description*/}
      <div className="description_input prose">
        <TextEditor
          content={data.description}
          updateContent={(description) => update({ description })}
        />
      </div>
      {/*end::Description*/}

      {/*begin::Icons*/}
      <div className="icons">
        {data.icons_titles &&
          data.icons_titles.map((item, idx) => (
            <div key={idx} className="d-flex u-align-center  u-gap-25">
              <HomeImageLeftDescriptionFloatingWindowIconsIcon
                idx={idx}
                data={data}
                item={item}
                updateData={updateData}
              />
              <TextAreaEdit
                name="title"
                className="title"
                value={item.title}
                setValue={(val) => updateData(val, idx)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
