import React from 'react';
import { KTSVG } from '../../../../../../../_start/helpers';
import { TextEditor } from '../../../../../../components/TextEditor';
import { ButtonTooltip } from '../../../../components/ButtonTooltip';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { HeaderSelectWrapper } from '../../../../components/HeaderSelectWrapper';
import { InlineEdit } from '../../../../components/InlineEdit';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';
import {
  AdvantageInfo,
  defaultAdvantageInfo,
  HomeAdvantage,
} from './HomeAdvantages';
import { HomeAdvantagesCard } from './HomeAdvantagesCard';

interface Props {
  index: number;
  data: HomeAdvantage;
  updateData: (element: IElement, index: number) => void;
}

export const HomeAdvantagesFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<HomeAdvantage>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const addCard = () => {
    const advantages = [...data.advantages];
    advantages.push({ ...defaultAdvantageInfo });
    update({ advantages });
  };

  const updateCard = (idx: number, item: AdvantageInfo) => {
    const advantages = [...data.advantages];
    advantages[idx] = { ...item };
    update({ advantages });
  };

  const removeCard = (idx: number) => {
    const advantages = [...data.advantages];
    advantages.splice(idx, 1);
    update({ advantages });
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-advantages">
        <div className="content">
          <div className="header--content">
            <HeaderSelectWrapper
              name={`heading_type`}
              value={data.heading_type ?? ElementHeaderType.H2}
              setValue={update}
            >
              <TextAreaEdit
                className="heading"
                name={'heading'}
                value={data.heading}
                setValue={update}
              />
            </HeaderSelectWrapper>

            <div className="description">
              <TextEditor
                content={data.description}
                updateContent={(description) => update({ description })}
              />
            </div>
          </div>
          {/* begin::Action Button */}
          <div className="btn-container">
            <ButtonTooltip
              name={`button_url`}
              value={data.button_url}
              setValue={update}
              isCheckboxChecked={data.button_url_is_external}
              checkboxChanged={(v) => update({ button_url_is_external: v })}
            >
              <button className="btn rounded-pill content-button__inner">
                <InlineEdit
                  name="button_title"
                  className="px-1 text-white text-center text-primary-color"
                  value={data.button_title}
                  setValue={update}
                  activeVerticalPadding={'py-1'}
                  dynamicWidth
                  style={{
                    fontWeight: 600,
                    width: '200px',
                  }}
                />
              </button>
            </ButtonTooltip>
          </div>
          {/* end::Action Button */}
        </div>
        <div className="services-cards">
          {data.advantages.map((val, idx) => (
            <HomeAdvantagesCard
              index={idx}
              elementIndex={index}
              advantage={val}
              data={data}
              updateAdvantage={updateCard}
              removeAdvantage={removeCard}
            />
          ))}
          <div onClick={addCard} className="add-item">
            <div className="add-item__inner">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-muted svg-icon-3hx text-center"
              />
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
