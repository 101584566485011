import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export interface IProcess {
  id: number;
  label: string;
  heading: string;
  title: string;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
}

export const defaultProcess: IProcess = {
  id: 0,
  label: "Strategy",
  heading: "Step 0",
  title: "VoIP Telefonie",
  description:
    "<p>Bellen via VoIP zonder gedoe. Volledig op maat en bespaar ook tot 40% op reguliere zakelijke KPN tarieven.</p>",
  button_title: "Lees meer",
  button_url: "",
  button_url_is_external: false,
};

export type ProcessHighlight = IElement & {
  title: string;
  title_type: ElementHeaderType;
  description: string;
  processes: Array<IProcess>;
};

export const defaultProcessHighlight: ProcessHighlight = {
  id: 0,
  name: ElementType.PROCESS_HIGHLIGHT,
  title: "Het proces",
  title_type: ElementHeaderType.H2,
  description:
    "<p>Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Nulla vitae elit libero, a pharetra augue. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Lorem ipsum dolor sit.</p>",
  processes: [defaultProcess],
};

export enum ProcessHighlightItemTypes {
  PROCESS = "process",
}
