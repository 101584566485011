import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../../_start/helpers';
import { HeaderSelectWrapper } from '../../../../components/HeaderSelectWrapper';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { ElementHeaderType } from '../../../utils';
import { HomeCustomerReviews } from './HomeCustomerReviews';
import { HomeCustomerReviewsList } from './HomeCustomerReviewsList';

interface Props {
  data: HomeCustomerReviews;
  update: (to: Partial<HomeCustomerReviews>) => void;
}

export const HomeCustomerReviewsContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="reviews__content">
      <HeaderSelectWrapper
        name={`heading_type`}
        value={data.heading_type ?? ElementHeaderType.H2}
        setValue={update}
      >
        <TextAreaEdit
          className="heading"
          name={'heading'}
          value={data.heading}
          setValue={update}
        />
      </HeaderSelectWrapper>

      <div className="rating">
        <div className="rating__stars">
          {[...Array(5)].map((x, i) => (
            <div key={i}>
              <KTSVG
                path={toAbsoluteUrl('/assets/icons/icon-star-solid.svg')}
                className="icon"
              />
            </div>
          ))}
          <div className="rating__score">{`(* Total Reviews *)`}</div>
        </div>
      </div>
      <HomeCustomerReviewsList data={data} update={update} />
    </div>
  );
};
