import { PageLink } from "../../../_start/layout/core";

export const roleAndPermissionSubmenu: Array<PageLink> = [
  {
    title: "Roles",
    path: "/role-and-permission/roles",
    isActive: true,
  },
  {
    title: "Permissions",
    path: "/role-and-permission/permissions",
    isActive: true,
  },
];
