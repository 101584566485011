import React from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { TeamEmployees } from './TeamEmployees';

interface Props {
  data: TeamEmployees;
  update: (to: Partial<TeamEmployees>) => void;
}

export const TeamEmployeesIcon: React.FC<Props> = ({ data, update }) => {
  return (
    <>
      <div className="icon__outer">
        <div className="overlay">
          <div className="overlay-layer bg-dark bg-opacity-10">
            <label
              className="px-1 btn btn-sm btn-icon btn-color-primary btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target={`#team-employees-${data.id}`}
            >
              <KTSVG
                path="/media/icons/duotone/General/Edit.svg"
                className="svg-icon-1"
              />
            </label>
          </div>
          <div>
            <img
              className="icon"
              src={data.team_description.icon}
              alt="Uploaded"
            />
          </div>
        </div>
      </div>
    </>
  );
};
