import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../_start/helpers';
import { ActionsDropdown } from '../../../../components/ActionsDropdown';
import { DeleteModal } from '../../../../components/modals/DeleteModal';
import { Pagination } from '../../../../components/Pagination';
import { AppTable } from '../../../../components/table/AppTable';
import { Cell } from '../../../../components/table/Cell';
import { Head } from '../../../../components/table/Head';
import { Row } from '../../../../components/table/Row';
import { useDebounce } from '../../../../hooks/useDebounce';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from '../../../../support/utils';
import { defaultPartner, IPartner } from '../../models/Partner';
import { deletePartner, listPartner } from '../../redux/PartnerCRUD';

type PartnersPageProps = {
  actionRelativePath?: string;
};

export const PartnersPage = ({
  actionRelativePath = `partners`,
}: PartnersPageProps) => {
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  /**
   * INITIALIZATION
   */
  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listPartner();
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();
      setInitialized(true);
    }
  }, []);

  /**
   * SEARCH
   */
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listPartner(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  /**
   * DELETE
   */
  const [selected, setSelected] = useState<IPartner>(defaultPartner);

  const handleDelete = async (item: IPartner) => {
    setLoading(true);
    try {
      await deletePartner(item);
      successToast('Partner has been deleted.');
      setLoading(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  /**
   * PAGINATION
   */
  const [paginatedValue, setPaginatedValue] = useState<
    PaginatedValue<IPartner>
  >(new PaginatedValue());

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IPartner>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <>
      <DeleteModal
        headerTitle="Delete Partner"
        item={selected}
        handleDelete={handleDelete}
      />
      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                '' ? 'w-xxl-600px' : 'w-xxl-350px'
              }`}
              placeholder="Zoeken"
            />
          </div>
          <div className="card-toolbar">
            <Link
              className="btn btn-sm btn-light"
              to={`${actionRelativePath}/create`}
            >
              Create Partner
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <AppTable
            head={
              <thead>
                <Row className="fw-bolder fs-6 text-gray-800">
                  <Head>Name</Head>
                  <Head>Business Name</Head>
                  <Head></Head>
                </Row>
              </thead>
            }
            body={
              <tbody>
                {paginatedValue.data.map((element, idx) => (
                  <Row key={idx} className="align-middle">
                    <Cell>{element.name}</Cell>
                    <Cell>{element.business_name}</Cell>
                    <Cell className="text-end">
                      {/* begin::Dropdown */}
                      <ActionsDropdown>
                        <div className="menu-item px-3">
                          <Link
                            to={`${actionRelativePath}/${element.id}/update`}
                            className="menu-link px-3"
                          >
                            Update
                          </Link>
                        </div>
                        <div className="menu-item px-3">
                          <a
                            onClick={() => setSelected(element)}
                            href="#"
                            className="menu-link px-3 text-danger"
                            data-bs-toggle="modal"
                            data-bs-target="#custom_delete_kt_modal_1"
                          >
                            Delete
                          </a>
                        </div>
                        <div className="separator mt-3 opacity-75"></div>
                      </ActionsDropdown>
                      {/* end::Dropdown */}
                    </Cell>
                  </Row>
                ))}
              </tbody>
            }
          />
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};
