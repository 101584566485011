import React from 'react';
import { AttachMediaModal } from '../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { ContactsImageRightDescriptionContent } from './ContactsImageRightDescriptionContent';
import { ContactsImageRightDescriptionElement } from './ContactsImageRightDescriptionElement';
import { ContactsImageRightDescriptionFigure } from './ContactsImageRightDescriptionFigure';

interface ContactsImageRightDescriptionProps {
  index: number;
  data: ContactsImageRightDescriptionElement;
  updateData: (element: IElement, index: number) => void;
}

export const ContactsImageRightDescriptionFC: React.FC<
  ContactsImageRightDescriptionProps
> = ({ index, data, updateData }) => {
  const update = (
    fieldsToUpdate: Partial<ContactsImageRightDescriptionElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="contacts-image-right-description">
          <div className="position-relative align-items-center showcase">
            <ContactsImageRightDescriptionContent data={data} update={update} />
            <ContactsImageRightDescriptionFigure data={data} update={update} />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
