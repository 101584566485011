import React from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { MediaGallery } from './MediaGallery';
import { MediaGalleryItem } from './MediaGalleryItem';

type Props = {
  data: MediaGallery;
  update: (fieldsToUpdate: Partial<MediaGallery>) => void;
  addImage: () => void;
  removeImage: (index: number) => void;
};

export const MediaGalleryFigure: React.FC<Props> = ({
  data,
  update,
  addImage,
  removeImage,
}) => {
  return (
    <div className="figure">
      <div className="inner">
        <div className="grid">
          {data.image_previews.map((item, index) => (
            <MediaGalleryItem
              data={item}
              mediaGallery={data}
              index={index}
              update={update}
              removeItem={removeImage}
            />
          ))}

          <div onClick={addImage} className="add-item">
            <div className="m-auto d-flex flex-column">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-muted svg-icon-3hx text-center"
              />
              <span className="mt-4 text-gray-500">Add new item</span>
            </div>
          </div>
        </div>

        <TextAreaEdit
          name="caption"
          className="caption"
          value={data.caption}
          setValue={update}
        />
      </div>
    </div>
  );
};
