/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../_start/helpers';
import { ActionsDropdown } from '../../../components/ActionsDropdown';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { Pagination } from '../../../components/Pagination';
import { useDebounce } from '../../../hooks/useDebounce';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from '../../../support/utils';
import { IMember } from '../../user-management/models/Member';
import { getMembers } from '../../user-management/redux/UserManagementCRUD';
import { QuotationsAssignToModal } from '../modal/QuotationsAssignToModal';
import { QuotationsStatusModal } from '../modal/QuotationsStatusModal';
import {
  defaultQuotation,
  IQuotation,
  QuotationStatusType,
} from '../models/Quotation';
import {
  deleteQuotation,
  listQuotation,
  setQuotationAdvisor,
  setQuotationStatus,
  updateQuotation,
} from '../redux/QuotationCRUD';

export const QuotationsPage = () => {
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const [advisors, setAdvisors] = useState<Array<IMember>>([]);

  /**
   * SEARCH
   */
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listQuotation(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  /**
   * DELETE
   */
  const [selected, setSelected] = useState<IQuotation>(defaultQuotation);

  const handleDelete = async (item: IQuotation) => {
    setLoading(true);
    try {
      await deleteQuotation(item);
      successToast('Quotation has been deleted.');
      setLoading(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleUpdate = async (data: IQuotation) => {
    setLoading(true);

    try {
      let payload: IQuotation = {
        ...data,
      };
      await updateQuotation(payload);
      successToast('Quotation has been updated.');
      handleFetchList();
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleSetAdvisor = async (
    event: React.ChangeEvent<HTMLSelectElement>,
    item: IQuotation
  ) => {
    const userId = parseInt(event.target.value);
    const payload = {
      user_id: userId === 0 ? null : userId,
      quotation_id: item.id,
    };

    try {
      setLoading(true);
      await setQuotationAdvisor(payload);
      handleFetchList();
      setLoading(false);
      successToast('Quotation advisor has been updated successfully.');
    } catch (error) {
      setLoading(false);
      failureToast(error);
    }
  };

  const handleSetStatus = async (
    event: React.ChangeEvent<HTMLSelectElement>,
    item: IQuotation
  ) => {
    const status = event.target.value as QuotationStatusType;

    const payload = {
      quotation_id: item.id,
      status: status,
    };

    try {
      setLoading(true);
      await setQuotationStatus(payload);
      handleFetchList();
      setLoading(false);
      successToast('Quotation status has been updated successfully.');
    } catch (error) {
      setLoading(false);
      failureToast(error);
    }
  };

  /**
   * PAGINATION
   */
  const [paginatedValue, setPaginatedValue] = useState<
    PaginatedValue<IQuotation>
  >(new PaginatedValue());

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<IQuotation>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  /**
   * INITIALIZATION
   */
  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listQuotation();
      setPaginatedValue(result);
      const members = await getMembers();
      setAdvisors(members.data);

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();
      setInitialized(true);
    }
  }, []);

  return (
    <>
      <DeleteModal
        headerTitle="Delete Customer"
        item={selected}
        handleDelete={handleDelete}
      />

      <QuotationsAssignToModal
        headerTitle="Change advisor"
        item={selected}
        handleUpdate={handleUpdate}
        advisors={advisors}
        handleSetAdvisor={handleSetAdvisor}
      />

      <QuotationsStatusModal
        headerTitle="Change Status"
        item={selected}
        handleUpdate={handleUpdate}
        handleSetStatus={handleSetStatus}
      />
      <div
        className={`card card-custom card-flush ${
          loading ? 'overlay overlay-block' : ''
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                '' ? 'w-xxl-600px' : 'w-xxl-350px'
              }`}
              placeholder="Zoeken"
            />
          </div>
          <div className="card-toolbar">
            <Link className="btn btn-sm btn-light" to={`quotations/send-quote`}>
              Send Quote
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Date</th>
                <th>Invoice #</th>
                <th>Customer</th>
                <th>Phone</th>
                <th>Company</th>
                <th>Advisor</th>
                <th>Status</th>
                <th>Created By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedValue.data.map((element, idx) => (
                <tr key={idx} className="align-middle">
                  <td>{element.date}</td>
                  <td>
                    {' '}
                    <Link
                      to={`quotations/${element.invoice_id}/bekijk-offerte`}
                      className="menu-link px-3"
                    >
                      {element.invoice_id}
                    </Link>
                  </td>
                  <td>{element.customer.email}</td>
                  <td>{element.customer.phone_number}</td>
                  <td>{element.customer.company_name}</td>
                  <td>{element.user ? element.user.name : 'NA'}</td>
                  <td>{element.status}</td>
                  <td>{element.created_by}</td>
                  <td className="text-end">
                    {/* begin::Dropdown */}
                    <ActionsDropdown>
                      <div className="menu-item px-3">
                        <Link
                          to={`quotations/${element.id}/update`}
                          className="menu-link px-3"
                        >
                          Update
                        </Link>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(element)}
                          href="#"
                          className="menu-link px-3 text-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_update_advisor_kt_modal_1"
                        >
                          Assign to
                        </a>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(element)}
                          href="#"
                          className="menu-link px-3 text-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_update_status_kt_modal_1"
                        >
                          Set status
                        </a>
                      </div>
                      {/* <div className="menu-item px-3">
												<Link
													to={`quotations/${element.id}/voipgrid`}
													className="menu-link px-3"
												>
													VoipGrid
												</Link>
											</div> */}
                      {/* <div className="menu-item px-3">
												<a
													onClick={() =>
														setSelected(element)
													}
													href="#"
													className="menu-link px-3 text-danger"
													data-bs-toggle="modal"
													data-bs-target="#custom_delete_kt_modal_1"
												>
													Delete
												</a>
											</div> */}
                      <div className="separator mt-3 opacity-75"></div>
                    </ActionsDropdown>
                    {/* end::Dropdown */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};
