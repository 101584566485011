import { PageLink } from '../../../_start/layout/core';

export const contentManagementSubmenu: Array<PageLink> = [
  {
    title: 'Paketten',
    path: '/content-management/packages',
    isActive: true,
  },
  {
    title: 'Pakkettype',
    path: '/content-management/package-types',
    isActive: true,
  },
  {
    title: 'Producten',
    path: '/content-management/products',
    isActive: true,
  },
  {
    title: 'Categorieën',
    path: '/content-management/categories',
    isActive: true,
  },
  {
    title: 'Beltarieven',
    path: '/content-management/call-rates',
    isActive: true,
  },
  {
    title: 'FAQs',
    path: '/content-management/faqs',
    isActive: true,
  },
];
