import { ElementType, IElement } from '../../../models/Element';

export type QuoteBlock = IElement & {
  quote: string;
  footer: string;
};

export const defaultQuoteBlock: QuoteBlock = {
  id: 0,
  name: ElementType.QUOTE_BLOCK,
  quote:
    '<p>Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Etiam porta sem malesuada magna mollis euismod. Curabitur blandit tempus porttitor.</p>',
  footer: '— Paul Zuidhof',
};
