export interface ICookieBanner {
  id: number;

  name: string;
  message: string;
  button_text: string;
  button_settings_text: string;
  settings_title: string;
  settings_text: string;
  settings_essential_text: string;
  settings_functional_text: string;
  settings_analytics_text: string;
  settings_marketing_text: string;
}

export const defaultICookieBanner: ICookieBanner = {
  id: 0,
  name: '',
  message: '',
  button_text: '',
  button_settings_text: '',
  settings_title: '',
  settings_text: '',
  settings_essential_text: '',
  settings_functional_text: '',
  settings_analytics_text: '',
  settings_marketing_text: '',
};
