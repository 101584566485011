import React from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextEditor } from '../../../../../components/TextEditor';
import { ButtonTooltip } from '../../../components/ButtonTooltip';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { ColumnContent, FourColumnContent } from './FourColumnContent';

interface Props {
  itemIndex: number;
  data: ColumnContent;
  allData: FourColumnContent;
  updateItem: (idx: number, item: ColumnContent) => void;
  removeItem: (idx: number) => void;
}

export const FourColumnContentItem: React.FC<Props> = ({
  itemIndex,
  data,
  allData,
  updateItem,
  removeItem,
}) => {
  const update = (to: Partial<ColumnContent>) => {
    const updatedData = { ...data, ...to };
    updateItem(itemIndex, updatedData);
  };
  return (
    <div className="card">
      <span
        onClick={() => removeItem(itemIndex)}
        className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>

      <TextAreaEdit className="heading" name={'heading'} value={data.heading} setValue={update} />

      <div className="description">
        <TextEditor
          content={data.description}
          updateContent={(description) => update({ description })}
        />
      </div>

      <div className="btn">
        <ButtonTooltip
          name={'button_url'}
          value={data.button_url}
          setValue={update}
          isCheckboxChecked={data.button_url_is_external}
          checkboxChanged={(v) => update({ button_url_is_external: v })}
        >
          <InlineEdit
            className="btn-text"
            name={'button_title'}
            value={data.button_title ?? ''}
            setValue={update}
          />
        </ButtonTooltip>
      </div>
    </div>
  );
};
