import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeCustomerReviews } from './HomeCustomerReviews';
import { HomeCustomerReviewsContent } from './HomeCustomerReviewsContent';
import { HomeCustomerReviewsFigure } from './HomeCustomerReviewsFigure';

interface Props {
  index: number;
  data: HomeCustomerReviews;
  updateData: (element: IElement, index: number) => void;
}

export const HomeCustomerReviewsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<HomeCustomerReviews>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-customer-reviews">
          <div className="grid">
            <div className="grid__inner">
              <div className="reviews--hero">
                <div className="reviews__inner">
                  <HomeCustomerReviewsContent data={data} update={update} />
                </div>
                <HomeCustomerReviewsFigure data={data} update={update} />
              </div>
            </div>
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
