export interface IPartner {
  id: number;
  name: string;
  business_name: string;
  logo: string;
}

export const defaultPartner: IPartner = {
  id: 0,
  name: '',
  business_name: '',
  logo: '',
};
