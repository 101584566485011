/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  getConfig,
  IThemeConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from "../../../_start/layout/core";
import { RoleAndPermissionPage } from "./RoleAndPermissionPage";
import { roleAndPermissionSubmenu } from "./RoleAndPermissionPageData";

const roleAndPermissionBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/",
    isActive: false,
  },
  {
    title: "Rol & Rechtenbeheer",
    path: "/role-and-permission",
    isActive: false,
  },
];

const defaultPageConfig = getConfig();
const roleAndPermissionPageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
    content: "user",
    bgColor: "bg-info",
  },
};

export const RoleAndPermissionWrapper = () => {
  const { setTheme } = useTheme();
  // Refresh UI after config updates
  useEffect(() => {
    setTheme(roleAndPermissionPageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={roleAndPermissionBreadCrumbs}
        submenu={roleAndPermissionSubmenu}
      />
      <RoleAndPermissionPage />
    </>
  );
};
