import React from "react";
import { TextEditor } from "../../../../../components/TextEditor";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { HeaderSelectWrapper } from "../../../components/HeaderSelectWrapper";
import { InlineEdit } from "../../../components/InlineEdit";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";
import { InfoBlockExpand } from "./InfoBlockExpand";

interface Props {
  index: number;
  data: InfoBlockExpand;
  updateData: (body: IElement, index: number) => void;
}

export const InfoBlockExpandFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<InfoBlockExpand>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="info-block-expand">
        <div className="content">
          <InlineEdit
            className="title"
            name={"title"}
            value={data.title}
            setValue={update}
            dynamicWidth
          />

          <HeaderSelectWrapper
            name={"heading_type"}
            value={data.heading_type ?? ElementHeaderType.H1}
            setValue={update}
          >
            <TextAreaEdit
              name="heading"
              className="heading"
              value={data.heading}
              setValue={update}
            />
          </HeaderSelectWrapper>

          <div className="description">
            <TextEditor
              content={data.description}
              updateContent={(description) => update({ description })}
            />
          </div>

          <div className="content">
            <TextEditor
              content={data.content}
              updateContent={(content) => update({ content })}
            />
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
