import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import GoogleReviewsPage from '../../modules/google-reviews/GoogleReviewsPage';

export const GoogleMyBusinessPage = () => {
  return (
    <Switch>
      {/* begin::Group */}
      <Route exact={true} path="/google-reviews">
        <>
          <PageTitle>Google Reviews</PageTitle>
          <GoogleReviewsPage />
        </>
      </Route>

      <Redirect
        from="/google-reviews"
        exact={true}
        to="/google-reviews"
      />
      <Redirect to="/google-reviews" />
    </Switch>
  );
};
