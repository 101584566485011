import { formatDistanceToNowStrict } from "date-fns";
import { IQuotationActivityLog } from "../models/QuotationActivityLog";

type SystemLogProps = {
	log: IQuotationActivityLog;
};

export const SystemLog = ({ log }: SystemLogProps) => {
	return (
		<div className="mt-3">
			<div
				className="d-flex align-items-center justify-content-between"
				id="modal-headline"
			>
				<span className="fs-4">{log.attributes.causer}</span>
				<span className="text-gray-500 fs-6">
					{formatDistanceToNowStrict(
						new Date(log.attributes.updated_at),
						{ addSuffix: true }
					)}
				</span>
			</div>
			<div className="text-left">
				<p className="fs-6 text-gray-500">
					{`Changed status to ${log.attributes.status}`}
				</p>
			</div>
		</div>
	);
};
