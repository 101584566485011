import React from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { HomeLeftDescriptionWithFloatingWindowElement } from './HomeLeftDescriptionFloatingWindowElement';

interface Props {
  data: HomeLeftDescriptionWithFloatingWindowElement;
  update: (
    fieldsToUpdate: Partial<HomeLeftDescriptionWithFloatingWindowElement>
  ) => void;
}

export const HomeLeftDescriptionFloatingWindowFloatingText: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="showcase__cta">
      <div className="heading_cta">
        <TextEditor
          content={data.floating_text}
          updateContent={(floating_text) => update({ floating_text })}
        />
      </div>
    </div>
  );
};
