import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export type ContactsImageRightDescriptionElement = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  image_preview: string;
  image_preview_alt: string;
};

export const defaultContactsImageRightDescriptionElement: ContactsImageRightDescriptionElement =
  {
    id: 0,
    name: ElementType.CONTACTS_IMAGE_RIGHT_DESCRIPTION,
    heading: 'Ondersteuning',
    description:
      'Indien het een storing of fout aan onze zijde betreft, ontvang je gratis support tot het probleem is opgelost. Zit de storing in niet door ons geleverde netwerk- of voip apparatuur? Dan proberen we je te helpen en betaal je supportkosten.',
    button_title: 'Supportkosten',
    button_url: '',
    button_url_is_external: false,
    image_preview: '',
    image_preview_alt: '',
  };
