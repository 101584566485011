export interface IPermission {
  id: number;
  name: string;
  description: string;
}

export const defaultPermission: IPermission = {
  id: 0,
  name: "",
  description: "",
};
