import { ElementType, IElement } from '../../../models/Element';

export type FormContactFormulier = IElement & {
  title: string;

  name_label: string;
  name_placeholder: string;

  email_label: string;
  email_placeholder: string;

  subject_label: string;
  subject_placeholder: string;

  message_label: string;
  message_placeholder: string;

  button_title: string;

  success_message?: string;
};

export const defaultFormContactFormulier: FormContactFormulier = {
  id: 0,
  name: ElementType.FORM_CONTACT_FORMULIER,

  title: 'Contact formulier',

  name_label: 'Naam',
  name_placeholder: 'Enter name',

  email_label: 'Emailadres',
  email_placeholder: 'Enter email',

  subject_label: 'Onderwerp',
  subject_placeholder: 'Enter subject',

  message_label: 'Bericht',
  message_placeholder: 'Enter message',

  button_title: 'Verstuur',
};
