/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from '../support/utils';
import { useDebounce } from './useDebounce';

export const listModel = async <T>(url: string) => {
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<T> }>(url);

  return value;
};

export const deleteModel = async <T>(url: string) => {
  const {
    data: { value },
  } = await axios.post<{ value: T }>(url);

  return value;
};

export const getAttributeName = (attribute: string, current: string) => {
  if (attribute === current) {
    return (attribute = `-${current}`);
  }

  return attribute;
};

export const getFiltersParam = (filters?: any) => {
  if (filters === undefined) {
    return '';
  }

  if (Object.keys(filters).length) {
    let filterKeys = Object.keys(filters);

    let filterParams = '';

    filterKeys.forEach((key) => {
      filterParams += `&filter[${key}]=${filters[key]}`;
    });

    return filterParams;
  }

  return '';
};

export const getListUrl = (
  url = '',
  search = '',
  paginate = 5,
  sort = '',
  filters?: any
) => {
  let queryParams = `${url}/list?search=${search}&paginate=${paginate}&sort=${sort}`;

  const filtersParam = getFiltersParam(filters);

  return queryParams + filtersParam;
};

export const useRepositoryList = <T, F extends unknown = {}>(
  url: string,
  defaultData: Array<T> = []
) => {
  const [paginatedValue, setPaginatedValue] = useState<PaginatedValue<T>>({
    ...new PaginatedValue(),
    data: defaultData,
  });

  const [isPending, setIsPending] = useState(true);
  const [refreshList, setRefreshList] = useState(false);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce<string>(search, 500);

  const [paginate, setPaginate] = useState(5);
  const [filters, setFilters] = useState<F | undefined>(undefined);

  const handleFetchList = async () => {
    setIsPending(true);
    try {
      // &filter[status]=draft
      const URL = getListUrl(url, search, paginate, sort, filters);

      const response = await listModel<T>(URL);
      setPaginatedValue(response);
      setIsPending(false);
    } catch (error) {
      failureToast(error);
      setIsPending(false);
    }
  };

  useEffect(() => {
    handleFetchList();
  }, [debouncedSearch, paginate, filters, refreshList]);

  const handlePaginateState = async (page_url: string) => {
    setIsPending(true);
    try {
      const result = await handlePaginate<T>(page_url);
      setPaginatedValue(result);
      setIsPending(false);
    } catch (error) {
      failureToast(error);
      setIsPending(false);
    }
  };

  /**
   * SORT
   */

  const [sort, setSort] = useState('');

  const handleSort = async (attribute: string) => {
    setIsPending(true);

    let attributeName = getAttributeName(attribute, sort);

    try {
      //   const URL = `${url}/list?search=${search}&paginate=${paginate}&sort=${attributeName}`;
      const URL = getListUrl(url, search, paginate, attributeName, filters);

      const response = await axios.get<{ value: PaginatedValue<T> }>(URL);
      setPaginatedValue(response.data.value);

      setSort(attributeName);
      setIsPending(false);
    } catch (error) {
      failureToast(error);
      setIsPending(false);
    }
  };

  /**
   * DELETE
   */

  const [selected, setSelected] = useState<T>();

  const handleDelete = async (item: T & { id: number }) => {
    setIsPending(true);
    try {
      const URL = `${url}/${item.id}/delete`;
      await deleteModel(URL);
      successToast('Item has been deleted.');
      setIsPending(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setIsPending(false);
    }
  };

  return {
    paginatedValue,
    isPending,
    setIsPending,
    paginate,
    setPaginate,
    search,
    setSearch,
    handleFetchList,
    handlePaginateState,
    sort,
    handleSort,
    selected,
    setSelected,
    handleDelete,
    setFilters,
    setRefreshList,
  };
};
