import { ElementType, IElement } from '../../../models/Element';

export type FormCallMe = IElement & {
  title: string;
  name_label: string;
  name_placeholder: string;

  phone_label: string;
  phone_placeholder: string;

  message_label: string;
  message_placeholder: string;

  button_title: string;

  success_message?: string;
};

export const defaultFormCallMe: FormCallMe = {
  id: 0,
  name: ElementType.FORM_CALL_ME,

  title: 'Call me',

  name_label: 'Naam',
  name_placeholder: 'Enter name',

  phone_label: 'Telefoonnummer',
  phone_placeholder: 'Enter phone',

  message_label: 'Bericht',
  message_placeholder: 'Enter message',

  button_title: 'Verstuur',
};
