/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import updateHelper from 'immutability-helper';
import {
  defaultFooter,
  FooterContentGroup,
  FooterLink,
  IFooter,
  SocialMediaLink,
} from '../../models/Footer';
import { updateFooter, viewFooter } from '../../redux/FootersCRUD';
import { failureToast, successToast } from '../../../../support/utils';
import { ContentGroup } from '../../components/ContentGroup';
import { GroupLink } from '../../components/GroupLink';
import { SocialMediaLinkFC } from '../../components/SocialMediaLink';
import { KTSVG } from '../../../../../_start/helpers';

export const UpdateFooter = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IFooter>(defaultFooter);

  const updateData = (fieldsToUpdate: Partial<IFooter>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const addBottomLink = () => {
    let bottom_links = [...(data.bottom_links ?? [])];
    bottom_links.push({ link_title: 'Link title', link_url: '' });

    const updatedData = { ...data, bottom_links };
    updateData(updatedData);
  };

  const removeBottomLink = (index: number) => {
    let bottom_links = updateHelper(data.bottom_links, {
      $splice: [[index, 1]],
    });

    const updatedData = { ...data, bottom_links };
    updateData(updatedData);
  };

  const updateLink = (index: number, link: FooterLink) => {
    let bottom_links = [...(data.bottom_links ?? [])];
    bottom_links[index] = link;
    const updatedData = { ...data, bottom_links };
    updateData(updatedData);
  };

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const [menu] = await Promise.all([viewFooter(id)]);
      setData({ ...data, ...menu });
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await updateFooter(data);
      successToast('Footer has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const addGroup = () => {
    updateData({
      content: [...data.content, { group_title: 'New group', links: [] }],
    });
  };

  const removeGroup = (index: number) => {
    let content = updateHelper(data.content, {
      $splice: [[index, 1]],
    });

    const updatedData = { ...data, content };
    updateData(updatedData);
  };

  const updateGroup = (index: number, content: FooterContentGroup) => {
    let newContent = [...data.content];
    newContent[index] = content;
    const updatedData = { ...data, content: newContent };
    updateData(updatedData);
  };

  const addSocialLink = () => {
    let social_media_links = [...(data.social_media_links ?? [])];
    social_media_links.push({
      svg_img: '',
      link_url: '',
      is_link_external: false,
    });

    const updatedData = { ...data, social_media_links };
    updateData(updatedData);
  };

  const updateSocialLink = (index: number, link: SocialMediaLink) => {
    let social_media_links = [...(data.social_media_links ?? [])];
    social_media_links[index] = link;
    const updatedData = { ...data, social_media_links };
    updateData(updatedData);
  };

  const removeSocialLink = (index: number) => {
    let social_media_links = updateHelper(data.social_media_links, {
      $splice: [[index, 1]],
    });

    const updatedData = { ...data, social_media_links };
    updateData(updatedData);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Footer</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) =>
                    updateData({
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <div className="col-lg-12 d-flex justify-content-between">
              <label className="col-form-label text-uppercase">Content</label>
              <button
                type="button"
                className="btn btn-sm btn-light"
                onClick={addGroup}
              >
                Add Content Group
              </button>
            </div>
            <div
              className="mt-8 col-lg-12"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '2rem',
              }}
            >
              {data.content.map((group, idx) => (
                <ContentGroup
                  key={idx}
                  index={idx}
                  group={group}
                  removeGroup={removeGroup}
                  updateGroup={updateGroup}
                />
              ))}
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          {/* begin::Form row */}
          <div className={`row mb-8`}>
            <div className="col-lg-12 d-flex justify-content-between">
              <label className="col-form-label text-uppercase">
                Bottom Links
              </label>
            </div>
            <div className="mt-8 col-lg-12">
              <div className="mt-4 group-links d-flex flex-column gap-2">
                {data.bottom_links?.map((link, idx) => (
                  <GroupLink
                    key={idx}
                    index={idx}
                    link={link}
                    removeLink={removeBottomLink}
                    updateLink={updateLink}
                  />
                ))}

                <button
                  type="button"
                  className="btn btn-sm btn-light"
                  onClick={addBottomLink}
                >
                  Add Link
                </button>
              </div>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          {/* begin::Form row */}
          <div className="card card-custom card-flush border border-1">
            <div
              className={`card card-custom card-flush ${
                loading ? 'overlay overlay-block' : ''
              }`}
            >
              <div className="pt-8 card-header">
                <h3 className="card-title">Social Media</h3>
              </div>
              <div
                className={`card-body py-5 ${loading ? 'overlay-wrapper' : ''}`}
              >
                <div
                  className="m-8 col-lg-12"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    gap: '2rem',
                  }}
                >
                  {data.social_media_links?.map((socialLink, idx) => (
                    <SocialMediaLinkFC
                      index={idx}
                      link={socialLink}
                      removeLink={removeSocialLink}
                      updateLink={updateSocialLink}
                    />
                  ))}
                  <div
                    onClick={addSocialLink}
                    className="position-relative d-flex justify-content-center align-items-center border-dashed border-1"
                    style={{ height: '8rem', width: '8rem' }}
                  >
                    <div className="m-auto d-flex flex-column">
                      <KTSVG
                        path="/media/icons/duotone/Interface/Plus-Square.svg"
                        className="svg-icon-muted svg-icon-3hx text-center"
                      />
                      <span className="mt-4 text-gray-500 text-center">
                        Add
                      </span>
                    </div>
                  </div>
                </div>
                {loading && (
                  <div className="overlay-layer rounded bg-dark bg-opacity-5">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div> */}
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/layout-management/footers`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
