import React from 'react';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { ContactsOtherInfoWithIconsElement } from './ContactsOtherInfoWithIconsElement';
import { TextEditor } from '../../../../../components/TextEditor';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { ElementHeaderType } from '../../utils';

interface Props {
  data: ContactsOtherInfoWithIconsElement;
  update: (to: Partial<ContactsOtherInfoWithIconsElement>) => void;
}

export const ContactsOtherInfoWithIconsContent: React.FC<Props> = ({
  data,
  update,
}) => {
  return (
    <div className="content">
      <HeaderSelectWrapper
        name={`heading_type`}
        value={data.heading_type ?? ElementHeaderType.H2}
        setValue={update}
      >
        <TextAreaEdit
          className="heading"
          name="heading"
          value={data.heading}
          setValue={update}
        />
      </HeaderSelectWrapper>
      <div className="description">
        <TextEditor
          content={data.description}
          updateContent={(description) => update({ description })}
        />
      </div>

      <TextEditor
        content={data.content}
        updateContent={(content) => update({ content })}
      />
    </div>
  );
};
