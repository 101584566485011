import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { CreateNotice } from "./pages/CreateNotice";
import { NoticesPage } from "./pages/NoticesPage";
import { UpdateNotice } from "./pages/UpdateNotice";

export const NoticeManagementPage = () => {
  return (
    <Switch>
      {/* begin::Page Routes */}
      <Route exact={true} path="/notice-management/notices">
        <>
          <PageTitle>Notices</PageTitle>
          <NoticesPage />
        </>
      </Route>

      <Route exact={true} path="/notice-management/notices/create">
        <>
          <PageTitle>Notices</PageTitle>
          <CreateNotice />
        </>
      </Route>

      <Route exact={true} path="/notice-management/notices/:id/update">
        <>
          <PageTitle>Notices</PageTitle>
          <UpdateNotice />
        </>
      </Route>

      {/* end::Page Routes */}

      <Redirect
        from="/notice-management"
        exact={true}
        to="/notice-management/notices"
      />

      <Redirect to="/notice-management/notices" />
    </Switch>
  );
};
