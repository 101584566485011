import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export interface IconHeadingAndDescription {
  id: number;
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  icon: string;
}

const thumbs_up_icon = `<svg data-src="assets/icons/icon-thumb.svg" focusable="false" aria-hidden="true" data-cache="604800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 41" data-id="svg-loader_32"><path d="M40.75 15.17a5.593 5.593 0 0 0-4.36-2.08h-9.85l.67-5.69c.22-1.88-.38-3.77-1.64-5.18A6.669 6.669 0 0 0 20.6 0h-.74c-1.84 0-3.49 1.09-4.21 2.78L9.86 16.37H5.61c-3.09 0-5.61 2.51-5.61 5.6v13.44c0 3.09 2.52 5.6 5.61 5.6H32.8c2.63 0 4.94-1.86 5.49-4.43l3.59-16.71c.36-1.66-.05-3.37-1.12-4.69ZM5.01 35.4V21.96c0-.33.27-.6.6-.6h4.23V36H5.61c-.33 0-.6-.27-.6-.6Zm28.38.13a.6.6 0 0 1-.59.48H14.85V17.45L20.15 5h.45c.64 0 1.04.34 1.23.55.18.21.48.64.4 1.28l-.79 6.75c-.13 1.15.23 2.3 1 3.16s1.87 1.36 3.03 1.36h10.92c.24 0 .39.12.47.22.08.1.17.27.12.51l-3.59 16.71Z"></path></svg>`;
const heart_icon = `<svg data-src="assets/icons/icon-heart.svg" focusable="false" aria-hidden="true" data-cache="604800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 27" data-id="svg-loader_92"><path d="M15.08 27h-.16c-.5 0-.98-.2-1.34-.55L2.67 15.53C.95 13.81 0 11.53 0 9.1s.95-4.72 2.67-6.43C4.39.95 6.67 0 9.1 0c2.19 0 4.25.76 5.9 2.17 3.57-3.04 8.96-2.88 12.33.5C29.05 4.39 30 6.67 30 9.1s-.95 4.71-2.67 6.43L16.41 26.44c-.36.36-.84.55-1.34.55ZM9.1 3.79c-1.42 0-2.75.55-3.76 1.56-1 1-1.56 2.34-1.56 3.76s.55 2.75 1.56 3.76L15 22.52l9.65-9.65c1-1 1.56-2.34 1.56-3.76s-.55-2.75-1.56-3.76c-1-1-2.34-1.56-3.76-1.56s-2.75.55-3.76 1.56l-.54.54c-.85.85-2.34.85-3.2 0l-.54-.54c-1-1-2.34-1.56-3.76-1.56ZM4 4.01Z"></path></svg>`;

export const defaultIconAndTitle1: IconHeadingAndDescription = {
  id: 0,
  heading: 'Kies slimme modules.',
  description:
    'Onze diensten kan je altijd per dag aanvragen maar ook stopzetten.',
  icon: thumbs_up_icon,
};

export const defaultIconAndTitle2: IconHeadingAndDescription = {
  id: 0,
  heading: 'Een solide service.',
  description:
    'Onze diensten kan je altijd per dag aanvragen maar ook stopzetten.',
  icon: heart_icon,
};

export type HomeImageTwoRightDescriptionFloatingWindowElement = IElement & {
  image_preview: string;
  image_preview_alt: string;
  floating_text: string;
  floating_button_title: string;
  floating_button_url: string;
  floating_button_url_is_external: boolean;
  icons_headings: IconHeadingAndDescription[];
};

export const defaultHomeImageTwoRightDescriptionFloatingWindowElement: HomeImageTwoRightDescriptionFloatingWindowElement =
  {
    id: 0,
    name: ElementType.HOME_IMAGE_TWO_RIGHT_DESCRIPTION_WITH_FLOATING,
    floating_text:
      'Bij Verbonden regel je zelf jouw telefonie zaken, 24/7. Dat is telefonie anno 2022.',
    floating_button_title: 'Contact',
    floating_button_url: '',
    floating_button_url_is_external: false,
    image_preview: '',
    image_preview_alt: '',
    icons_headings: [defaultIconAndTitle1, defaultIconAndTitle2],
  };
