import { IEmployee } from '../../../../employee-management/models/Employee';
import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export interface TeamDescription {
  icon: string;
  title: string;
  description: string;
}

export const defaulTeamDescription: TeamDescription = {
  icon: '',
  title: 'Team Klantgeluk.',
  description:
    'De strategie bepalen wij samen met jou. The long barrow was built on land previously inhabited in the Mesolithic period. It consisted of a sub rectangular earthen tumulus, estimated to have been 15 metres (50 feet).',
};

export type TeamEmployees = IElement & {
  title: string;
  heading: string;
  heading_type: ElementHeaderType;
  employees: IEmployee[];
  team_description: TeamDescription;
};

export const defaultTeamEmployees: TeamEmployees = {
  id: 0,
  name: ElementType.TEAM_EMPLOYEES,
  title: 'Wat jij zegt',
  heading: 'Wie zijn wij?',
  heading_type: ElementHeaderType.H2,
  employees: [],
  team_description: defaulTeamDescription,
};
