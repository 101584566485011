import immutabilityHelper from 'immutability-helper';
import React from 'react';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { defaultImagePreview, MediaGallery } from './MediaGallery';
import { MediaGalleryContent } from './MediaGalleryContent';
import { MediaGalleryFigure } from './MediaGalleryFigure';

interface Props {
  index: number;
  data: MediaGallery;
  updateData: (body: IElement, index: number) => void;
}

export const MediaGalleryFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<MediaGallery>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const addImage = () => {
    const image_previews = immutabilityHelper(data.image_previews, {
      $push: [defaultImagePreview],
    });

    const updatedData = { ...data, image_previews };
    updateData(updatedData, index);
  };

  const removeImage = (itemIndex: number) => {
    let image_previews = immutabilityHelper(data.image_previews, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, image_previews };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="media-gallery">
        <MediaGalleryContent data={data} update={update} />
        <MediaGalleryFigure
          data={data}
          update={update}
          addImage={addImage}
          removeImage={removeImage}
        />
      </div>
    </ElementWrapper>
  );
};
