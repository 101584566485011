import { put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload } from "../../auth";
import { IRole } from "../../role-and-permission/pages/roles/Role";
import { IMember } from "../models/Member";
import { getAllRoles, getMembers } from "./UserManagementCRUD";

export const actionTypes = {
  MembersRequested: "[Request Members] Action",
  MembersLoaded: "[Load Members] User Management API",
  RolesRequested: "[Request Roles] Action",
  RolesLoaded: "[Load Roles] Role and Permission API",
};

export interface IUserManagementState {
  list?: Array<IMember>;
  roles?: Array<IRole>;
}

const initialUserManagementState: IUserManagementState = {
  list: [],
  roles: [],
};

export const reducer = (
  state: IUserManagementState = initialUserManagementState,
  action: ActionWithPayload<IUserManagementState>
): IUserManagementState => {
  switch (action.type) {
    case actionTypes.MembersRequested:
      return { ...state, list: [] };

    case actionTypes.MembersLoaded:
      const members = action.payload?.list;
      return { ...state, list: members };

    case actionTypes.RolesRequested:
      return { ...state, roles: [] };

    case actionTypes.RolesLoaded:
      const roles = action.payload?.roles;
      return { ...state, roles: roles };

    default:
      return state;
  }
};

export const actions = {
  requestMembers: () => ({ type: actionTypes.MembersRequested }),
  fulfillMembers: (list: Array<IMember>) => ({
    type: actionTypes.MembersLoaded,
    payload: { list },
  }),
  requestRoles: () => ({ type: actionTypes.RolesRequested }),
  fulfillRoles: (roles: Array<IRole>) => ({
    type: actionTypes.RolesLoaded,
    payload: { roles },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.MembersRequested, function* membersRequested() {
    const {
      data: { value },
    } = yield getMembers();
    yield put(actions.fulfillMembers(value));
  });

  yield takeLatest(actionTypes.RolesRequested, function* rolesRequests() {
    const {
      data: { value },
    } = yield getAllRoles();
    yield put(actions.fulfillRoles(value.data));
  });
}
