import { ElementType, IElement } from '../../../models/Element';

export type FormInterestedIn = IElement & {
  title: string;
  name_label: string;
  name_placeholder: string;

  email_label: string;
  email_placeholder: string;

  message_label: string;
  message_placeholder: string;

  button_title: string;

  success_message?: string;
};

export const defaultFormInterestedIn: FormInterestedIn = {
  id: 0,
  name: ElementType.FORM_INTERESTED_IN,

  title: 'Interested in',

  name_label: 'Naam',
  name_placeholder: 'Enter name',

  email_label: 'Emailadres',
  email_placeholder: 'Enter email',

  message_label: 'Bericht',
  message_placeholder: 'Enter message',

  button_title: 'Verstuur',
};
