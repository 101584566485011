import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { ElementHeaderType } from '../../utils';
import { PluginPartners } from './PluginPartners';

type HomeEmployeesContentProps = {
  data: PluginPartners;
  update: (fieldsToUpdate: Partial<PluginPartners>) => void;
};

export const PluginPartnersContent = ({
  data,
  update,
}: HomeEmployeesContentProps) => {
  return (
    <div className="plugin-partners__content">
      {/* begin::Heading */}
      <HeaderSelectWrapper
        name={`heading_type`}
        value={data.heading_type ?? ElementHeaderType.H2}
        setValue={update}
      >
        <TextAreaEdit
          name="heading"
          className="content-heading"
          value={data.heading}
          setValue={update}
        />
      </HeaderSelectWrapper>
      {/* end::Heading */}
    </div>
  );
};
