import { defaultBlogAuthorFooter } from '../elements/blog/blog-author-footer/BlogAuthorFooter';
import { defaultBlogAuthorHeader } from '../elements/blog/blog-author-header/BlogAuthorHeader';
import { defaultBlogContentDescriptionWithInfo } from '../elements/blog/blog-content-description-with-info/BlogContentDescriptionWithInfo';
import { defaultBlogContentDescription } from '../elements/blog/blog-content-description/BlogContentDescription';
import { defaultBlogContentHeading } from '../elements/blog/blog-content-heading/BlogContentHeading';
import { defaultBlogContentImage } from '../elements/blog/blog-content-image/BlogContentImage';
import { defaultBlogContentInfoPanel } from '../elements/blog/blog-content-info-panel/BlogContentInfoPanel';
import { defaultBlogContentWideDescriptionTwoColumn } from '../elements/blog/blog-content-wide-description-two-column/BlogContentWideDescriptionTwoColumn';
import { defaultBlogContentWideDescription } from '../elements/blog/blog-content-wide-description/BlogContentWideDescription';
import { defaultBlogFeatured } from '../elements/blog/blog-featured/BlogFeatured';
import { defaultBlogHeader } from '../elements/blog/blog-header/BlogHeader';
import { defaultHomeHeroWideButtonsElement } from '../elements/body/home/hero-wide-buttons/HomeHeroWideButtonsElement';
import { defaultHomeHeroWideIconsElement } from '../elements/body/home/hero-wide-three-icons/HomeHeroWideThreeIconsElement';
import { defaultHomeAdvantage } from '../elements/body/home/home-advantages/HomeAdvantages';
import { defaultHomeCustomerReviews } from '../elements/body/home/home-customer-reviews/HomeCustomerReviews';
import { defaultHomePackages } from '../elements/body/home/home-packages/HomePackages';
import { defaultHomeReviewsSingle } from '../elements/body/home/home-reviews-single/HomeReviewsSingle';
import { defaultHomeReviewsSlider } from '../elements/body/home/home-reviews-slider/HomeReviewsSlider';
import { defaultHomeShowcaseBoxHighlightLeft } from '../elements/body/home/home-showcase-box-highlight-left-image/HomeShowcaseBoxHighlightLeft';
import { defaultHomeShowcaseBoxHighlightRight } from '../elements/body/home/home-showcase-box-highlight-right-image/HomeShowcaseBoxHighlightRight';
import { defaultHomeImageLeftDescriptionBlueElement } from '../elements/body/home/image-with-left-description-and-blue-theme/HomeImageLeftDescriptionBlueThemeElement';
import { defaultHomeLeftDescriptionWithFloatingWindowElement } from '../elements/body/home/image-with-left-description-and-floating-window/HomeLeftDescriptionFloatingWindowElement';
import { defaultHomeImageLeftDescriptionFloatingWindowIconsElement } from '../elements/body/home/image-with-left-description-floating-window-and-icons/HomeImageLeftDescriptionFloatingWindowIconsElement';
import { defaultHomeImageLeftDescriptionElement } from '../elements/body/home/image-with-left-heading-and-description/HomeImageLeftDescriptionElement';
import { defaultHomeImageRightDescriptionBlueElement } from '../elements/body/home/image-with-right-description-and-blue-theme/HomeImageRightDescriptionBlueThemeElement';
import { defaultHomeRightDescriptionWithFloatingWindowElement } from '../elements/body/home/image-with-right-description-and-floating-window/HomeRightDescriptionFloatingWindowElement';
import { defaultHomeImageRightDescriptionFloatingWindowIconsElement } from '../elements/body/home/image-with-right-description-floating-window-and-icons/HomeImageRightDescriptionFloatingWindowIconsElement';

import { defaultHomeImageRightDescriptionElement } from '../elements/body/home/image-with-right-heading-and-description/HomeImageRightDescriptionElement';
import { defaultHomeImageTwoLeftDescriptionFloatingWindowElement } from '../elements/body/home/image-with-two-left-description-and-floating-comp/HomeImageTwoLeftDescriptionFloatingWindowElement';
import { defaultHomeImageTwoRightDescriptionFloatingWindowElement } from '../elements/body/home/image-with-two-right-description-and-floating-comp/HomeImageTwoRightDescriptionFloatingWindowElement';
import { defaultHomeSixIconsWithTitlesElement } from '../elements/body/home/six-icons-with-titles/HomeSixIconsWithTitlesElement';
// import { defaultHomeFAQ } from "../elements/body/home/text-with-frequently-asked-questions/HomeFAQ";
import { defaultThreeIconsHeadingsAndDescriptionsNarrowElement } from '../elements/body/home/three-icons-headings-and-descriptions-narrow/HomeThreeIconsHeadingsAndDescriptionsNarrowElement';
import { defaultThreeIconsHeadingsAndDescriptionsElement } from '../elements/body/home/three-icons-headings-and-descriptions/HomeThreeIconsHeadingsAndDescriptionsElement';
import { defaultContactsImageLeftDescriptionFloatingWindowIconsElement } from '../elements/contacts/contacts-image-with-left-description-floating-window-and-icons/ContactsImageLeftDescriptionFloatingWindowIconsElement';
import { defaultContactsImageLeftDescriptionElement } from '../elements/contacts/contacts-image-with-left-heading-and-description/ContactsImageLeftDescriptionElement';
import { defaultContactsImageRightDescriptionElement } from '../elements/contacts/contacts-image-with-right-heading-and-description/ContactsImageRightDescriptionElement';
import { defaultContactsOtherInfoWithIconsElement } from '../elements/contacts/contacts-other-info-with-icons/ContactsOtherInfoWithIconsElement';
// import { defaultFAQ2 } from "../elements/faq/faq-built-in/FAQ2";
import { defaultFAQSelect } from '../elements/faq/faq-select/FAQSelect';
import { defaultFormCallMe } from '../elements/forms/form-call-me/FormCallMe';
import { defaultFormContactFormulier } from '../elements/forms/form-contact-formulier/FormContactFormulier';
import { defaultFormContactWithRemark } from '../elements/forms/form-contact-with-remark/FormContactWithRemark';
import { defaultFormInterestedIn } from '../elements/forms/form-interested-in/FormInterestedIn';
import { defaultFormNewsLetter } from '../elements/forms/form-news-letter/FormNewsLetter';
import { defaultFormPackage } from '../elements/forms/form-package/FormPackage';
import { defaultMediaCarousel } from '../elements/media/media-carousel/MediaCarousel';
import { defaultMediaGallery } from '../elements/media/media-gallery/MediaGallery';
import { defaultMediaSlider } from '../elements/media/media-slider/MediaSlider';
import { defaultMediaVideo } from '../elements/media/media-video/MediaVideo';
import { defaultArchiveReview } from '../elements/page-elements/archive-review/ArchiveReview';
import { defaultPluginExtraFeatures } from '../elements/page-elements/extra-features/PluginExtraFeatures';
import { defaultFeaturedProducts } from '../elements/page-elements/featured_products/FeaturedProducts';
import { defaultFourColumnContent } from '../elements/page-elements/four-column-content/FourColumnContent';
import { defaultHeadingDescription } from '../elements/page-elements/heading-description/HeadingDescription';
import { defaultInfoBlockBlue } from '../elements/page-elements/info-block-blue/InfoBlockBlue';
import { defaultInfoBlockBordered } from '../elements/page-elements/info-block-bordered/InfoBlockBordered';
import { defaultInfoBlockExpand } from '../elements/page-elements/info-block-expand/InfoBlockExpand';
import { defaultPluginPartners } from '../elements/page-elements/partners/PluginPartners';
import { defaultProcessHighlight } from '../elements/page-elements/process-highlight/ProcessHighlight';
import { defaultProjectHighlights } from '../elements/page-elements/project-highlights/ProjectHighlights';
import { defaultQuoteBlock } from '../elements/page-elements/quote-block/QuoteBlock';
import { defaultTableFiveColumns } from '../elements/page-elements/table-five-columns/TableFiveColumns';
import { defaultTableFourColumns } from '../elements/page-elements/table-four-columns/TableFourColumns';
import { defaultTableThreeColumns } from '../elements/page-elements/table-three-columns/TableThreeColumns';
import { defaultTableTwoColumns } from '../elements/page-elements/table-two-columns/TableTwoColumns';
import { defaultTeamEmployees } from '../elements/page-elements/team-employees/TeamEmployees';
import { defaultTitleThreeColumnContent } from '../elements/page-elements/title-three-column-content/TitleThreeColumnContent';
import { defaultWebsiteScreenshot } from '../elements/page-elements/website-screenshot/WebsiteScreenshot';
// import { defaultFAQ } from "../elements/faq/faq-text-with-frequently-asked-questions/FAQ";
import { defaultMarginBottomElement } from '../elements/utility/margin-bottom-element/MarginBottomElement';
import { defaultMarginTopElement } from '../elements/utility/margin-top-element/MarginTopElement';
import { IElement } from '../models/Element';
import { SidebarElementDrag } from './SidebarElementDrag';

const utilityElements: Array<IElement> = [
  defaultMarginTopElement,
  defaultMarginBottomElement,
];

const formsElements: Array<IElement> = [
  defaultFormInterestedIn,
  defaultFormCallMe,
  defaultFormNewsLetter,
  defaultFormContactFormulier,
  defaultFormContactWithRemark,
  defaultFormPackage,
];

const blogElements: Array<IElement> = [
  defaultBlogHeader,
  defaultBlogAuthorHeader,
  defaultBlogContentHeading,
  defaultBlogContentDescription,
  defaultBlogContentImage,
  defaultBlogContentDescriptionWithInfo,
  defaultBlogContentWideDescriptionTwoColumn,
  defaultBlogContentInfoPanel,
  defaultBlogContentWideDescription,
  defaultBlogAuthorFooter,
  defaultBlogFeatured,
];

const homeElements: Array<IElement> = [
  defaultHomeImageRightDescriptionElement,
  defaultHomeImageLeftDescriptionElement,
  defaultHomeRightDescriptionWithFloatingWindowElement,
  defaultHomeLeftDescriptionWithFloatingWindowElement,
  defaultHomeImageRightDescriptionFloatingWindowIconsElement,
  defaultHomeImageLeftDescriptionFloatingWindowIconsElement,
  defaultHomeImageRightDescriptionBlueElement,
  defaultHomeImageLeftDescriptionBlueElement,
  defaultHomeImageTwoRightDescriptionFloatingWindowElement,
  defaultHomeImageTwoLeftDescriptionFloatingWindowElement,
  defaultThreeIconsHeadingsAndDescriptionsElement,
  defaultThreeIconsHeadingsAndDescriptionsNarrowElement,
  defaultHomeSixIconsWithTitlesElement,
  defaultHomeHeroWideIconsElement,
  defaultHomeHeroWideButtonsElement,
  defaultHomeCustomerReviews,
  defaultHomeReviewsSingle,
  defaultHomeReviewsSlider,
  defaultHomePackages,
  defaultHomeShowcaseBoxHighlightLeft,
  defaultHomeShowcaseBoxHighlightRight,
  defaultHomeAdvantage,
];

const contactsElements: Array<IElement> = [
  defaultContactsImageRightDescriptionElement,
  defaultContactsImageLeftDescriptionElement,
  defaultContactsImageLeftDescriptionFloatingWindowIconsElement,
  defaultContactsOtherInfoWithIconsElement,
];

const FAQElements: Array<IElement> = [defaultFAQSelect];

const pageElements: Array<IElement> = [
  defaultTableTwoColumns,
  defaultTableThreeColumns,
  defaultTableFourColumns,
  defaultTableFiveColumns,
  defaultHeadingDescription,
  defaultProcessHighlight,
  defaultTeamEmployees,
  defaultWebsiteScreenshot,
  defaultInfoBlockBlue,
  defaultInfoBlockBordered,
  defaultInfoBlockExpand,
  defaultTitleThreeColumnContent,
  defaultFourColumnContent,
  defaultQuoteBlock,
  defaultPluginExtraFeatures,
  defaultPluginPartners,
  defaultProjectHighlights,
  defaultFeaturedProducts,
  defaultArchiveReview,
];

const mediaElements: Array<IElement> = [
  defaultMediaCarousel,
  defaultMediaSlider,
  defaultMediaGallery,
  defaultMediaVideo,
];

export const SidebarEditContent = () => {
  return (
    <div id="kt_sidebar_content" className="py-10 px-7 px-lg-12">
      <div
        className="hover-scroll-y me-lg-n7 pe-lg-5"
        data-kt-scroll="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-offset="10px"
        data-kt-scroll-wrappers="#kt_sidebar_content"
      >
        <div>
          {/*begin::Page Components*/}
          <div className="form">
            {/*begin::Utility*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Utility</label>
              <div className="d-flex flex-column">
                {utilityElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Utility*/}

            {/*begin::FORMS*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Forms</label>
              <div className="d-flex flex-column">
                {formsElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::FORMS*/}

            {/*begin::Blog*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Blog</label>
              <div className="d-flex flex-column">
                {blogElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Blog*/}

            {/*begin::Home*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Home</label>
              <div className="d-flex flex-column">
                {homeElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Home*/}

            {/*begin::Contacts*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Contacts</label>
              <div className="d-flex flex-column">
                {contactsElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Contacts*/}

            {/*begin::FAQ*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">FAQs</label>
              <div className="d-flex flex-column">
                {FAQElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::FAQ*/}

            {/*begin::Page*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">
                Page Elements
              </label>
              <div className="d-flex flex-column">
                {pageElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Page*/}

            {/*begin::Media*/}
            <div className="mb-11">
              <label className="fs-2 fw-bolder text-dark mb-4">Media</label>
              <div className="d-flex flex-column">
                {mediaElements.map((element, idx) => (
                  <SidebarElementDrag key={idx} element={element} />
                ))}
              </div>
            </div>
            {/*end::Media*/}
          </div>
          {/*end::Page Components*/}
        </div>
      </div>
    </div>
  );
};
