import React from 'react';
import { TextEditor } from '../../../../../components/TextEditor';
import {
  ContactsOtherInfoWithIconsElement,
  IconAndDescription,
} from './ContactsOtherInfoWithIconsElement';
import { ContactsOtherInfoWithIconsIcon } from './ContactsOtherInfoWithIconsIcon';

interface Props {
  data: ContactsOtherInfoWithIconsElement;
  update: (to: Partial<ContactsOtherInfoWithIconsElement>) => void;
}

export const ContactsOtherInfoWithIconsUSP: React.FC<Props> = ({
  data,
  update,
}) => {
  const updateIcon = (to: Partial<IconAndDescription>, idx: number) => {
    const items = [...data.icons_descriptions];
    items[idx] = { ...items[idx], ...to };
    update({ ...data, icons_descriptions: items });
  };
  return (
    <div className="usp">
      <div className="usp__inner">
        {data.icons_descriptions.map((item, idx) => (
          <div key={idx} className="usp__item">
            <ContactsOtherInfoWithIconsIcon
              idx={idx}
              data={data}
              item={item}
              updateData={updateIcon}
            />
            <div className="usp__item-description">
              <TextEditor
                content={item.description}
                updateContent={(description) =>
                  updateIcon({ description }, idx)
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
