import React from "react";
import { TextEditor } from "../../../../../components/TextEditor";
import { ButtonTooltip } from "../../../components/ButtonTooltip";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { HeaderSelectWrapper } from "../../../components/HeaderSelectWrapper";
import { InlineEdit } from "../../../components/InlineEdit";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";
import { InfoBlockBordered } from "./InfoBlockBordered";

interface Props {
  index: number;
  data: InfoBlockBordered;
  updateData: (body: IElement, index: number) => void;
}

export const InfoBlockBorderedFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<InfoBlockBordered>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="info-block-bordered">
        <div className="content">
          <InlineEdit
            className="title"
            name={"title"}
            value={data.title}
            setValue={update}
            dynamicWidth
          />

          <HeaderSelectWrapper
            name={"heading_type"}
            value={data.heading_type ?? ElementHeaderType.H1}
            setValue={update}
          >
            <TextAreaEdit
              name="heading"
              className="heading"
              value={data.heading}
              setValue={update}
            />
          </HeaderSelectWrapper>

          <div className="description">
            <TextEditor
              content={data.description}
              updateContent={(description) => update({ description })}
            />
          </div>

          <div className="button">
            <ButtonTooltip
              name={"button_url"}
              value={data.button_url}
              setValue={update}
              isCheckboxChecked={data.button_url_is_external}
              checkboxChanged={(v) => update({ button_url_is_external: v })}
            >
              <InlineEdit
                name={"button_title"}
                value={data.button_title ?? ""}
                setValue={update}
                className="button__text"
                dynamicWidth
              />
            </ButtonTooltip>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
