export enum ElementType {
  // UTILITY
  UTILITY_MARGIN_TOP = 'margin-top-element',
  UTILITY_MARGIN_BOTTOM = 'margin-bottom-element',

  // FORM
  FORM_INTERESTED_IN = 'form-interested-in',
  FORM_CALL_ME = 'form-call-me',
  FORM_NEWS_LETTER = 'form-news-letter',
  FORM_CONTACT_FORMULIER = 'form-contact-formulier',
  FORM_CONTACT_WITH_REMARK = 'form-contact-with-remark',
  FORM_PACKAGE = 'form-package',

  // BLOG
  BLOG_HEADER = 'blog-header',
  BLOG_AUTHOR_HEADER = 'blog-author-header',
  BLOG_CONTENT_HEADING = 'blog-content-heading',
  BLOG_CONTENT_DESCRIPTION = 'blog-content-description',
  BLOG_CONTENT_IMAGE = 'blog-content-image',
  BLOG_CONTENT_DESCRIPTION_WITH_INFO = 'blog-content-description-with-info',
  BLOG_CONTENT_WIDE_DESCRIPTION_TWO_COLUMN = 'blog-content-wide-description-two-column',
  BLOG_CONTENT_WIDE_DESCRIPTION = 'blog-content-wide-description',
  BLOG_CONTENT_INFO_PANEL = 'blog-content-info-panel',
  BLOG_CONTENT_AUTHOR_INFO = 'blog-content-author-info',
  BLOG_AUTHOR_FOOTER = 'blog-author-footer',
  BLOG_FEATURED = 'blog-featured',

  // HOME
  HOME_IMAGE_RIGHT_DESCRIPTION = 'home-image-with-right-heading-and-description',
  HOME_IMAGE_LEFT_DESCRIPTION = 'home-image-with-left-heading-and-description',
  HOME_IMAGE_RIGHT_DESCRIPTION_BLUE = 'home-image-with-right-description-and-blue-theme',
  HOME_IMAGE_LEFT_DESCRIPTION_BLUE = 'home-image-with-left-description-and-blue-theme',
  HOME_IMAGE_RIGHT_DESCRIPTION_WITH_FLOATING = 'home-image-with-right-description-and-floating-window',
  HOME_IMAGE_LEFT_DESCRIPTION_WITH_FLOATING = 'home-image-with-left-description-and-floating-window',
  HOME_IMAGE_RIGHT_DESCRIPTION_FLOATING_WITH_ICONS = 'home-image-with-right-description-floating-window-and-icons',
  HOME_IMAGE_LEFT_DESCRIPTION_FLOATING_WITH_ICONS = 'home-image-with-left-description-floating-window-and-icons',
  HOME_IMAGE_TWO_RIGHT_DESCRIPTION_WITH_FLOATING = 'home-image-with-two-right-description-and-floating-comp',
  HOME_IMAGE_TWO_LEFT_DESCRIPTION_WITH_FLOATING = 'home-image-with-two-left-description-and-floating-comp',
  HOME_THREE_ICONS_HEADINGS_AND_DESCRIPTIONS = 'home-three-icons-headings-and-descriptions',
  HOME_THREE_ICONS_HEADINGS_AND_DESCRIPTIONS_NARROW = 'home-three-icons-headings-and-descriptions-narrow',
  HOME_SIX_ICONS_WITH_TITLES = 'home-six-icons-with-titles',
  HOME_FAQ = 'home-text-with-frequently-asked-questions',
  HOME_HERO_WIDE_THREE_ICONS = 'home-hero-wide-three-icons',
  HOME_HERO_WIDE_BUTTONS = 'home-hero-wide-buttons',
  HOME_CUSTOMER_REVIEWS = 'home-customer-reviews',
  HOME_REVIEWS_SINGLE = 'home-reviews-single',
  HOME_REVIEWS_SLIDER = 'home-reviews-slider',
  HOME_PACKAGES = 'home-packages',
  HOME_ADVANTAGES = 'home-advantages',
  HOME_SHOWCASE_BOX_HIGHLIGHT_LEFT = 'home-showcase-box-highlight-left',
  HOME_SHOWCASE_BOX_HIGHLIGHT_RIGHT = 'home-showcase-box-highlight-right',

  // Contacts
  CONTACTS_IMAGE_RIGHT_DESCRIPTION = 'contacts-image-with-right-heading-and-description',
  CONTACTS_IMAGE_LEFT_DESCRIPTION = 'contacts-image-with-left-heading-and-description',
  CONTACTS_IMAGE_LEFT_DESCRIPTION_FLOATING_WITH_ICONS = 'contacts-image-with-left-description-floating-window-and-icons',
  CONTACTS_OTHER_INFO_WITH_ICONS = 'contacts-other-info-with-icons',

  // FAQ
  FAQ = 'faq-text-with-frequently-asked-questions',
  FAQ2 = 'faq-built-in',
  FAQSELECT = 'faq-select',

  // PAGE
  PROCESS_HIGHLIGHT = 'process-highlight',
  WEBSITE_SCREENSHOT = 'website-screenshot',
  INFO_BLOCK_BLUE = 'info-block-blue',
  INFO_BLOCK_BORDERED = 'info-block-bordered',
  INFO_BLOCK_EXPAND = 'info-block-expand',
  HEADING_DESCRIPTION = 'heading-description',
  FOUR_COLUMN_CONTENT = 'four-column-content',
  TITLE_THREE_COLUMN_CONTENT = 'title-three-column-content',
  QUOTE_BLOCK = 'quote-block',
  TABLE_TWO_COLUMNS = 'table-two-columns',
  TABLE_THREE_COLUMNS = 'table-three-columns',
  TABLE_FOUR_COLUMNS = 'table-four-columns',
  TABLE_FIVE_COLUMNS = 'table-five-columns',
  TEAM_EMPLOYEES = 'team-employees',
  EXTRA_FEATURES = 'extra-features',
  PARTNERS = 'partners',
  PROJECT_HIGHLIGHTS = 'project-highlights',
  FEATURED_PRODUCTS = 'featured-products',
  ARCHIVE_REVIEW = 'archive-review',

  // Media
  MEDIA_CAROUSEL = 'media-carousel',
  MEDIA_SLIDER = 'media-slider',
  MEDIA_GALLERY = 'media-gallery',
  MEDIA_VIDEO = 'media-video',
}

export enum EditContentItemType {
  ELEMENT_MOVE = 'element-move',
  ELEMENT_INSERT = 'element-insert',
}

export interface IElement {
  id: number;
  name: ElementType;
}
