import { BlogContentInfoPanel } from "./BlogContentInfoPanel";
import { IElement } from "../../../models/Element";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { TextEditor } from "../../../../../components/TextEditor";

type BlogContentInfoPanelFCProps = {
  index: number;
  data: BlogContentInfoPanel;
  updateData: (body: IElement, index: number) => void;
};

export const BlogContentInfoPanelFC = ({
  index,
  data,
  updateData,
}: BlogContentInfoPanelFCProps) => {
  const update = (fieldsToUpdate: Partial<BlogContentInfoPanel>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="blog-content-info-panel body-my-8">
        <div className="blog-content">
          <TextEditor
            content={data.content}
            updateContent={(content) => update({ content })}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
