import { defaultPackageType, IPackageType } from './PackageType';
import { IProduct } from './Product';

export interface IPackageDialog {
  title: string;
  description: string;
  button_title: string;
  button_url: string;
}

export const defaultPackageDialog: IPackageDialog = {
  title: 'Verbonden pakket',
  description:
    'Alle prijzen in de offerte gelden voor 14 dagen en zijn exclusief btw. Een kopie van je aanvraag vind je trouwens ook in je mailbox',
  button_title: 'Ik weet genoeg',
  button_url: '',
};

export interface PriceUpTo {
  price_per_month: string;
  one_time_fee: string;
  up_to: string;
}

export const defaultPriceUpTo: PriceUpTo = {
  price_per_month: '',
  one_time_fee: '',
  up_to: '',
};

export interface IPackage {
  id: number;
  name: string;
  description: string;
  price_per_month: string;
  price_one_time: string;
  price_per_number_per_month: string;
  price_per_number_one_time: string;
  price_per_number_port_per_month: string;
  price_per_number_port_one_time: string;
  index: number;
  activate: number;
  show_on_verbonden: number;
  is_modifiable: number;
  products: Array<IProduct>;
  free_products: Array<IProduct>;
  show_products: Array<IProduct>;
  dialog?: IPackageDialog;
  quantitif_discount: number;
  price_up_to: Array<PriceUpTo> | null;

  package_type_id: number;
  package_type: IPackageType;
  calculate_discount_all_group_or_new_group: number;
}

export const defaultPackage: IPackage = {
  id: 0,
  name: '',
  description: '',
  price_per_month: '',
  price_one_time: '',
  price_per_number_per_month: '',
  price_per_number_one_time: '',
  price_per_number_port_per_month: '',
  price_per_number_port_one_time: '',
  index: 999,
  activate: 1,
  show_on_verbonden: 1,
  is_modifiable: 1,
  products: [],
  free_products: [],
  show_products: [],
  quantitif_discount: 1,
  price_up_to: [],

  package_type_id: 0,
  package_type: defaultPackageType,
  calculate_discount_all_group_or_new_group: 0,
};
