import { PageDataContainer, PageLink } from "../../../_start/layout/core";
import { inquiryManagementSubmenu } from "./InquiryManagementData";
import { InquiryManagementPage } from "./InquiryManagementPage";

const breadcrumbs: Array<PageLink> = [
	{
		title: "Home",
		path: "/",
		isActive: false,
	},
	{
		title: "Inquiry Management",
		path: "/inquiry-management",
		isActive: false,
	},
];

export const InquiryManagementWrapper = () => {
	return (
		<>
			<PageDataContainer
				breadcrumbs={breadcrumbs}
				submenu={inquiryManagementSubmenu}
			/>
			<InquiryManagementPage />
		</>
	);
};
