import React, { useRef } from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { RemoveElementButton } from '../../../../components/RemoveElementButton';

interface Props {
  idx: number;
  data: string;
  update: (to: string) => void;
  type: 'pro' | 'con';
  deleteItem: (idx: number) => void;
}

let deleting = false;

export const HomePackagesListItemProsCons: React.FC<Props> = ({
  idx,
  data,
  update,
  type,
  deleteItem,
}) => {
  const removeButtonRef = useRef<HTMLButtonElement>(null);
  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.remove('d-none');
    // addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = 'pointer';
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.add('d-none');
    // addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = 'auto';
  };
  if (type === 'pro') {
    return (
      <div
        className="pro"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {/* begin::Remove Button */}
        <RemoveElementButton
          index={idx}
          remove={(idx) => {
            deleting = true;
            setTimeout(() => {
              deleting = false;
            }, 100);
            deleteItem(idx);
            // deleting = false;
          }}
          buttonRef={removeButtonRef}
        />
        {/* end::Body Remove Button */}
        <svg
          className="icon"
          data-src="assets/icons/icon-check-circled.svg"
          aria-hidden="true"
          data-cache="604800"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          data-id="svg-loader_84"
        >
          <circle cx="16" cy="16" r="16" fill="currentColor"></circle>
          <path d="M13.75 22c-.28 0-.55-.12-.74-.32l-3.75-4.09a.996.996 0 0 1 .06-1.41.996.996 0 0 1 1.41.06l3.01 3.29 7.51-8.2c.37-.41 1-.43 1.41-.06.41.37.43 1.01.06 1.41l-8.25 9c-.19.21-.46.32-.74.32Z"></path>
        </svg>
        {/* <TextAreaEdit
          name={'pro'}
          value={data}
          setValue={(val) => update(val.pro)}
        /> */}
        <TextEditor
          content={data}
          updateContent={(val) => {
            if (!deleting) update(val);
          }}
        />
      </div>
    );
  }
  return (
    <div
      className="con"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* begin::Remove Button */}
      <RemoveElementButton
        index={idx}
        remove={(idx) => {
          deleting = true;
          setTimeout(() => {
            deleting = false;
          }, 100);
          deleteItem(idx);
          // deleting = false;
        }}
        buttonRef={removeButtonRef}
      />
      {/* end::Body Remove Button */}

      <svg
        className="icon"
        data-src="assets/icons/icon-cross.svg"
        aria-hidden="true"
        data-cache="604800"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
        xmlSpace="preserve"
        data-id="svg-loader_34"
      >
        <path d="m8.84 7.5 5.88-5.88A.95.95 0 0 0 13.38.28L7.5 6.16 1.62.28A.95.95 0 0 0 .28 1.62L6.16 7.5.28 13.38a1 1 0 0 0 0 1.34A.91.91 0 0 0 1 15a.94.94 0 0 0 .67-.28L7.5 8.84l5.88 5.88a.94.94 0 0 0 .67.28.92.92 0 0 0 .67-.28 1 1 0 0 0 0-1.34Z"></path>
      </svg>

      {/* <TextAreaEdit
        name={'pro'}
        value={data}
        setValue={(val) => {
          if (!deleting) update(val.pro);
        }}
      /> */}
      <TextEditor
        content={data}
        updateContent={(val) => {
          if (!deleting) update(val);
        }}
      />
    </div>
  );
};
