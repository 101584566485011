import { EditContentEvents } from '../../app/modules/edit-content/redux/EditContentEvents';
import { MediaLibraryEvents } from '../../app/modules/media-management/redux/MediaLibraryEvents';
import { SvgLibraryEvents } from '../../app/modules/media-management/redux/SvgLibraryEvents';
import { RedirectLinkEvents } from '../../app/modules/redirect-link-management/redux/RedirectLinkEvents';

type EventTypes =
  | EditContentEvents
  | MediaLibraryEvents
  | SvgLibraryEvents
  | RedirectLinkEvents;

export const makeBaseAction =
  <Event extends EventTypes>(type: Event) =>
  () => ({
    type,
  });

export const makePayloadAction =
  <E extends EventTypes, P>(type: E) =>
  (payload: P) => ({
    type,
    payload,
  });

export const makeParamsAction =
  <E extends EventTypes, A>(type: E) =>
  (args: A) => ({
    type,
    ...args,
  });

export const makePayloadWithParamsAction =
  <E extends EventTypes, P, A>(type: E) =>
  (payload: P, args: A) => ({
    type,
    payload,
    ...args,
  });

interface IStringMap<T> {
  [key: string]: T;
}

type IAnyFunction = (...args: any[]) => any;

/**
 *
 */
export type IActionUnion<A extends IStringMap<IAnyFunction>> = ReturnType<
  A[keyof A]
>;
