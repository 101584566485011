import React, { useEffect, useState } from 'react';
import { useRepositoryList } from '../../hooks/useRepositoryList';
import { API_URL } from '../../support/api';
import axios, { AxiosError } from 'axios';
import { ErrorResponse, customFailureToast, successToast } from '../../support/utils';
import CardListHeader from '../../components/card/CardListHeader';
import CardListBody from '../../components/card/CardListBody';
import CardListFooter from '../../components/card/CardListFooter';
import { IGoogleReview } from './GoogleReview';
import GoogleReviewsTable from './GoogleReviewsTable';

const GoogleReviewsPage = () => {
  const {
    paginatedValue,
    isPending,
    setIsPending,
    paginate,
    setPaginate,
    search,
    setSearch,
    handlePaginateState,
    selected,
    setSelected,
    handleDelete,
    setRefreshList,
  } = useRepositoryList<IGoogleReview>(`${API_URL}/v1/gmb/reviews`);

  const [authUrl, setAuthUrl] = useState<string>('');

  const authenticate = async () => {
    setIsPending(true);
    try {
      const response = await axios.get(
        `${API_URL}/v1/gmb/authenticate`
      );

      setAuthUrl(response.data.value.auth_url);
      successToast('redirecting...', 'Redirect to Google Login');

      setIsPending(false);
      setRefreshList(true);
    } catch (error) {
      const e = error as AxiosError<ErrorResponse>;
      customFailureToast({
        title: "GMB",
        message: e.response!.data.message
      });
      setIsPending(false);
    }
    setIsPending(false);
  };

  const syncGoogleReviews = async () => {
    setIsPending(true);
    try {
      const response = await axios.get(
        `${API_URL}/v1/gmb/sync-reviews`
      );

      successToast(response.data.value.message)
      setIsPending(false);
      setRefreshList(true);
    } catch (error) {
      const e = error as AxiosError<ErrorResponse>;
      customFailureToast({
        title: "GMB - Reviews",
        message: e.response!.data.message
      });
      setIsPending(false);
    }
    setIsPending(false);
    setRefreshList(false);

  };

  useEffect(() => {
    if (authUrl) {
      const anchor = document.createElement('a');
      anchor.href = authUrl;
      anchor.rel = 'noopener noreferrer';  // For security best practices
      anchor.style.display = 'none';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }, [authUrl]);

  return (
    <React.Fragment>
      <div
        className={`card card-custom card-flush ${isPending ? 'overlay overlay-block' : ''
          }`}
      >
        <CardListHeader
          paginate={paginate}
          setPaginate={setPaginate}
          search={search}
          setSearch={setSearch}
          toolbar={
            <div className='row'>
              <div className='col-6'>
                <button type="button"
                  className="btn btn-sm btn-light"
                  disabled={isPending}
                  onClick={authenticate}
                >
                  <span className="indicator-label">Authenticate</span>
                </button>
              </div>
              <div className='col-6'>
                <button type="button"
                  className='btn btn-sm btn-primary'
                  onClick={syncGoogleReviews}
                >
                  {!isPending && (
                    <span className="indicator-label">Synchroniseer</span>
                  )}
                  {isPending && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Please wait...{' '}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          }
        ></CardListHeader>

        <CardListBody isPending={isPending}>
          <GoogleReviewsTable
            data={paginatedValue.data}
            selected={selected}
            setSelected={setSelected}
            handleDelete={handleDelete}
          />
        </CardListBody>

        <CardListFooter
          isPending={isPending}
          paginatedValue={paginatedValue}
          handlePaginateState={handlePaginateState}
        />
      </div>
    </React.Fragment>
  );
};

export default GoogleReviewsPage;
