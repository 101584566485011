export interface IVoip {
  name: string;
  monthly_payment: string;
  one_time_payment: string;
}

export const defaultVoip: IVoip = {
  name: '',
  monthly_payment: '',
  one_time_payment: '',
};

export interface IUser {
  name: string;
  monthly_payment: string;
  one_time_payment: string;
}

export const defaultUser: IUser = {
  name: '',
  monthly_payment: '',
  one_time_payment: '',
};

export interface IPhone {
  name: string;
  monthly_payment: string;
  one_time_payment: string;
}

export const defaultPhone: IPhone = {
  name: '',
  monthly_payment: '',
  one_time_payment: '',
};

export interface IPhonePortering {
  name: string;
  monthly_payment: string;
  one_time_payment: string;
}

export const defaultPhonePortering: IPhonePortering = {
  name: '',
  monthly_payment: '',
  one_time_payment: '',
};

export interface IFreeModule {
  name: string;
  monthly_payment: string;
  one_time_payment: string;
}

export const defaultFreeModule: IFreeModule = {
  name: '',
  monthly_payment: '',
  one_time_payment: '',
};

export interface IAdditionalItems {
  voip: IVoip;
  users: IUser;
  phone: IPhone;
  phone_portering: IPhonePortering;
}

export const defaultAdditionalItems: IAdditionalItems = {
  voip: defaultVoip,
  users: defaultUser,
  phone: defaultPhone,
  phone_portering: defaultPhonePortering,
};

export interface IInvoice {
  id: number;

  payment_type: string;
  voip_accounts: string | null;
  user_accounts: string | null;
  new_regional_numbers: string;
  new_nationwide_numbers: string;
  migrate_regional_numbers: string;
  migrate_nationwide_numbers: string;
  additional_items: IAdditionalItems;
  free_modules: Array<IFreeModule>;
  total_monthly_price: string;
  total_one_time_price: string;
}

export const defaultInvoice: IInvoice = {
  id: 0,

  payment_type: '',
  voip_accounts: '',
  user_accounts: '',
  new_regional_numbers: '',
  new_nationwide_numbers: '',
  migrate_regional_numbers: '',
  migrate_nationwide_numbers: '',
  additional_items: defaultAdditionalItems,
  free_modules: [],
  total_monthly_price: '',
  total_one_time_price: '',
};
