import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IDepartment } from "../models/Department";

export const BASE_DEPARTMENT_URL = `${API_URL}/v1/employee-management/departments`;
export const GET_ALL_DEPARTMENT_URL = `${API_URL}/v1/employee-management/departments/get-all`;
export const CREATE_DEPARTMENT_URL = `${API_URL}/v1/employee-management/departments/create`;

export const listDepartment = async (search = "", direction = "asc") => {
	const URL = `${BASE_DEPARTMENT_URL}/list?search=${search}&direction=${direction}`;
	const {
		data: { value },
	} = await axios.get<{ value: PaginatedValue<IDepartment> }>(URL);

	return value;
};

export const createDepartment = async (payload: IDepartment) => {
	const {
		data: { value },
	} = await axios.post<{ value: IDepartment }>(
		CREATE_DEPARTMENT_URL,
		payload
	);

	return value;
};

export const viewDepartment = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: IDepartment }>(
		`${BASE_DEPARTMENT_URL}/${id}/get`
	);

	return value;
};

export const updateDepartment = async (payload: IDepartment) => {
	const {
		data: { value },
	} = await axios.post<{ value: IDepartment }>(
		`${BASE_DEPARTMENT_URL}/${payload.id}/update`,
		payload
	);

	return value;
};

export const deleteDepartment = async (payload: IDepartment) => {
	const {
		data: { value },
	} = await axios.post<{ value: IDepartment }>(
		`${BASE_DEPARTMENT_URL}/${payload.id}/delete`,
		payload
	);

	return value;
};

// unpaginated categories
export const getAllDepartment = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: Array<IDepartment> }>(GET_ALL_DEPARTMENT_URL);

	return value;
};
