import immutabilityHelper from "immutability-helper";
import React from "react";
import { KTSVG } from "../../../../../../_start/helpers";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { HeaderSelectWrapper } from "../../../components/HeaderSelectWrapper";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { IElement } from "../../../models/Element";
import {
  ColumnContent,
  defaultColumnContent,
  TitleThreeColumnContent,
} from "./TitleThreeColumnContent";
import { TitleThreeColumnContentItem } from "./TitleThreeColumnContentItem";

interface Props {
  index: number;
  data: TitleThreeColumnContent;
  updateData: (body: IElement, index: number) => void;
}

export const TitleThreeColumnContentFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<TitleThreeColumnContent>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const updateItem = (idx: number, item: ColumnContent) => {
    let contents = [...data.contents];
    contents[idx] = item;

    const updatedData = { ...data, contents };
    update(updatedData);
  };

  const addItem = () => {
    const contents = immutabilityHelper(data.contents, {
      $push: [defaultColumnContent],
    });
    const updatedData = { ...data, contents };
    update(updatedData);
  };

  const removeItem = (itemIndex: number) => {
    let contents = immutabilityHelper(data.contents, {
      $splice: [[itemIndex, 1]],
    });
    const updatedData = { ...data, contents };
    update(updatedData);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="title-three-column-content">
        <HeaderSelectWrapper
          name={"heading_type"}
          value={data.heading_type}
          setValue={update}
        >
          <TextAreaEdit
            className="title"
            name={"heading"}
            value={data.heading}
            setValue={update}
          />
        </HeaderSelectWrapper>
        <div className="grid">
          {data.contents.map((item, index) => (
            <TitleThreeColumnContentItem
              itemIndex={index}
              data={item}
              allData={data}
              updateItem={updateItem}
              removeItem={removeItem}
            />
          ))}

          <div onClick={addItem} className="add-item">
            <div className="m-auto d-flex flex-column">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-muted svg-icon-3hx text-center"
              />
              <span className="mt-4 text-gray-500">Add</span>
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
