import { BlogContentWideDescriptionTwoColumn } from './BlogContentWideDescriptionTwoColumn';
import { IElement } from '../../../models/Element';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { TextEditor } from '../../../../../components/TextEditor';
import { ElementHeaderType } from '../../utils';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';

type BlogContentWideDescriptionTwoColumnFCProps = {
  index: number;
  data: BlogContentWideDescriptionTwoColumn;
  updateData: (body: IElement, index: number) => void;
};

export const BlogContentWideDescriptionTwoColumnFC = ({
  index,
  data,
  updateData,
}: BlogContentWideDescriptionTwoColumnFCProps) => {
  const update = (
    fieldsToUpdate: Partial<BlogContentWideDescriptionTwoColumn>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="blog-content-wide-description-two-column body-my-8">
        <div className="blog-content">
          {/* begin::Title */}
          <HeaderSelectWrapper
            name={`title_type`}
            value={data.title_type ?? ElementHeaderType.H2}
            setValue={update}
          >
            <TextAreaEdit
              name="title"
              className={`blog-content__title`}
              value={data.title}
              setValue={update}
            />
          </HeaderSelectWrapper>
          {/* end::Title */}

          <TextEditor
            content={data.content}
            updateContent={(content) => update({ content })}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
