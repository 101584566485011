import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IPermission } from "../pages/permissions/Permission";

export const listPermissions = async (search = "") => {
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IPermission> }>(
    `${API_URL}/v1/role-and-permission/permissions?search=${search}`
  );

  return value;
};

export const viewPermisison = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IPermission }>(
    `${API_URL}/v1/role-and-permission/permissions/${id}/view`
  );

  return value;
};

export const updatePermission = async (payload: IPermission) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPermission }>(
    `${API_URL}/v1/role-and-permission/permissions/${payload.id}/update`,
    payload
  );

  return value;
};
