import React from 'react';

type RowProps = {
  style?: React.CSSProperties | undefined;
  className?: string;
  rowRef?: React.LegacyRef<HTMLTableRowElement> | undefined;
};

export const Row: React.FC<RowProps> = ({
  children,
  className = '',
  rowRef = undefined,
  style = undefined,
}) => {
  return (
    <tr style={style} ref={rowRef} className={className}>
      {children}
    </tr>
  );
};
