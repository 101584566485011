import { PaginatedValue } from '../../../support/utils';
import { IMedia } from '../models/Media';
import { SvgLibraryActions } from './SvgLibraryActions';
import { SvgLibraryEvents } from './SvgLibraryEvents';

export interface ISvgLibraryState {
  initialized: boolean;
  paginatedValue: PaginatedValue<IMedia>;
}

const defaultMediaLibraryState: ISvgLibraryState = {
  initialized: false,
  paginatedValue: new PaginatedValue(),
};

export const reducer = (
  state: ISvgLibraryState = defaultMediaLibraryState,
  action: SvgLibraryActions
): ISvgLibraryState => {
  switch (action.type) {
    case SvgLibraryEvents.SET_SVG_PAGINATED_VALUE:
      return {
        ...state,
        initialized: true,
        paginatedValue: action.payload,
      };

    case SvgLibraryEvents.SET_SEARCH_SVG_PAGINATED_VALUE:
      return {
        ...state,
        paginatedValue: action.payload,
      };

    default:
      return state;
  }
};
