import { PageLink } from "../../../_start/layout/core";

export const mediaManagementSubmenu: Array<PageLink> = [
	// {
	// 	title: "Content",
	// 	path: "/media-management/content",
	// 	isActive: true,
	// },
	{
		title: "Media Library",
		path: "/media-management/media-library",
		isActive: true,
	},
    {
		title: "SVG Library",
		path: "/media-management/svg-library",
		isActive: true,
	},
];
