import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export type ArchiveReview = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
};

export const defaultArchiveReview: ArchiveReview = {
  id: 0,
  name: ElementType.ARCHIVE_REVIEW,
  heading: 'Dit zijn al onze reviews',
  heading_type: ElementHeaderType.H1,
  description: `<p>Zij gaven gemiddeld een 9.8 op Feedback Company</p>`,
};
