/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../../../setup';
import { failureToast, successToast } from '../../../support/utils';
import { IRedirectLink } from '../models/RedirectLink';
import * as redirectLinkActions from '../redux/RedirectLinkActions';
import {
  getAllRedirectLink,
  manageRedirectLink,
} from '../redux/RedirectLinkCRUD';

export const ManageRedirectLinkPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  // const loading = useSelector(
  //   (state: RootState) => state.redirectLinkManagement.loading
  // );

  const [loading, setLoading] = useState(false);
  const [redirectLinks, setRedirectLinks] = useState<Array<IRedirectLink>>([]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // dispatch(
    //   redirectLinkActions.manageRedirectLinkRequest({
    //     payload: formValues,
    //   })
    // );
    setLoading(true);
    try {
      //@ts-ignore
      manageRedirectLink({ links: formValues });
      successToast('Saved successfully');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  //dwiff's meh
  const [formValues, setFormValues] = useState([{ url: '', target: '' }]);

  useEffect(() => {
    let newArray: any[] = [];
    redirectLinks.map((item) => {
      newArray.push({ url: item.url, target: item.target });
      setFormValues(newArray);
    });
  }, [redirectLinks]);

  const handleChange = (i: any, e: any) => {
    const newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([...formValues, { url: '', target: '' }]);
  };

  const removeFormFields = (i: any) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const handleFetchRedirectLinks = async () => {
    setLoading(true);
    try {
      const result = await getAllRedirectLink();
      setRedirectLinks(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchRedirectLinks();
  }, []);
  //end of dwiff's meh

  // handle successful request
  const createdLink = useSelector(
    (state: RootState) => state.redirectLinkManagement.createdRedirectLink
  );
  useEffect(() => {
    if (createdLink.id !== 0) {
      history.goBack();
      dispatch(redirectLinkActions.resetCreateRedirectLink());
    }
  }, [createdLink]);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Manage Links</h3>
      </div>

      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">URL</label>
            <div className="col-lg-9">
              <div className="form-text">
                Do not prepend <strong>"https://verbonden.nl"</strong> on the URL input, instead use relative path
                such as <strong>"example-redirect"</strong> which will then be accessible as
                <strong>"https://verbonden.nl/link/example-redirect"</strong>.
              </div>
            </div>
          </div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Target</label>
            <div className="col-lg-9">
              <div className="form-text">
                Should use a valid full url such as {` `}
                <strong>"https://example.com/sample-page-1"</strong> for the which will be the target URL
                for redirecting.
              </div>
            </div>
          </div>
          {/* end::Form row */}
          {formValues.map((element, index) => (
            <div className="row my-8" key={index}>
              <div className="form-floating col-5">
                <input
                  id="floatingUrl"
                  type="text"
                  name="url"
                  className="form-control"
                  value={element.url || ''}
                  onChange={(e) => handleChange(index, e)}
                />
                <label htmlFor="floatingUrl">URL</label>
              </div>
              <div className="form-floating col-5">
                <input
                  id="floatingTarget"
                  type="text"
                  name="target"
                  className="form-control"
                  value={element.target || ''}
                  onChange={(e) => handleChange(index, e)}
                />
                <label htmlFor="floatingTarget">Target</label>
              </div>
              <div className="col-2">
                <button
                  style={{ marginRight: '5px' }}
                  className="btn btn-info"
                  type="button"
                  onClick={() => addFormFields()}
                >
                  +
                </button>
                {index ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => removeFormFields(index)}
                    >
                      X
                    </button>
                  </>
                ) : null}
              </div>
            </div>
          ))}
          <div className="row">
            <div>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to="redirect-link-management/links"
              >
                Cancel
              </Link>
            </div>
          </div>
        </div>
        {/* begin::Form row */}
        {/* end::Form row */}
      </form>
      {/* end::Form */}
    </div>
  );
};
