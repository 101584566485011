/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { PropsWithChildren } from 'react';

import { KTSVG } from '../../../../../_start/helpers';
import { FormSvgFieldModal } from './FormSvgFieldModal';

/* eslint-disable jsx-a11y/anchor-has-content */
type FormImageFieldProps = {
  name: string;
  src: string;
  update: (fieldsToUpdate: Partial<any>) => void;
  modalId: string;
  imageWidth?: string;
  imageHeight?: string;
};

export const FormSvgField = ({
  modalId,
  imageWidth = '300px',
  imageHeight = '200px',
  name,
  src,
  update,
  children,
}: PropsWithChildren<FormImageFieldProps>) => {
  return (
    <>
      {/* begin::Media Modal */}

      <FormSvgFieldModal modalId={modalId} name={name} update={update} />

      {/* end::Media Modal */}

      {/* begin::Media Preview */}
      <div className="position-relative w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover overlay-wrapper">
        {src ? (
          <img
            alt="Alt"
            className="m-auto"
            src={src}
            style={{
              width: imageWidth,
              height: imageHeight,
              objectFit: 'cover',
            }}
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          />
        ) : (
          <div
            className="m-auto d-flex flex-column"
            data-bs-toggle="modal"
            data-bs-target={`#${modalId}`}
          >
            <KTSVG
              path="/media/icons/duotone/Interface/Image.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
            <span className="mt-4 text-gray-500">Select media</span>
          </div>
        )}
      </div>
      {/* end::Media Preview */}

      {/* begin::Media Actions */}
      <div
        className={`mt-3 d-flex gap-3 justify-content-center ${
          src ? '' : 'd-none'
        }`}
      >
        <a
          className="text-dark underline"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
        >
          Update
        </a>
        {`|`}
        <a
          className="text-dark underline"
          onClick={() => update({ [name]: '' })}
        >
          Remove
        </a>
      </div>
      {/* end::Media Actions */}
    </>
  );
};
