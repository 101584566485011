import { PaginatedValue } from '../../support/utils';
import { Pagination } from '../Pagination';

type CardListFooterProps = {
  isPending: boolean;
  paginatedValue: PaginatedValue;
  handlePaginateState: (url: string) => Promise<void>;
};

const CardListFooter = ({
  isPending,
  paginatedValue,
  handlePaginateState,
}: CardListFooterProps) => {
  return (
    <div className="card-footer">
      <Pagination
        loading={isPending}
        pagination={paginatedValue}
        handlePaginateState={handlePaginateState}
      />
    </div>
  );
};

export default CardListFooter;
