import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IFooter } from '../models/Footer';

export const BASE_FOOTER_URL = `${API_URL}/v1/layout/footer`;
export const GET_ALL_FOOTER_URL = `${API_URL}/v1/layout/footer/get-all`;
export const CREATE_FOOTER_URL = `${API_URL}/v1/layout/footer`;

export const listFooter = async (search = '', direction = 'asc') => {
  const URL = `${BASE_FOOTER_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IFooter> }>(URL);

  return value;
};

export const createFooter = async (payload: IFooter) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFooter }>(CREATE_FOOTER_URL, payload);

  return value;
};

export const viewFooter = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IFooter }>(`${BASE_FOOTER_URL}/${id}/view`);

  return value;
};

export const updateFooter = async (payload: IFooter) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFooter }>(
    `${BASE_FOOTER_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteFooter = async (payload: IFooter) => {
  const {
    data: { value },
  } = await axios.post<{ value: IFooter }>(
    `${BASE_FOOTER_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllFooter = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IFooter> }>(GET_ALL_FOOTER_URL);

  return value;
};
