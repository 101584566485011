/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ActionsDropdown } from "../../../../components/ActionsDropdown";
import { Pagination } from "../../../../components/Pagination";
import {
	failureToast,
	handlePaginate,
	PaginatedValue,
} from "../../../../support/utils";
import { ICallRate } from "../../models/CallRate";
import { listCallRate } from "../../redux/CallRateCRUD";

export const CallRatesPage = () => {
	const [loading, setLoading] = useState(false);

	const [initialized, setInitialized] = useState(false);

	/**
	 * PAGINATION
	 */
	const [paginatedValue, setPaginatedValue] = useState<
		PaginatedValue<ICallRate>
	>(new PaginatedValue());

	const handlePaginateState = async (page_url: string) => {
		setLoading(true);
		try {
			const result = await handlePaginate<ICallRate>(page_url);
			setPaginatedValue(result);
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	/**
	 * INITIALIZATION
	 */
	const handleFetchList = async () => {
		setLoading(true);
		try {
			const result = await listCallRate();
			setPaginatedValue(result);
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!initialized) {
			handleFetchList();
			setInitialized(true);
		}
	}, []);

	return (
		<>
			<div
				className={`card card-custom card-flush ${
					loading ? "overlay overlay-block" : ""
				}`}
			>
				<div
					className={`card-body py-5 ${
						loading ? "overlay-wrapper" : ""
					}`}
				>
					<table className="table table-row-gray-300 gy-7">
						<thead>
							<tr className="fw-bolder fs-6 text-gray-800">
								<th>Name</th>
								<th>Tafief p/m</th>
								<th>Starttarief</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{paginatedValue.data.map((element, idx) => (
								<tr key={idx} className="align-middle">
									<td>{element.name}</td>
									<td>{element.monthly}</td>
									<td>{element.starting_rate}</td>
									<td className="text-end">
										{/* begin::Dropdown */}
										<ActionsDropdown>
											<div className="menu-item px-3">
												<Link
													to={`call-rates/${element.id}/update`}
													className="menu-link px-3"
												>
													Update
												</Link>
											</div>
											<div className="separator mt-3 opacity-75"></div>
										</ActionsDropdown>
										{/* end::Dropdown */}
									</td>
								</tr>
							))}
						</tbody>
					</table>
					{loading && (
						<div className="overlay-layer rounded bg-dark bg-opacity-5">
							<div
								className="spinner-border text-primary"
								role="status"
							>
								<span className="visually-hidden">
									Loading...
								</span>
							</div>
						</div>
					)}
				</div>
				<div className="card-footer">
					<Pagination
						loading={loading}
						pagination={paginatedValue}
						handlePaginateState={handlePaginateState}
					/>
				</div>
			</div>
		</>
	);
};
