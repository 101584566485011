import { IElement } from '../models/Element';
import { editContentActions, EditContentActions } from './EditContentActions';
import { EditContentEvents } from './EditContentEvents';

import immutabilityHelper from 'immutability-helper';
import { put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../../../../setup';
import { defaultPost, IPost } from '../../post-management/models/Post';
import { IEmployee } from '../../employee-management/models/Employee';
import { defaultPage, IPage } from '../../page-management/models/Page';
import { IPackage } from '../../content-management/models/Package';
import { PaginatedValue } from '../../../support/utils';
import { IMedia } from '../../media-management/models/Media';
import { IPartner } from '../../client-management/models/Partner';
import { IProduct } from '../../content-management/models/Product';

export interface IEditContentState {
  content: Array<IElement>;
  post: IPost;
  page: IPage;
  allPosts: Array<IPost>;
  allEmployees: Array<IEmployee>;
  allPackages: Array<IPackage>;
  allProducts: Array<IProduct>;
  allPartners: Array<IPartner>;
  featuredPackages: Array<IPackage>;
  featuredProducts: Array<IProduct>;

  partners: Array<IPartner>;

  /**
   * MEDIA LIBRARY
   */
  media_library: PaginatedValue<IMedia>;
  file_library: PaginatedValue<IMedia>;
  svg_library: PaginatedValue<IMedia>;
}

const defaultEditContentState: IEditContentState = {
  content: [],
  post: defaultPost,
  page: defaultPage,
  allPosts: [],
  allEmployees: [],
  allPackages: [],
  allProducts: [],
  allPartners: [],

  featuredPackages: [],
  featuredProducts: [],
  partners: [],

  media_library: new PaginatedValue(),
  file_library: new PaginatedValue(),
  svg_library: new PaginatedValue(),
};

export const reducer = (
  state: IEditContentState = defaultEditContentState,
  action: EditContentActions
): IEditContentState => {
  switch (action.type) {
    case EditContentEvents.SET_CONTENT:
      return {
        ...state,
        content: action.payload,
      };

    case EditContentEvents.SET_POST:
      return {
        ...state,
        post: action.payload,
        featuredPackages: action.payload.featured_packages,
      };

    case EditContentEvents.SET_PAGE:
      return {
        ...state,
        page: action.payload,
        featuredPackages: action.payload.featured_packages,
      };

    case EditContentEvents.SET_ALL_POSTS:
      return {
        ...state,
        allPosts: action.payload,
      };

    case EditContentEvents.SET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payload,
      };

    case EditContentEvents.SET_ALL_PACKAGES:
      return {
        ...state,
        allPackages: action.payload,
      };

    case EditContentEvents.SET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload,
      };

    case EditContentEvents.SET_ALL_PARTNERS:
      return {
        ...state,
        allPartners: action.payload,
      };

    case EditContentEvents.SET_AUTHOR:
      return {
        ...state,
        post: {
          ...state.post,
          author_id: action.payload.id,
          author: action.payload,
        },
      };

    case EditContentEvents.SET_FEATURED_PACKAGES:
      return {
        ...state,
        featuredPackages: action.payload,
      };

    case EditContentEvents.SET_FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProducts: action.payload,
      };

    case EditContentEvents.SET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };

    case EditContentEvents.SET_MEDIA_LIBRARY:
      return {
        ...state,
        media_library: action.payload,
      };

    case EditContentEvents.SET_FILE_LIBRARY:
      return {
        ...state,
        file_library: action.payload,
      };

    case EditContentEvents.SET_SVG_LIBRARY:
      return {
        ...state,
        svg_library: action.payload,
      };

    default:
      return state;
  }
};

const generateUniqueComponentId = (items: Array<IElement>): number => {
  if (items.length === 0) {
    return 0;
  }

  let sortedItems = [...items].sort((a, b) => {
    return a.id - b.id;
  });

  return sortedItems[sortedItems.length - 1].id + 1;
};

const moveBodyComponent = (
  bodyList: Array<IElement>,
  dragIndex: number,
  hoverIndex: number
) => {
  return immutabilityHelper(bodyList, {
    $splice: [
      [dragIndex, 1],
      [hoverIndex, 0, bodyList[dragIndex] as IElement],
    ],
  });
};

const updateBodyComponent = (
  bodyList: Array<IElement>,
  body: IElement,
  index: number
) => {
  return immutabilityHelper(bodyList, { [index]: { $set: body } });
};

const removeBodyComponent = (bodyList: Array<IElement>, index: number) => {
  return immutabilityHelper(bodyList, {
    $splice: [[index, 1]],
  });
};

// SELECTOR
const content = (state: RootState) => state.editContent.content;

export function* saga() {
  yield takeLatest(
    EditContentEvents.INSERT_ELEMENT,
    function* insertingElement(
      action: ReturnType<typeof editContentActions.insertElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      let contentSnapshot = [...stateContent];

      // assign an id, this is importantly needed for the move method
      const component: IElement = {
        ...action.element,
        id: generateUniqueComponentId(contentSnapshot),
      };

      contentSnapshot.push(component);

      yield put(editContentActions.setContent(contentSnapshot));
    }
  );

  yield takeLatest(
    EditContentEvents.MOVE_ELEMENT,
    function* movingBody(
      action: ReturnType<typeof editContentActions.moveElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      const updatedBody = moveBodyComponent(
        stateContent,
        action.dragIndex,
        action.hoverIndex
      );

      yield put(editContentActions.setContent(updatedBody));
    }
  );

  yield takeLatest(
    EditContentEvents.REMOVE_ELEMENT,
    function* removingBody(
      action: ReturnType<typeof editContentActions.removeElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      const updatedBody = removeBodyComponent(stateContent, action.index);

      yield put(editContentActions.setContent(updatedBody));
    }
  );

  yield takeLatest(
    EditContentEvents.UPDATE_ELEMENT,
    function* updatingBody(
      action: ReturnType<typeof editContentActions.updateElement>
    ) {
      const stateContent: Array<IElement> = yield select(content);

      const updatedBody = updateBodyComponent(
        stateContent,
        action.element,
        action.index
      );

      yield put(editContentActions.setContent(updatedBody));
    }
  );
}
