/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select, { MultiValue } from 'react-select';
import { KTSVG } from '../../../../../_start/helpers';
import { failureToast, successToast } from '../../../../support/utils';

import {
  defaultPackage,
  defaultPriceUpTo,
  IPackage,
  PriceUpTo,
} from '../../models/Package';
import { IPackageType } from '../../models/PackageType';
import { IProduct } from '../../models/Product';
import { createPackage } from '../../redux/PackageCRUD';
import { getAllPackageType } from '../../redux/PackageTypeCRUD';
import { getAllProduct } from '../../redux/ProductCRUD';

export const CreatePackage = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IPackage>(defaultPackage);

  const updateData = (fieldsToUpdate: Partial<IPackage>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const updateDiscounts = (to: Partial<PriceUpTo>, index: number) => {
    const price_up_to = [...(data.price_up_to ?? [])];
    price_up_to[index] = { ...price_up_to[index], ...to };
    updateData({ price_up_to });
  };

  const addDiscount = () => {
    const price_up_to = [...(data.price_up_to ?? [])];
    price_up_to.push(defaultPriceUpTo);
    updateData({ price_up_to });
  };

  const removeDiscount = (index: number) => {
    const price_up_to = [...(data.price_up_to ?? [])];
    price_up_to.splice(index, 1);
    updateData({ price_up_to });
  };

  const toggleCheckbox = () => {
    const calculate_discount_all_group_or_new_group =
      data.calculate_discount_all_group_or_new_group;
    updateData({
      calculate_discount_all_group_or_new_group:
        calculate_discount_all_group_or_new_group === 0 ? 1 : 0,
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      const payload: IPackage = {
        ...data,
        // @ts-ignore
        products: selectedProducts.map((item) => item.id),
        // @ts-ignore
        free_products: selectedFreeProducts.map((item) => item.id),
        // @ts-ignore
        show_products: selectedShowProducts.map((item) => item.id),
      };

      await createPackage(payload);
      successToast('Package has been created.');
      setLoading(false);
      setData(defaultPackage);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  // removing unnecessary re-render
  // and calling the same api/s for initilization
  const [initialized, setInitialized] = useState(false);

  const [products, setProducts] = useState<Array<IProduct>>([]);

  const [packageTypes, setPackageTypes] = useState<Array<IPackageType>>([]);

  const [selectedProducts, setSelectedProducts] = useState< MultiValue<IProduct>>([]);
  const [selectedFreeProducts, setSelectedFreeProducts] = useState<MultiValue<IProduct>>([]);
  const [selectedShowProducts, setSelectedShowProducts] = useState<MultiValue<IProduct>>([]);

  const handleInitialization = async () => {
    setLoading(true);

    try {
      const products = await getAllProduct();
      setProducts(products);

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handlePackageTypes = async () => {
    setLoading(true);

    try {
      const packageTypes = await getAllPackageType();
      setPackageTypes(packageTypes);

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleInitialization();
      handlePackageTypes();
      setInitialized(true);
    }
  }, []);

  const filterOptions = (options: IProduct[], selected: MultiValue<IProduct>) => {
    return options.filter((option) => !selected.some((item) => item.id === option.id));
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Create Package</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) =>
                    updateData({
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Package Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package Type..."
                value={data.package_type_id}
                onChange={(e) =>
                  updateData({
                    package_type_id: parseInt(e.target.value),
                  })
                }
              >
                {packageTypes.map((item, idx) => (
                  <option value={item.id} key={idx}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              {data.package_type_id === 3
                ? `Price per user (p/m)`
                : `Price per voip account (p/m)`}
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter price"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.price_per_month}
                  onChange={(e) =>
                    updateData({
                      price_per_month: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              {data.package_type_id === 3
                ? `Price per user (one time)`
                : `Price per voip account (one time)`}
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter price"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.price_one_time}
                  onChange={(e) =>
                    updateData({
                      price_one_time: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Price per number (p/m)
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter price"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.price_per_number_per_month}
                  onChange={(e) =>
                    updateData({
                      price_per_number_per_month: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Price per number (one time)
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter price"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.price_per_number_one_time}
                  onChange={(e) =>
                    updateData({
                      price_per_number_one_time: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {data.package_type_id === 3 && (
            <>
              {/* begin::Form row */}
              <div className="row mb-8">
                <label className="col-lg-3 col-form-label">
                  Price per number port (p/m)
                </label>
                <div className="col-lg-9">
                  <div className="spinner spinner-sm spinner-primary spinner-right">
                    <input
                      placeholder="Enter price"
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                      value={data.price_per_number_port_per_month}
                      onChange={(e) =>
                        updateData({
                          price_per_number_port_per_month: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              {/* end::Form row */}

              {/* begin::Form row */}
              <div className="row mb-8">
                <label className="col-lg-3 col-form-label">
                  Price per number port (one time)
                </label>
                <div className="col-lg-9">
                  <div className="spinner spinner-sm spinner-primary spinner-right">
                    <input
                      placeholder="Enter price"
                      className="form-control form-control-lg form-control-solid"
                      type="text"
                      value={data.price_per_number_port_one_time}
                      onChange={(e) =>
                        updateData({
                          price_per_number_port_one_time: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>
              </div>
              {/* end::Form row */}
            </>
          )}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Activate</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select activate..."
                value={data.activate}
                onChange={(e) =>
                  updateData({
                    activate: parseInt(e.target.value),
                  })
                }
              >
                <option value={1}>YES</option>
                <option value={0}>NO</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Show on Verbonden</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select show on verbonden..."
                value={data.show_on_verbonden}
                onChange={(e) =>
                  updateData({
                    show_on_verbonden: parseInt(e.target.value),
                  })
                }
              >
                <option value={1}>YES</option>
                <option value={0}>NO</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Modifiable</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select if package is modifiable by customer..."
                value={data.is_modifiable}
                onChange={(e) =>
                  updateData({
                    is_modifiable: parseInt(e.target.value),
                  })
                }
              >
                <option value={1}>YES</option>
                <option value={0}>NO</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Free Products</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select items...`}
                value={selectedFreeProducts}
                onChange={setSelectedFreeProducts}
                getOptionValue={(model: IProduct) => model.id.toString()}
                getOptionLabel={(model: IProduct) => model.name}
                options={filterOptions(products, [...selectedProducts, ...selectedShowProducts])}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Paid Products</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select items...`}
                value={selectedProducts}
                onChange={setSelectedProducts}
                getOptionValue={(model: IProduct) => model.id.toString()}
                getOptionLabel={(model: IProduct) => model.name}
                options={filterOptions(products, [...selectedFreeProducts, ...selectedShowProducts])}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Show to Modules Dialog</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Select items...`}
                value={selectedShowProducts}
                onChange={setSelectedShowProducts}
                getOptionValue={(model: IProduct) => model.id.toString()}
                getOptionLabel={(model: IProduct) => model.name}
                options={filterOptions(products, [...selectedFreeProducts, ...selectedProducts])}
                isMulti
                isSearchable
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Quantitif discount
            </label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select if package is modifiable by customer..."
                value={data.quantitif_discount}
                onChange={(e) =>
                  updateData({
                    quantitif_discount: parseInt(e.target.value),
                  })
                }
              >
                <option value={1}>YES</option>
                <option value={0}>NO</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {data.quantitif_discount === 1 && (
            <>
              <div className="row mb-8">
                <label className="col-lg-3 col-form-label">
                  Price per/up to
                </label>
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col col-form-label text-center">
                      price per month
                    </div>
                    <div className="col col-form-label text-center">
                      one time fee
                    </div>
                    <div className="col col-form-label text-center">up to</div>
                  </div>
                  {data.price_up_to?.map((val, idx) => (
                    <div className="row mb-8 position-relative pt-6">
                      <span
                        onClick={() => removeDiscount(idx)}
                        className="position-absolute top-0 z-index-2 opacity-75-hover"
                      >
                        <KTSVG
                          path="/media/icons/duotone/Interface/Minus-Square.svg"
                          className="svg-icon-danger svg-icon-1"
                        />
                      </span>
                      <div className="col">
                        <div className="spinner spinner-sm spinner-primary spinner-right">
                          <input
                            placeholder="Enter price"
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            value={val.price_per_month}
                            onChange={(e) =>
                              updateDiscounts(
                                {
                                  price_per_month: e.target.value,
                                },
                                idx
                              )
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="spinner spinner-sm spinner-primary spinner-right">
                          <input
                            placeholder="Enter price"
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            value={val.one_time_fee}
                            onChange={(e) =>
                              updateDiscounts(
                                {
                                  one_time_fee: e.target.value,
                                },
                                idx
                              )
                            }
                            required
                          />
                        </div>
                      </div>

                      <div className="col">
                        <div className="spinner spinner-sm spinner-primary spinner-right">
                          <input
                            placeholder="Enter price"
                            className="form-control form-control-lg form-control-solid"
                            type="text"
                            value={val.up_to}
                            onChange={(e) =>
                              updateDiscounts(
                                {
                                  up_to: e.target.value,
                                },
                                idx
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    onClick={addDiscount}
                    className="position-relative d-flex justify-content-center align-items-center border-dashed border-1"
                    style={{ height: '5rem', width: '100%' }}
                  >
                    <div className="m-auto d-flex flex-column">
                      <KTSVG
                        path="/media/icons/duotone/Interface/Plus-Square.svg"
                        className="svg-icon-muted svg-icon-3hx text-center"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-8 d-flex justify-content-center align-items-center">
                <label className="col-lg-3 col-form-label"></label>
                <div className="col-lg-9">
                  <div className="row form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      checked={
                        data.calculate_discount_all_group_or_new_group === 0
                      }
                      onChange={toggleCheckbox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      calculate discount for all groups
                    </label>
                  </div>
                  <div className="row form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckChecked"
                      checked={
                        data.calculate_discount_all_group_or_new_group === 1
                      }
                      onChange={toggleCheckbox}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked"
                    >
                      calculate discount from the new group
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/content-management/packages`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
