import React from 'react';
import { v4 as uuid } from 'uuid';
import { KTSVG } from '../../../../../../../_start/helpers';
import { AttachSvgModal } from '../../../../components/AttachSvgModal';
import { IPackageItemDetail } from './HomePackages';

interface Props {
  idx: number;
  data: IPackageItemDetail;
  update: (to: Partial<IPackageItemDetail>, idx: number) => void;
}

export const HomePackagesListItemIcon: React.FC<Props> = ({
  idx,
  data,
  update,
}) => {
  const id = uuid();

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files!;
    let fileContent: string | ArrayBuffer | null = '';

    const fr = new FileReader();
    fr.onload = () => {
      fileContent = fr.result;
      update(
        {
          [event.target.name]: fileContent,
        },
        idx
      );
    };

    fr.readAsText(fileList[0]);
  };
  return (
    <div>
      <AttachSvgModal
        modalId={`home-packages-${idx}-${data.id}-icon`}
        updateCallback={(icon_url) => update({ icon_url }, idx)}
      />
      <div className="overlay">
        <div className="overlay-layer bg-dark bg-opacity-10">
          <label
            className="px-1 btn btn-sm btn-icon btn-color-primary btn-light-primary"
            data-bs-toggle="modal"
            data-bs-target={`#home-packages-${idx}-${data.id}-icon`}
          >
            <KTSVG
              path="/media/icons/duotone/General/Edit.svg"
              className="svg-icon-1"
            />
          </label>
        </div>
        <div>
          {data.icon_url ? (
            <div className="card__holder">
              <img className="icon" src={data.icon_url} alt="Uploaded" />
            </div>
          ) : (
            <KTSVG
              path={data.icon ?? ''}
              svgClassName="icon"
              className="card__holder"
            />
          )}
        </div>
      </div>
      {/* <input
        id={`contacts-other-info-with-icons-input-${id}`}
        type="file"
        accept=".svg"
        name="icon"
        style={{ display: 'none' }}
        onChange={(event) => {
          handleFile(event);
        }}
      /> */}
    </div>
  );
};
