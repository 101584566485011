import React, { useRef } from "react";
import { KTSVG } from "../../../../_start/helpers";

import { MediaLibraryCollection } from "@spatie/media-library-pro-react-collection";

import { MediaLibrary } from "@spatie/media-library-pro-core/dist/types";
import { MediaLibrary as MediaLibraryClass } from "@spatie/media-library-pro-core/dist";
import axios from "axios";
import Grid from "./Grid";
import Field from "./Field";
import { API_URL, WEB_URL } from "../../../support/api";
import { MEDIA_LIBRARY_ROUTE_PREFIX } from "../redux/MediaLibraryCRUD";

type UploadModalProps = {
	handleFetchList: () => void;
};

export const UploadModal = ({ handleFetchList }: UploadModalProps) => {
	const [value, setValue] = React.useState<{
		name: string;
		media: {
			[uuid: string]: MediaLibrary.MediaAttributes;
		};
	}>({
		name: "",
		media: {},
	});

	const [validationErrors, setValidationErrors] = React.useState<
		MediaLibrary.State["validationErrors"]
	>({});
	// const [isUploadSuccess, setIsUploadSuccess] = React.useState(false);

	const mediaLibrary = React.useRef<MediaLibraryClass | null>(null);

	const closeRef = useRef<HTMLButtonElement>(null);

	const handleSubmit = async () => {
		// setIsUploadSuccess(false);
		setValidationErrors({});

		try {
			const result = await axios.post(
				`${API_URL}/v1/media-library/store`,
				value
			);

			if (result.data.value.success) {
				// setIsUploadSuccess(true);
				setValue({ name: "", media: {} });

				mediaLibrary.current?.changeState((state) => {
					state.media = [];
				});
			}

			handleFetchList();
			closeRef.current?.click();
		} catch (error) {
			const resultError: any = error;

			console.error(resultError);

			if (
				resultError &&
				resultError.response &&
				resultError.response.data
			) {
				setValidationErrors(resultError.response.data.errors);
			}
		}
	};

	return (
		<div className="modal fade" tabIndex={-1} id="custom_upload_kt_modal_1">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Upload media</h5>
						<div
							className="btn btn-icon btn-sm btn-active-light-primary ms-2"
							data-bs-dismiss="modal"
							aria-label="Close"
						>
							<KTSVG
								path="/media/icons/duotone/Navigation/Close.svg"
								className="svg-icon svg-icon-2x"
							/>
						</div>
					</div>
					<div className="modal-body">
						<Grid>
							<Field>
								<MediaLibraryCollection
									name="media"
									routePrefix={MEDIA_LIBRARY_ROUTE_PREFIX}
									uploadDomain={WEB_URL}
									validationRules={{
										accept: [
											"image/png",
											"image/jpeg",
											"image/webp",
											"image/gif",
											"application/pdf"
										],
									}}
									validationErrors={validationErrors}
									setMediaLibrary={(mediaLib) =>
										(mediaLibrary.current = mediaLib)
									}
									onChange={(media) =>
										setValue((value) => ({
											...value,
											media,
										}))
									}
									fieldsView={({
										getCustomPropertyInputProps,
										getCustomPropertyInputErrors,
										getNameInputErrors,
										getNameInputProps,
									}) => (
										<div className="media-library-properties">
											<div className="media-library-field">
												<label className="media-library-label">
													Name
												</label>
												<input
													className="media-library-input"
													{...getNameInputProps()}
													{...{
														dusk: "media-library-field-name",
													}}
												/>

												{getNameInputErrors().map(
													(error) => (
														<p
															key={error}
															className="media-library-text-error"
														>
															{error}
														</p>
													)
												)}
											</div>

											<div className="media-library-field">
												<label className="media-library-label">
													Alt text
												</label>
												<input
													className="media-library-input"
													{...getCustomPropertyInputProps(
														"alt_text"
													)}
													{...{
														dusk: "media-library-extra-field",
													}}
												/>

												{getCustomPropertyInputErrors(
													"alt_text"
												).map((error: string) => (
													<p
														key={error}
														className="media-library-text-error"
													>
														{error}
													</p>
												))}
											</div>

											<div className="media-library-field">
												<label className="media-library-label">
													Caption
												</label>
												<input
													className="media-library-input"
													{...getCustomPropertyInputProps(
														"caption"
													)}
													{...{
														dusk: "media-library-extra-field",
													}}
												/>

												{getCustomPropertyInputErrors(
													"caption"
												).map((error: string) => (
													<p
														key={error}
														className="media-library-text-error"
													>
														{error}
													</p>
												))}
											</div>
										</div>
									)}
								></MediaLibraryCollection>
							</Field>

							<button
								type="button"
								className="mt-5 btn btn-info btn-sm"
								onClick={handleSubmit}
							>
								Submit
							</button>
						</Grid>
					</div>
					<div className="modal-footer">
						<button
							ref={closeRef}
							type="button"
							className="btn btn-light"
							data-bs-dismiss="modal"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
