import { ElementType, IElement } from "../../../../models/Element";

export interface IconWithTitleItem {
  title: string;
  icon: string;
  icon_url?: string;
}

const check_icon = `<svg data-src="assets/icons/icon-check.svg" focusable="false" aria-hidden="true" data-cache="604800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 21" xml:space="preserve" data-id="svg-loader_82">
<path d="M10.52 21a1.56 1.56 0 0 1-1.11-.46L.46 11.61a1.57 1.57 0 0 1 2.23-2.22l7.83 7.82L27.31.46a1.59 1.59 0 0 1 2.23 0 1.58 1.58 0 0 1 0 2.22l-17.9 17.86a1.57 1.57 0 0 1-1.12.46Z"></path>
</svg>`;

export const defaultIconTitleItem: IconWithTitleItem = {
  title: "Niet gebonden aan een mobiele operator.",
  icon: check_icon,
  icon_url: '',
};

export type HomeSixIconsWithTitlesElement = IElement & {
  item_list: Array<IconWithTitleItem>;
};

export const defaultHomeSixIconsWithTitlesElement: HomeSixIconsWithTitlesElement =
  {
    id: 0,
    name: ElementType.HOME_SIX_ICONS_WITH_TITLES,
    item_list: [
      defaultIconTitleItem,
      defaultIconTitleItem,
      defaultIconTitleItem,
      defaultIconTitleItem,
      defaultIconTitleItem,
      defaultIconTitleItem,
    ],
  };
