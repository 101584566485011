import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export type HomeReviewsSingle = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  total_reviews: number;
  average_rating: number;
  image_preview: string;
  image_preview_alt: string;
};

export const defaultHomeReviewsSingle: HomeReviewsSingle = {
  id: 0,
  name: ElementType.HOME_REVIEWS_SINGLE,
  heading: 'Klanten over Verbonden',
  description: 'Zij gaven gemiddeld een 9.8 op Feedback Company',
  total_reviews: 80,
  average_rating: 4,
  image_preview: '',
  image_preview_alt: '',
};
