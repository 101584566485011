import React from 'react';
import { toAbsoluteUrl } from '../../../../../../../_start/helpers';
import { HomeReviewsSlider } from './HomeReviewsSlider';

interface Props {
  data: HomeReviewsSlider;
  update: (to: Partial<HomeReviewsSlider>) => void;
}

export const HomeReviewsSliderFigure: React.FC<Props> = ({ data, update }) => {
  return (
    <div className="reviews__figure">
      {/* begin::Content Image Preview */}
      <div className="content-image">
        {data.image_preview ? (
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                alt="dummy"
                src={data.image_preview}
                style={{
                  width: '100%',
                  height: '320px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div
              className="overlay-layer bg-dark bg-opacity-10 flex-column h-50 fixed-bottom"
              style={{ zIndex: 1, top: '150px' }}
            >
              <div>
                <button
                  name="image_preview"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      image_preview: '',
                      image_preview_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-50 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_alt ?? ''}
                onChange={(event) =>
                  update({
                    image_preview_alt: event.target.value,
                  })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              data-bs-toggle="modal"
              data-bs-target={`#${data.name}-${data.id}`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/441x430.png')}
                alt=""
                style={{
                  width: '100%',
                  height: '320px',
                  objectFit: 'cover',
                }}
              />
            </label>
          </>
        )}
      </div>
      {/* end::Content Image Preview */}
    </div>
  );
};
