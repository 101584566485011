/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { failureToast, successToast } from '../../../support/utils';
import { ContentGroup } from '../components/ContentGroup';
import {
  defaultMenu,
  defaultMenuPackageBulletPoint,
  IMenu,
  MenuContentGroup,
  MenuLink,
  MenuPackageBulletPoint,
} from '../models/Menu';
import { updateMenu, viewMenu } from '../redux/MenusCRUD';

import updateHelper from 'immutability-helper';
import { SidebarLink } from '../components/SidebarLink';
import { IPackage } from '../../content-management/models/Package';
import { getAllPackage } from '../../content-management/redux/PackageCRUD';
import { BulletPoint } from '../components/BulletPoint';
import { FormSvgField } from '../../media-management/components/svg/FormSvgField';

export const UpdateMenu = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IMenu>(defaultMenu);

  const updateData = (fieldsToUpdate: Partial<IMenu>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const [packages, setPackages] = useState<Array<IPackage>>([]);

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const [packages, menu] = await Promise.all([
        getAllPackage(),
        viewMenu(id),
      ]);
      setPackages(packages);
      setData({ ...data, ...menu });
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await updateMenu(data);
      successToast('Menu has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const addGroup = () => {
    updateData({
      content: [...data.content, { group_title: 'New group', links: [] }],
    });
  };

  const removeGroup = (index: number) => {
    let content = updateHelper(data.content, {
      $splice: [[index, 1]],
    });

    const updatedData = { ...data, content };
    updateData(updatedData);
  };

  const updateGroup = (index: number, content: MenuContentGroup) => {
    let newContent = [...data.content];
    newContent[index] = content;
    const updatedData = { ...data, content: newContent };
    updateData(updatedData);
  };

  const addSidebarLink = () => {
    updateData({
      sidebar: [...data.sidebar, { link_title: 'Sidebar link', link_url: '' }],
    });
  };

  const removeSidebarLink = (index: number) => {
    let sidebar = updateHelper(data.sidebar, {
      $splice: [[index, 1]],
    });

    const updatedData = { ...data, sidebar };
    updateData(updatedData);
  };

  const updateSidebarLink = (index: number, link: MenuLink) => {
    let sidebar = [...data.sidebar];
    sidebar[index] = link;
    const updatedData = { ...data, sidebar };
    updateData(updatedData);
  };

  const addBulletPoint = () => {
    const bullet_points = [...data.bullet_points];
    bullet_points.push(defaultMenuPackageBulletPoint);
    updateData({ bullet_points });
  };

  const updateBulletPoint = (index: number, point: MenuPackageBulletPoint) => {
    let bullet_points = [...data.bullet_points];
    bullet_points[index] = point;
    updateData({ bullet_points });
  };

  const removeBulletPoint = (index: number) => {
    let bullet_points = [...data.bullet_points];
    bullet_points.splice(index, 1);
    updateData({ bullet_points });
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Menu</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) =>
                    updateData({
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.title}
                  onChange={(e) =>
                    updateData({
                      title: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Expand</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select..."
                value={data.expand}
                onChange={(e) =>
                  updateData({
                    expand: parseInt(e.target.value),
                  })
                }
              >
                <option value={0}>NO</option>
                <option value={1}>YES</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          {
            <div className={`row mb-8`}>
              <label className="col-lg-3 col-form-label">URL</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    name="url"
                    placeholder="Enter URL"
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.url}
                    onChange={(e) =>
                      updateData({
                        url: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          }
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className={`row mb-8 ${data.expand ? '' : 'd-none'}`}>
            <label className="col-lg-3 col-form-label">Featured Package</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Package..."
                value={data.package_id}
                onChange={(e) =>
                  updateData({
                    package_id: parseInt(e.target.value),
                  })
                }
              >
                {packages.map((element, idx) => (
                  <option value={element.id} key={idx}>
                    {element.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Package Icon</label>
            <div className="col-lg-9">
              <FormSvgField
                name={`package_icon_preview`}
                src={data.package_icon_preview}
                update={updateData}
                modalId={`package_icon_preview_model`}
              />
            </div>
          </div>
          {/* end::Form row */}

          <div
            className={`separator my-10 ${data.expand ? '' : 'd-none'}`}
          ></div>

          {/* begin::Form row */}
          <div className={`row mb-8 ${data.expand ? '' : 'd-none'}`}>
            <div className="col-lg-12 d-flex justify-content-between">
              <label className="col-form-label">Content</label>
              <button
                type="button"
                className="btn btn-sm btn-light"
                onClick={addGroup}
              >
                Add Content Group
              </button>
            </div>
            <div
              className="mt-8 col-lg-12"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '2rem',
              }}
            >
              {data.content.map((group, idx) => (
                <ContentGroup
                  key={idx}
                  index={idx}
                  group={group}
                  removeGroup={removeGroup}
                  updateGroup={updateGroup}
                />
              ))}
            </div>
          </div>
          {/* end::Form row */}

          <div
            className={`separator my-10 ${data.expand ? '' : 'd-none'}`}
          ></div>

          {/* begin::Form row */}
          <div className={`row mb-8 ${data.expand ? '' : 'd-none'}`}>
            <div className="col-lg-12 d-flex justify-content-between">
              <label className="col-form-label">Sidebar</label>
              <button
                type="button"
                className="btn btn-sm btn-light"
                onClick={addSidebarLink}
              >
                Add Sidebar Link
              </button>
            </div>
            <div
              className="col-lg-12"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: '2rem',
              }}
            >
              {data.sidebar.map((link, idx) => (
                <SidebarLink
                  key={idx}
                  index={idx}
                  link={link}
                  removeSidebarLink={removeSidebarLink}
                  updateSidebarLink={updateSidebarLink}
                />
              ))}
            </div>
          </div>
          {/* end::Form row */}

          <div
            className={`separator my-10 ${data.expand ? '' : 'd-none'}`}
          ></div>

          {/* begin::Form row */}
          <div className="col-lg-12 d-flex justify-content-between">
            <label className="col-form-label">
              Feature Package Bullet Points
            </label>
          </div>
          {/* end::Form row */}

          {data.bullet_points.map((point, idx) => (
            <BulletPoint
              index={idx}
              point={point}
              update={updateBulletPoint}
              remove={removeBulletPoint}
            />
          ))}
          <button
            type="button"
            className="btn btn-sm btn-light"
            onClick={addBulletPoint}
          >
            Add BulletPoint
          </button>

          <div
            className={`separator my-10 ${data.expand ? '' : 'd-none'}`}
          ></div>

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/layout-management/menus`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
