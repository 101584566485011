import React from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { ButtonTooltip } from '../../../../components/ButtonTooltip';
import { InlineEdit } from '../../../../components/InlineEdit';
import { HomeImageTwoLeftDescriptionFloatingWindowElement } from './HomeImageTwoLeftDescriptionFloatingWindowElement';

interface Props {
  data: HomeImageTwoLeftDescriptionFloatingWindowElement;
  update: (
    fieldsToUpdate: Partial<HomeImageTwoLeftDescriptionFloatingWindowElement>
  ) => void;
}

export const HomeImageTwoLeftDescriptionFloatingWindowFloatingComponent: React.FC<
  Props
> = ({ data, update }) => {
  return (
    <div className="showcase__cta">
      <div className="heading__cta">
        <TextEditor
          content={data.floating_text}
          updateContent={(floating_text) => update({ floating_text })}
        />
      </div>

      {/* begin::Action Button */}
      <div className="content-button">
        <ButtonTooltip
          name={`floating_button_url`}
          value={data.floating_button_url}
          setValue={update}
          isCheckboxChecked={data.floating_button_url_is_external}
          checkboxChanged={(v) =>
            update({ floating_button_url_is_external: v })
          }
        >
          <button className="btn rounded-pill content-button__inner">
            <InlineEdit
              name="floating_button_title"
              className="px-1 text-white text-center text-primary-color"
              value={data.floating_button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '180px',
              }}
            />
          </button>
        </ButtonTooltip>
        {/* end::Action Button */}
      </div>
    </div>
  );
};
