import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IInquiry } from "../models/Inquiry";

export const BASE_INQUIRY_URL = `${API_URL}/v1/customer-inquiries`;
export const GET_ALL_INQUIRY_URL = `${API_URL}/v1/customer-inquiries/get-all`;
export const CREATE_INQUIRY_URL = `${API_URL}/v1/customer-inquiries`;

export const listInquiry = async (search = "", direction = "asc") => {
	const URL = `${BASE_INQUIRY_URL}/list?search=${search}&direction=${direction}`;
	const {
		data: { value },
	} = await axios.get<{ value: PaginatedValue<IInquiry> }>(URL);

	return value;
};

export const createInquiry = async (payload: IInquiry) => {
	const {
		data: { value },
	} = await axios.post<{ value: IInquiry }>(CREATE_INQUIRY_URL, payload);

	return value;
};

export const viewInquiry = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: IInquiry }>(`${BASE_INQUIRY_URL}/${id}/get`);

	return value;
};

export const updateInquiry = async (payload: IInquiry) => {
	const {
		data: { value },
	} = await axios.post<{ value: IInquiry }>(
		`${BASE_INQUIRY_URL}/${payload.id}/update`,
		payload
	);

	return value;
};

export const deleteInquiry = async (payload: IInquiry) => {
	const {
		data: { value },
	} = await axios.post<{ value: IInquiry }>(
		`${BASE_INQUIRY_URL}/${payload.id}/delete`,
		payload
	);

	return value;
};

// unpaginated categories
export const getAllInquiry = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: Array<IInquiry> }>(GET_ALL_INQUIRY_URL);

	return value;
};
