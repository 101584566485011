import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { AttachMediaModal } from '../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { IElement } from '../../../models/Element';
import { BlogContentImage } from './BlogContentImage';

type BlogContentImageFCProps = {
  index: number;
  data: BlogContentImage;
  updateData: (body: IElement, index: number) => void;
};

export const BlogContentImageFC = ({
  index,
  data,
  updateData,
}: BlogContentImageFCProps) => {
  const update = (fieldsToUpdate: Partial<BlogContentImage>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt, image_caption) =>
          update({ image, image_alt, image_caption })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="blog-content-image my-10">
          <div className="blog-content">
            <div className="blog-content__figure">
              {data.image ? (
                <div className="overlay">
                  <div className="overlay-wrapper">
                    <img alt="Uploaded" src={data.image} />
                  </div>
                  <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
                    <div>
                      <button
                        name="image"
                        className="btn btn-primary btn-shadow"
                        onClick={() =>
                          update({
                            image: '',
                            image_alt: '',
                          })
                        }
                      >
                        Remove
                      </button>
                      <label
                        className="btn btn-light-primary btn-shadow ms-2"
                        data-bs-toggle="modal"
                        data-bs-target={`#${data.name}-${data.id}`}
                      >
                        Change
                      </label>
                    </div>

                    <input
                      placeholder="Alt attribute"
                      className="w-50 mt-2 form-control form-control-lg form-control-solid"
                      type="text"
                      value={data.image_alt ?? ''}
                      onChange={(event) =>
                        update({
                          image_alt: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <>
                  <label
                    data-bs-toggle="modal"
                    data-bs-target={`#${data.name}-${data.id}`}
                  >
                    <img
                      src={toAbsoluteUrl('/assets/images/1160x522.png')}
                      alt="Placeholder"
                    />
                  </label>
                </>
              )}
            </div>
            <InlineEdit
              name="image_caption"
              className="blog-content__caption"
              value={data.image_caption}
              setValue={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
