/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { ButtonTooltipOld } from "../../../components/ButtonTooltipOld";
import { InlineEdit } from "../../../components/InlineEdit";
import { RemoveElementButton } from "../../../components/RemoveElementButton";
import { TextAreaEdit } from "../../../components/TextAreaEdit";

import { QuestionItem } from "./FAQ";

interface FAQListItemProps {
  index: number;
  data: QuestionItem;
  remove: (index: number) => void;
  update: (fieldsToUpdate: Partial<QuestionItem>, index: number) => void;
}

export const FAQListItem: React.FC<FAQListItemProps> = ({
  index,
  data,
  update,
  remove,
}) => {
  const [active, setActive] = useState(false);
  const updateData = (toUpdate: Partial<QuestionItem>) => {
    update(toUpdate, index);
  };

  const removeButtonRef = useRef<HTMLButtonElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.remove("d-none");
    // addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = "pointer";
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.add("d-none");
    // addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = "auto";
  };
  return (
    <div
      className="accordion__item"
      id={`faq_accordion_${index}`}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      data-element="accordion"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <a
        className={`accordion__toggle ${active && "accordion__active"}`}
        onClick={() => setActive(!active)}
      >
        {/* begin::Remove Button */}
        <RemoveElementButton
          index={index}
          remove={remove}
          buttonRef={removeButtonRef}
        />
        {/* end::Body Remove Button */}
        <InlineEdit
          value={data.title}
          name="title"
          setValue={updateData}
          className="accordion__text"
        />

        <span className="accordion__holder u-flex u-align-center u-justify-center">
          <svg
            className="accordion__icon icon-14 rotate-180"
            data-src="assets/icons/icon-angle.svg"
            aria-hidden="true"
            focusable="false"
            data-cache="disabled"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            xmlSpace="preserve"
            data-id="svg-loader_36"
          >
            <path d="M16 11.5c0 .3-.1.5-.3.7-.4.4-1 .4-1.4 0L8 5.9l-6.3 6.3c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0l7 7c.2.2.3.4.3.7z"></path>
          </svg>
        </span>
      </a>
      <div
        className="accordion__inner"
        style={{ display: !active ? "none" : "block" }}
      >
        <p>
          <InlineEdit
            value={data.heading}
            setValue={updateData}
            name="heading"
            className="accordion__heading"
          />
        </p>

        <TextAreaEdit
          value={data.description}
          setValue={updateData}
          name="description"
          className="accordion__description"
        />

        {/* begin::Action Button with Editable Link Tooltip */}
        <ButtonTooltipOld
          name={`button_url`}
          className="content-action"
          value={data.button_url}
          setValue={updateData}
        >
          <button className="btn btn-success rounded-pill">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center"
              value={data.button_title}
              setValue={updateData}
              activeVerticalPadding={"py-1"}
              dynamicWidth
              style={{
                maxWidth: "200px",
              }}
            />
          </button>
        </ButtonTooltipOld>
        {/* end::Action Button with Editable Link Tooltip */}
      </div>
    </div>
  );
};
