/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import CardListBody from "../../../components/card/CardListBody";
import CardListFooter from "../../../components/card/CardListFooter";
import CardListHeader from "../../../components/card/CardListHeader";
import QueryFilter from "../../../components/QueryFilter";
import { useRepositoryList } from "../../../hooks/useRepositoryList";
import { PAGE_URL } from "../../../support/api";
import { getPageQueryFilters, IPage, PageQueryFilters, PageQueryFilterValue } from "../models/Page";
import PagesTable from "./PagesTable";

export const PagesPage = () => {
  const {
    paginatedValue,
    isPending,
    setIsPending,
    paginate,
    setPaginate,
    search,
    setSearch,
    handlePaginateState,
    sort,
    handleSort,
    selected,
    setSelected,
    handleDelete,
    handleFetchList,
    setFilters,
  } = useRepositoryList<IPage, PageQueryFilters>(PAGE_URL);

  return (
    <div
      className={`card card-custom card-flush ${
        isPending ? 'overlay overlay-block' : ''
      }`}
    >
      <CardListHeader
        create={`pages/create`}
        paginate={paginate}
        setPaginate={setPaginate}
        search={search}
        setSearch={setSearch}
      >
        <QueryFilter
          filterValue={PageQueryFilterValue.ALL}
          getQueryFilters={getPageQueryFilters}
          setFilters={setFilters}
        >
          <option value="">All</option>
          <option value={PageQueryFilterValue.DRAFT}>Draft</option>
          <option value={PageQueryFilterValue.FUTURE}>Future</option>
          <option value={PageQueryFilterValue.PUBLISHED}>Published</option>
          <option value={PageQueryFilterValue.PARENT}>Parent</option>
          <option value={PageQueryFilterValue.CHILD}>Child</option>
        </QueryFilter>
      </CardListHeader>

      <CardListBody isPending={isPending}>
        <PagesTable
          setIsPending={setIsPending}
          data={paginatedValue.data}
          sort={sort}
          handleSort={handleSort}
          selected={selected}
          setSelected={setSelected}
          handleDelete={handleDelete}
          handleFetchList={handleFetchList}
        />
      </CardListBody>

      <CardListFooter
        isPending={isPending}
        paginatedValue={paginatedValue}
        handlePaginateState={handlePaginateState}
      />
    </div>
  );
};
