import { ElementType, IElement } from "../../../../models/Element";

export interface HeadingsAndDescriptionsItem {
  id: number;
  heading: string;
  description: string;
  icon: string;
  icon_url?: string;
}

const pie_icon = `<svg data-src="assets/icons/icon-pie-chart.svg" focusable="false" aria-hidden="true" data-cache="604800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" data-id="svg-loader_80"><path d="M17.89 37c-1.64 0-3.27-.22-4.85-.67A17.903 17.903 0 0 1 .91 24.78C0 22.03-.24 19.06.24 16.19c.47-2.87 1.66-5.61 3.42-7.92 1.76-2.31 4.09-4.18 6.73-5.4a2.36 2.36 0 0 1 3.14 1.16c.55 1.19.03 2.6-1.16 3.14-1.94.9-3.65 2.27-4.95 3.97-1.3 1.7-2.16 3.71-2.51 5.82-.35 2.11-.17 4.29.5 6.32.68 2.03 1.85 3.88 3.4 5.35s3.45 2.56 5.51 3.14c2.06.58 4.25.64 6.34.19s4.06-1.42 5.69-2.79c1.63-1.38 2.92-3.15 3.72-5.13.49-1.21 1.87-1.8 3.08-1.31s1.8 1.87 1.31 3.08c-1.09 2.7-2.84 5.11-5.06 6.98-2.22 1.87-4.9 3.19-7.74 3.8-1.24.27-2.51.4-3.78.4Z"></path><path d="M31.41 19.93h-8.42c-3.27 0-5.92-2.66-5.92-5.92V5.59c0-1.76.79-3.43 2.12-4.48 1.22-.97 2.8-1.33 4.31-.97a17.748 17.748 0 0 1 8.59 4.77c1.67 1.67 2.98 3.62 3.88 5.8.37.9.67 1.83.9 2.78.35 1.52 0 3.09-.97 4.31-1.05 1.33-2.73 2.12-4.49 2.12ZM22.33 4.74c-.06 0-.12.02-.2.08-.15.12-.33.39-.33.77V14c0 .65.53 1.18 1.18 1.18h8.42c.38 0 .65-.18.77-.33.09-.11.09-.19.07-.29-.16-.7-.38-1.39-.66-2.05-.66-1.6-1.62-3.04-2.85-4.27a12.952 12.952 0 0 0-4.27-2.85c-.66-.27-1.35-.5-2.05-.66-.03 0-.06-.01-.09-.01Z"></path></svg>`;

export type HomeThreeIconsHeadingsAndDescriptionsElement = IElement & {
  item_list: Array<HeadingsAndDescriptionsItem>;
};

export type HomeThreeIconsHeadingsAndDescriptionsNarrowElement = IElement & {
  item_list: Array<HeadingsAndDescriptionsItem>;
};

export const defaultHeadingsAndDescriptionsItem: HeadingsAndDescriptionsItem = {
  id: 0,
  heading: "Altijd bereikbaar.",
  description:
    "Onze diensten kan je altijd per dag aanvragen maar ook stopzetten.",
  icon: pie_icon,
  icon_url: '',
};

export const defaultThreeIconsHeadingsAndDescriptionsNarrowElement: HomeThreeIconsHeadingsAndDescriptionsNarrowElement =
  {
    id: 0,
    name: ElementType.HOME_THREE_ICONS_HEADINGS_AND_DESCRIPTIONS_NARROW,
    item_list: [
      defaultHeadingsAndDescriptionsItem,
      defaultHeadingsAndDescriptionsItem,
      defaultHeadingsAndDescriptionsItem,
    ],
  };
