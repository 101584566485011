import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { TextEditor } from '../../../../../components/TextEditor';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';
import { ArchiveReview } from './ArchiveReview';

interface Props {
  index: number;
  data: ArchiveReview;
  updateData: (element: IElement, index: number) => void;
}

export const ArchiveReviewFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<ArchiveReview>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="archive-reviews">
        <div className="content">
          <HeaderSelectWrapper
            name={`heading_type`}
            value={data.heading_type ?? ElementHeaderType.H1}
            setValue={update}
          >
            <TextAreaEdit
              className="heading"
              name={'heading'}
              value={data.heading}
              setValue={update}
            />
          </HeaderSelectWrapper>

          <div className="description">
            <TextEditor
              content={data.description}
              updateContent={(description) => update({ description })}
            />
          </div>

          <div className="rating">
            <div className="rating__stars">
              {[...Array(5)].map((x, i) => (
                <div key={i}>
                  <KTSVG
                    path={toAbsoluteUrl('/assets/icons/icon-star-solid.svg')}
                    className="icon"
                  />
                </div>
              ))}
              <div className="rating__score">{`(* Total Reviews *)`}</div>
            </div>
          </div>
        </div>

        <div className="reviews">
          <div className="review">Reviews appear here randomly</div>
          <div className="review">Reviews appear here randomly</div>
          <div className="review">Reviews appear here randomly</div>
        </div>
      </div>
    </ElementWrapper>
  );
};
