import { useState } from "react";
import { KTSVG } from "../../../../_start/helpers";
import { StaticWidthInlineEdit } from "../../edit-content/components/StaticWidthInlineEdit";
import {
	defaultMenuContentGroup,
	MenuContentGroup,
	MenuLink,
} from "../models/Menu";

import updateHelper from "immutability-helper";
import { GroupLink } from "./GroupLink";

type ContentGroupProps = {
	index: number;
	group: MenuContentGroup;
	removeGroup: (index: number) => void;
	updateGroup: (index: number, content: MenuContentGroup) => void;
};

export const ContentGroup = ({
	index,
	group,
	removeGroup,
	updateGroup,
}: ContentGroupProps) => {
	const [data, setData] = useState<MenuContentGroup>(
		group ? group : defaultMenuContentGroup
	);

	const updateData = (fieldsToUpdate: Partial<MenuContentGroup>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		setData(updatedData);
		updateGroup(index, updatedData);
	};

	const addLink = () => {
		const links = updateHelper(data.links, {
			$push: [{ link_title: "Link title", link_url: "" }],
		});
		const updatedData = { ...data, links };
		updateData(updatedData);
	};

	const removeLink = (index: number) => {
		let links = updateHelper(data.links, {
			$splice: [[index, 1]],
		});

		const updatedData = { ...data, links };
		updateData(updatedData);
	};

	const updateLink = (index: number, link: MenuLink) => {
		let links = [...data.links];
		links[index] = link;
		const updatedData = { ...data, links };
		updateData(updatedData);
	};

	return (
		<div className="content-group position-relative">
			<span
				onClick={() => removeGroup(index)}
				className="position-absolute top-0 end-0 opacity-75-hover cursor-pointer"
			>
				<KTSVG
					path="/media/icons/duotone/Interface/Minus-Square.svg"
					className="svg-icon-danger svg-icon-1"
				/>
			</span>

			<StaticWidthInlineEdit
				name="group_title"
				className="fw-bolder"
				style={{ width: "90%" }}
				value={data.group_title}
				setValue={updateData}
			/>

			<div className="mt-4 group-links d-flex flex-column gap-2">
				{group.links.map((link, idx) => (
					<GroupLink
						key={idx}
						index={idx}
						link={link}
						removeLink={removeLink}
						updateLink={updateLink}
					/>
				))}

				<button
					type="button"
					className="btn btn-sm btn-light"
					onClick={addLink}
				>
					Add Link
				</button>
			</div>
		</div>
	);
};
