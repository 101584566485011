import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IManageRedirectLink, IRedirectLink } from '../models/RedirectLink';

const REDIRECT_LINK_URL = `${API_URL}/v1/redirect-links`;

export const createRedirectLink = async (payload: IRedirectLink) => {
  const response = await axios.post<{ value: IRedirectLink }>(
    `${REDIRECT_LINK_URL}/create`,
    payload
  );

  return response.data.value;
};

export const manageRedirectLink = async (
  payload: Array<IManageRedirectLink>
) => {
  const response = await axios.post<{ value: IManageRedirectLink }>(
    `${REDIRECT_LINK_URL}/manage`,
    payload
  );

  return response.data.value;
};

export const viewRedirectLink = async (RedirectLink_id: string | number) => {
  const response = await axios.get<{ value: IRedirectLink }>(
    `${REDIRECT_LINK_URL}/${RedirectLink_id}/get`
  );

  return response.data.value;
};

export const updateRedirectLink = async (payload: IRedirectLink) => {
  const response = await axios.post<{ value: IRedirectLink }>(
    `${REDIRECT_LINK_URL}/${payload.id}/update`,
    payload
  );

  return response.data.value;
};

export const deleteRedirectLink = async (payload: IRedirectLink) => {
  const response = await axios.post<{ value: IRedirectLink }>(
    `${REDIRECT_LINK_URL}/${payload.id}/delete`,
    payload
  );

  return response.data.value;
};

export const listRedirectLink = async (search = '', direction = 'asc') => {
  const URL = `${REDIRECT_LINK_URL}/list?search=${search}&direction=${direction}`;
  const response = await axios.get<{ value: PaginatedValue<IRedirectLink> }>(
    URL
  );

  return response.data.value;
};

export const getAllRedirectLink = async () => {
  const response = await axios.get<{ value: Array<IRedirectLink> }>(
    `${REDIRECT_LINK_URL}/get-all`
  );

  return response.data.value;
};
