import React from 'react';
import { TextEditor } from '../../../../../components/TextEditor';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { ElementHeaderType } from '../../utils';
import { MediaCarousel } from './MediaCarousel';

type Props = {
  data: MediaCarousel;
  update: (fieldsToUpdate: Partial<MediaCarousel>) => void;
};

export const MediaCarouselContent: React.FC<Props> = ({ data, update }) => {
  return (
    <div className="content">
      <div className="inner">
        <HeaderSelectWrapper
          name={'heading_type'}
          value={data.heading_type ?? ElementHeaderType.H2}
          setValue={update}
        >
          <TextAreaEdit
            name="heading"
            className="heading"
            value={data.heading}
            setValue={update}
          />
        </HeaderSelectWrapper>

        {/* begin::Description */}
        <div className="description">
          <TextEditor
            content={data.description}
            updateContent={(description) => update({ description })}
          />
        </div>
        {/* end::Description */}
      </div>
    </div>
  );
};
