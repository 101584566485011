import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeImageTwoRightDescriptionFloatingWindowContent } from './HomeImageTwoRightDescriptionFloatingWindowContent';
import { HomeImageTwoRightDescriptionFloatingWindowElement } from './HomeImageTwoRightDescriptionFloatingWindowElement';
import { HomeImageTwoRightDescriptionFloatingWindowFigure } from './HomeImageTwoRightDescriptionFloatingWindowFigure';

interface Props {
  index: number;
  data: HomeImageTwoRightDescriptionFloatingWindowElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeImageTwoRightDescriptionFloatingWindowFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (
    fieldsToUpdate: Partial<HomeImageTwoRightDescriptionFloatingWindowElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-image-two-right-description-float">
          <div className="showcase">
            <HomeImageTwoRightDescriptionFloatingWindowContent
              data={data}
              update={update}
            />
            <HomeImageTwoRightDescriptionFloatingWindowFigure
              data={data}
              update={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
