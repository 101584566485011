import React from "react";
import { ElementWrapper } from "../../../../components/ElementWrapper";
import { IElement } from "../../../../models/Element";
import { HomeSixIconsWithTitlesContent } from "./HomeSixIconsWithTitlesContent";
import { HomeSixIconsWithTitlesElement } from "./HomeSixIconsWithTitlesElement";

interface Props {
  index: number;
  data: HomeSixIconsWithTitlesElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeSixIconsWithTitlesFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<HomeSixIconsWithTitlesElement>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-six-icons-with-titles">
        <HomeSixIconsWithTitlesContent data={data} update={update} />
      </div>
    </ElementWrapper>
  );
};
