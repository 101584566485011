import { PageLink } from '../../../_start/layout/core';

export const clientManagementSubmenu: Array<PageLink> = [
  {
    title: 'Customers',
    path: '/client-management/clients',
    isActive: true,
  },

  {
    title: 'Partners',
    path: '/client-management/partners',
    isActive: true,
  },
];
