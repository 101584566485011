/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import {
  getConfig,
  IThemeConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from '../../../_start/layout/core';
import { googleMyBusinessSubmenu,  } from './GoogleReviewPageData';
import { GoogleMyBusinessPage } from './GoogleMyBusinessPage';

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: 'Google Reviews',
    path: '/google-reviews',
    isActive: false,
  },
];

const defaultPageConfig = getConfig();
const pageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
    content: 'user',
    bgColor: 'bg-info',
  },
};

export const GoogleMyBusinessWrapper = () => {
  const { setTheme } = useTheme();

  // Refresh UI after config updates
  useEffect(() => {
    setTheme(pageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={breadCrumbs}
        submenu={googleMyBusinessSubmenu}
      />
      <GoogleMyBusinessPage />
    </>
  );
};
