import { IPackage } from "../../../../content-management/models/Package";
import { ElementType, IElement } from "../../../models/Element";

export type FormPackage = IElement & {
  heading: string;
  label: string;
  featured_packages: Array<IPackage>
  button_title: string;
  button_url: string;
}

export const defaultFormPackage: FormPackage = {
  id: 0,
  name: ElementType.FORM_PACKAGE,
  heading: 'Ik wil wel een offerte.',
  label: 'Selecteer jouw pakket',
  featured_packages: [],
  button_title: 'Offerte bekijken',
  button_url: '',
}
