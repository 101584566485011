import {
	defaultPackage,
	IPackage,
} from "../../content-management/models/Package";

export interface ICustomerAskPriceQuote {
	id: number;
	keep_phone_number: string;
	migrate_number: string;
	net_number: string;
	firstname: string;
	email: string;
	company: string;

	package_id: number;
	package: IPackage;
}

export const defaultCustomerAskPriceQuote: ICustomerAskPriceQuote = {
	id: 0,
	keep_phone_number: "",
	migrate_number: "",
	net_number: "",
	firstname: "",
	email: "",
	company: "",

	package_id: 0,
	package: defaultPackage,
};
