import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IEmployee } from "../models/Employee";

export const BASE_EMPLOYEE_URL = `${API_URL}/v1/employee-management/employees`;
export const GET_ALL_EMPLOYEE_URL = `${API_URL}/v1/employee-management/employees/get-all`;
export const CREATE_EMPLOYEE_URL = `${API_URL}/v1/employee-management/employees/create`;

export const listEmployee = async (search = "", direction = "asc") => {
	const URL = `${BASE_EMPLOYEE_URL}/list?search=${search}&direction=${direction}`;
	const {
		data: { value },
	} = await axios.get<{ value: PaginatedValue<IEmployee> }>(URL);

	return value;
};

export const createEmployee = async (payload: IEmployee) => {
	const {
		data: { value },
	} = await axios.post<{ value: IEmployee }>(CREATE_EMPLOYEE_URL, payload);

	return value;
};

export const viewEmployee = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: IEmployee }>(`${BASE_EMPLOYEE_URL}/${id}/get`);

	return value;
};

export const updateEmployee = async (payload: IEmployee) => {
	const {
		data: { value },
	} = await axios.post<{ value: IEmployee }>(
		`${BASE_EMPLOYEE_URL}/${payload.id}/update`,
		payload
	);

	return value;
};

export const deleteEmployee = async (
	payload: IEmployee & { password: string }
) => {
	const {
		data: { value },
	} = await axios.post<{ value: IEmployee }>(
		`${BASE_EMPLOYEE_URL}/${payload.id}/delete`,
		payload
	);

	return value;
};

// unpaginated categories
export const getAllEmployee = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: Array<IEmployee> }>(GET_ALL_EMPLOYEE_URL);

	return value;
};

export const transferEmployee = async (
	payload: IEmployee & { password: string; transfer_to: number }
) => {
	const {
		data: { value },
	} = await axios.post<{ value: IEmployee }>(
		`${BASE_EMPLOYEE_URL}/${payload.id}/transfer-ownership`,
		payload
	);

	return value;
};
