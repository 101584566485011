import { IPermission } from "../permissions/Permission";

export interface IRole {
  id: number;
  name: string;
  description: string;
  permissions?: Array<number | IPermission>;
}

export const defaultRole: IRole = {
  id: 0,
  name: "",
  description: "",
};
