import immutabilityHelper from 'immutability-helper';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextEditor } from '../../../../../components/TextEditor';
import {
  defaultProduct,
  IProduct,
} from '../../../../content-management/models/Product';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { IElement } from '../../../models/Element';
import { setFeaturedProducts } from '../../../redux/EditContentActions';
import { ElementHeaderType } from '../../utils';
import { FeaturedProducts } from './FeaturedProducts';
import { FeaturedProductsItemWrapper } from './FeaturedProductsItemWrapper';

interface Props {
  index: number;
  data: FeaturedProducts;
  updateData: (element: IElement, index: number) => void;
}

export const FeaturedProductsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const dispatch = useDispatch();

  const featuredProducts = useSelector(
    (state: RootState) => state.editContent.featuredProducts
  );

  const allProducts = useSelector(
    (state: RootState) => state.editContent.allProducts
  );

  const [selectedProductsMap, setSelectedProductsMap] = useState<
    Map<number, IProduct>
  >(new Map());

  useEffect(() => {
    if (allProducts.length) {
      const iterable: Array<[number, IProduct]> = featuredProducts.map(
        (product) => [product.id, product]
      );

      setSelectedProductsMap(new Map(iterable));
    }
  }, [allProducts, featuredProducts]);

  const update = (fieldsToUpdate: Partial<FeaturedProducts>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    // update featured plugins on redux based from the element change featured plugins
    dispatch(setFeaturedProducts(updatedData.featured_products));
  };

  const updateFeaturedProduct = (idx: number, plugin: IProduct) => {
    let featured_products = [...data.featured_products];
    featured_products[idx] = plugin;

    const updatedData: FeaturedProducts = {
      ...data,
      featured_products,
    };

    update(updatedData);
  };

  const addProduct = () => {
    const featured_products = immutabilityHelper(data.featured_products, {
      $push: [defaultProduct],
    });

    const updatedData = { ...data, featured_products };
    update(updatedData);
  };

  const removeProduct = (itemIndex: number) => {
    let featured_products = immutabilityHelper(data.featured_products, {
      $splice: [[itemIndex, 1]],
    });

    const updatedData = { ...data, featured_products };
    update(updatedData);
  };

  return (
    <ElementWrapper
      elementIndex={index}
      removeCallback={() => dispatch(setFeaturedProducts([]))}
    >
      <div className="plugin-featured-plugins">
        <div className="plugin-featured-plugins__content">
          {/** begin:: Heading */}
          <HeaderSelectWrapper
            name={`heading_type`}
            value={data.heading_type ?? ElementHeaderType.H2}
            setValue={update}
          >
            <TextAreaEdit
              className="heading"
              name="heading"
              value={data.heading}
              setValue={update}
            />
          </HeaderSelectWrapper>
          {/** end:: Heading */}

          {/** begin:: Description */}
          <div className="description">
            <TextEditor
              content={data.description}
              updateContent={(description) => update({ description })}
            />
          </div>
          {/** end:: Description */}
        </div>

        <div className="plugin-featured-plugins__items">
          {data.featured_products.map((element, idx) => (
            <FeaturedProductsItemWrapper
              key={idx}
              itemIndex={idx}
              product={element}
              updateSelected={updateFeaturedProduct}
              selectedMap={selectedProductsMap}
            >
              <div className="card">
                <span
                  onClick={() => removeProduct(idx)}
                  className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
                >
                  <KTSVG
                    path="/media/icons/duotone/Interface/Minus-Square.svg"
                    className="svg-icon-danger svg-icon-1"
                  />
                </span>

                <div className="heading">{element.name}</div>

                <div className="description">{element.description}</div>

                <div className="link">
                  <div className="text">Uitleg</div>
                  <KTSVG className="icon" path="/assets/icons/icon-arrow.svg" />
                </div>
              </div>
            </FeaturedProductsItemWrapper>
          ))}

          <div onClick={addProduct} className="add-item">
            <div className="add-item__inner">
              <div className="m-auto d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-3hx text-center"
                />
                <span className="mt-4 text-gray-500">Add new item</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
