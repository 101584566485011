import React from "react";
import { KTSVG } from "../../../../../../../_start/helpers";
import { IconHeadingAndDescription } from "./HomeImageTwoRightDescriptionFloatingWindowElement";

interface Props {
  idx: number;
  data: IconHeadingAndDescription;
  update: (to: Partial<IconHeadingAndDescription>, idx: number) => void;
}

export const HomeImageTwoRightDescriptionFloatingWindowIcon: React.FC<
  Props
> = ({ idx, data, update }) => {
  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files!;
    let fileContent: string | ArrayBuffer | null = "";

    const fr = new FileReader();
    fr.onload = () => {
      fileContent = fr.result;
      update(
        {
          [event.target.name]: fileContent,
        },
        idx
      );
    };

    fr.readAsText(fileList[0]);
  };
  return (
    <div style={{ width: "3rem", height: "3rem" }}>
      <div className="overlay">
        <div className="overlay-layer bg-dark bg-opacity-10">
          <label
            htmlFor={`home-image-two-right-description-floating-window-input-${idx}`}
            className="btn btn-light-primary btn-shadow"
          >
            Change
          </label>
        </div>
        <div>
          <KTSVG svgClassName="icons" path={data.icon ?? ""} />
        </div>
      </div>
      <input
        id={`home-image-two-right-description-floating-window-input-${idx}`}
        type="file"
        accept=".svg"
        name="icon"
        style={{ display: "none" }}
        onChange={(event) => {
          handleFile(event);
        }}
      />
    </div>
  );
};
