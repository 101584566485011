import {
  defaultMenuContentGroup,
  defaultMenuLink,
  MenuContentGroup,
  MenuLink,
} from './Menu';

export type FooterLink = MenuLink;

export const defaultFooterLink: FooterLink = defaultMenuLink;

export type FooterContentGroup = MenuContentGroup;

export const defaultFooterContentGroup: FooterContentGroup =
  defaultMenuContentGroup;

export type SocialMediaLink = {
  svg_img: string;
  link_url: string;
  is_link_external: boolean;
};

export const defaultSocialMediaLink: SocialMediaLink = {
  svg_img: '',
  link_url: '',
  is_link_external: false,
};

export interface IFooter {
  id: number;
  name: string;
  content: Array<FooterContentGroup>;
  bottom_links?: Array<FooterLink>;
  social_media_links?: Array<SocialMediaLink>;
}

export const defaultFooter: IFooter = {
  id: 0,
  name: '',
  content: [],
  bottom_links: [],
  social_media_links: [],
};
