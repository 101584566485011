/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
	getConfig,
	IThemeConfig,
	PageDataContainer,
	PageLink,
	useTheme,
} from "../../../_start/layout/core";
import { quotationManagementSubmenu } from "./QuotationManagementData";
import { QuotationManagementPage } from "./QuotationManagementPage";

const defaultPageConfig = getConfig();
const quotationManagementPageConfig: Partial<IThemeConfig> = {};

const quotationManagementBreadCrumbs: Array<PageLink> = [
	{
		title: "Home",
		path: "/",
		isActive: false,
	},
	{
		title: "Offertebeheer",
		path: "/quotation-management",
		isActive: false,
	},
];

export const QuotationManagementWrapper = () => {
	const { setTheme } = useTheme();

	// Refresh UI after config updates
	useEffect(() => {
		setTheme(quotationManagementPageConfig);
		return () => {
			setTheme(defaultPageConfig);
		};
	}, []);

	return (
		<>
			<PageDataContainer
				breadcrumbs={quotationManagementBreadCrumbs}
				submenu={quotationManagementSubmenu}
			/>
			<QuotationManagementPage />
		</>
	);
};
