import Carousel from 'react-elastic-carousel';
import { PluginPartners } from './PluginPartners';
import { AddFeaturedWrapper } from './AddFeaturedWrapper';
import { KTSVG } from '../../../../../../_start/helpers';
import {
  HorizontalDragType,
  HorizontalDragWrapper,
} from '../../../components/HorizontalDragWrapper';
import { IElement } from '../../../models/Element';
import { IPartner } from '../../../../client-management/models/Partner';

type HomeEmployeesCarouselProps = {
  index: number;
  data: PluginPartners;
  updateData: (body: IElement, index: number) => void;
  addPartner: (selected: IPartner) => void;
  removePartner: (itemIndex: number) => void;
  updatePartner: (itemIndex: number, selected: IPartner) => void;
  allModels: Array<IPartner>;
  selectedMap: Map<number, IPartner>;
};

export const PluginPartnersCarousel = ({
  index,
  data,
  updateData,
  addPartner,
  removePartner,
  allModels,
  selectedMap,
}: HomeEmployeesCarouselProps) => {
  return (
    <div className="plugin-partners__carousel">
      <Carousel
        itemsToShow={3}
        isRTL={false}
        pagination={true}
        enableSwipe={false}
        enableMouseSwipe={false}
      >
        {
          // since previous implementation does not include "featured_partner" property then this value is undefined
          data.featured_partners !== undefined
            ? data.featured_partners.map((partner, idx) => (
                <HorizontalDragWrapper
                  key={idx}
                  horizontalDragType={HorizontalDragType.PARTNER}
                  element={data}
                  elementIndex={index}
                  listPropertyName={`featured_partners`}
                  updateData={updateData}
                  itemIndex={idx}
                >
                  <div className="item position-relative p-1">
                    {/* begin::Delete Item */}
                    <span
                      onClick={() => removePartner(idx)}
                      className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
                    >
                      <KTSVG
                        path="/media/icons/duotone/Interface/Minus-Square.svg"
                        className="svg-icon-danger svg-icon-1"
                      />
                    </span>
                    {/* end::Delete Item */}

                    {/* begin::Image */}
                    <img
                      src={partner.logo}
                      alt={`partner logo`}
                      style={{
                        width: '100%',
                        borderRadius: '20px',
                        objectFit: 'cover',
                      }}
                    />
                    {/* end::Image */}
                  </div>
                </HorizontalDragWrapper>
              ))
            : [].map((partner: IPartner, idx) => (
                <HorizontalDragWrapper
                  key={idx}
                  horizontalDragType={HorizontalDragType.PARTNER}
                  element={data}
                  elementIndex={index}
                  listPropertyName={`featured_partners`}
                  updateData={updateData}
                  itemIndex={idx}
                >
                  <div className="item position-relative p-1">
                    {/* begin::Delete Item */}
                    <span
                      onClick={() => removePartner(idx)}
                      className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
                    >
                      <KTSVG
                        path="/media/icons/duotone/Interface/Minus-Square.svg"
                        className="svg-icon-danger svg-icon-1"
                      />
                    </span>
                    {/* end::Delete Item */}

                    {/* begin::Image */}
                    <img
                      src={partner.logo}
                      alt={`partner logo`}
                      style={{
                        width: '100%',
                        borderRadius: '20px',
                        objectFit: 'cover',
                      }}
                    />
                    {/* end::Image */}
                  </div>
                </HorizontalDragWrapper>
              ))
        }

        <AddFeaturedWrapper
          searchProperty={`name`}
          allModels={allModels}
          selectedMap={selectedMap}
          addSelected={addPartner}
        >
          <div className="add-item"></div>
        </AddFeaturedWrapper>
      </Carousel>
    </div>
  );
};
