/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
	getConfig,
	IThemeConfig,
	PageDataContainer,
	PageLink,
	useTheme,
} from "../../../_start/layout/core";
import { contentManagementSubmenu } from "./ContentManagementData";
import { ContentManagementPage } from "./ContentManagementPage";

const contentManagementBreadCrumbs: Array<PageLink> = [
	{
		title: "Home",
		path: "/",
		isActive: false,
	},
	{
		title: "Inhoud beheer",
		path: "/content-management",
		isActive: false,
	},
];

const defaultPageConfig = getConfig();
const contentManagementPageConfig: Partial<IThemeConfig> = {
	sidebar: {
		...defaultPageConfig.sidebar,
		display: false,
		content: "user",
		bgColor: "bg-info",
	},
};

export const ContentManagementWrapper = () => {
	const { setTheme } = useTheme();

	// Refresh UI after config updates
	useEffect(() => {
		setTheme(contentManagementPageConfig);
		return () => {
			setTheme(defaultPageConfig);
		};
	}, []);

	return (
		<>
			<PageDataContainer
				breadcrumbs={contentManagementBreadCrumbs}
				submenu={contentManagementSubmenu}
			/>
			<ContentManagementPage />
		</>
	);
};
