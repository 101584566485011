import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { IElement } from '../../../models/Element';
import { FormNewsLetter } from './FormNewsLetter';

type FormNewsLetterFCProps = {
  index: number;
  data: FormNewsLetter;
  updateData: (element: IElement, index: number) => void;
};

export const FormNewsLetterFC = ({
  index,
  data,
  updateData,
}: FormNewsLetterFCProps) => {
  const update = (fieldsToUpdate: Partial<FormNewsLetter>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="form-interested-in">
        <div className="form-interested-in__content">
          <StaticWidthInlineEdit
            name="title"
            className="title"
            value={data.title}
            setValue={update}
          />

          <div className="form-field">
            <a>
              <StaticWidthInlineEdit
                name={`name_label`}
                value={data.name_label}
                setValue={update}
                style={{
                  maxWidth: '22rem',
                }}
              />
            </a>
          </div>

          <div className="form-field">
            <a>
              <StaticWidthInlineEdit
                name={`email_label`}
                value={data.email_label}
                setValue={update}
                style={{
                  maxWidth: '22rem',
                }}
              />
            </a>
          </div>

          {/* begin::Action Button */}
          <div className="action">
            <a>
              <InlineEdit
                className="action-text"
                name={`button_title`}
                value={data.button_title}
                setValue={update}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </a>
          </div>
          {/* end::Action Button */}

          <StaticWidthInlineEdit
            name="success_message"
            className="text-white"
            value={
              data.success_message ??
              'We hebben je aanmelding ontvangen. Bevestig je inschrijving.'
            }
            setValue={update}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
