import React from 'react';
import {
  IPackage,
  defaultPackage,
} from '../../../../content-management/models/Package';
import { KTSVG } from '../../../../../../_start/helpers';
import { InlineEdit } from '../../../components/InlineEdit';

type FormPackageItemProps = {
  formPackage: IPackage;
  formPackageIndex: number;
  updatePackage: (itemIndex: number, selected: IPackage) => void;
  removePackage: (itemIndex: number) => void;
};

const FormPackageItem = ({
  formPackage,
  formPackageIndex,
  updatePackage,
  removePackage,
}: FormPackageItemProps) => {
  const [data, setData] = React.useState(defaultPackage);

  React.useEffect(() => {
    setData(formPackage);
  }, [formPackage]);

  const updateData = (fieldsToUpdate: Partial<IPackage>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updatePackage(formPackageIndex, updatedData);
  };

  return (
    <>
      {/* begin::Delete Item */}
      <span
        onClick={() => removePackage(formPackageIndex)}
        className="position-absolute z-index-2 top-0 end-0 m-n2 opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>
      {/* end::Delete Item */}

      <h3 className="heading h-medium t-fancy t-ls1- s-extrabold">
        {data.name}
      </h3>
      <p>{data.description}</p>
      <div className="card__price h-small s-bold u-flex u-gap-5 u-align-center">
        {/* <p>{element.price_per_month}</p> */}
        <InlineEdit
          // className="text-center"
          name="price_per_month"
          value={data.price_per_month}
          setValue={updateData}
          activeVerticalPadding={'py-1'}
          dynamicWidth
          dynamicWidthValue={100}
          style={{
            maxWidth: '22rem',
          }}
        />
        <span>p/m</span>
      </div>
    </>
  );
};

export default FormPackageItem;
