import React, { useState, useEffect, useRef } from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import { IPackage } from '../../../../content-management/models/Package';

type SelectPackageWrapperProps = {
  actionType: 'add' | 'update'
  itemIndex: number;
  searchProperty?: string;
  allModels: Array<IPackage>;
  addSelected: (model: IPackage) => void;
  updateSelected: (index: number, model: IPackage) => void;
  selectedMap: Map<number, IPackage>;
};

export const SelectPackageWrapper: React.FC<SelectPackageWrapperProps> = ({
  actionType,
  itemIndex,
  searchProperty = `name`, // could be 'name' or 'title' property based on the provided model
  allModels,
  addSelected,
  updateSelected,
  selectedMap,
  children,
}) => {
  const [options, setOptions] = useState<Array<IPackage>>([]);

  /**
   * Initialization
   */
  useEffect(() => {
    if (allModels) {
      setOptions(allModels);
    }
  }, [allModels]);

  /**
   * Handling blur events
   */
  const [active, setActive] = useState(false);
  const sectionRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (active) {
      sectionRef.current?.focus();
    }
  }, [active]);

  const onBlur = (event: React.FocusEvent<HTMLLIElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setActive(false);
    }
  };

  const handleActivate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataValue = (event.target as HTMLElement).getAttribute('data-value');

    if (dataValue !== 'child-li') {
      setActive(true);
    }
  };

  /**
   * Select Handler
   */
  const handleSelect = (item: IPackage) => {
    if (actionType === 'update') {
      updateSelected(itemIndex, item);
      setActive(false);
      return;
    }

    addSelected(item);
    setActive(false);
  };

  /**
   * Search handler
   */
  const handleLocalSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value.toLowerCase();

    const filtered_items = allModels.filter((item) => {
      const name = item[searchProperty].toLowerCase();
      return name.indexOf(keyword) > -1;
    });

    setOptions(filtered_items);
  };

  return (
    <div
      data-value="parent-li"
      // onClick={handleActivate}
      className="position-relative"
    >
      <button
        onClick={handleActivate}
        className={`position-absolute start-0 end-0 z-index-3 mx-auto btn btn-sm btn-icon btn-color-primary btn-light-primary ${
          active ? 'visually-hidden' : ''
        }`}
        style={{
          // marginTop: `${-17}rem`,
          bottom: '80%',
          transform: 'translate(0, -50%)',
        }}
      >
        <KTSVG
          path="/media/icons/duotone/Layout/Layout-4-blocks-2.svg"
          className="svg-icon-1"
        />
      </button>

      {/* begin::Select Case Detail with Search */}
      {active && (
        <section
          ref={sectionRef}
          onBlur={onBlur}
          tabIndex={1}
          className="position-absolute start-0 end-0 other-case-list"
          style={{
            zIndex: '999',
            // marginTop: `${-17}rem`,
            top: '45%',
            transform: 'translate(0, -50%)',
          }}
        >
          <div className="d-flex flex-column align-items-center">
            {/* begin::Case Page List*/}
            <div
              className="rounded"
              style={{
                background: 'white',
              }}
            >
              {/* begin::Search */}
              <div className="position-relative px-3 pt-3">
                <span
                  className="position-absolute"
                  style={{
                    top: '60%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotone/General/Search.svg"
                    className="ps-1 svg-icon-1 svg-icon-primary"
                  />
                </span>
                <input
                  onChange={handleLocalSearch}
                  className="ps-10 form-control form-control-solid"
                  type="text"
                  placeholder="Zoeken"
                />
              </div>
              {/* end::Search */}

              <div className="separator mt-2"></div>

              {/* begin::Options */}
              <ul
                className="my-1 me-2 p-1 list-unstyled overflow-auto select-other-case"
                style={{
                  maxHeight: '128px',
                }}
              >
                {options.map(
                  (option, idx) =>
                    !selectedMap.has(option.id) && (
                      <li
                        key={idx}
                        data-value="child-li"
                        onClick={() => handleSelect(option)}
                      >
                        {option.name}
                      </li>
                    )
                )}
                {(options.length === 0 ||
                  selectedMap.size === options.length) && (
                  <div className="mb-1 px-2 py-1 text-center text-gray-500">
                    No data available
                  </div>
                )}
              </ul>
              {/* end::Options */}

              <div className="separator mb-2"></div>
            </div>
            {/* end::Case Page List*/}
            {/* begin::ArrowDown Element*/}
            <div
              style={{
                width: '0',
                height: '0',
                borderLeft: '12px solid transparent',
                borderRight: '12px solid transparent',
                borderTop: '12px solid white',
              }}
            ></div>
            {/* end::ArrowDown Element*/}
          </div>
        </section>
      )}
      {/* end::Select Case Detail with Search */}

      {children}
    </div>
  );
};
