import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeImageLeftDescriptionBlueContent } from './HomeImageLeftDescriptionBlueThemeContent';
import { HomeImageLeftDescriptionBlueElement } from './HomeImageLeftDescriptionBlueThemeElement';
import { HomeImageLeftDescriptionBlueFigure } from './HomeImageLeftDescriptionBlueThemeFigure';

interface HomeImageLeftDescriptionProps {
  index: number;
  data: HomeImageLeftDescriptionBlueElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeImageLeftDescriptionBlueFC: React.FC<
  HomeImageLeftDescriptionProps
> = ({ index, data, updateData }) => {
  const update = (
    fieldsToUpdate: Partial<HomeImageLeftDescriptionBlueElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-image-left-description-blue">
          <div className="position-relative align-items-center showcase">
            <HomeImageLeftDescriptionBlueContent data={data} update={update} />
            <HomeImageLeftDescriptionBlueFigure data={data} update={update} />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
