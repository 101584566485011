/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../../_start/helpers";
import { ActionsDropdown } from "../../../components/ActionsDropdown";
import { DeleteModal } from "../../../components/modals/DeleteModal";
import { Pagination } from "../../../components/Pagination";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  failureToast,
  handlePaginate,
  PaginatedValue,
  successToast,
} from "../../../support/utils";
import { defaultNotice, INotice } from "../models/Notice";
import { activateNotice, deleteNotice, listNotice } from "../redux/NoticesCRUD";
import { ActivateNoticeModal } from "./ActivateNoticeModal";
import ReactHtmlParser from "react-html-parser";

export const NoticesPage = () => {
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const [paginatedValue, setPaginatedValue] = useState<PaginatedValue<INotice>>(
    new PaginatedValue()
  );

  const handlePaginateState = async (page_url: string) => {
    setLoading(true);
    try {
      const result = await handlePaginate<INotice>(page_url);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleFetchList = async () => {
    setLoading(true);
    try {
      const result = await listNotice();
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleFetchList();

      setInitialized(true);
    }
  }, []);

  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce<string>(search, 750);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const result = await listNotice(search);
      setPaginatedValue(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (initialized) {
      handleSearch();
    }
  }, [debouncedSearch]);

  const [selected, setSelected] = useState<INotice>(defaultNotice);

  const handleDelete = async (item: INotice) => {
    setLoading(true);
    try {
      await deleteNotice(item);
      successToast("Notice has been deleted.");
      setLoading(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleActivateNotice = async (item: INotice) => {
    setLoading(true);
    try {
      await activateNotice(item);
      successToast("Notice has been activated.");
      setLoading(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <>
      <DeleteModal
        headerTitle={`Delete Notice`}
        item={selected}
        handleDelete={handleDelete}
      >
        <>
          <p>{`Are you sure to delete this notice? This action is irreversible.`}</p>
          <p className="my-8 d-block text-center fw-bold">{selected.title}</p>
        </>
      </DeleteModal>

      <ActivateNoticeModal
        headerTitle={`Activate Notice`}
        item={selected}
        handleActivateNotice={handleActivateNotice}
      >
        <>
          <p>{`Enabling this notice will deactivate the current active notice. Do you want to proceed?`}</p>
          <p className="my-8 d-block text-center fw-bold">{selected.title}</p>
        </>
      </ActivateNoticeModal>

      <div
        className={`card card-custom card-flush ${
          loading ? "overlay overlay-block" : ""
        }`}
      >
        <div className="pt-8 card-header">
          <div className="d-flex flex-center border py-1 px-4 bg-white rounded">
            <KTSVG
              path="/media/icons/duotone/General/Search.svg"
              className="svg-icon-1 svg-icon-primary"
            />
            <input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              type="text"
              className={`form-control border-0 fw-bold ps-2 ${
                "" ? "w-xxl-600px" : "w-xxl-350px"
              }`}
              placeholder="Zoeken"
            />
          </div>
          <div className="card-toolbar">
            <Link className="btn btn-sm btn-light" to={`notices/create`}>
              Create Notice
            </Link>
          </div>
        </div>
        <div className={`card-body py-5 ${loading ? "overlay-wrapper" : ""}`}>
          <table className="table table-row-gray-300 gy-7">
            <thead>
              <tr className="fw-bolder fs-6 text-gray-800">
                <th>Name</th>
                <th>Message</th>
                <th>Is Active</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paginatedValue.data.map((element, idx) => (
                <tr key={idx} className="align-middle">
                  <td>{element.title}</td>
                  <td>{ReactHtmlParser(element.message)}</td>
                  <td>{element.is_active ? "true" : "false"}</td>
                  <td className="text-end">
                    {/* begin::Dropdown */}
                    <ActionsDropdown>
                      <div className="menu-item px-3">
                        <Link
                          to={`notices/${element.id}/update`}
                          className="menu-link px-3"
                        >
                          Update
                        </Link>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(element)}
                          href="#"
                          className="menu-link px-3 text-info"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_activate_kt_modal_1"
                        >
                          Activate
                        </a>
                      </div>
                      <div className="menu-item px-3">
                        <a
                          onClick={() => setSelected(element)}
                          href="#"
                          className="menu-link px-3 text-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#custom_delete_kt_modal_1"
                        >
                          Delete
                        </a>
                      </div>
                      <div className="separator mt-3 opacity-75"></div>
                    </ActionsDropdown>
                    {/* end::Dropdown */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {loading && (
            <div className="overlay-layer rounded bg-dark bg-opacity-5">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
        </div>
        <div className="card-footer">
          <Pagination
            loading={loading}
            pagination={paginatedValue}
            handlePaginateState={handlePaginateState}
          />
        </div>
      </div>
    </>
  );
};
