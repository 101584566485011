import React from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { TextElementSelectWrapper } from '../../../../components/TextElementSelectWrapper';
import { ElementHeaderType } from '../../../utils';
import {
  HomeImageTwoRightDescriptionFloatingWindowElement,
  IconHeadingAndDescription,
} from './HomeImageTwoRightDescriptionFloatingWindowElement';
import { HomeImageTwoRightDescriptionFloatingWindowIcon } from './HomeImageTwoRightDescriptionFloatingWindowIcon';

interface Props {
  data: HomeImageTwoRightDescriptionFloatingWindowElement;
  update: (
    fieldsToUpdate: Partial<HomeImageTwoRightDescriptionFloatingWindowElement>
  ) => void;
}

export const HomeImageTwoRightDescriptionFloatingWindowContent: React.FC<
  Props
> = ({ data, update }) => {
  const updateData = (to: Partial<IconHeadingAndDescription>, idx: number) => {
    const icons_titles = [...data.icons_headings];
    icons_titles[idx] = { ...icons_titles[idx], ...to };
    update({ ...data, icons_headings: icons_titles });
  };
  return (
    <div className="showcase__content">
      {data.icons_headings.map((item, idx) => (
        <div className="u-contain">
          <HomeImageTwoRightDescriptionFloatingWindowIcon
            idx={idx}
            data={item}
            update={updateData}
          />
          {/*begin::Heading*/}
          <TextElementSelectWrapper
            name={`heading_type`}
            value={item.heading_type ?? ElementHeaderType.H2}
            setValue={(value) =>
              updateData({ heading_type: value as ElementHeaderType }, idx)
            }
          >
            <TextAreaEdit
              name="heading"
              className="heading"
              value={item.heading}
              setValue={(val) => updateData(val, idx)}
            />
          </TextElementSelectWrapper>
          {/*end::Heading*/}

          {/*begin::Description*/}
          <div className="description_input prose">
            <TextEditor
              content={item.description}
              updateContent={(description) => updateData({ description }, idx)}
            />
          </div>
          {/*end::Description*/}
        </div>
      ))}
    </div>
  );
};
