import { IFAQCategory } from "./FAQCategory";

export interface IFAQ {
  id: number;
  title: string;
  title_description: string;
  description: string;
  button_title: string;
  url: string;
  categories?: Array<IFAQCategory>;
}

export interface CreateFAQPayload {
  title: string;
  title_description: string;
  description: string;
  button_title: string;
  url: string;
  categories?: Array<number>;
}

export type UpdateFAQPayload = CreateFAQPayload;

export const defaultFAQ: IFAQ = {
  id: 0,
  title: "",
  title_description: "",
  description: "",
  button_title: "",
  url: "",
  categories: [],
};
