/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { failureToast, successToast } from "../../../support/utils";
import { defaultVoipGrid, IVoipGrid } from "../models/VoipGrid";
import {
	createVoipGridAccount,
	CreateVoipGridAccountPayload,
	viewQuotation,
} from "../redux/QuotationCRUD";

export const CreateVoipGrid = () => {
	const history = useHistory();

	const { id } = useParams<{ id: string }>();

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<IVoipGrid>(defaultVoipGrid);

	const updateData = (fieldsToUpdate: Partial<IVoipGrid>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		setData(updatedData);
	};

	const handleFetch = async (id: string) => {
		setLoading(true);

		try {
			const result = await viewQuotation(id);
			setData({
				...data,
				// customer
				quotation_id: result.id,
				name: result.customer.firstname,
				kvk_number: result.customer.chamber_of_commerce_number,
				phone_number: `+${result.customer.phone_number}`,
				email: result.customer.email,
				billing_iban: result.customer.bank_account?.iban ?? "",
				billing_account_holder:
					result.customer.bank_account?.account_holder ?? "",
				// emptied for now since this was not asked during the become a customer action
				billing_vat_number: "",
				billing_email_address: result.customer.email,
			});
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			handleFetch(id);
		}
	}, [id]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);

		try {
			const payload: CreateVoipGridAccountPayload = {
				quotation_id: data.quotation_id,
				name: data.name,
				kvk_number: data.kvk_number,
				anonymize_after: data.anonymize_after,
				phone_number: data.phone_number,
				proposition: data.proposition,
				blocked_call_permissions: [
					"INFORMATION_FREE",
					"NATIONAL_FIXED",
					"NATIONAL_MOBILE",
					"NATIONAL_UNKNOWN",
				],
				country: data.country,
				language: data.language,
				address: {
					country: data.address_country,
					street: data.address_street,
					number: data.address_number,
					complement: data.address_complement,
					zipcode: data.address_zipcode,
					city: data.address_city,
				},
				user: {
					first_name: data.admin_user_first_name,
					preposition: data.admin_user_preposition,
					last_name: data.admin_user_last_name,
					email_address: data.admin_user_email_address,
				},
				billing: {
					iban: data.billing_iban,
					account_holder: data.billing_account_holder,
					vat_number: data.billing_vat_number,
					email_address: data.billing_email_address,
					pay_available: !!data.billing_pay_available,
					invoice_characteristic: data.billing_invoice_characteristic,
				},
			};

			await createVoipGridAccount(payload);

			successToast("VoipGrid has been created.");
			setData(defaultVoipGrid);
			history.goBack();
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	return (
		<div className="card">
			<div className="card-header">
				<h3 className="card-title">Create Category</h3>
			</div>
			{/* begin::Form */}
			<form onSubmit={handleSubmit} className="form d-flex flex-center">
				<div className="card-body mw-800px py-20">
					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Name</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter name"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.name}
									onChange={(e) =>
										updateData({
											name: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">KVK</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter KVK"
									className="form-control form-control-lg form-control-solid"
									type={"number"}
									value={data.kvk_number}
									onChange={(e) =>
										updateData({
											kvk_number: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Anonymize After
						</label>
						<div className="col-lg-9">
							<select
								className="form-select form-select-lg form-select-solid text-capitalize"
								data-control="select2"
								data-placeholder="Select..."
								value={data.anonymize_after}
								onChange={(e) =>
									updateData({
										anonymize_after: e.target.value,
									})
								}
							>
								<option value={1}>1</option>
								<option value={2}>2</option>
								<option value={3}>3</option>
							</select>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Phone</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter phone"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.phone_number}
									onChange={(e) =>
										updateData({
											phone_number: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Proposition
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter proposition"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.proposition}
									onChange={(e) =>
										updateData({
											proposition: e.target.value,
										})
									}
									required
									disabled
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Blocked Call Permissions
						</label>
						<div className="col-lg-9">
							<select
								className="form-select form-select-lg form-select-solid text-capitalize"
								data-control="select2"
								data-placeholder="Select..."
								value={data.blocked_call_permissions}
								onChange={(e) =>
									updateData({
										blocked_call_permissions:
											e.target.value,
									})
								}
							>
								<option value={"INFORMATION_FREE"}>
									INFORMATION_FREE
								</option>
								<option value={"NATIONAL_FIXED"}>
									NATIONAL_FIXED
								</option>
								<option value={"NATIONAL_MOBILE"}>
									NATIONAL_MOBILE
								</option>
								<option value={"NATIONAL_UNKNOWN"}>
									NATIONAL_UNKNOWN
								</option>
							</select>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Email Address
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter email address"
									className="form-control form-control-lg form-control-solid"
									type={"email"}
									value={data.email}
									onChange={(e) =>
										updateData({
											email: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Country
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter country"
									className="form-control form-control-lg form-control-solid"
									type={"text"}
									value={data.country}
									onChange={(e) =>
										updateData({
											country: e.target.value,
										})
									}
									required
									disabled
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Language
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter language"
									className="form-control form-control-lg form-control-solid"
									type={"text"}
									value={data.language}
									onChange={(e) =>
										updateData({
											language: e.target.value,
										})
									}
									required
									disabled
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					<div className="separator separator-dashed my-10"></div>

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-12 col-form-label text-center">
							ADDRESS INFORMATION
						</label>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Country
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter address country"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.address_country}
									onChange={(e) =>
										updateData({
											address_country: e.target.value,
										})
									}
									required
									disabled
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Street
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter address street"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.address_street}
									onChange={(e) =>
										updateData({
											address_street: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Number
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter address number"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.address_number}
									onChange={(e) =>
										updateData({
											address_number: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Complement
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter address complement"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.address_complement}
									onChange={(e) =>
										updateData({
											address_complement: e.target.value,
										})
									}
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Zip Code
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter address zip code"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.address_zipcode}
									onChange={(e) =>
										updateData({
											address_zipcode: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">City</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter address city"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.address_city}
									onChange={(e) =>
										updateData({
											address_city: e.target.value,
										})
									}
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					<div className="separator separator-dashed my-10"></div>

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-12 col-form-label text-center">
							ADMIN USER INFORMATION
						</label>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Firstname
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter admin firstname"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.admin_user_first_name}
									onChange={(e) =>
										updateData({
											admin_user_first_name:
												e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Preposition
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter admin preposition"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.admin_user_preposition}
									onChange={(e) =>
										updateData({
											admin_user_preposition:
												e.target.value,
										})
									}
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Lastname
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter admin lastname"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.admin_user_last_name}
									onChange={(e) =>
										updateData({
											admin_user_last_name:
												e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Email Address
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter admin email address"
									className="form-control form-control-lg form-control-solid"
									type={"email"}
									value={data.admin_user_email_address}
									onChange={(e) =>
										updateData({
											admin_user_email_address:
												e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					<div className="separator separator-dashed my-10"></div>

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-12 col-form-label text-center">
							BILLING INFORMATION
						</label>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">IBAN</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter IBAN"
									className="form-control form-control-lg form-control-solid"
									type={"text"}
									value={data.billing_iban}
									onChange={(e) =>
										updateData({
											billing_iban: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Account Holder
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter account holder"
									className="form-control form-control-lg form-control-solid"
									type={"text"}
									value={data.billing_account_holder}
									onChange={(e) =>
										updateData({
											billing_account_holder:
												e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							VAT Number
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter VAT number"
									className="form-control form-control-lg form-control-solid"
									type={"text"}
									value={data.billing_vat_number}
									onChange={(e) =>
										updateData({
											billing_vat_number: e.target.value,
										})
									}
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Email Address
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter billing email address"
									className="form-control form-control-lg form-control-solid"
									type={"email"}
									value={data.billing_email_address}
									onChange={(e) =>
										updateData({
											billing_email_address:
												e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Pay Available (direct Debit)
						</label>
						<div className="col-lg-9">
							<select
								className="form-select form-select-lg form-select-solid text-capitalize"
								data-control="select2"
								data-placeholder="Select..."
								value={data.billing_pay_available}
								onChange={(e) =>
									updateData({
										billing_pay_available: e.target.value,
									})
								}
							>
								<option value={1}>Yes</option>
								<option value={0}>No</option>
							</select>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Invoice Characteristic
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter invoice characteristic"
									className="form-control form-control-lg form-control-solid"
									type={"text"}
									value={data.billing_invoice_characteristic}
									onChange={(e) =>
										updateData({
											billing_invoice_characteristic:
												e.target.value,
										})
									}
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row">
						<label className="col-lg-3 col-form-label"></label>
						<div className="col-lg-9">
							<button
								type="submit"
								disabled={loading}
								className="btn btn-primary fw-bolder px-6 py-3 me-3"
							>
								{!loading && (
									<span className="indicator-label">
										Save
									</span>
								)}
								{loading && (
									<span
										className="indicator-progress"
										style={{ display: "block" }}
									>
										Please wait...{" "}
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
							<Link
								className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
								to={`/content-management/categories`}
							>
								Cancel
							</Link>
						</div>
					</div>
					{/* end::Form row */}
				</div>
			</form>
			{/* end::Form */}
		</div>
	);
};
