import React from 'react';
import { TextEditor } from '../../../../../components/TextEditor';
import { ButtonTooltipOld } from '../../../components/ButtonTooltipOld';
import { InlineEdit } from '../../../components/InlineEdit';
import { ContactsImageLeftDescriptionFloatingWindowIconsElement } from './ContactsImageLeftDescriptionFloatingWindowIconsElement';

interface Props {
  data: ContactsImageLeftDescriptionFloatingWindowIconsElement;
  update: (
    fieldsToUpdate: Partial<ContactsImageLeftDescriptionFloatingWindowIconsElement>
  ) => void;
}

export const ContactsImageLeftDescriptionFloatingWindowIconsFloatingComponent: React.FC<
  Props
> = ({ data, update }) => {
  return (
    <div className="showcase__cta">
      <div className="heading__cta">
        <TextEditor
          content={data.floating_text}
          updateContent={(floating_text) => update({ floating_text })}
        />
      </div>

      {/* begin::Action Button */}
      <div className="content-button">
        <ButtonTooltipOld
          name={`floating_button_url`}
          value={data.floating_button_url}
          setValue={update}
        >
          <button className="btn rounded-pill content-button__inner">
            <InlineEdit
              name="floating_button_title"
              className="px-1 text-white text-center text-primary-color"
              value={data.floating_button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '180px',
              }}
            />
          </button>
        </ButtonTooltipOld>
        {/* end::Action Button */}
      </div>
    </div>
  );
};
