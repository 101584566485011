import React from 'react';
import { MediaVideo } from './MediaVideo';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { InlineEdit } from '../../../components/InlineEdit';

type Props = {
  data: MediaVideo;
  update: (fieldsToUpdate: Partial<MediaVideo>) => void;
};

export const MediaVideoFigure: React.FC<Props> = ({ data, update }) => {
  return (
    <div className="figure">
      <div className="inner">
        <div className="link">
          <div>Youtube Video Link: </div>
          <div className="border">
            <InlineEdit
              className="item"
              name={'video_link'}
              value={data.video_link ?? ''}
              setValue={update}
            />
          </div>
        </div>

        <TextAreaEdit
          name="caption"
          className="caption"
          value={data.caption}
          setValue={update}
        />
      </div>
    </div>
  );
};
