import { useDrag, useDrop } from 'react-dnd';
import { IElement } from '../models/Element';

import updateHelper from 'immutability-helper';
import { useRef } from 'react';

export enum HorizontalDragType {
  HOME_SERVICES = 'home-services',
  BLOG_FEATURED = 'blog-featured',
  FEATURED_OTHER_CASES = 'featured-other-cases',
  SPECIALIZATION_FEATURED_CASES = 'specialization-featured-cases',
  SPECIALIZATION_FEATURED_POSTS = 'specialization-featured-posts',
  PARTNER = 'partner',
}

type HorizontalDragWrapperProps = {
  horizontalDragType: HorizontalDragType;
  element: IElement;
  elementIndex: number;
  listPropertyName: string;
  updateData: (body: IElement, index: number) => void;
  itemIndex: number;
};

export const HorizontalDragWrapper: React.FC<HorizontalDragWrapperProps> = ({
  horizontalDragType,
  element,
  elementIndex,
  listPropertyName,
  updateData,
  itemIndex,
  children,
}) => {
  const moveListItem = (dragIndex: number, hoverIndex: number) => {
    let updatedList = updateHelper(element[listPropertyName], {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, element[listPropertyName][dragIndex]],
      ],
    });

    const updatedData = { ...element, [listPropertyName]: updatedList };
    updateData(updatedData, elementIndex);
  };

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: `${horizontalDragType}-${element.id}`,
    item: () => ({ id: element.id, index: itemIndex }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver, canDrop, handlerId }, drop] = useDrop({
    accept: `${horizontalDragType}-${element.id}`,
    drop: (item: { id: number; index: number }) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = itemIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveListItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  drop(drag(ref));

  const opacity = isDragging ? 0.3 : 1;

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
      className={`${canDrop ? 'can-drop-highlight' : ''} ${
        isOver ? 'is-over-highlight' : ''
      }`}
    >
      {children}
    </div>
  );
};
