import {
  defaultPackage,
  IPackage,
} from '../../content-management/models/Package';

export type MenuLink = {
  link_title: string;
  link_url: string;
};
export const defaultMenuLink: MenuLink = {
  link_title: '',
  link_url: '',
};

export type MenuContentGroup = {
  group_title: string;
  links: Array<MenuLink>;
};
export const defaultMenuContentGroup: MenuContentGroup = {
  group_title: '',
  links: [],
};

export type MenuSidebar = {
  links: Array<MenuLink>;
};

export enum BulletType {
  CHECK = 'check',
  CROSS = 'cross',
}

export type MenuPackageBulletPoint = {
  bullet_type: BulletType;
  content: string;
};

export const defaultMenuPackageBulletPoint: MenuPackageBulletPoint = {
  bullet_type: BulletType.CHECK,
  content: 'Alle modules inbegrepen',
};

export interface IMenu {
  id: number;

  name: string;

  title: string;
  description: string;
  expand: number;
  url: string;

  content: Array<MenuContentGroup>;
  sidebar: Array<MenuLink>;

  package_id: number;
  package: IPackage;
  package_icon_preview: string;
  bullet_points: Array<MenuPackageBulletPoint>;
}

export const defaultMenu: IMenu = {
  id: 0,

  name: '',

  title: '',
  description: '',
  expand: 0,
  url: '',

  content: [],
  sidebar: [],

  package_id: 0,
  package: defaultPackage,
  package_icon_preview: '',
  bullet_points: [],
};
