import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IPartner } from '../models/Partner';

const PARTNER_URL = `${API_URL}/v1/partner-management`;

export const createPartner = async (payload: IPartner) => {
  const response = await axios.post<{ value: IPartner }>(
    `${PARTNER_URL}/create`,
    payload
  );

  return response.data.value;
};

export const viewPartner = async (customer_id: string | number) => {
  const response = await axios.get<{ value: IPartner }>(
    `${PARTNER_URL}/${customer_id}/view`
  );

  return response.data.value;
};

export const updatePartner = async (payload: IPartner) => {
  const response = await axios.post<{ value: IPartner }>(
    `${PARTNER_URL}/${payload.id}/update`,
    payload
  );

  return response.data.value;
};

export const deletePartner = async (payload: IPartner) => {
  const response = await axios.post<{ value: IPartner }>(
    `${PARTNER_URL}/${payload.id}/delete`,
    payload
  );

  return response.data.value;
};

export const listPartner = async (search = '', direction = 'asc') => {
  const URL = `${PARTNER_URL}/list?search=${search}&direction=${direction}`;
  const response = await axios.get<{ value: PaginatedValue<IPartner> }>(URL);

  return response.data.value;
};

export const getAllPartner = async () => {
  const response = await axios.get<{ value: Array<IPartner> }>(
    `${PARTNER_URL}/all`
  );

  return response.data.value;
};
