import { BlogContentDescription } from './BlogContentDescription';
import { IElement } from '../../../models/Element';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { TextEditor } from '../../../../../components/TextEditor';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';
import { ElementHeaderType } from '../../utils';

type BlogContentDescriptionFCProps = {
  index: number;
  data: BlogContentDescription;
  updateData: (body: IElement, index: number) => void;
};

export const BlogContentDescriptionFC = ({
  index,
  data,
  updateData,
}: BlogContentDescriptionFCProps) => {
  const update = (fieldsToUpdate: Partial<BlogContentDescription>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="blog-content-description my-10">
        <div className="blog-content">
          {/* begin::Title */}
          <HeaderSelectWrapper
            name={`title_type`}
            value={data.title_type ?? ElementHeaderType.H2}
            setValue={update}
          >
            <TextAreaEdit
              name="title"
              className={`blog-content__title`}
              value={data.title}
              setValue={update}
            />
          </HeaderSelectWrapper>
          {/* end::Title */}

          <TextEditor
            content={data.content}
            updateContent={(content) => update({ content })}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
