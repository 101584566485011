import React from "react";
import { TextEditor } from "../../../../../components/TextEditor";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { IElement } from "../../../models/Element";
import { QuoteBlock } from "./QuoteBlock";

interface Props {
  index: number;
  data: QuoteBlock;
  updateData: (body: IElement, index: number) => void;
}

export const QuoteBlockFC: React.FC<Props> = ({ index, data, updateData }) => {
  const update = (to: Partial<QuoteBlock>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="quote-block">
        <div className="quote">
          <TextEditor
            content={data.quote}
            updateContent={(quote) => update({ quote })}
          />
        </div>

        <div className="footer">
          <TextEditor
            content={data.footer}
            updateContent={(footer) => update({ footer })}
          />
        </div>
      </div>
    </ElementWrapper>
  );
};
