import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export interface IPackageItemDetail {
  id: number;
  packageId: number;
  pros: string[];
  cons: string[];
  isMostChosen: boolean;
  icon: string;
  icon_url: string;
  price: string;
}

const triangle_icon = `<svg data-src="assets/icons/icon-triangle.svg" aria-hidden="true" data-cache="604800" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 32" data-id="svg-loader_82"><path d="M37 32H0L18.5 0 37 32Zm-26.88-5.84h16.76L18.5 11.67l-8.38 14.49Z"></path></svg>`;

export type HomePackages = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  button_url: string;
  button_title: string;
  button_url_is_external: boolean;
  packages: IPackageItemDetail[];
};

export const defaultPackageItemDetail: IPackageItemDetail = {
  id: 0,
  packageId: -1,
  pros: [],
  cons: [],
  isMostChosen: false,
  icon: triangle_icon,
  icon_url: '',
  price: '0,00',
};

export const defaultPro = 'Tot wel 2 gesprekken per dag';
export const defaultCon = 'Geschikt tot 5 koppelingen';

export const defaultHomePackages: HomePackages = {
  id: 0,
  name: ElementType.HOME_PACKAGES,
  heading: 'Kies jouw pakket',
  button_url: '',
  button_url_is_external: false,
  button_title: 'Alle pakketten',
  packages: [],
};
