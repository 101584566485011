import { Link } from 'react-router-dom';
import { MenuItem } from './MenuItem';

export function MenuInner() {
  return (
    <>
      <div className="row">
        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Dashboard</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/dashboard" title="Home" />
            </li>
          </ul>
        </div>
        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Paginabeheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/page-management/pages" title="Pagina's" />
            </li>
            <li className="menu-item">
              <MenuItem to="/page-management/faqs" title="FAQs" />
            </li>
            <li className="menu-item">
              <MenuItem
                to="/page-management/faq-categories"
                title="FAQ Categories"
              />
            </li>
          </ul>
        </div>
        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Berichtbeheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/post-management/posts" title="Berichten" />
            </li>
            <li className="menu-item">
              <MenuItem to="/post-management/categories" title="Categorieën" />
            </li>
            <li className="menu-item">
              <MenuItem to="/post-management/tags" title="Tags" />
            </li>
          </ul>
        </div>
        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Site instellingen</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/settings/seo" title="SEO" />
            </li>
            <li className="menu-item">
              <MenuItem to="/google-reviews" title="Google Reviews" />
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Inhoud beheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/content-management/packages" title="Paketten" />
            </li>
            <li className="menu-item">
              <MenuItem to="/content-management/products" title="Producten" />
            </li>
            <li className="menu-item">
              <MenuItem
                to="/content-management/categories"
                title="Categorieën"
              />
            </li>
            <li className="menu-item">
              <MenuItem
                to="/content-management/call-rates"
                title="Beltarieven"
              />
            </li>
          </ul>
        </div>
        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Personeelsbeheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/employee-management/employees" title="Personeel" />
              <MenuItem
                to="/employee-management/departments"
                title="Afdelingen"
              />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Client & Partner</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/client-management/clients" title="Clients" />
              <MenuItem to="/client-management/partners" title="Partners" />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Rol & Permissie</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/role-and-permission/roles" title="Rollen" />
            </li>
            <li className="menu-item">
              <MenuItem to="/role-and-permission/permissions" title="Rechten" />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Offertebeheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/quotation-management" title="Aanvragen" />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Gebruikersbeheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/user-management/members" title="Gebruikers" />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Lay-outbeheer</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/layout-management/menus" title="Menus" />
            </li>
            <li className="menu-item">
              <MenuItem to="/layout-management/footers" title="Footers" />
            </li>
            <li className="menu-item">
              <MenuItem
                to="/layout-management/cookie-banner"
                title="Cookie Banner"
              />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Inquiry Management</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/inquiry-management/inquiries" title="Inquiries" />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Media Management</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem
                to="/media-management/media-library"
                title="Media Library"
              />
            </li>
            <li className="menu-item">
              <MenuItem
                to="/media-management/svg-library"
                title="SVG Library"
              />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Notice Management</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/notice-management/notices" title="Notices" />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Manage Redirect Links</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem
                to="/redirect-link-management/redirect-link"
                title="Redirect Links"
              />
            </li>
          </ul>
        </div>

        <div className="col-sm-3">
          <h3 className="fw-bolder mb-5">Profiel</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <Link to="/profile/account" className="menu-link ps-0 py-2">
                Account
              </Link>
            </li>
            <li className="menu-item">
              <Link
                className="menu-link ps-0 py-2"
                to="/profile/change-password"
              >
                Change Password
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
