import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CookieBannersPage } from './pages/cookie-banner/CookieBannersPage';
import { UpdateCookieBanner } from './pages/cookie-banner/UpdateCookieBanner';
import { FootersPage } from './pages/footer/FootersPage';
import { UpdateFooter } from './pages/footer/UpdateFooter';
import { MenusPage } from './pages/MenusPage';
import { UpdateMenu } from './pages/UpdateMenu';

export const LayoutManagementPage = () => {
  return (
    <Switch>
      {/* begin::Page Routes */}
      <Route exact={true} path="/layout-management/menus">
        <>
          <PageTitle>Menus</PageTitle>
          <MenusPage />
        </>
      </Route>

      <Route exact={true} path="/layout-management/menus/:id/update">
        <>
          <PageTitle>Menus</PageTitle>
          <UpdateMenu />
        </>
      </Route>

      <Route exact={true} path="/layout-management/footers">
        <>
          <PageTitle>Footers</PageTitle>
          <FootersPage />
        </>
      </Route>

      <Route exact={true} path="/layout-management/footers/:id/update">
        <>
          <PageTitle>Footers</PageTitle>
          <UpdateFooter />
        </>
      </Route>

      <Route exact={true} path="/layout-management/cookie-banner">
        <>
          <PageTitle>Cookie Banner</PageTitle>
          <CookieBannersPage />
        </>
      </Route>

      <Route exact={true} path="/layout-management/cookie-banner/:id/update">
        <>
          <PageTitle>Cookie Banner</PageTitle>
          <UpdateCookieBanner />
        </>
      </Route>

      {/* end::Page Routes */}

      <Redirect
        from="/layout-management"
        exact={true}
        to="/layout-management/menus"
      />

      <Redirect to="/layout-management/menus" />
    </Switch>
  );
};
