import { ElementType, IElement } from '../../../models/Element';

export type FormNewsLetter = IElement & {
  title: string;
  name_label: string;
  name_placeholder: string;

  email_label: string;
  email_placeholder: string;

  button_title: string;

  success_message?: string;
};

export const defaultFormNewsLetter: FormNewsLetter = {
  id: 0,
  name: ElementType.FORM_NEWS_LETTER,

  title: 'Newsletter',

  name_label: 'Naam',
  name_placeholder: 'Enter name',

  email_label: 'Emailadres',
  email_placeholder: 'Enter email',

  button_title: 'Verstuur',
};
