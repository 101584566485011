import { useRef, useState } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../_start/helpers';
import { AttachSvgModal } from '../../edit-content/components/AttachSvgModal';
import { ButtonTooltip } from '../../edit-content/components/ButtonTooltip';
import { defaultSocialMediaLink, SocialMediaLink } from '../models/Footer';

type SocialMediaLinkProps = {
  index: number;
  link: SocialMediaLink;
  removeLink: (index: number) => void;
  updateLink: (index: number, link: SocialMediaLink) => void;
};

export const SocialMediaLinkFC = ({
  index,
  link,
  removeLink,
  updateLink,
}: SocialMediaLinkProps) => {
  const [data, setData] = useState<SocialMediaLink>(
    link ? link : defaultSocialMediaLink
  );

  const updateData = (fieldsToUpdate: Partial<SocialMediaLink>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateLink(index, updatedData);
  };

  const removeItemRef = useRef<HTMLSpanElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    removeItemRef.current?.classList.remove('d-none');
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    removeItemRef.current?.classList.add('d-none');
  };

  return (
    <div
      className="position-relative d-flex justify-content-center align-items-center border-dashed border-1"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ height: '8rem', width: '8rem' }}
    >
      <span
        ref={removeItemRef}
        onClick={() => removeLink(index)}
        className="position-absolute top-0 end-0 z-index-2 d-none opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>

      <AttachSvgModal
        modalId={`svg-icon-social-media-icons-${index}`}
        updateCallback={(name) => updateData({ svg_img: name })}
      />

      <ButtonTooltip
        name={`link_url`}
        className={`z-index-1`}
        value={data.link_url}
        setValue={updateData}
        isCheckboxChecked={data.is_link_external}
        checkboxChanged={(checked) => updateData({ is_link_external: checked })}
      >
        <div
          className=" d-flex justify-content-center align-items-center"
          style={{ height: '8rem', width: '8rem' }}
        >
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                className="icon"
                style={{
                  display: 'flex',
                  height: '4rem',
                  width: '4rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                src={
                  data.svg_img
                    ? data.svg_img
                    : toAbsoluteUrl('/assets/icons/icon-check.svg')
                }
                alt="Uploaded"
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10">
              <label
                className="btn btn-light-primary btn-shadow btn-sm ms-2"
                data-bs-toggle="modal"
                data-bs-target={`#svg-icon-social-media-icons-${index}`}
              >
                Edit
              </label>
            </div>
          </div>
        </div>
      </ButtonTooltip>
    </div>
  );
};
