import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeImageLeftDescriptionFloatingWindowIconsContent } from './HomeImageLeftDescriptionFloatingWindowIconsContent';
import { HomeImageLeftDescriptionFloatingWindowIconsElement } from './HomeImageLeftDescriptionFloatingWindowIconsElement';
import { HomeImageLeftDescriptionFloatingWindowIconsFigure } from './HomeImageLeftDescriptionFloatingWindowIconsFigure';

interface Props {
  index: number;
  data: HomeImageLeftDescriptionFloatingWindowIconsElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeImageLeftDescriptionFloatingWindowIconsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (
    fieldsToUpdate: Partial<HomeImageLeftDescriptionFloatingWindowIconsElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-image-floating-left-description-icons">
          <div className="showcase">
            <HomeImageLeftDescriptionFloatingWindowIconsContent
              data={data}
              update={update}
            />
            <HomeImageLeftDescriptionFloatingWindowIconsFigure
              data={data}
              update={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
