import { ReactNode } from 'react';

type AppTableProps = {
  className?: string;
  head: ReactNode;
  body: ReactNode;
};

export const AppTable = ({
  className = 'table table-row-gray-300 gy-7',
  head,
  body,
}: AppTableProps) => {
  return (
    <table className={className}>
      {head}
      {body}
    </table>
  );
};
