export type NoticeLink = {
  link_title: string;
  link_url: string;
};
export const defaultNoticeLink: NoticeLink = {
  link_title: "",
  link_url: "",
};

export type NoticeContentGroup = {
  group_title: string;
  links: Array<NoticeLink>;
};
export const defaultNoticeContentGroup: NoticeContentGroup = {
  group_title: "",
  links: [],
};

export type NoticeSidebar = {
  links: Array<NoticeLink>;
};

export interface INotice {
  id: number;

  title: string;

  icon: string;
  message: string;
  is_active: boolean;

  button_title: string;
  url: string;
}

export const defaultNotice: INotice = {
  id: 0,

  title: "",

  icon: "",
  message: "",
  is_active: false,

  button_title: "",
  url: "",
};
