import React from 'react';
import { KTSVG } from '../../../../../../../_start/helpers';
import { defaultPackage, IPackage } from '../../../../../content-management/models/Package';
import { defaultCon, defaultPro, HomePackages, IPackageItemDetail } from './HomePackages';
import { HomePackagesListItemIcon } from './HomePackagesListItemIcon';
import { HomePackagesListItemProsCons } from './HomePackagesListItemProsCons';
import { InlineEdit } from '../../../../components/InlineEdit';

interface Props {
  idx: number;
  pack: IPackage;
  details: IPackageItemDetail;
  updateData: (to: Partial<IPackageItemDetail>, itemIndex: number) => void;
  update: (to: Partial<HomePackages>) => void;
}

export const HomePackagesListItem: React.FC<Props> = ({
  idx,
  pack,
  details,
  updateData,
  update,
}) => {
  const addPros = () => {
    const pros = [...details.pros];
    pros.push(`${defaultPro}`);
    updateData({ ...details, pros }, idx);
  };
  const updatePros = (val: string, index: number) => {
    const pros = [...details.pros];
    pros[index] = `${val}`;
    updateData({ ...details, pros }, idx);
  };
  const deletePro = (index: number) => {
    const pros = [...details.pros];
    pros.splice(index, 1);
    updateData({ ...details, pros }, idx);
  };

  const addCons = () => {
    const cons = [...details.cons];
    cons.push(`${defaultCon}`);
    updateData({ ...details, cons }, idx);
  };
  const updateCons = (val: string, index: number) => {
    const cons = [...details.cons];
    cons[index] = `${val}`;
    updateData({ ...details, cons }, idx);
  };
  const deleteCon = (index: number) => {
    const cons = [...details.cons];
    cons.splice(index, 1);
    updateData({ ...details, cons }, idx);
  };

  const toggleIsMostChosen = (val: boolean) => {
    var isMostChosen = details.isMostChosen;
    isMostChosen = !isMostChosen;
    updateData({ ...details, isMostChosen }, idx);
  };

  const updatePrice = (fieldsToUpdate: Partial<IPackage>) => {
    var price = details.price;
    price = fieldsToUpdate.price_per_month!;
    updateData({ ...details, price}, idx);
  }

  return (
    <div className="d-flex align-items-center flex-column">
      <div className="mb-5">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            checked={details.isMostChosen}
            onChange={(val) => {
              toggleIsMostChosen(val.target.checked);
            }}
          />
          <label className="form-check-label" htmlFor="flexCheckChecked">
            Most Chosen
          </label>
        </div>
      </div>

      <div className="card">
        <div className="card__header">
          <div className="card__package-name">
            <div className="card__heading">{pack.name}</div>
            <div className="card__subtitle">
              <p>{pack.description}</p>
            </div>
          </div>
          <HomePackagesListItemIcon
            idx={idx}
            data={details}
            update={updateData}
          />
        </div>

        <div className="card__price">
          <span>Vanaf</span>
          <small>€</small>
          <strong>
            <InlineEdit
              name="price_per_month"
              value={details.price}
              setValue={updatePrice}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              dynamicWidthValue={100}
              style={{
                maxWidth: '22rem',
              }}
            />
          </strong>
          <span>p/m</span>
        </div>

        <div className="d-flex mb-10">
          <div className="card__button">
            <div className="button__text">Offerte maken</div>
          </div>
        </div>

        <div className="card_pros-cons">
          {details.pros.map((val, i) => (
            <HomePackagesListItemProsCons
              idx={i}
              data={val}
              update={(val) => updatePros(val, i)}
              type={'pro'}
              deleteItem={deletePro}
            />
          ))}
          {/* Add Pros */}
          <div
            onClick={addPros}
            style={{
              minHeight: '38px',
            }}
            className="w-100 d-flex rounded border-gray-400 border-dashed border-2 mb-2"
          >
            <div className="m-auto d-flex flex-row align-items-center">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-muted svg-icon-2hx text-center"
              />
              <div>Add Pro</div>
            </div>
          </div>

          {details.cons.map((val, i) => (
            <HomePackagesListItemProsCons
              idx={i}
              data={val}
              update={(val) => updateCons(val, i)}
              type={'con'}
              deleteItem={deleteCon}
            />
          ))}
          {/* Add Cons */}
          <div
            onClick={addCons}
            style={{
              minHeight: '38px',
            }}
            className="w-100 d-flex rounded border-gray-400 border-dashed border-2"
          >
            <div className="m-auto d-flex flex-row align-items-center">
              <KTSVG
                path="/media/icons/duotone/Interface/Plus-Square.svg"
                className="svg-icon-muted svg-icon-2hx text-center"
              />
              <div>Add Con</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
