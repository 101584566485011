import React from 'react';
import { ButtonTooltipOld } from '../../../../components/ButtonTooltipOld';
import { HeaderSelectWrapper } from '../../../../components/HeaderSelectWrapper';
import { InlineEdit } from '../../../../components/InlineEdit';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { ElementHeaderType } from '../../../utils';
import { HomePackages } from './HomePackages';

interface Props {
  data: HomePackages;
  update: (to: Partial<HomePackages>) => void;
}

export const HomePackagesHeader: React.FC<Props> = ({ data, update }) => {
  return (
    <div className="element__header">
      <HeaderSelectWrapper
        name={`heading_type`}
        value={data.heading_type ?? ElementHeaderType.H2}
        setValue={update}
      >
        <TextAreaEdit
          className="heading"
          name="heading"
          value={data.heading}
          setValue={update}
        />
      </HeaderSelectWrapper>

      {/* begin::Action Button */}
      <div className="button">
        <ButtonTooltipOld
          name={`button_url`}
          value={data.button_url}
          setValue={update}
        >
          <button className="btn rounded-pill content-button__inner">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center text-primary-color"
              value={data.button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '180px',
                fontWeight: 600,
              }}
            />
          </button>
        </ButtonTooltipOld>
      </div>
      {/* end::Action Button */}
    </div>
  );
};
