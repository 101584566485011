import { IActionUnion, makePayloadAction } from '../../../../setup/redux/utils';
import { PaginatedValue } from '../../../support/utils';
import { IMedia } from '../models/Media';
import { SvgLibraryEvents } from './SvgLibraryEvents';

export const setSvgPaginatedValue = makePayloadAction<
  SvgLibraryEvents.SET_SVG_PAGINATED_VALUE,
  PaginatedValue<IMedia>
>(SvgLibraryEvents.SET_SVG_PAGINATED_VALUE);

export const setSearchSvgPaginatedValue = makePayloadAction<
  SvgLibraryEvents.SET_SEARCH_SVG_PAGINATED_VALUE,
  PaginatedValue<IMedia>
>(SvgLibraryEvents.SET_SEARCH_SVG_PAGINATED_VALUE);

export const svgLibraryActions = {
  setSvgPaginatedValue,
  setSearchSvgPaginatedValue,
};

export type SvgLibraryActions = IActionUnion<typeof svgLibraryActions>;
