import { useState } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_start/helpers";
import { handleFileChange, handleFileDelete } from "../../support/helpers";

type TestStorage = {
	image: string;
};

const defaultTestStorage: TestStorage = {
	image: "",
};

export const StorageTest = () => {
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<TestStorage>(defaultTestStorage);

	const updateData = (fieldsToUpdate: Partial<TestStorage>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		setData(updatedData);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);

		try {
			// const response = await axios.post(
			// 	`${API_URL}/v1/storage/content/store`,
			// 	data
			// );
			// console.log(response.data.value);

			setLoading(false);
		} catch (error) {
			setLoading(false);
		}

		// try {
		// 	await createCategory(data);
		// 	successToast("Category has been created.");
		// 	setData(defaultCategory);
		// 	history.goBack();
		// 	setLoading(false);
		// } catch (error) {
		// 	failureToast(error);
		// 	setLoading(false);
		// }
	};

	return (
		<div className="card">
			<div className="card-header">
				<h3 className="card-title">Create Category</h3>
			</div>
			{/* begin::Form */}
			<form onSubmit={handleSubmit} className="form d-flex flex-center">
				<div className="card-body mw-800px py-20">
					{/* begin::Form row */}
					<div className="row mb-20">
						<label className="col-lg-3 col-form-label">
							Image Preview
						</label>
						<div className="col-lg-9">
							{data.image && (
								<div className="overlay">
									<div className="w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover overlay-wrapper">
										<img
											alt=""
											className="m-auto"
											src={data.image}
											style={{
												width: "400px",
												height: "500px",
												objectFit: "cover",
											}}
										/>
									</div>
									<div className="overlay-layer bg-dark bg-opacity-10">
										<button
											name="image"
											className="btn btn-primary btn-shadow"
											onClick={(event) =>
												handleFileDelete(
													event,
													data.image,
													updateData
												)
											}
										>
											Remove
										</button>
										<label
											htmlFor={`case-image-preview-input`}
											className="btn btn-light-primary btn-shadow ms-2"
										>
											Change
										</label>
									</div>
								</div>
							)}
							<label
								htmlFor={`case-image-preview-input`}
								className={`w-100 p-5 d-flex rounded border-dashed border-2 opacity-75-hover ${
									data.image ? "d-none" : ""
								}`}
							>
								<img
									className="m-auto"
									src={toAbsoluteUrl(
										"/assets/images/400x500.png"
									)}
									style={{
										objectFit: "cover",
									}}
									alt=""
								/>
							</label>
							<div className="form-text">
								This image preview will be used as a preview
								page on CRM side.
							</div>
						</div>
						<input
							id={`case-image-preview-input`}
							type="file"
							accept="image/*"
							name="image"
							style={{ display: "none" }}
							onChange={(event) =>
								handleFileChange(event, updateData, data.image)
							}
						/>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row">
						<label className="col-lg-3 col-form-label"></label>
						<div className="col-lg-9">
							<button
								type="submit"
								disabled={loading}
								className="btn btn-primary fw-bolder px-6 py-3 me-3"
							>
								{!loading && (
									<span className="indicator-label">
										Save
									</span>
								)}
								{loading && (
									<span
										className="indicator-progress"
										style={{ display: "block" }}
									>
										Please wait...{" "}
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
							<Link
								className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
								to={`/post-management/categories`}
							>
								Cancel
							</Link>
						</div>
					</div>
					{/* end::Form row */}
				</div>
			</form>
			{/* end::Form */}
		</div>
	);
};
