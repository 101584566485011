/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import {
  getConfig,
  IThemeConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from '../../../_start/layout/core';
import { EmployeeManagementPage } from './EmployeeManagementPage';
import { employeeManagementSubmenu } from './EmployeeManagementPageData';

const employeeManagementBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/',
    isActive: false,
  },
  {
    title: 'Personeelsbeheer',
    path: '/employee-management',
    isActive: false,
  },
];

const defaultPageConfig = getConfig();
const employeeManagementPageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
    content: 'user',
    bgColor: 'bg-info',
  },
};

export const EmployeeManagementWrapper = () => {
  const { setTheme } = useTheme();

  // Refresh UI after config updates
  useEffect(() => {
    setTheme(employeeManagementPageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={employeeManagementBreadCrumbs}
        submenu={employeeManagementSubmenu}
      />
      <EmployeeManagementPage />
    </>
  );
};
