import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { AttachSvgModal } from '../../../../components/AttachSvgModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeHeroWideThreeIconsContent } from './HomeHeroWideThreeIconsContent';
import {
  HomeHeroWideThreeIconsElement,
  IconTitle,
} from './HomeHeroWideThreeIconsElement';
import { HomeHeroWideThreeIconsFigure } from './HomeHeroWideThreeIconsFigure';
import { HomeHeroWideThreeIconsItems } from './HomeHeroWideThreeIconsItems';

interface Props {
  index: number;
  data: HomeHeroWideThreeIconsElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeHeroWideThreeIconsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<HomeHeroWideThreeIconsElement>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const updateIcon = (to: Partial<IconTitle>, idx: number) => {
    const titles = [...data.icon_titles];
    titles[idx] = { ...titles[idx], ...to };
    update({ ...data, icon_titles: titles });
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      {
        // different approach since having this element inside icon component,
        // styling issue arises and modal attach feature is not possible
        data.icon_titles.map((item, idx) => (
          <AttachSvgModal
            key={idx}
            modalId={`home-hero-wide-three-icons-${idx}-${data.id}`}
            updateCallback={(icon_url) => updateIcon({ icon_url }, idx)}
          />
        ))
      }

      <ElementWrapper elementIndex={index}>
        <div className="home-hero-wide-three-icons">
          <div className="grid">
            <div className="grid__inner">
              <div className="hero">
                <div className="hero__container">
                  <HomeHeroWideThreeIconsContent data={data} update={update} />
                  <HomeHeroWideThreeIconsItems data={data} update={update} />
                  <HomeHeroWideThreeIconsFigure data={data} update={update} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
