import React from 'react';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import {
  HomeSixIconsWithTitlesElement,
  IconWithTitleItem,
} from './HomeSixIconsWithTitlesElement';
import { HomeSixIconsWithTitlesIcon } from './HomeSixIconsWithTitlesIcon';

interface Props {
  data: HomeSixIconsWithTitlesElement;
  item: IconWithTitleItem;
  index: number;
  update: (toUpdate: Partial<IconWithTitleItem>, idx: number) => void;
}

export const HomeSixIconsWithTitlesItem: React.FC<Props> = ({
  index,
  data,
  item,
  update,
}) => {
  const updateValue = (to: Partial<IconWithTitleItem>) => {
    update(to, index);
  };
  return (
    <div className="usp__item">
      <HomeSixIconsWithTitlesIcon
        idx={index}
        data={data}
        item={item}
        updateData={update}
      />

      {/*begin::Heading*/}
      <TextAreaEdit
        name="title"
        className="heading"
        value={item.title}
        setValue={updateValue}
      />
      {/*end::Heading*/}
    </div>
  );
};
