import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { CallRatesPage } from './pages/call-rate/CallRatesPage';
import { UpdateCallRate } from './pages/call-rate/UpdateCallRate';
import { CategoriesPage } from './pages/category/CategoriesPage';
import { CreateCategory } from './pages/category/CreateCategory';
import { UpdateCategory } from './pages/category/UpdateCategory';
import { CreateFAQ } from './pages/faq/CreateFAQ';
import { FAQsPage } from './pages/faq/FAQsPage';
import { UpdateFAQ } from './pages/faq/UpdateFAQ';
import { CreatePackageType } from './pages/package-type/CreatePackageType';
import { PackageTypesPage } from './pages/package-type/PackageTypesPage';
import { UpdatePackageType } from './pages/package-type/UpdatePackageType';
import { CreatePackage } from './pages/package/CreatePackage';
import { PackagesPage } from './pages/package/PackagesPage';
import { UpdatePackage } from './pages/package/UpdatePackage';
import { CreateProduct } from './pages/product/CreateProduct';
import { ProductsPage } from './pages/product/ProductsPage';
import { UpdateProduct } from './pages/product/UpdateProduct';

export const ContentManagementPage = () => {
  return (
    <Switch>
      {/* PACKAGES */}
      <Route exact={true} path="/content-management/packages">
        <>
          <PageTitle>Pakketten</PageTitle>
          <PackagesPage />
        </>
      </Route>
      <Route exact={true} path="/content-management/packages/create">
        <>
          <PageTitle>Pakketten</PageTitle>
          <CreatePackage />
        </>
      </Route>
      <Route exact={true} path="/content-management/packages/:id/update">
        <>
          <PageTitle>Pakketten</PageTitle>
          <UpdatePackage />
        </>
      </Route>
      {/* PACKAGES */}

      {/* PACKAGE TYPES */}
      <Route exact={true} path="/content-management/package-types">
        <>
          <PageTitle>Pakkettype</PageTitle>
          <PackageTypesPage />
        </>
      </Route>
      <Route exact={true} path="/content-management/package-types/create">
        <>
          <PageTitle>Pakkettype</PageTitle>
          <CreatePackageType />
        </>
      </Route>
      <Route exact={true} path="/content-management/package-types/:id/update">
        <>
          <PageTitle>Pakkettype</PageTitle>
          <UpdatePackageType />
        </>
      </Route>
      {/* PACKAGES */}

      {/* PRODUCTS */}
      <Route exact={true} path="/content-management/products">
        <>
          <PageTitle>Producten</PageTitle>
          <ProductsPage />
        </>
      </Route>
      <Route exact={true} path="/content-management/products/create">
        <>
          <PageTitle>Producten</PageTitle>
          <CreateProduct />
        </>
      </Route>
      <Route exact={true} path="/content-management/products/:id/update">
        <>
          <PageTitle>Producten</PageTitle>
          <UpdateProduct />
        </>
      </Route>
      {/* PRODUCTS */}

      {/* CATEGORIES */}
      <Route exact={true} path="/content-management/categories">
        <>
          <PageTitle>Categorieën</PageTitle>
          <CategoriesPage />
        </>
      </Route>
      <Route exact={true} path="/content-management/categories/create">
        <>
          <PageTitle>Categorieën</PageTitle>
          <CreateCategory />
        </>
      </Route>
      <Route exact={true} path="/content-management/categories/:id/update">
        <>
          <PageTitle>Categorieën</PageTitle>
          <UpdateCategory />
        </>
      </Route>
      {/* CATEGORIES */}

      {/* CALL RATES */}
      <Route exact={true} path="/content-management/call-rates">
        <>
          <PageTitle>Beltarieven</PageTitle>
          <CallRatesPage />
        </>
      </Route>

      <Route exact={true} path="/content-management/call-rates/:id/update">
        <>
          <PageTitle>Beltarieven</PageTitle>
          <UpdateCallRate />
        </>
      </Route>

      {/* CALL RATES */}

      {/* FAQs */}
      <Route exact={true} path="/content-management/faqs">
        <>
          <PageTitle>FAQs</PageTitle>
          <FAQsPage />
        </>
      </Route>
      <Route exact={true} path="/content-management/faqs/create">
        <>
          <PageTitle>FAQs</PageTitle>
          <CreateFAQ />
        </>
      </Route>
      <Route exact={true} path="/content-management/faqs/:id/update">
        <>
          <PageTitle>FAQs</PageTitle>
          <UpdateFAQ />
        </>
      </Route>
      {/* FAQs */}

      <Redirect
        from="/content-management"
        exact={true}
        to="/content-management/categories"
      />
      <Redirect to="/content-management/categories" />
    </Switch>
  );
};
