import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_start/helpers';
import { IQuotation, QuotationStatusType } from '../models/Quotation';

type Props = {
  headerTitle?: string;
  bodyMessage?: string;
  item: IQuotation;
  handleSetStatus: (
    event: React.ChangeEvent<HTMLSelectElement>,
    item: IQuotation
  ) => void;
  handleUpdate: (item: any) => void;
};

export const QuotationsStatusModal: React.FC<Props> = ({
  headerTitle = 'Change Status',
  bodyMessage = `Change Quotation's status:`,
  item,
  handleUpdate,
  children,
  handleSetStatus,
}) => {
  const [currentStatus, setCurrentStatus] = useState(item.status);

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    item: IQuotation
  ) => {
    setCurrentStatus(event.target.value as QuotationStatusType);
    handleSetStatus(event, item);
  };

  useEffect(() => {
    setCurrentStatus(item.status);
  }, [item]);
  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id="custom_update_status_kt_modal_1"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{headerTitle}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            {children ? (
              children
            ) : (
              <>
                <p>{bodyMessage}</p>
                {/* <p className="block">{item}</p> */}
                {/* begin::Form row */}
                <div className="row mb-8">
                  <label className="col-lg-3 col-form-label">Status</label>
                  <div className="col-lg-9">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      data-control="select2"
                      data-placeholder="Select status..."
                      value={currentStatus}
                      onChange={(e) => handleChange(e, item)}
                    >
                      {/* <option value={QuotationStatusType.NEW}>NEW</option> */}
                      <option value={QuotationStatusType.OPEN}>OPEN</option>
                      <option value={QuotationStatusType.CLOSED}>CLOSED</option>
                      <option value={QuotationStatusType.PAUSED}>PAUSED</option>
                      <option value={QuotationStatusType.REMINDED}>
                        REMIND
                      </option>
                      <option value={QuotationStatusType.EXPIRED}>
                        EXPIRED
                      </option>
                    </select>
                  </div>
                </div>
                {/* end::Form row */}
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {/* <button
              onClick={() => handleUpdate(item)}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Confirm
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
