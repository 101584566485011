import React from 'react';
import { toAbsoluteUrl } from '../../../../../../../_start/helpers';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { AdvantageInfo, HomeAdvantage } from './HomeAdvantages';

interface Props {
  index: number;
  data: AdvantageInfo;
  elementData: HomeAdvantage;
  update: (fieldsToUpdate: Partial<AdvantageInfo>) => void;
}

export const HomeAdvantagesFigure: React.FC<Props> = ({
  data,
  update,
  elementData,
  index,
}) => {
  return (
    <>
      <AttachMediaModal
        modalId={`${elementData.name}-${elementData.id}-${index}-image`}
        updateCallback={(image, image_alt) => update({ image, image_alt })}
      />
      <div className="figure-outer">
        <div className="figure">
          {data.image ? (
            <div className="overlay">
              <div className="overlay-wrapper">
                <img
                  alt="Uploaded"
                  src={data.image}
                  style={{ width: '100%', height: '400px' }}
                />
              </div>
              <div
                className="overlay-layer bg-dark bg-opacity-10 flex-column"
                style={{ zIndex: 2 }}
              >
                <div>
                  <button
                    name="image"
                    className="btn btn-primary btn-shadow"
                    onClick={() =>
                      update({
                        image: '',
                        image_alt: '',
                      })
                    }
                  >
                    Remove
                  </button>
                  <label
                    className="btn btn-light-primary btn-shadow ms-2"
                    data-bs-toggle="modal"
                    data-bs-target={`#${elementData.name}-${elementData.id}-${index}-image`}
                  >
                    Change
                  </label>
                </div>

                <input
                  placeholder="Alt attribute"
                  className="w-50 mt-2 form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.image_alt ?? ''}
                  onChange={(event) =>
                    update({ image_alt: event.target.value })
                  }
                />
              </div>
            </div>
          ) : (
            <>
              <label
                data-bs-toggle="modal"
                data-bs-target={`#${elementData.name}-${elementData.id}-${index}-image`}
              >
                <img
                  src={toAbsoluteUrl('/assets/images/224x676.png')}
                  style={{ width: '100%', height: '400px' }}
                  alt="Placeholder"
                />
              </label>
            </>
          )}
        </div>
      </div>
    </>
  );
};
