import React from "react";
import { KTSVG } from "../../../../_start/helpers";

type ActivateNoticeModalProps = {
  headerTitle?: string;
  bodyMessage?: string;
  item: any;
  handleActivateNotice: (item: any) => void;
};

export const ActivateNoticeModal: React.FC<ActivateNoticeModalProps> = ({
  headerTitle = "Activate Notice",
  bodyMessage = "Are you sure to activate this item? This action will deactivate the current active notice.",
  item,
  handleActivateNotice,
  children,
}) => {
  return (
    <div className="modal fade" tabIndex={-1} id="custom_activate_kt_modal_1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{headerTitle}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            {children ? (
              children
            ) : (
              <>
                <p>{bodyMessage}</p>
                <p className="block">{item.name}</p>
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={() => handleActivateNotice(item)}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
