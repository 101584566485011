import React from "react";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import { FAQ2 } from "./FAQ2";

interface FAQContentProps {
  data: FAQ2;
  update: (fieldsToUpdate: Partial<FAQ2>) => void;
}

export const FAQ2Content: React.FC<FAQContentProps> = ({ data, update }) => {
  return (
    <div className="specializations-faq__content">
      <TextAreaEdit
        name="heading"
        value={data.heading}
        setValue={update}
        className="content-heading"
      />

      <TextAreaEdit
        name="description"
        value={data.description}
        setValue={update}
        className="content-description"
      />
    </div>
  );
};
