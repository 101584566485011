import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_start/helpers';
import { AttachMediaModal } from '../../../components/AttachMediaModal';
import { Feature, PluginExtraFeatures } from './PluginExtraFeatures';

type Props = {
  index: number;
  data: Feature;
  plugin: PluginExtraFeatures;
  update: (fieldsToUpdate: Partial<Feature>) => void;
};

export const PluginExtraFeaturesFigure: React.FC<Props> = ({
  index,
  data,
  update,
  plugin,
}) => {
  return (
    <>
      <AttachMediaModal
        modalId={`${plugin.name}-${plugin.id}-${index}`}
        updateCallback={(image, image_alt) => update({ image, image_alt })}
      />

      <div className="card__figure">
        {data.image ? (
          <div className="mt-5 overlay">
            <div className="overlay-wrapper">
              <img
                alt="dummy"
                src={data.image}
                style={{
                  width: '100%',
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column justify-items-center align-items-center">
              <div>
                <button
                  name="image"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      image: '',
                      image_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${plugin.name}-${plugin.id}-${index}`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-25 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_alt ?? ''}
                onChange={(event) => update({ image_alt: event.target.value })}
              />
            </div>
          </div>
        ) : (
          <>
            <label
              className="mt-5"
              data-bs-toggle="modal"
              data-bs-target={`#${plugin.name}-${plugin.id}-${index}`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/900x774.webp')}
                style={{
                  width: '100%',
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
                alt=""
              />
            </label>
          </>
        )}
      </div>
    </>
  );
};
