import React from "react";
import { ElementWrapper } from "../../../../components/ElementWrapper";
import { IElement } from "../../../../models/Element";

import { HomeFAQ } from "./HomeFAQ";
import { HomeFAQContent } from "./HomeFAQContent";
import { HomeFAQList } from "./HomeFAQList";

interface HomeFAQProps {
  index: number;
  data: HomeFAQ;
  updateData: (body: IElement, index: number) => void;
}

export const HomeFAQFC: React.FC<HomeFAQProps> = ({
  index,
  data,
  updateData,
}) => {
  const update = (fieldsToUpdate: Partial<HomeFAQ>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="specializations-faq body-my-8">
        <div className="specializations-grid">
          <div className="grid__inner">
            <HomeFAQContent data={data} update={update} />
            <HomeFAQList data={data} update={update} />
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
