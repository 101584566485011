import React from 'react';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomePackages } from './HomePackages';
import { HomePackagesHeader } from './HomePackagesHeader';
import { HomePackagesList } from './HomePackagesList';

interface Props {
  index: number;
  data: HomePackages;
  updateData: (element: IElement, index: number) => void;
}

export const HomePackagesFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<HomePackages>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-packages">
        <div className="grid">
          <div className="grid__inner">
            <HomePackagesHeader data={data} update={update} />
            <HomePackagesList data={data} update={update} />
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
