/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { failureToast, successToast } from '../../../support/utils';
import {
  defaultPackage,
  IPackage,
} from '../../content-management/models/Package';
import { getAllPackage } from '../../content-management/redux/PackageCRUD';
import { defaultSendQuote, ISendQuote } from '../models/SendQuote';
import { sendQuote } from '../redux/QuotationCRUD';

import Select, { MultiValue } from 'react-select';

import { IProduct } from '../../content-management/models/Product';
import { getAllProduct } from '../../content-management/redux/ProductCRUD';

export const SendQuote = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ISendQuote>(defaultSendQuote);

  const updateData = (fieldsToUpdate: Partial<ISendQuote>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  // removing unnecessary re-render during initialization
  // and calling the same api/s
  const [initialized, setInitialized] = useState(false);

  const [packagesMap, setPackagesMap] = useState<Map<number, IPackage>>(
    new Map()
  );

  const [freeProducts, setFreeProducts] = useState<MultiValue<IProduct>>([]);

  const [paidProducts, setPaidProducts] = useState<MultiValue<IProduct>>([]);

  const [productsMap, setProductsMap] = useState<Map<number, IProduct>>(
    new Map()
  );

  const dynamicFreeOptions = useMemo(() => {
    let tempProductsMap = new Map(productsMap);

    paidProducts.forEach((element) => {
      tempProductsMap.delete(element.id);
    });

    return [...tempProductsMap.values()];
  }, [paidProducts]);

  const dynamicPaidOptions = useMemo(() => {
    let tempProductsMap = new Map(productsMap);

    freeProducts.forEach((element) => {
      tempProductsMap.delete(element.id);
    });

    return [...tempProductsMap.values()];
  }, [freeProducts]);

  const selectedProductsMap: Map<number, IProduct> = useMemo(() => {
    let iterableFreeProducts: Array<[number, IProduct]> = freeProducts.map(
      (element) => [element.id, { ...element, quantity: 1, one_time_payment: '0', monthly_payment: '0' }]
    );

    let iterablePaidProducts: Array<[number, IProduct]> = paidProducts.map(
      (element) => [element.id, { ...element, quantity: 1 }]
    );

    let iterable = [...iterableFreeProducts, ...iterablePaidProducts];

    return new Map(iterable);
  }, [freeProducts, paidProducts]);

  const [payloadProductsMap, setPayloadProductsMap] = useState<
    Map<number, IProduct>
  >(new Map());

  useEffect(() => {
    setPayloadProductsMap(new Map(selectedProductsMap));

    return () => {};
  }, [selectedProductsMap]);

  const selectedPackage = useMemo(() => {
    return packagesMap.get(data.package_id) ?? defaultPackage;
  }, [data.package_id]);

  const totalOneTime = useMemo(() => {
    let voipAmount =
      data.voip_accounts * parseFloat(selectedPackage.price_one_time);

    let userAmount =
      data.voip_accounts * parseFloat(selectedPackage.price_one_time);

    let productAmount = [...payloadProductsMap.values()].reduce(
      (prev, currentValue) => {
        return prev + parseFloat(currentValue.one_time_payment);
      },
      0
    );

    let newPhoneAmount =
      parseFloat(selectedPackage.price_per_number_one_time) *
      data.new_phone_numbers;

    let existingPhoneAmount =
      parseFloat(selectedPackage.price_per_number_one_time) *
      data.existing_phone_numbers;

    return (
      voipAmount +
      userAmount +
      productAmount +
      newPhoneAmount +
      existingPhoneAmount
    );
  }, [data.voip_accounts, payloadProductsMap]);

  const totalMonthly = useMemo(() => {
    let voipAmount =
      data.voip_accounts * parseFloat(selectedPackage.price_per_month);

    let userAmount =
      data.user_accounts * parseFloat(selectedPackage.price_per_month);

    let productAmount = [...payloadProductsMap.values()].reduce(
      (prev, currentValue) => {
        return prev + parseFloat(currentValue.monthly_payment);
      },
      0
    );

    let newPhoneAmount =
      parseFloat(selectedPackage.price_per_number_per_month) *
      data.new_phone_numbers;

    let existingPhoneAmount =
      parseFloat(selectedPackage.price_per_number_per_month) *
      data.existing_phone_numbers;

    return (
      voipAmount +
      userAmount +
      productAmount +
      newPhoneAmount +
      existingPhoneAmount
    );
  }, [data.voip_accounts, payloadProductsMap]);

  const handleInitialization = async () => {
    setLoading(true);

    try {
      const [packages, products] = await Promise.all([
        getAllPackage(),
        getAllProduct(),
      ]);

      let productsIterable: Array<[number, IProduct]> = products.map(
        (element) => [element.id, element]
      );

      setProductsMap(new Map(productsIterable));

      let iterable: Array<[number, IPackage]> = packages.map((element) => [
        element.id,
        element,
      ]);

      setPackagesMap(new Map(iterable));

      const defaultPackage = packages[0];
      updateData({ package_id: defaultPackage.id });

      // set free products
      setFreeProducts(defaultPackage.free_products);

      // set paid products
      setPaidProducts(defaultPackage.products);

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialized) {
      handleInitialization();
      setInitialized(true);
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      await sendQuote({
        ...data,
        // @ts-ignore
        products: [...payloadProductsMap.values()].map((element) => element),
        // @ts-ignore
        free_products: freeProducts,
        paid_products: paidProducts,
      });

      successToast('Quote offer has been created.');
      setLoading(false);
      setData(defaultSendQuote);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Send Quote</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Package</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.package_id}
                onChange={(e) => {
                  let selectedPackage =
                    packagesMap.get(parseInt(e.target.value)) ?? defaultPackage;

                  updateData({
                    package_id: parseInt(e.target.value),
                    voip_accounts:
                      selectedPackage.package_type.account_type === 'voip'
                        ? 1
                        : 0,
                    user_accounts:
                      selectedPackage.package_type.account_type === 'voip'
                        ? 0
                        : 1,
                  });

                  // set free products
                  setFreeProducts(selectedPackage.free_products);

                  // set paid products
                  setPaidProducts(selectedPackage.products);
                }}
              >
                {[...packagesMap.values()].map((item, idx) => (
                  <option value={item.id} key={idx}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              {packagesMap.get(data.package_id)?.package_type.account_type ===
              'voip'
                ? 'Voip Accounts'
                : 'User Accounts'}
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                {packagesMap.get(data.package_id)?.package_type.account_type ===
                'voip' ? (
                  <input
                    placeholder="Enter voip accounts"
                    className="form-control form-control-lg form-control-solid"
                    type="number"
                    value={data.voip_accounts}
                    onChange={(e) =>
                      updateData({
                        voip_accounts: parseInt(e.target.value),
                      })
                    }
                    min="1"
                    step="1"
                    required
                  />
                ) : (
                  <input
                    placeholder="Enter user accounts"
                    className="form-control form-control-lg form-control-solid"
                    type="number"
                    value={data.user_accounts}
                    onChange={(e) =>
                      updateData({
                        user_accounts: parseInt(e.target.value),
                      })
                    }
                    min="1"
                    step="1"
                    required
                  />
                )}
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Free Products</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Free product/s`}
                value={freeProducts}
                onChange={setFreeProducts}
                getOptionValue={(model: IProduct) => model.id.toString()}
                getOptionLabel={(model: IProduct) => model.name}
                options={dynamicFreeOptions}
                isMulti
                isSearchable
                isClearable
                isDisabled={
                  packagesMap.get(data.package_id)?.is_modifiable === 0
                }
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    display: 'flex',
                    flexDirection: 'column',
                  }),
                  multiValue: (provided, state) => ({
                    ...provided,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }),
                }}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Paid Products</label>
            <div className="col-lg-9">
              <Select
                closeMenuOnSelect={false}
                placeholder={`Paid product/s`}
                value={paidProducts}
                onChange={setPaidProducts}
                getOptionValue={(model: IProduct) => model.id.toString()}
                getOptionLabel={(model: IProduct) => model.name}
                options={dynamicPaidOptions}
                isMulti
                isSearchable
                isClearable
                isDisabled={
                  packagesMap.get(data.package_id)?.is_modifiable === 0
                }
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    display: 'flex',
                    flexDirection: 'column',
                  }),
                  multiValue: (provided, state) => ({
                    ...provided,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }),
                }}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          {/* <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Product Quantity</label>
            <div className="col-lg-9">
              <div>
                {[...payloadProductsMap.values()].map((element, idx) => (
                  <div key={idx} className="row mb-2">
                    <span className="col-lg-9">{element.name}</span>
                    <div
                      className={`${
                        element.is_bulk_purchase ? '' : 'visually-hidden'
                      } col-lg-3`}
                    >
                      <input
                        placeholder="Enter quantity"
                        className="form-control form-control-sm form-control-solid"
                        type="number"
                        min="1"
                        step="1"
                        value={element.quantity}
                        onChange={(e) => {
                          setPayloadProductsMap((prev) => {
                            return new Map(prev).set(element.id, {
                              ...element,
                              quantity: e.target.value,
                            });
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              New Phone Numbers Amount
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter new phone numbers amount"
                  className="form-control form-control-lg form-control-solid"
                  type="number"
                  value={data.new_phone_numbers}
                  onChange={(e) =>
                    updateData({
                      new_phone_numbers: parseInt(e.target.value),
                    })
                  }
                  min={0}
                  step={1}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">
              Existing Phone Numbers Amount
            </label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter existing phone numbers amount"
                  className="form-control form-control-lg form-control-solid"
                  type="number"
                  value={data.existing_phone_numbers}
                  onChange={(e) =>
                    updateData({
                      existing_phone_numbers: parseInt(e.target.value),
                    })
                  }
                  min={0}
                  step={1}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8 p-3">
            <table className="col-lg-12">
              <thead>
                <tr className="fw-bolder fs-6 text-gray-800">
                  <th>Item</th>
                  <th>Eenmalig</th>
                  <th>Maandelijks</th>
                </tr>
              </thead>
              <tbody>
                {/* begin::Products */}
                {[...payloadProductsMap.values()].map((element, idx) => (
                  <tr key={idx}>
                    <td>{element.name}</td>
                    <td>{element.one_time_payment}</td>
                    <td>{element.monthly_payment}</td>
                  </tr>
                ))}
                {/* end::Products */}

                {/* begin::New Phone Number */}
                <tr>
                  <td>
                    Regionaal/085 nummer (per stuk) * {data.new_phone_numbers}
                  </td>
                  <td>
                    {parseFloat(selectedPackage.price_per_number_one_time) *
                      data.new_phone_numbers}
                  </td>
                  <td>
                    {parseFloat(selectedPackage.price_per_number_per_month) *
                      data.new_phone_numbers}
                  </td>
                </tr>
                {/* begin::New Phone Number */}

                {/* begin::Existing Phone Number */}
                <tr>
                  <td>
                    Regionaal/085 nummer (per stuk) (portering) *{' '}
                    {data.existing_phone_numbers}
                  </td>
                  <td>
                    {parseFloat(selectedPackage.price_per_number_one_time) *
                      data.existing_phone_numbers}
                  </td>
                  <td>
                    {parseFloat(selectedPackage.price_per_number_per_month) *
                      data.existing_phone_numbers}
                  </td>
                </tr>
                {/* begin::Existing Phone Number */}

                {selectedPackage.package_type.account_type === 'voip' ? (
                  <tr>
                    <td>VoIP Accounts * {data.voip_accounts}</td>
                    <td>
                      {parseFloat(selectedPackage.price_one_time) *
                        data.voip_accounts}
                    </td>
                    <td>
                      {parseFloat(selectedPackage.price_per_month) *
                        data.voip_accounts}
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>User Accounts * {data.user_accounts}</td>
                    <td>
                      {parseFloat(selectedPackage.price_one_time) *
                        data.user_accounts}
                    </td>
                    <td>
                      {parseFloat(selectedPackage.price_per_month) *
                        data.user_accounts}
                    </td>
                  </tr>
                )}

                {/* begin::Total */}
                <tr>
                  <td>Total</td>
                  <td>{totalOneTime}</td>
                  <td>{totalMonthly}</td>
                </tr>
                {/* begin::Total */}
              </tbody>
            </table>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Firstname</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter firstname"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.firstname}
                  onChange={(e) =>
                    updateData({
                      firstname: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Email</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter email"
                  className="form-control form-control-lg form-control-solid"
                  type="email"
                  value={data.email}
                  onChange={(e) => updateData({ email: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Phone</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter phone"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.phone_number}
                  onChange={(e) =>
                    updateData({
                      phone_number: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          <div className={`separator my-10`}></div>

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Company Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter company name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.company_name}
                  onChange={(e) =>
                    updateData({
                      company_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Company Email</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter company email address"
                  className="form-control form-control-lg form-control-solid"
                  type="email"
                  value={data.company_email}
                  onChange={(e) =>
                    updateData({
                      company_email: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Send</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/quotation-management/quotations`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
