import React from 'react';
import { KTSVG } from '../../../../../../../_start/helpers';
import { AttachSvgModal } from '../../../../components/AttachSvgModal';
import {
  HomeImageLeftDescriptionFloatingWindowIconsElement,
  IconAndTitle,
} from './HomeImageLeftDescriptionFloatingWindowIconsElement';

interface Props {
  idx: number;
  data: HomeImageLeftDescriptionFloatingWindowIconsElement;
  item: IconAndTitle;
  updateData: (to: Partial<IconAndTitle>, idx: number) => void;
}

export const HomeImageLeftDescriptionFloatingWindowIconsIcon: React.FC<
  Props
> = ({ idx, data, item, updateData }) => {
  return (
    <>
      <AttachSvgModal
        modalId={`home-image-with-left-description-floating-window-and-icons-${idx}-${data.id}`}
        updateCallback={(icon_url) => updateData({ icon_url }, idx)}
      />
      <div className="icon__outer">
        <div className="overlay">
          <div className="overlay-layer bg-dark bg-opacity-10">
            <label
              className="px-1 btn btn-sm btn-icon btn-color-primary btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target={`#home-image-with-left-description-floating-window-and-icons-${idx}-${data.id}`}
            >
              <KTSVG
                path="/media/icons/duotone/General/Edit.svg"
                className="svg-icon-1"
              />
            </label>
          </div>
          <div>
            {item.icon_url ? (
              <img className="icon" src={item.icon_url} alt="Uploaded" />
            ) : (
              <KTSVG svgClassName="icon" path={item.icon ?? ''} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
