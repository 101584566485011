export interface ICategory {
	id: number;
	name: string;
	description: string;
}

export const defaultCategory: ICategory = {
	id: 0,
	name: "",
	description: "",
};
