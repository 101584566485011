import React from 'react';
import { AttachMediaModal } from '../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { IElement } from '../../../models/Element';
import { ContactsImageLeftDescriptionFloatingWindowIconsContent } from './ContactsImageLeftDescriptionFloatingWindowIconsContent';
import { ContactsImageLeftDescriptionFloatingWindowIconsElement } from './ContactsImageLeftDescriptionFloatingWindowIconsElement';
import { ContactsImageLeftDescriptionFloatingWindowIconsFigure } from './ContactsImageLeftDescriptionFloatingWindowIconsFigure';

interface Props {
  index: number;
  data: ContactsImageLeftDescriptionFloatingWindowIconsElement;
  updateData: (element: IElement, index: number) => void;
}

export const ContactsImageLeftDescriptionFloatingWindowIconsFC: React.FC<
  Props
> = ({ index, data, updateData }) => {
  const update = (
    fieldsToUpdate: Partial<ContactsImageLeftDescriptionFloatingWindowIconsElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-image-floating-left-description-icons">
          <div className="showcase">
            <ContactsImageLeftDescriptionFloatingWindowIconsContent
              data={data}
              update={update}
            />
            <ContactsImageLeftDescriptionFloatingWindowIconsFigure
              data={data}
              update={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
