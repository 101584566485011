import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export interface Feature {
  id: number;
  heading: string;
  heading_type: ElementHeaderType;
  description: string;
  image: string;
  image_alt: string;
  button_primary_title: string;
  button_secondary_title: string;
}

export type PluginExtraFeatures = IElement & {
  heading: string;
  heading_type: ElementHeaderType;
  features: Array<Feature>;
};

export const defaultFeature: Feature = {
  id: 0,
  heading: 'Geschikt voor élk thema',
  heading_type: ElementHeaderType.H2,
  description:
    '<p>Via de Thema-API van de WordPress makelaar plugin kun je alle gewenste data ophalen bij objecten. Je hebt keuze in vrijwel alle gewenste thema’s, omdat je zelf kunt bepalen welke data je wilt weergeven.</p>',
  image: '',
  image_alt: '',
  button_primary_title: 'title',
  button_secondary_title: 'title',
};

export const defaultPluginExtraFeatures: PluginExtraFeatures = {
  id: 0,
  name: ElementType.EXTRA_FEATURES,
  heading: 'Alle features',
  heading_type: ElementHeaderType.H3,
  features: [defaultFeature, defaultFeature],
};
