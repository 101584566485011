import React from "react";
import { ElementWrapper } from "../../../../components/ElementWrapper";
import { IElement } from "../../../../models/Element";
import { HomeThreeIconsHeadingsAndDescriptionContent } from "./HomeThreeIconsHeadingsAndDescriptionContent";
import { HomeThreeIconsHeadingsAndDescriptionsElement } from "./HomeThreeIconsHeadingsAndDescriptionsElement";

interface Props {
  index: number;
  data: HomeThreeIconsHeadingsAndDescriptionsElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeThreeIconsHeadingsAndDescriptionsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (
    fieldsToUpdate: Partial<HomeThreeIconsHeadingsAndDescriptionsElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="home-three-icons-headings-and-descriptions">
        <HomeThreeIconsHeadingsAndDescriptionContent
          data={data}
          update={update}
        />
      </div>
    </ElementWrapper>
  );
};
