/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { failureToast, successToast } from "../../../../support/utils";
import { defaultTag, ITag } from "../../models/Tag";
import { createTag } from "../../redux/TagsCRUD";

export const CreateTag = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<ITag>(defaultTag);

	const updateData = (fieldsToUpdate: Partial<ITag>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		setData(updatedData);
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);
		try {
			await createTag(data);
			successToast("Tag has been created.");
			setLoading(false);
			setData(defaultTag);
			history.goBack();
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	return (
		<div className="card">
			<div className="card-header">
				<h3 className="card-title">Create Tag</h3>
			</div>
			{/* begin::Form */}
			<form onSubmit={handleSubmit} className="form d-flex flex-center">
				<div className="card-body mw-800px py-20">
					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Name</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter name"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.name}
									onChange={(e) =>
										updateData({ name: e.target.value })
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Color</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter name"
									className="form-control form-control-lg form-control-solid"
									type="color"
									value={data.color}
									onChange={(e) =>
										updateData({ color: e.target.value })
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Description
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<textarea
									rows={2}
									placeholder="Enter description"
									className="form-control form-control-lg form-control-solid"
									value={data.description}
									onChange={(e) =>
										updateData({
											description: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row">
						<label className="col-lg-3 col-form-label"></label>
						<div className="col-lg-9">
							<button
								type="submit"
								disabled={loading}
								className="btn btn-primary fw-bolder px-6 py-3 me-3"
							>
								{!loading && (
									<span className="indicator-label">
										Save
									</span>
								)}
								{loading && (
									<span
										className="indicator-progress"
										style={{ display: "block" }}
									>
										Please wait...{" "}
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
							<Link
								className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
								to={`/post-management/tags`}
							>
								Cancel
							</Link>
						</div>
					</div>
					{/* end::Form row */}
				</div>
			</form>
			{/* end::Form */}
		</div>
	);
};
