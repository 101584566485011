import { useEffect, useState } from 'react';
import { KTSVG } from '../../../../../../_start/helpers';
import {
  defaultPackageDialog,
  IPackage,
  IPackageDialog,
} from '../../../../content-management/models/Package';
import { ButtonTooltipOld } from '../../../components/ButtonTooltipOld';
import { InlineEdit } from '../../../components/InlineEdit';
import { TextAreaEdit } from '../../../components/TextAreaEdit';

type PackageDialogProps = {
  itemIndex: number;
  element: IPackage;
  updatePackage: (itemIndex: number, selected: IPackage) => void;
};

export const PackageDialog = ({ itemIndex, element, updatePackage }: PackageDialogProps) => {
  const [data, setData] = useState<IPackageDialog>(element.dialog ?? defaultPackageDialog);

  useEffect(() => {
    if (element.dialog) {
      setData(element.dialog);
    }
  }, [element.dialog]);

  const updateData = (fieldsToUpdate: Partial<IPackageDialog>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData as IPackageDialog);

    updatePackage(itemIndex, {...element, dialog: updatedData})
  };

  return (
    <>
      <button
        //   onClick={() => removePackage(idx)}
        className="position-absolute z-index-2 top-0 start-0 m-n4 opacity-75-hover btn btn-sm btn-icon btn-color-info btn-light-info"
        data-bs-toggle="modal"
        data-bs-target={`#package-item-modal-${element.id}`}
      >
        <KTSVG
          // path="/media/icons/duotone/Interface/Options-Square.svg"
          path="/media/icons/duotone/Layout/Layout-top-panel-6.svg"
          className="svg-icon-2"
        />
      </button>

      <div className="modal fade" tabIndex={-1} id={`package-item-modal-${element.id}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{`${element.name} - Dialog`}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotone/Navigation/Close.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              <TextAreaEdit
                className="w-100 heading h-medium s-extrabold t-fancy text-secondary1 m20b"
                name={`title`}
                value={data.title}
                setValue={updateData}
              />
              <TextAreaEdit
                className="h-100 w-100"
                name={`description`}
                value={data.description}
                setValue={updateData}
                minRows={3}
              />
              {/* <TextEditor
                content={data.description}
                updateContent={(description) => updateData({ description })}
              /> */}

              <div className="form-package__action m30t m30b">
                <ButtonTooltipOld
                  name={`button_url`}
                  className="heading-action"
                  value={data.button_url}
                  setValue={updateData}
                >
                  <a>
                    <InlineEdit
                      className="action-text"
                      name="button_title"
                      value={data.button_title}
                      setValue={updateData}
                      activeVerticalPadding={'py-0'}
                      dynamicWidth
                      style={{
                        maxWidth: '22rem',
                      }}
                    />
                  </a>
                </ButtonTooltipOld>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
