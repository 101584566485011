/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  getConfig,
  IThemeConfig,
  PageDataContainer,
  PageLink,
  useTheme,
} from "../../../_start/layout/core";
import { UserManagementPage } from "./UserManagementPage";
import { userManagementSubmenu } from "./UserManagementPageData";

const userManagementBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/",
    isActive: false,
  },
  {
    title: "Gebruikersbeheer",
    path: "/user-management",
    isActive: false,
  },
];

const defaultPageConfig = getConfig();
const userManagementPageConfig: Partial<IThemeConfig> = {
  sidebar: {
    ...defaultPageConfig.sidebar,
    display: false,
    content: "user",
    bgColor: "bg-info",
  },
};

export const UserManagementWrapper = () => {
  const { setTheme } = useTheme();
  // Refresh UI after config updates
  useEffect(() => {
    setTheme(userManagementPageConfig);
    return () => {
      setTheme(defaultPageConfig);
    };
  }, []);

  return (
    <>
      <PageDataContainer
        breadcrumbs={userManagementBreadCrumbs}
        submenu={userManagementSubmenu}
      />
      <UserManagementPage />
    </>
  );
};
