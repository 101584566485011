import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeImageTwoLeftDescriptionFloatingWindowContent } from './HomeImageTwoLeftDescriptionFloatingWindowContent';
import { HomeImageTwoLeftDescriptionFloatingWindowElement } from './HomeImageTwoLeftDescriptionFloatingWindowElement';
import { HomeImageTwoLeftDescriptionFloatingWindowFigure } from './HomeImageTwoLeftDescriptionFloatingWindowFigure';

interface Props {
  index: number;
  data: HomeImageTwoLeftDescriptionFloatingWindowElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeImageTwoLeftDescriptionFloatingWindowFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (
    fieldsToUpdate: Partial<HomeImageTwoLeftDescriptionFloatingWindowElement>
  ) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-image-two-left-description-float">
          <div className="showcase">
            <HomeImageTwoLeftDescriptionFloatingWindowContent
              data={data}
              update={update}
            />
            <HomeImageTwoLeftDescriptionFloatingWindowFigure
              data={data}
              update={update}
            />
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
