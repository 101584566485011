import React, { useRef } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { IEmployee } from '../../../../employee-management/models/Employee';
import { TeamEmployees } from './TeamEmployees';

interface Props {
  teamEmployees: TeamEmployees;
  employee: IEmployee;
  index: number;
  removeEmployee: (index: number) => void;
}

export const TeamEmployeesItem: React.FC<Props> = ({
  teamEmployees,
  employee,
  index,
  removeEmployee,
}) => {
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const handleRemove = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    removeEmployee(index);
  };

  return (
    <div className="item">
      {/* begin::Delete Item */}
      <span
        ref={removeItemRef}
        onClick={handleRemove}
        className="position-absolute z-index-2 top-0 end-0 me-2 mt-2 opacity-75-hover"
      >
        <KTSVG
          path="/media/icons/duotone/Interface/Minus-Square.svg"
          className="svg-icon-danger svg-icon-1"
        />
      </span>

      {/* end::Delete Item */}

      {/* begin::Employee Image */}
      {employee.gif ? (
        <img
          className="item-figure"
          src={employee.gif}
          alt=""
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
          }}
        />
      ) : (
        <img
          className="item-figure"
          src={toAbsoluteUrl('/assets/images/400x500.png')}
          alt=""
          style={{
            width: '100%',
            height: '400px',
            objectFit: 'cover',
          }}
        />
      )}
      {/* end::Employee Image */}

      <div className="item-content">
        <span className="item-content__name">{employee.name}</span>
      </div>
    </div>
  );
};
