import { call, put, takeLatest } from 'redux-saga/effects';
import {
  defaultPaginatedValue,
  failureToast,
  PaginatedValue,
  successToast,
} from '../../../support/utils';
import {
  defaultManageRedirectLink,
  defaultRedirectLink,
  IManageRedirectLink,
  IRedirectLink,
} from '../models/RedirectLink';
import {
  createRedirectLink,
  deleteRedirectLink,
  listRedirectLink,
  updateRedirectLink,
  viewRedirectLink,
} from './RedirectLinkCRUD';
import { RedirectLinkEvents } from './RedirectLinkEvents';

import * as redirectLinkActions from './RedirectLinkActions';

interface IRedirectLinkManagementState {
  loading: boolean;
  createdRedirectLink: IRedirectLink;
  manageRedirectLink: IManageRedirectLink;
  viewedRedirectLink: IRedirectLink;
  updatedRedirectLink: IRedirectLink;
  deletedRedirectLink: IRedirectLink;
  paginatedRedirectLinks: PaginatedValue<IRedirectLink>;
}

const defaultRedirectLinkManagementState: IRedirectLinkManagementState = {
  loading: false,
  createdRedirectLink: defaultRedirectLink,
  manageRedirectLink: defaultManageRedirectLink,
  viewedRedirectLink: defaultRedirectLink,
  updatedRedirectLink: defaultRedirectLink,
  deletedRedirectLink: defaultRedirectLink,
  paginatedRedirectLinks: defaultPaginatedValue,
};

export const reducer = (
  state: IRedirectLinkManagementState = defaultRedirectLinkManagementState,
  action: redirectLinkActions.RedirectLinkActions
): IRedirectLinkManagementState => {
  switch (action.type) {
    // CREATE
    case RedirectLinkEvents.CREATE_REDIRECT_LINK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RedirectLinkEvents.CREATE_REDIRECT_LINK_LOADED:
      return {
        ...state,
        loading: false,
        createdRedirectLink: action.payload,
      };

    case RedirectLinkEvents.CREATE_REDIRECT_LINK_FAILED:
      return {
        ...state,
        loading: false,
      };

    // MANAGE
    case RedirectLinkEvents.MANAGE_REDIRECT_LINK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RedirectLinkEvents.MANAGE_REDIRECT_LINK_LOADED:
      return {
        ...state,
        loading: false,
        manageRedirectLink: action.payload,
      };

    case RedirectLinkEvents.MANAGE_REDIRECT_LINK_FAILED:
      return {
        ...state,
        loading: false,
      };

    // VIEW
    case RedirectLinkEvents.VIEW_REDIRECT_LINK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RedirectLinkEvents.VIEW_REDIRECT_LINK_LOADED:
      return {
        ...state,
        loading: false,
        viewedRedirectLink: action.payload,
      };

    case RedirectLinkEvents.VIEW_REDIRECT_LINK_FAILED:
      return {
        ...state,
        loading: false,
      };

    // UPDATE
    case RedirectLinkEvents.UPDATE_REDIRECT_LINK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RedirectLinkEvents.UPDATE_REDIRECT_LINK_LOADED:
      return {
        ...state,
        loading: false,
        updatedRedirectLink: action.payload,
      };

    case RedirectLinkEvents.UPDATE_REDIRECT_LINK_FAILED:
      return {
        ...state,
        loading: false,
      };

    // DELETE
    case RedirectLinkEvents.DELETE_REDIRECT_LINK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RedirectLinkEvents.DELETE_REDIRECT_LINK_LOADED:
      return {
        ...state,
        loading: false,
        deletedRedirectLink: action.payload,
      };

    case RedirectLinkEvents.DELETE_REDIRECT_LINK_FAILED:
      return {
        ...state,
        loading: false,
      };

    // LIST
    case RedirectLinkEvents.LIST_REDIRECT_LINK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RedirectLinkEvents.LIST_REDIRECT_LINK_LOADED:
      return {
        ...state,
        loading: false,
        paginatedRedirectLinks: action.payload,
      };

    case RedirectLinkEvents.LIST_REDIRECT_LINK_FAILED:
      return {
        ...state,
        loading: false,
      };

    case RedirectLinkEvents.RESET_CREATE_REDIRECT_LINK:
      return {
        ...state,
        createdRedirectLink: defaultRedirectLink,
      };

    // SEARCH
    case RedirectLinkEvents.SEARCH_REDIRECT_LINK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case RedirectLinkEvents.SEARCH_REDIRECT_LINK_LOADED:
      return {
        ...state,
        loading: false,
        paginatedRedirectLinks: action.payload,
      };

    case RedirectLinkEvents.SEARCH_REDIRECT_LINK_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export function* saga() {
  yield takeLatest(
    RedirectLinkEvents.CREATE_REDIRECT_LINK_REQUESTED,
    function* createRedirectLinkRequest(
      action: ReturnType<typeof redirectLinkActions.createRedirectLinkRequest>
    ) {
      try {
        const redirect_link: IRedirectLink = yield call(
          createRedirectLink,
          action.redirect_link
        );

        yield put(redirectLinkActions.createRedirectLinkLoad(redirect_link));

        yield call(successToast, 'Link has been created.');
      } catch (error) {
        yield put(redirectLinkActions.createRedirectLinkFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    RedirectLinkEvents.VIEW_REDIRECT_LINK_REQUESTED,
    function* viewRedirectLinkRequest(
      action: ReturnType<typeof redirectLinkActions.viewRedirectLinkRequest>
    ) {
      try {
        const redirect_link: IRedirectLink = yield call(
          viewRedirectLink,
          action.redirect_link_id
        );

        yield put(redirectLinkActions.viewRedirectLinkLoad(redirect_link));
      } catch (error) {
        yield put(redirectLinkActions.viewRedirectLinkFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    RedirectLinkEvents.UPDATE_REDIRECT_LINK_REQUESTED,
    function* updateRedirectLinkRequest(
      action: ReturnType<typeof redirectLinkActions.updateRedirectLinkRequest>
    ) {
      try {
        const redirect_link: IRedirectLink = yield call(
          updateRedirectLink,
          action.redirect_link
        );

        yield put(redirectLinkActions.updateRedirectLinkLoad(redirect_link));
        yield call(successToast, 'Link has been updated.');
      } catch (error) {
        yield put(redirectLinkActions.updateRedirectLinkFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    RedirectLinkEvents.DELETE_REDIRECT_LINK_REQUESTED,
    function* deleteRedirectLinkRequest(
      action: ReturnType<typeof redirectLinkActions.deleteRedirectLinkRequest>
    ) {
      try {
        const redirect_link: IRedirectLink = yield call(
          deleteRedirectLink,
          action.redirect_link
        );

        yield put(redirectLinkActions.deleteRedirectLinkLoad(redirect_link));
        yield call(successToast, 'Link has been deleted.');

        yield put(redirectLinkActions.listRedirectLinkRequest());
      } catch (error) {
        yield put(redirectLinkActions.deleteRedirectLinkFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    RedirectLinkEvents.LIST_REDIRECT_LINK_REQUESTED,
    function* listRedirectLinkRequest() {
      try {
        const paginatedRedirectLinks: PaginatedValue<IRedirectLink> =
          yield call(listRedirectLink);

        yield put(
          redirectLinkActions.listRedirectLinkLoad(paginatedRedirectLinks)
        );
      } catch (error) {
        yield put(redirectLinkActions.listRedirectLinkFailed());
        yield call(failureToast, error);
      }
    }
  );

  yield takeLatest(
    RedirectLinkEvents.SEARCH_REDIRECT_LINK_REQUESTED,
    function* searchRedirectLinkRequest(
      action: ReturnType<typeof redirectLinkActions.searchRedirectLinkRequest>
    ) {
      try {
        const paginatedRedirectLinks: PaginatedValue<IRedirectLink> =
          yield call(listRedirectLink, action.search);

        yield put(
          redirectLinkActions.searchRedirectLinkLoad(paginatedRedirectLinks)
        );
      } catch (error) {
        yield put(redirectLinkActions.searchRedirectLinkFailed());
        yield call(failureToast, error);
      }
    }
  );
}
