/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextEditor } from '../../../../../components/TextEditor';
import { ButtonTooltipOld } from '../../../components/ButtonTooltipOld';
import { InlineEdit } from '../../../components/InlineEdit';
import {
  IHighlight,
  ProjectHighlights,
  ProjectHighlightsItemTypes,
} from './ProjectHighlights';

type HighlightProps = {
  index: number; // index of the list item which is inside a the body component
  highlight: IHighlight;
  projectHighlight: ProjectHighlights;
  updateProjectHighlight: (idx: number, highlight: IHighlight) => void;
  removeListItem: (itemIndex: number) => void;
  moveListItem: (dragIndex: number, hoverIndex: number) => void;
};

export const Highlight = ({
  index,
  highlight,
  projectHighlight,
  updateProjectHighlight,
  removeListItem,
  moveListItem,
}: HighlightProps) => {
  const [data, setData] = useState(highlight);

  const update = (fieldsToUpdate: Partial<IHighlight>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateProjectHighlight(index, updatedData);
  };

  useEffect(() => {
    setData(highlight);
  }, [highlight]);

  const ref = useRef<HTMLLIElement>(null);
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.remove('d-none');
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.add('d-none');
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: `${ProjectHighlightsItemTypes.HIGHLIGHT}-${projectHighlight.id}`,
    item: () => ({ id: data.id, index: index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver, canDrop, handlerId }, drop] = useDrop({
    accept: `${ProjectHighlightsItemTypes.HIGHLIGHT}-${projectHighlight.id}`,
    drop: (item: { id: number; index: number }) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveListItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  drop(drag(ref));

  const opacity = isDragging ? 0.3 : 1;

  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity }}
      className={`list-item ${canDrop ? 'can-drop-highlight' : 'bg-light'} ${
        isOver ? 'is-over-highlight' : ''
      }`}
    >
      {/* begin::Delete Item */}
      {projectHighlight.highlights.length > 1 && (
        <span
          ref={removeItemRef}
          onClick={() => removeListItem(index)}
          className="position-absolute top-0 end-0 d-none mt-n3 me-n3 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
      )}
      {/* end::Delete Item */}

      {/* begin::Labels */}
      <div className="item-labels">
        <ButtonTooltipOld
          name={`label_start`}
          value={data.label_start}
          setValue={update}
        >
          <a
            className="labels-item back-purple1"
            style={{
              color: 'white',
            }}
          >
            <span className="labels-item__title">{data.label_start}</span>
          </a>
        </ButtonTooltipOld>

        <ButtonTooltipOld
          name={`label_end`}
          value={data.label_end}
          setValue={update}
        >
          <a
            className="labels-item back-pink1"
            style={{
              color: 'white',
            }}
          >
            <span className="labels-item__title">{data.label_end}</span>
          </a>
        </ButtonTooltipOld>
      </div>
      {/* end::Labels */}

      {/* begin::Highlight - title */}
      <InlineEdit
        name="title"
        className="item-title"
        value={data.title}
        setValue={update}
        activeVerticalPadding="py-0"
      />
      {/* end::Highlight - title */}

      {/* begin::Highlight - description */}
      <div className="item-description">
        <TextEditor
          content={data.description}
          updateContent={(description) => update({ description })}
        />
      </div>
      {/* end::Highlight - description */}
    </li>
  );
};
