import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { IProduct } from '../../content-management/models/Product';
import { IMember } from '../../user-management/models/Member';
import { IQuotation, QuotationStatusType } from '../models/Quotation';
import { IQuotationActivityLog } from '../models/QuotationActivityLog';
import { ISendQuote } from '../models/SendQuote';

export const BASE_QUOTATION_URL = `${API_URL}/v1/quotation/management/quotations`;
export const GET_ALL_QUOTATION_URL = `${API_URL}/v1/quotation/management/quotations/get-all`;
export const SEND_QUOTATION_URL = `${API_URL}/v1/management/quotations/send-quote`;

export const listQuotation = async (search = '', direction = 'asc') => {
  const URL = `${BASE_QUOTATION_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IQuotation> }>(URL);

  return value;
};

export const sendQuote = async (payload: ISendQuote) => {
  const {
    data: { value },
  } = await axios.post<{ value: ISendQuote }>(SEND_QUOTATION_URL, payload);

  return value;
};

export type EditQuotationInitializationResponseValue = {
  quotation: IQuotation;
  allProducts: Array<IProduct>;
  advisors: Array<IMember>;
};

export const initializeEditQuotation = async (quotation: string) => {
  const response = await axios.get<{
    value: EditQuotationInitializationResponseValue;
  }>(`${API_URL}/v1/management/initialization/edit-quotation/${quotation}`);

  const { value } = response.data;

  return value;
};

export type QuotationAdvisorPayload = {
  user_id: number | null;
  quotation_id: number;
};

export const setQuotationAdvisor = async (payload: QuotationAdvisorPayload) => {
  const response = await axios.post<{ value: IQuotation }>(
    `${API_URL}/v1/management/quotations/assign-to`,
    payload
  );

  return response.data.value;
};

type QuotationStatusPayload = {
  quotation_id: number;
  status: QuotationStatusType;
};

export const setQuotationStatus = async (payload: QuotationStatusPayload) => {
  let response: any = null;

  if (payload.status === QuotationStatusType.OPEN) {
    response = await axios.post<{ value: IQuotation }>(
      `${API_URL}/v1/management/quotations/open-quote/${payload.quotation_id}`,
      payload
    );
  }

  if (payload.status === QuotationStatusType.CLOSED) {
    response = await axios.post<{ value: IQuotation }>(
      `${API_URL}/v1/management/quotations/close-quote/${payload.quotation_id}`,
      payload
    );
  }

  if (payload.status === QuotationStatusType.PAUSED) {
    response = await axios.post<{ value: IQuotation }>(
      `${API_URL}/v1/management/quotations/pause-quote/${payload.quotation_id}`,
      payload
    );
  }

  if (payload.status === QuotationStatusType.REMINDED) {
    response = await axios.post<{ value: IQuotation }>(
      `${API_URL}/v1/management/quotations/remind-quote/${payload.quotation_id}`,
      payload
    );
  }

  if (payload.status === QuotationStatusType.EXPIRED) {
    response = await axios.post<{ value: IQuotation }>(
      `${API_URL}/v1/management/quotations/expired-quote/${payload.quotation_id}`,
      payload
    );
  }

  const { value } = response.data;

  return value;
};

//not in use
export const viewQuotation = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IQuotation }>(
    `${API_URL}/v1/management/initialization/view-quotation/${id}`
  );

  return value;
};

export const viewQuote = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IQuotation }>(
    `${API_URL}/v1/management/quotations/${id}/view-quote`
  );

  return value;
};

export const updateQuotation = async (quotation: IQuotation) => {
  const payload = {
    quote: quotation.products,
  };
  const {
    data: { value },
  } = await axios.post<{ value: IQuotation }>(
    `${API_URL}/v1/management/quotations/edit-quote/${quotation.id}`,
    payload
  );

  return value;
};

export const deleteQuotation = async (payload: IQuotation) => {
  const {
    data: { value },
  } = await axios.post<{ value: IQuotation }>(
    `${BASE_QUOTATION_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllQuotation = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<IQuotation> }>(GET_ALL_QUOTATION_URL);

  return value;
};

export const getQuotationActivityLog = async (
  quotation_id: number | string
) => {
  const response = await axios.get<{ value: Array<IQuotationActivityLog> }>(
    `${API_URL}/v1/management/activity-log/${quotation_id}`
  );

  return response.data.value;
};

export type CreateVoipGridAccountPayload = {
  quotation_id: number;
  name: string;
  kvk_number: string;
  anonymize_after: string;
  phone_number: string;
  proposition: string;
  blocked_call_permissions: Array<string>;
  country: string;
  language: string;
  address: {
    country: string;
    street: string;
    number: string;
    complement: string;
    zipcode: string;
    city: string;
  };
  user: {
    first_name: string;
    preposition: string;
    last_name: string;
    email_address: string;
  };
  billing: {
    iban: string;
    account_holder: string;
    vat_number: string;
    email_address: string;
    pay_available: boolean;
    invoice_characteristic: string;
  };
};

export const createVoipGridAccount = async (
  payload: CreateVoipGridAccountPayload
) => {
  const response = await axios.post(
    `${API_URL}/v1/management/voipgrid/create-account`,
    payload
  );

  const { value } = response.data;

  return value;
};
