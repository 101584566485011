import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { INotice } from "../models/Notice";

export const BASE_NOTICE_URL = `${API_URL}/v1/notices`;
export const GET_ALL_NOTICE_URL = `${API_URL}/v1/notices/get-all`;
export const CREATE_NOTICE_URL = `${API_URL}/v1/notices/create`;

export const listNotice = async (search = "", direction = "desc") => {
  const URL = `${BASE_NOTICE_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<INotice> }>(URL);

  return value;
};

export const createNotice = async (payload: INotice) => {
  const {
    data: { value },
  } = await axios.post<{ value: INotice }>(CREATE_NOTICE_URL, payload);

  return value;
};

export const getNotice = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: INotice }>(`${BASE_NOTICE_URL}/${id}/get`);

  return value;
};

export const updateNotice = async (payload: INotice) => {
  const {
    data: { value },
  } = await axios.post<{ value: INotice }>(
    `${BASE_NOTICE_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteNotice = async (payload: INotice) => {
  const {
    data: { value },
  } = await axios.post<{ value: INotice }>(
    `${BASE_NOTICE_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

export const activateNotice = async (payload: INotice) => {
  const {
    data: { value },
  } = await axios.post<{ value: INotice }>(
    `${BASE_NOTICE_URL}/${payload.id}/activate`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllNotice = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<INotice> }>(GET_ALL_NOTICE_URL);

  return value;
};
