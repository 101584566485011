import React from 'react';
import { AttachMediaModal } from '../../../../components/AttachMediaModal';
import { ElementWrapper } from '../../../../components/ElementWrapper';
import { IElement } from '../../../../models/Element';
import { HomeHeroWideThreeIconsContent } from './HomeHeroWideButtonsContent';
import { HomeHeroWideButtonsElement } from './HomeHeroWideButtonsElement';
import { HomeHeroWideThreeIconsFigure } from './HomeHeroWideButtonsFigure';
import { HomeHeroWideThreeIconsItems } from './HomeHeroWideButtonsItems';

interface Props {
  index: number;
  data: HomeHeroWideButtonsElement;
  updateData: (element: IElement, index: number) => void;
}

export const HomeHeroWideButtonsFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<HomeHeroWideButtonsElement>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${data.name}-${data.id}`}
        updateCallback={(image, image_alt) =>
          update({ image_preview: image, image_preview_alt: image_alt })
        }
      />

      <ElementWrapper elementIndex={index}>
        <div className="home-hero-wide-buttons">
          <div className="grid">
            <div className="grid__inner">
              <div className="hero">
                <div className="hero__container">
                  <HomeHeroWideThreeIconsContent data={data} update={update} />
                  <HomeHeroWideThreeIconsItems data={data} update={update} />
                  <HomeHeroWideThreeIconsFigure data={data} update={update} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ElementWrapper>
    </>
  );
};
