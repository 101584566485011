export interface IRedirectLink {
  id: number;
  url: string;
  target: string;
}

export const defaultRedirectLink: IRedirectLink = {
  id: 0,
  url: '',
  target: '',
};

export interface IManageRedirectLink {
  url: string;
  target: string;
}

export const defaultManageRedirectLink: IManageRedirectLink = {
  url: '',
  target: '',
};
