export interface IInquiry {
	id: number;
	email: string;
	firstname: string;
	topic: string;
	remarks: string;
}

export const defaultInquiry: IInquiry = {
	id: 0,
	email: "",
	firstname: "",
	topic: "",
	remarks: "",
};
