import { IPackage } from '../../content-management/models/Package';
import { IProduct } from '../../content-management/models/Product';
import { IElement } from '../../edit-content/models/Element';
import {
  defaultEmployee,
  IEmployee,
} from '../../employee-management/models/Employee';
import { defaultCategory, ICategory } from './Category';
import { defaultTag, ITag } from './Tag';

export enum PostStatusType {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FUTURE = 'future',
}

export enum SEOTitleType {
  SITE_TITLE = 'site-title',
  TITLE = 'title',
  TITLE_SEPARATOR = 'title-separator',
}

export enum RobotType {
  INDEX = 'index',
  NO_INDEX = 'noindex',
  NONE = 'none',
  NO_IMAGE_INDEX = 'noimageindex',
  FOLLOW = 'follow',
  NO_FOLLOW = 'nofollow',
  NO_ARCHIVE_NO_CACHE = 'noarchive/nocache',
  NO_SNIPPET = 'nosnippet',
  NO_TRANSLATE = 'notranslate',
  UNAVAILABLE_AFTER = 'unavailable_after',
  NOODP = 'noodp',
  NOYDIR = 'noydir',
}

export enum SchemaPageType {
  WEB_PAGE = 'web page',
  ITEM_PAGE = 'item page',
  ABOUT_PAGE = 'about page',
  FAQ_PAGE = 'faq page',
  QA_PAGE = 'qa page',
  PROFILE_PAGE = 'profile page',
  CONTACT_PAGE = 'contact page',
  MEDICAL_WEB_PAGE = 'medical web page',
  COLLECTION_PAGE = 'collection page',
  CHECKOUT_PAGE = 'checkout page',
  REAL_ESTATE_LISTING = 'real estate listing',
  SEARCH_RESULT_PAGE = 'search results page',
}

export enum SchemaArticleType {
  ARTICLE = 'article',
  BLOG_POST = 'blog post',
  SOCIAL_MEDIA_POSTING = 'social media posting',
  NEWS_ARTICLE = 'news article',
  ADVISER_CONTENT_ARTICLE = 'advertiser content article',
  SATIRICAL_ARTICLE = 'satirical article',
  SCHOLARLY_ARTICLE = 'scholarly article',
  TECH_ARTICLE = 'tech article',
  REPORT = 'report',
  NONE = 'none',
}

export interface IPost {
  id: number;
  title: string;
  slug: string;
  publish_date: string | Date;
  status: PostStatusType;
  description: string;
  synopsis: string;
  image_preview: string;
  image_preview_alt_text: string;
  post_category: ICategory;
  category_id: number;
  tags: Array<ITag>;
  featured_posts: Array<IPost>;
  content: Array<IElement>;
  author_id: number | null;
  author: IEmployee;

  // SEO stuff
  seo_title: Array<SEOTitleType>;
  robots: Array<RobotType>;

  schema_page_type: SchemaPageType;
  schema_article_type: SchemaArticleType;

  facebook_image: string;
  facebook_title: string;
  facebook_description: string;

  twitter_image: string;
  twitter_title: string;
  twitter_description: string;

  featured_packages: Array<IPackage>;
  featured_products: Array<IProduct>;
}

export const defaultPost: IPost = {
  id: 0,
  title: '',
  slug: '',
  publish_date: new Date(),
  status: PostStatusType.DRAFT,
  description: '',
  synopsis: '',
  image_preview: '',
  image_preview_alt_text: '',
  post_category: defaultCategory,
  category_id: 0,
  tags: [],
  featured_posts: [],
  content: [],
  author_id: 0,
  author: { ...defaultEmployee, name: 'John Doe' },

  seo_title: [],
  robots: [],

  schema_page_type: SchemaPageType.WEB_PAGE,
  schema_article_type: SchemaArticleType.ARTICLE,

  facebook_image: '',
  facebook_title: '',
  facebook_description: '',

  twitter_image: '',
  twitter_title: '',
  twitter_description: '',

  featured_packages: [],
  featured_products: [],
};

export const defaultPostPlaceholder: IPost = {
  ...defaultPost,
  title: 'Placeholder',
  tags: [
    {
      ...defaultTag,
      name: 'Tag',
    },
  ],
};

export enum PostQueryFilterValue {
  ALL = '',
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FUTURE = 'future',
}

export type PostQueryFilters = {
  status: string;
};

const defaultPostQueryFilters = (): PostQueryFilters => ({
  status: '',
});

export const getPostQueryFilters = (value: PostQueryFilterValue) => {
  switch (value) {
    case PostQueryFilterValue.DRAFT:
      return {
        ...defaultPostQueryFilters(),
        status: PostQueryFilterValue.DRAFT,
      };

    case PostQueryFilterValue.PUBLISHED:
      return {
        ...defaultPostQueryFilters(),
        status: PostQueryFilterValue.PUBLISHED,
      };

    case PostQueryFilterValue.FUTURE:
      return {
        ...defaultPostQueryFilters(),
        status: PostQueryFilterValue.FUTURE,
      };

    default:
      return defaultPostQueryFilters();
  }
};
