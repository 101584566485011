import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IRole } from "../../role-and-permission/pages/roles/Role";
import { IMember } from "../models/Member";

export const LIST_USERS_URL = `${API_URL}/v1/user-management/users`;
export const GET_ALL_ROLES_URL = `${API_URL}/v1/role-and-permission/roles`;
export const CREATE_MEMBER_URL = `${API_URL}/v1/user-management/users`;

export const getMembers = async (search = "") => {
  const response = await axios.get<{ value: PaginatedValue<IMember> }>(
    `${API_URL}/v1/user-management/users?search=${search}`
  );

  return response.data.value;
};

export const getAllRoles = () => {
  return axios.get<{ data: Array<IRole> }>(GET_ALL_ROLES_URL);
};

export const createMember = (payload: IMember) => {
  return axios.post(CREATE_MEMBER_URL, payload);
};

export const viewMember = (member_id: number | string) => {
  return axios.get<{ value: IMember }>(
    `${API_URL}/v1/user-management/users/${member_id}/view`
  );
};

export const updateMember = (payload: IMember) => {
  return axios.post<{ value: IMember }>(
    `${API_URL}/v1/user-management/users/${payload.id}/update`,
    payload
  );
};

export const deleteMember = (payload: IMember) => {
  return axios.post<{ value: IMember }>(
    `${API_URL}/v1/user-management/users/${payload.id}/delete`
  );
};
