import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { failureToast } from '../../../support/utils';
import { viewQuote } from '../redux/QuotationCRUD';
import { IQuotation } from '../models/Quotation';
import { KTSVG } from '../../../../_start/helpers';

export const ViewQuote = () => {
  const { id } = useParams<{ id: string }>();
  const [, setLoading] = useState(false);

  const [data, setData] = useState<IQuotation>();

  const handleQuote = async (id: string) => {
    setLoading(true);

    try {
      const result = await viewQuote(id);
      console.log(result);
      setData(result);

      setLoading(false);

      return result;
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  function additionalItems() {
    if (data?.invoice.additional_items !== null) {
      return (
        <>
          {data?.invoice.additional_items.users !== undefined && (
            <tr className="content">
              <td>{data?.invoice.additional_items.users.name}</td>
              <td>€ {data?.invoice.additional_items.users.one_time_payment}</td>
              <td>€ {data?.invoice.additional_items.users.monthly_payment}</td>
            </tr>
          )}
          {data?.invoice.additional_items.voip !== undefined && (
            <tr className="content">
              <td>{data?.invoice.additional_items.voip.name}</td>
              <td>€ {data?.invoice.additional_items.voip.one_time_payment}</td>
              <td>€ {data?.invoice.additional_items.voip.monthly_payment}</td>
            </tr>
          )}
          {data?.invoice.additional_items.phone !== undefined && (
            <tr className="content">
              <td>{data?.invoice.additional_items.phone.name}</td>
              <td>€ {data?.invoice.additional_items.phone.one_time_payment}</td>
              <td>€ {data?.invoice.additional_items.phone.monthly_payment}</td>
            </tr>
          )}
          {data?.invoice.additional_items.phone_portering !== undefined && (
            <tr className="content">
              <td>{data?.invoice.additional_items.phone_portering.name}</td>
              <td>
                €{' '}
                {
                  data?.invoice.additional_items.phone_portering
                    .one_time_payment
                }
              </td>
              <td>
                €{' '}
                {data?.invoice.additional_items.phone_portering.monthly_payment}
              </td>
            </tr>
          )}
        </>
      );
    }
  }

  useEffect(() => {
    if (id) {
      handleQuote(id);
    }
  }, [id]);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">View Quote</h3>
        </div>
        <div className="form d-flex flex-center">
          <div className="card-body mw-800px py-20">
            <div className="d-flex flex-row p-2  justify-content-between">
              {/* <div className="d-flex flex-row gap-2">
                <KTSVG
                  path="/media/logos/verbonden-compact.svg"
                  className="svg-icon-1 svg-icon-primary"
                />
                <span className="font-weight-bold">
                  {`Je Offerte: `}
                  <small className="badge bg-primary">{data?.invoice_id}</small>
                </span>
              </div> */}
              <div className="d-flex flex-column">
                <div className="d-flex flex-row p-2">
                  <KTSVG
                    path="/media/logos/verbonden-compact.svg"
                    className="svg-icon-1 svg-icon-primary"
                  />
                  <span className="font-weight-bold">
                    {`Je Offerte: `}
                    <small className="badge bg-primary">
                      {data?.invoice_id}
                    </small>
                  </span>
                </div>

                <span className="font-weight-bold">Date: {data?.date}</span>

                <span className="font-weight-bold">
                  Adviseur: {data?.user?.name}
                </span>
              </div>
              <div className="d-flex flex-column">
                <span className="font-weight-bold">
                  Automatic Incasso :{' '}
                  {data?.invoice.payment_type}
                </span>
                <span className="font-weight-bold">
                  Pakket Geselecteerd : {data?.package.name}
                </span>
                <span className="font-weight-bold">
                  Status:{' '}
                  <small className="badge bg-primary">{data?.status}</small>
                </span>
                <span className="font-weight-bold">
                  Bankrekening: {data?.customer.bank_account?.iban}
                </span>
                <span className="font-weight-bold">
                  Accountnaam: {data?.customer.bank_account?.account_holder}
                </span>
                <span className="font-weight-bold">
                  KVK: {data?.customer.chamber_of_commerce_number}
                </span>
              </div>
            </div>
            <hr />
            <div className="products p-2">
              <table className="table table-borderless">
                <thead>
                  <tr className="add">
                    <td className="font-weight-bold">Contactgegevens</td>
                    <td className="font-weight-bold">Bedrijfsgegevens</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="content">
                    <td className="col-form-label">
                      {data?.customer.firstname} {data?.customer.lastname}<br />
                      {data?.customer.email} <br />
                      {data?.customer.phone_number} <br />
                    </td>
                    <td className="col-form-label">
                      {data?.customer.company_name} <br />
                      {data?.customer.postal_code} <br />
                      {data?.customer.house_number} <br />
                      {data?.customer.address} <br />
                      {data?.customer.city} <br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div className="products p-2">
              <table className="table table-borderless">
                <thead>
                  <tr className="add">
                    <td className="font-weight-bold">Item</td>
                    <td className="font-weight-bold">Eenmalig</td>
                    <td className="font-weight-bold">Maandelijks</td>
                  </tr>
                </thead>
                <tbody>
                  {data?.invoice.free_modules.map((item, idx) => (
                    <tr key={idx} className="content">
                      <td>{item.name}</td>
                      <td>€ 0.00</td>
                      <td>€ 0.00</td>
                    </tr>
                  ))}
                  {data?.products.map((item, idx) => (
                    <tr key={idx} className="content">
                      <td>{item.name}</td>
                      <td>€ {item.one_time_payment}</td>
                      <td>€ {item.monthly_payment}</td>
                    </tr>
                  ))}
                  {additionalItems()}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Totaal</td>
                    <td>€ {data?.invoice.total_one_time_price}</td>
                    <td>€ {data?.invoice.total_monthly_price}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <hr />
            <div className="address p-2">
              <table className="table table-borderless">
                <thead>
                  <tr className="add text-center">
                    <td className="font-weight-bold">Opmerking</td>
                    <td className="font-weight-bold">Signature</td>
                  </tr>
                </thead>
                <tbody>
                  <tr className="content text-center">
                    <td>{data?.customer.remarks}</td>
                    <td>
                      {' '}
                      <img
                        alt=""
                        className="m-auto"
                        src={data?.customer.signature}
                        style={{
                          width: '300px',
                          height: '150px',
                          objectFit: 'cover',
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
