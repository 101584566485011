import { PageDataContainer, PageLink } from "../../../_start/layout/core";
import { PageManagementPage } from "./PageManagementPage";
import { pageManagementSubmenu } from "./PageManagementPageData";

const pageManagementBreadCrumbs: Array<PageLink> = [
	{
		title: "Home",
		path: "/",
		isActive: false,
	},
	{
		title: "Paginabeheer",
		path: "/page-management",
		isActive: false,
	},
];

export const PageManagementWrapper = () => {
	return (
		<>
			<PageDataContainer
				breadcrumbs={pageManagementBreadCrumbs}
				submenu={pageManagementSubmenu}
			/>
			<PageManagementPage />
		</>
	);
};
