import axios from "axios";
import React from "react";
import { API_URL } from "./api";
import { failureToast } from "./utils";

export type UploadResponse = {
	image_name: string;
	image_url: string;
	mime: string;
};

export const handleFileDelete = async (
	event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	current_path: string,
	update: (fieldsToUpdate: Partial<any>) => void
) => {
	// this is needed because somewhat weird thing happen
	// where name attribute cannot be access inside
	// try catch block
	const attributeName = event.currentTarget.name

	try {
		await axios.post<{ value: UploadResponse }>(
			`${API_URL}/v1/storage/content/destroy`,
			{
				current_path,
			}
		);

		update({
			[attributeName]: "",
		});
	} catch (error) {
		failureToast(error);
	}
};

export const handleFileChange = (
	event: React.ChangeEvent<HTMLInputElement>,
	update: (fieldsToUpdate: Partial<any>) => void,
	currentPath: string
) => {
	const files = event.target.files;

	if (!files?.length) return;

	const reader = new FileReader();

	reader.onload = async (ev) => {
		const result = ev.target?.result;

		const formData = new FormData();

		const blob = await fetch(result as string);

		formData.append("image", await blob.blob());

		formData.append("current_path", currentPath);

		try {
			const response = await axios.post<{ value: UploadResponse }>(
				`${API_URL}/v1/storage/content/store`,
				formData
			);
			update({ [event.target.name]: response.data.value.image_url });
		} catch (error) {
			failureToast(error);
		}
	};

	reader.onerror = function () {
		console.log(reader.result);
	};

	reader.readAsDataURL(files[files.length - 1]);

	// this line right below will reset the
	// input field so if you removed it you can re-add the same file
	event.target.value = "";
};

// use this if you need base64 string file from an input
export const handleFileChangeBase64 = (
	event: React.ChangeEvent<HTMLInputElement>,
	update: (fieldsToUpdate: Partial<any>) => void
) => {
	const files = event.target.files;

	if (!files?.length) return;

	const reader = new FileReader();

	reader.onload = (ev) => {
		const result = ev.target?.result;
		update({
			[event.target.name]: result,
		});
	};

	reader.onerror = function () {
		console.log(reader.result);
	};

	reader.readAsDataURL(files[files.length - 1]);

	// this line right below will reset the
	// input field so if you removed it you can re-add the same file
	event.target.value = "";
};

/**
 * Convert string to slug
 * @param value Input string
 * @returns slug of the input
 */
export const convertToSlug = (value: string) => {
	return value
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^\w-]+/g, "");
};

export const generateListItemId = (items: Array<{ id: number }>) => {
	if (items.length === 0) {
		return 0;
	}

	let sortedItems = [...items].sort((a, b) => {
		return a.id - b.id;
	});

	return sortedItems[sortedItems.length - 1].id + 1;
};
