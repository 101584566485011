import React from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../../../../../_start/helpers';
import { HomeReviewsSingle } from './HomeReviewsSingle';

interface Props {
  data: HomeReviewsSingle;
  update: (to: Partial<HomeReviewsSingle>) => void;
}

export const HomeReviewsSingleList: React.FC<Props> = ({ data, update }) => {
  return (
    <div className="reviews__slider">
      <div className="viewport">
        <div className="slider">
          <div className="slide">
            <div className="rating">
              <div className="rating__stars">
                {[...Array(5)].map((v, i) => (
                  <KTSVG
                    key={i}
                    path={toAbsoluteUrl('/assets/icons/icon-star-solid.svg')}
                    svgClassName="icon"
                  />
                ))}
              </div>
            </div>

            <div className="description">
              {'Review Messages will show here.....'}
            </div>
            <div className="author">{' Author of the Review '}</div>
          </div>
        </div>
      </div>
      {/* <div className="button__prev">
        <KTSVG path={arrow_prev} svgClassName="icon" />
      </div>
      <div className="button__next">
        <KTSVG path={arrow_next} svgClassName="icon" />
      </div> */}
      {/* <div className="page-dots">
        {data.reviews.map((v, i) => (
          <div key={i} className="dot"></div>
        ))}
      </div> */}
    </div>
  );
};
