/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  defaultPackageItemDetail,
  HomePackages,
  IPackageItemDetail,
} from "./HomePackages";
import { IPackage } from "../../../../../content-management/models/Package";
import { getAllPackage } from "../../../../../content-management/redux/PackageCRUD";
import { failureToast } from "../../../../../../support/utils";
import { HomePackagesListItem } from "./HomePackagesListItem";

interface Props {
  data: HomePackages;
  update: (to: Partial<HomePackages>) => void;
}

export const HomePackagesList: React.FC<Props> = ({ data, update }) => {
  const [loading, setLoading] = useState(true);
  const [packages, setPackages] = useState<IPackage[]>([]);

  const updateData = (to: Partial<IPackageItemDetail>, itemIndex: number) => {
    const packs = [...data.packages];
    packs[itemIndex] = { ...packs[itemIndex], ...to };
    update({ ...data, packages: packs });
  };

  const fetchPackages = async () => {
    try {
      setLoading(true);
      const dat = await getAllPackage();
      setPackages(dat);

      const packs = [...data.packages];
      dat.map((p) => {
        if (packs.find((v) => v.packageId === p.id) == undefined)
          packs.push({ ...defaultPackageItemDetail, packageId: p.id });
      });
      update({ ...data, packages: packs });

      setLoading(false);
    } catch (error) {
      failureToast(error);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  if (loading) {
    return (
      <div className="packages__cards">
        <div className="loading">Loading...</div>
      </div>
    );
  }
  return (
    <div className="packages__cards">
      {packages.length !== 0 &&
        packages.map((val, idx) => (
          <HomePackagesListItem
            idx={idx}
            pack={val}
            details={data.packages.find((v) => v.packageId == val.id)!}
            updateData={updateData}
            update={update}
          />
        ))}
    </div>
  );
};
