export interface IPackageType {
  id: number;
  name: string;
  account_type: AccountType;
}

export enum AccountType {
  VOIP = 'voip',
  USER = 'user',
}

export const defaultPackageType: IPackageType = {
  id: 0,
  name: '',
  account_type: AccountType.VOIP,
};
