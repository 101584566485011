import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export type InfoBlockBordered = IElement & {
  title: string;
  heading: string;
  heading_type: ElementHeaderType;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
};

export const defaultInfoBlockBordered: InfoBlockBordered = {
  id: 0,
  name: ElementType.INFO_BLOCK_BORDERED,
  title: "Hier vertellen wij over",
  heading: "De mogelijkheden van vast op mobiel",
  heading_type: ElementHeaderType.H1,
  description: `<p>Inmiddels bestaat het team uit 10 enthousiaste medewerkers. Door onze platte en open organisatie kennen we geen bazen en managers. Communiceren doen we horizontaal en hierdoor kunnen we snel schakelen. Tussendoor is inmiddels onderdeel van TOV. Benieuwd naar onze openstande vacatures of bedrijfscultuur?</p>`,
  button_title: "Interesse?",
  button_url: "",
  button_url_is_external: false,
};
