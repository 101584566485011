import { ElementType, IElement } from "../../../models/Element";
import { ElementHeaderType } from "../../utils";

export type HeadingDescription = IElement & {
  title: string;
  heading: string;
  heading_type: ElementHeaderType;
  description: string;
};

export const defaultHeadingDescription: HeadingDescription = {
  id: 0,
  name: ElementType.HEADING_DESCRIPTION,
  title: "Wat jij zegt",
  heading: "Wil je nog ergens een titel toevoegen? Ha dat kan!",
  heading_type: ElementHeaderType.H1,
  description:
    "Bekijk onderstaande screenshots om een indicatie te krijgen van de werking van de WordPress Makelaars plugin.",
};
