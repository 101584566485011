import { ElementType, IElement } from '../../../models/Element';
import { ElementHeaderType } from '../../utils';

export interface ColumnContent {
  heading: string;
  description: string;
}

export const defaultColumnContent: ColumnContent = {
  heading: 'Chop chop',
  description: 'Jij ontvangt de kortingscode per e-mail',
};

export type TitleThreeColumnContent = IElement & {
  heading: string;
  heading_type: ElementHeaderType;
  contents: ColumnContent[];
};

export const defaultTitleThreeColumnContent: TitleThreeColumnContent = {
  id: 0,
  name: ElementType.TITLE_THREE_COLUMN_CONTENT,
  heading: 'Dit is de titel',
  heading_type: ElementHeaderType.H2,
  contents: [],
};
