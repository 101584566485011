/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  defaultQuotation,
  IQuotation,
  QuotationStatusType,
} from '../models/Quotation';
import {
  getQuotationActivityLog,
  initializeEditQuotation,
  setQuotationAdvisor,
  setQuotationStatus,
  updateQuotation,
} from '../redux/QuotationCRUD';
import { IMember } from '../../user-management/models/Member';
import { failureToast, successToast } from '../../../support/utils';
import { IProduct } from '../../content-management/models/Product';

import Select, { MultiValue } from 'react-select';
import { getAllProduct } from '../../content-management/redux/ProductCRUD';
import { QuotationActivityLogModal } from '../components/QuotationActivityLogModal';
import { IQuotationActivityLog } from '../models/QuotationActivityLog';

export const UpdateQuotation = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IQuotation>(defaultQuotation);

  // const updateData = (fieldsToUpdate: Partial<IQuotation>) => {
  // 	const updatedData = { ...data, ...fieldsToUpdate };
  // 	setData(updatedData);
  // };

  const [advisors, setAdvisors] = useState<Array<IMember>>([]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      let payload: IQuotation = {
        ...data,
        // @ts-ignore
        products: selectedProducts.map((item) => item.id),
      };
      console.log(data);
      await updateQuotation(payload);
      successToast('Quotation has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const [products, setProducts] = useState<Array<IProduct>>([]);

  const [selectedProducts, setSelectedProducts] = useState<
    MultiValue<IProduct>
  >([]);

  const [activityLogs, setActivityLogs] = useState<
    Array<IQuotationActivityLog>
  >([]);

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const [result, products, logs] = await Promise.all([
        initializeEditQuotation(id),
        getAllProduct(),
        getQuotationActivityLog(id),
      ]);

      setProducts(products);
      setActivityLogs(logs);

      setSelectedProducts(result.quotation.products);
      setAdvisors(result.advisors);
      setData(result.quotation);

      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSetAdvisor = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const userId = parseInt(event.target.value);
    const payload = {
      user_id: userId === 0 ? null : userId,
      quotation_id: parseInt(id),
    };

    try {
      setLoading(true);
      await setQuotationAdvisor(payload);
      setData((prev) => {
        return { ...prev, user_id: userId };
      });

      const logs = await getQuotationActivityLog(id);
      setActivityLogs(logs);

      setLoading(false);
      successToast('Quotation advisor has been updated successfully.');
    } catch (error) {
      setLoading(false);
      failureToast(error);
    }
  };

  const handleSetStatus = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const status = event.target.value as QuotationStatusType;

    const payload = {
      quotation_id: parseInt(id),
      status: status,
    };

    try {
      setLoading(true);
      await setQuotationStatus(payload);
      setData((prev) => {
        return { ...prev, status: status };
      });

      const logs = await getQuotationActivityLog(id);
      setActivityLogs(logs);

      setLoading(false);
      successToast('Quotation status has been updated successfully.');
    } catch (error) {
      setLoading(false);
      failureToast(error);
    }
  };

  return (
    <>
      <QuotationActivityLogModal logs={activityLogs} />

      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Update Quotation - {data.invoice_id}</h3>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-sm btn-light"
              data-bs-toggle="modal"
              data-bs-target="#activity_log_modal"
            >
              Activity Log
            </button>
          </div>
        </div>
        {/* begin::Form */}
        <form onSubmit={handleSubmit} className="form d-flex flex-center">
          <div className="card-body mw-800px py-20">
            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Advisor</label>
              <div className="col-lg-9">
                <select
                  className="form-select form-select-lg form-select-solid"
                  data-control="select2"
                  data-placeholder="Select advisor..."
                  value={data.user_id ?? 'null'}
                  onChange={(e) => handleSetAdvisor(e)}
                >
                  <option value={'null'}>NA</option>
                  {advisors.map((item, idx) => (
                    <option value={item.id} key={idx}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Status</label>
              <div className="col-lg-9">
                <select
                  className="form-select form-select-lg form-select-solid"
                  data-control="select2"
                  data-placeholder="Select show on verbonden..."
                  value={data.status}
                  onChange={(e) => handleSetStatus(e)}
                >
                  {/* <option value={QuotationStatusType.NEW}>NEW</option> */}
                  <option value={QuotationStatusType.OPEN}>OPEN</option>
                  <option value={QuotationStatusType.CLOSED}>CLOSED</option>
                  <option value={QuotationStatusType.PAUSED}>PAUSED</option>
                  <option value={QuotationStatusType.EXPIRED}>EXPIRED</option>
                </select>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            {/* <div className="row mb-8">
							<label className="col-lg-3 col-form-label">
								Package
							</label>
							<div className="col-lg-9">
								<div className="spinner spinner-sm spinner-primary spinner-right">
									<input
										placeholder="Enter name"
										className="form-control form-control-lg form-control-solid"
										type="text"
										value={
											data.customer_ask_price_quote
												.package.name
										}
										disabled
										required
									/>
								</div>
							</div>
						</div> */}
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Products</label>
              <div className="col-lg-9">
                <Select
                  closeMenuOnSelect={false}
                  placeholder={`Select items...`}
                  value={selectedProducts}
                  onChange={setSelectedProducts}
                  getOptionValue={(model: IProduct) => model.id.toString()}
                  getOptionLabel={(model: IProduct) => model.name}
                  options={products}
                  isMulti
                  isSearchable
                  isClearable
                />
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            {/* <div className="row mb-8">
              <label className="col-lg-3 col-form-label">
                Keep Phone Number
              </label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={
                      data.customer_ask_price_quote.keep_phone_number === ""
                        ? "NO"
                        : data.customer_ask_price_quote.keep_phone_number
                    }
                    disabled
                    required
                  />
                </div>
              </div>
            </div> */}
            {/* end::Form row */}

            {/* begin::Form row */}
            {/* <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Migrate Number</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={
                      data.customer_ask_price_quote.migrate_number === ""
                        ? "NA"
                        : data.customer_ask_price_quote.migrate_number
                    }
                    disabled
                    required
                  />
                </div>
              </div>
            </div> */}
            {/* end::Form row */}

            {/* begin::Form row */}
            {/* <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Net Number</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer_ask_price_quote.net_number}
                    disabled
                    required
                  />
                </div>
              </div>
            </div> */}
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Firstname</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer.firstname}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Phone Number</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer.phone_number}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Email</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer.email}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Company Name</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer.company_name}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">KVK</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer.chamber_of_commerce_number}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Company Email</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer.company_email}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">
                Automatic Collection
              </label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <input
                    className="form-control form-control-lg form-control-solid"
                    type="text"
                    value={data.customer.payment_type ?? 'NA'}
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            {/* <div className="row mb-8">
						<label className="col-lg-3 col-form-label">IBAN</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={""}
									disabled
									required
								/>
							</div>
						</div>
					</div> */}
            {/* end::Form row */}

            {/* begin::Form row */}
            {/* <div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Account Holder
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={""}
									disabled
									required
								/>
							</div>
						</div>
					</div> */}
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Remarks</label>
              <div className="col-lg-9">
                <div className="spinner spinner-sm spinner-primary spinner-right">
                  <textarea
                    rows={2}
                    placeholder="Remarks"
                    className="form-control form-control-lg form-control-solid"
                    value={data.customer.remarks}
                    disabled
                  />
                </div>
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row mb-8">
              <label className="col-lg-3 col-form-label">Signature</label>
              <div className="col-lg-9">
                <img
                  alt=""
                  className="m-auto"
                  src={data.customer.signature}
                  style={{
                    width: '300px',
                    height: '150px',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </div>
            {/* end::Form row */}

            {/* begin::Form row */}
            <div className="row">
              <label className="col-lg-3 col-form-label"></label>
              <div className="col-lg-9">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary fw-bolder px-6 py-3 me-3"
                >
                  {!loading && <span className="indicator-label">Save</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Please wait...{' '}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <Link
                  className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                  to={`/quotation-management/quotations`}
                >
                  Cancel
                </Link>
              </div>
            </div>
            {/* end::Form row */}
          </div>
        </form>
        {/* end::Form */}
      </div>
    </>
  );
};
