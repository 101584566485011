export enum EditContentEvents {
  CONTENT_REQUESTED = '[Request Edit Content] Action',
  CONTENT_LOADED = '[Load Edit Content] Action',
  CONTENT_FAILED = '[Failure Edit Content] API Response',

  INSERT_ELEMENT = '[Insert ELEMENT] Action',
  MOVE_ELEMENT = '[Move ELEMENT] Action',
  UPDATE_ELEMENT = '[Update ELEMENT] Action',
  REMOVE_ELEMENT = '[Remove ELEMENT] Action',

  SET_CONTENT = '[Set CONTENT] Action',

  SET_POST = '[Set POST] Action',
  SET_PAGE = '[Set PAGE] Action',

  SET_ALL_POSTS = '[Set ALL POST] Action',
  SET_ALL_EMPLOYEES = '[Set ALL EMPLOYEE] Action',
  SET_ALL_PACKAGES = '[Set ALL PACKAGE] Action',
  SET_ALL_PRODUCTS = '[Set ALL PRODUCTS] Action',
  SET_ALL_PARTNERS = '[Set ALL PARTNER] Action',

  SET_AUTHOR = '[Set AUTHOR] Action',

  SET_FEATURED_PACKAGES = '[Set FEATURED PACKAGES] Action',
  SET_FEATURED_PRODUCTS = '[Set FEATURED PRODUCTS] Action',

  SET_MEDIA_LIBRARY = '[SET MEDIA LIBRARY] ACTION',
  SET_FILE_LIBRARY = '[SET FILE LIBRARY] ACTION',
  SET_SVG_LIBRARY = '[SET SVG LIBRARY] ACTION',
  SET_PARTNERS = '[Set PARTNERS] Action',

  // SET_WAS_INITIALLY_SAVED = "[Content Initially Saved] Action",

  // SET_POST_TYPES = "[Set Post Types] API",
  // SET_CASE_PAGES = "[Set Case Pages] API",
  // SET_BLOG_PAGES = "[Set Blog Pages] API",
  // SET_CUSTOMERS = "[Set Customers] API",
  // SET_EMPLOYEES = "[Set Employees] API",

  // SET_PAGE = "[Set Page] Action",

  // INSERT_HEADER = "[Insert Header] Action",
  // UPDATE_HEADER = "[Update Header] Action",
  // REMOVE_HEADER = "[Remove Header] Action",

  // INSERT_BODY = "[Insert Body] Action",
  // MOVE_BODY = "[Move Body] Action",
  // UPDATE_BODY = "[Update Body] Action",
  // REMOVE_BODY = "[Remove Body] Action",
}
