import { IPackage } from '../../content-management/models/Package';
import { IProduct } from '../../content-management/models/Product';
import { IElement } from '../../edit-content/models/Element';
import {
  RobotType,
  SchemaArticleType,
  SchemaPageType,
  SEOTitleType,
} from '../../post-management/models/Post';

export enum PageStatusType {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FUTURE = 'future',
}

export interface IPage {
  id: number;
  title: string;
  slug: string;
  publish_date: string | Date;
  status: PageStatusType;
  description: string;
  synopsis: string;
  content: Array<IElement>;

  // SEO stuff
  seo_title: Array<SEOTitleType>;
  robots: Array<RobotType>;

  schema_page_type: SchemaPageType;
  schema_article_type: SchemaArticleType;

  facebook_image: string;
  facebook_title: string;
  facebook_description: string;

  twitter_image: string;
  twitter_title: string;
  twitter_description: string;

  is_parent: boolean;
  parent_id?: number | null;
  parent?: IPage | null;

  featured_packages: Array<IPackage>;
  featured_products: Array<IProduct>;
}

export const defaultPage: IPage = {
  id: 0,
  title: '',
  slug: '',
  publish_date: new Date(),
  status: PageStatusType.DRAFT,
  description: '',
  synopsis: '',
  content: [],

  seo_title: [],
  robots: [],

  schema_page_type: SchemaPageType.WEB_PAGE,
  schema_article_type: SchemaArticleType.ARTICLE,

  facebook_image: '',
  facebook_title: '',
  facebook_description: '',

  twitter_image: '',
  twitter_title: '',
  twitter_description: '',

  is_parent: true,

  featured_packages: [],
  featured_products: [],
};

export enum PageQueryFilterValue {
  ALL = '',
  PARENT = '1',
  CHILD = '0',
  DRAFT = 'draft',
  PUBLISHED = 'published',
  FUTURE = 'future',
}

export type PageQueryFilters = {
  status: string;
  is_parent: string;
};

const defaultPageQueryFilters = (): PageQueryFilters => ({
  status: '',
  is_parent: '',
});

export const getPageQueryFilters = (value: PageQueryFilterValue) => {
  switch (value) {
    case PageQueryFilterValue.DRAFT:
      return {
        ...defaultPageQueryFilters(),
        status: PageQueryFilterValue.DRAFT,
      };

    case PageQueryFilterValue.PUBLISHED:
      return {
        ...defaultPageQueryFilters(),
        status: PageQueryFilterValue.PUBLISHED,
      };

    case PageQueryFilterValue.FUTURE:
      return {
        ...defaultPageQueryFilters(),
        status: PageQueryFilterValue.FUTURE,
      };

    case PageQueryFilterValue.PARENT:
      return {
        ...defaultPageQueryFilters(),
        is_parent: PageQueryFilterValue.PARENT,
      };

    case PageQueryFilterValue.CHILD:
      return {
        ...defaultPageQueryFilters(),
        is_parent: PageQueryFilterValue.CHILD,
      };

    default:
      return defaultPageQueryFilters();
  }
};
