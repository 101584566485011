import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { failureToast, successToast } from '../../../../support/utils';
import {
  AccountType,
  defaultPackageType,
  IPackageType,
} from '../../models/PackageType';
import {
  updatePackageType,
  viewPackageType,
} from '../../redux/PackageTypeCRUD';

export const UpdatePackageType = () => {
  const { id } = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IPackageType>(defaultPackageType);

  const updateData = (fieldsToUpdate: Partial<IPackageType>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleFetch = async (id: string) => {
    setLoading(true);

    try {
      const result = await viewPackageType(id);
      setData(result);
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleFetch(id);
    }
  }, [id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      await updatePackageType(data);
      successToast('Package type has been updated.');
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Package type</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) =>
                    updateData({
                      name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Account Type</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid"
                data-control="select2"
                data-placeholder="Select Status..."
                value={data.account_type}
                onChange={(e) =>
                  updateData({
                    account_type: e.target.value as AccountType,
                  })
                }
              >
                <option value={AccountType.VOIP}>VoIP Account</option>
                <option value={AccountType.USER}>User Account</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/content-management/package-types`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
