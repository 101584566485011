import { BlogContentDescriptionWithInfo } from './BlogContentDescriptionWithInfo';
import { IElement } from '../../../models/Element';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { TextAreaEdit } from '../../../components/TextAreaEdit';
import { TextEditor } from '../../../../../components/TextEditor';
import { ElementHeaderType } from '../../utils';
import { HeaderSelectWrapper } from '../../../components/HeaderSelectWrapper';

type BlogContentDescriptionWithInfoFCProps = {
  index: number;
  data: BlogContentDescriptionWithInfo;
  updateData: (body: IElement, index: number) => void;
};

export const BlogContentDescriptionWithInfoFC = ({
  index,
  data,
  updateData,
}: BlogContentDescriptionWithInfoFCProps) => {
  const update = (fieldsToUpdate: Partial<BlogContentDescriptionWithInfo>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="blog-content-description-with-info body-my-8">
        <div className="blog-content">
          {/* begin::Title */}
          <HeaderSelectWrapper
            name={`title_type`}
            value={data.title_type ?? ElementHeaderType.H2}
            setValue={update}
          >
            <TextAreaEdit
              name="title"
              className={`blog-content__title`}
              value={data.title}
              setValue={update}
            />
          </HeaderSelectWrapper>
          {/* end::Title */}

          <TextEditor
            content={data.content}
            updateContent={(content) => update({ content })}
          />

          <div className="blog-content__info">
            <TextEditor
              content={data.content_info}
              updateContent={(content_info) => update({ content_info })}
            />
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
