import { KTSVG, toAbsoluteUrl } from '../../../../../../_start/helpers';
import { AttachMediaModal } from '../../../components/AttachMediaModal';
import { ImagePreview, MediaCarousel } from './MediaCarousel';

type Props = {
  data: ImagePreview;
  mediaCarousel: MediaCarousel;
  index: number;
  update: (fieldsToUpdate: Partial<MediaCarousel>) => void;
  removeItem: (index: number) => void;
};

export const MediaCarouselItem = ({
  data,
  update,
  mediaCarousel,
  index,
  removeItem,
}: Props) => {
  const updateItem = (to: Partial<ImagePreview>) => {
    const image_previews = [...mediaCarousel.image_previews];
    image_previews[index] = { ...image_previews[index], ...to };

    update({ image_previews });
  };

  const handleRemove = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    event.preventDefault();
    removeItem(index);
  };
  return (
    <>
      <AttachMediaModal
        modalId={`${mediaCarousel.name}-${mediaCarousel.id}-${index}`}
        updateCallback={(image_preview, image_preview_alt) =>
          updateItem({ image_preview, image_preview_alt })
        }
      />
      <div className="item">
        <span
          onClick={handleRemove}
          className="position-absolute z-index-2 top-0 end-0 me-2 mt-6 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
        {data.image_preview ? (
          <div className="mt-5 overlay">
            <div className="overlay-wrapper">
              <img
                alt="Media Carousel Figure"
                src={data.image_preview}
                style={{
                  width: '100%',
                  height: '365px',
                  objectFit: 'contain',
                }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column align-items-start ps-12">
              <div>
                <button
                  name="image"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    updateItem({
                      image_preview: '',
                      image_preview_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${mediaCarousel.name}-${mediaCarousel.id}-${index}`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-75 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_alt ?? ''}
                onChange={(event) =>
                  updateItem({ image_preview_alt: event.target.value })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              className="mt-5"
              data-bs-toggle="modal"
              data-bs-target={`#${mediaCarousel.name}-${mediaCarousel.id}-${index}`}
            >
              <img
                src={toAbsoluteUrl('/assets/images/900x774.webp')}
                style={{
                  width: '100%',
                  height: '365px',
                  objectFit: 'contain',
                }}
                alt=""
              />
            </label>
          </>
        )}
      </div>
    </>
  );
};
