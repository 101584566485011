import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { failureToast, successToast } from "../../../support/utils";
import { defaultInquiry, IInquiry } from "../models/Inquiry";
import { updateInquiry, viewInquiry } from "../redux/InquiriesCRUD";

export const UpdateInquiry = () => {
	const { id } = useParams<{ id: string }>();

	const [loading, setLoading] = useState(false);

	const [data, setData] = useState<IInquiry>(defaultInquiry);

	const updateData = (fieldsToUpdate: Partial<IInquiry>) => {
		const updatedData = { ...data, ...fieldsToUpdate };
		setData(updatedData);
	};

	const handleFetch = async (id: string) => {
		setLoading(true);

		try {
			const result = await viewInquiry(id);
			setData(result);
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			handleFetch(id);
		}
	}, [id]);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoading(true);

		try {
			await updateInquiry(data);
			successToast("Inquiry has been updated.");
			setLoading(false);
		} catch (error) {
			failureToast(error);
			setLoading(false);
		}
	};

	return (
		<div className="card">
			<div className="card-header">
				<h3 className="card-title">Update Category</h3>
			</div>
			{/* begin::Form */}
			<form onSubmit={handleSubmit} className="form d-flex flex-center">
				<div className="card-body mw-800px py-20">
					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Firstname
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter firstname"
									className="form-control form-control-lg form-control-solid"
									type={`firstname`}
									value={data.firstname}
									onChange={(e) =>
										updateData({
											firstname: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Email</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter email"
									className="form-control form-control-lg form-control-solid"
									type={`email`}
									value={data.email}
									onChange={(e) =>
										updateData({
											email: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">Topic</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter topic"
									className="form-control form-control-lg form-control-solid"
									type={`text`}
									value={data.topic}
									onChange={(e) =>
										updateData({
											topic: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row mb-8">
						<label className="col-lg-3 col-form-label">
							Remarks
						</label>
						<div className="col-lg-9">
							<div className="spinner spinner-sm spinner-primary spinner-right">
								<input
									placeholder="Enter remarks"
									className="form-control form-control-lg form-control-solid"
									type="text"
									value={data.remarks}
									onChange={(e) =>
										updateData({
											remarks: e.target.value,
										})
									}
									required
								/>
							</div>
						</div>
					</div>
					{/* end::Form row */}

					{/* begin::Form row */}
					<div className="row">
						<label className="col-lg-3 col-form-label"></label>
						<div className="col-lg-9">
							<button
								type="submit"
								disabled={loading}
								className="btn btn-primary fw-bolder px-6 py-3 me-3"
							>
								{!loading && (
									<span className="indicator-label">
										Save
									</span>
								)}
								{loading && (
									<span
										className="indicator-progress"
										style={{ display: "block" }}
									>
										Please wait...{" "}
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
							<Link
								className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
								to={`/inquiry-management/inquiries`}
							>
								Cancel
							</Link>
						</div>
					</div>
					{/* end::Form row */}
				</div>
			</form>
			{/* end::Form */}
		</div>
	);
};
