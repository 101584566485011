import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { ICallRate } from "../models/CallRate";

export const BASE_CALL_RATE_URL = `${API_URL}/v1/content/call-rates`;
export const GET_ALL_CALL_RATE_URL = `${API_URL}/v1/content/call-rates/get-all`;
export const CREATE_CALL_RATE_URL = `${API_URL}/v1/content/call-rates`;

export const listCallRate = async (search = "", direction = "asc") => {
	const URL = `${BASE_CALL_RATE_URL}`;
	const {
		data: { value },
	} = await axios.get<{ value: PaginatedValue<ICallRate> }>(URL);

	return value;
};

export const createCallRate = async (payload: ICallRate) => {
	const {
		data: { value },
	} = await axios.post<{ value: ICallRate }>(CREATE_CALL_RATE_URL, payload);

	return value;
};

export const viewCallRate = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: ICallRate }>(
		`${BASE_CALL_RATE_URL}/${id}/view`
	);

	return value;
};

export const updateCallRate = async (payload: ICallRate) => {
	const {
		data: { value },
	} = await axios.post<{ value: ICallRate }>(
		`${BASE_CALL_RATE_URL}/${payload.id}/update`,
		payload
	);

	return value;
};

export const deleteCallRate = async (payload: ICallRate) => {
	const {
		data: { value },
	} = await axios.post<{ value: ICallRate }>(
		`${BASE_CALL_RATE_URL}/${payload.id}/delete`,
		payload
	);

	return value;
};

// unpaginated categories
export const getAllCallRate = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: Array<ICallRate> }>(GET_ALL_CALL_RATE_URL);

	return value;
};
