import { PropsWithChildren } from 'react';

type CardListBodyProps = {
  isPending: boolean;
};

const CardListBody = ({
  isPending,
  children,
}: PropsWithChildren<CardListBodyProps>) => {
  return (
    <div className={`card-body py-5 ${isPending ? 'overlay-wrapper' : ''}`}>
      {children}
      {isPending && (
        <div className="overlay-layer rounded bg-dark bg-opacity-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardListBody;
