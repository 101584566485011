import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  customFailureToast,
  failureToast,
  successToast,
} from '../../../../support/utils';
import { FormImageField } from '../../../media-management/components/form-image/FormImageField';
import { defaultPartner, IPartner } from '../../models/Partner';
import { createPartner } from '../../redux/PartnerCRUD';

type CreatePartnerPageProps = {
  cancelUrl?: string;
};

export const CreatePartnerPage = ({
  cancelUrl = `/customer-management/partners`,
}: CreatePartnerPageProps) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IPartner>(defaultPartner);

  const updateData = (fieldsToUpdate: Partial<IPartner>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!data.logo) {
      customFailureToast({
        title: 'The given data was invalid',
        message: 'The logo input is required.',
      });
      return true;
    }

    try {
      await createPartner(data);
      successToast('Partner has been created.');
      setLoading(false);
      setData(defaultPartner);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Create Partner</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) => updateData({ name: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Business name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter business name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.business_name}
                  onChange={(e) =>
                    updateData({ business_name: e.target.value })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Logo</label>
            <div className="col-lg-9">
              <FormImageField
                name={`logo`}
                src={data.logo}
                update={updateData}
                modalId={`logo_modal`}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={cancelUrl}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
