import immutabilityHelper from "immutability-helper";
import React from "react";
import { KTSVG } from "../../../../../../_start/helpers";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { IElement } from "../../../models/Element";
import {
  ColumnContent,
  defaultColumnContent,
  FourColumnContent,
} from "./FourColumnContent";
import { FourColumnContentItem } from "./FourColumnContentItem";

interface Props {
  index: number;
  data: FourColumnContent;
  updateData: (body: IElement, index: number) => void;
}

export const FourColumnContentFC: React.FC<Props> = ({
  index,
  data,
  updateData,
}) => {
  const update = (to: Partial<FourColumnContent>) => {
    const updatedData = { ...data, ...to };
    updateData(updatedData, index);
  };

  const updateItem = (idx: number, item: ColumnContent) => {
    let contents = [...data.contents];
    contents[idx] = item;

    const updatedData = { ...data, contents };
    update(updatedData);
  };

  const addItem = () => {
    const contents = immutabilityHelper(data.contents, {
      $push: [defaultColumnContent],
    });
    const updatedData = { ...data, contents };
    update(updatedData);
  };

  const removeItem = (itemIndex: number) => {
    let contents = immutabilityHelper(data.contents, {
      $splice: [[itemIndex, 1]],
    });
    const updatedData = { ...data, contents };
    update(updatedData);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="four-column-content">
        {data.contents.map((item, index) => (
          <FourColumnContentItem
            itemIndex={index}
            data={item}
            allData={data}
            updateItem={updateItem}
            removeItem={removeItem}
          />
        ))}

        <div onClick={addItem} className="add-item">
          <div className="m-auto d-flex flex-column">
            <KTSVG
              path="/media/icons/duotone/Interface/Plus-Square.svg"
              className="svg-icon-muted svg-icon-3hx text-center"
            />
            <span className="mt-4 text-gray-500">Add</span>
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
