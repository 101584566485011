import React from 'react';
import { MediaCarousel } from './MediaCarousel';
import Carousel from 'react-elastic-carousel';
import { MediaCarouselItem } from './MediaCarouselItem';
import { KTSVG } from '../../../../../../_start/helpers';
import { TextAreaEdit } from '../../../components/TextAreaEdit';

type Props = {
  data: MediaCarousel;
  update: (fieldsToUpdate: Partial<MediaCarousel>) => void;
  addImage: () => void;
  removeImage: (index: number) => void;
};

export const MediaCarouselFigure: React.FC<Props> = ({
  data,
  update,
  addImage,
  removeImage,
}) => {
  return (
    <div className="figure">
      <div className="inner">
        <div className="carousel">
          <Carousel
            itemsToShow={2}
            isRTL={false}
            pagination={true}
            enableSwipe={false}
            enableMouseSwipe={false}
          >
            {data.image_previews.map((item, index) => (
              <MediaCarouselItem
                data={item}
                mediaCarousel={data}
                index={index}
                update={update}
                removeItem={removeImage}
              />
            ))}

            <div onClick={addImage} className="add-item">
              <div className="m-auto d-flex flex-column">
                <KTSVG
                  path="/media/icons/duotone/Interface/Plus-Square.svg"
                  className="svg-icon-muted svg-icon-3hx text-center"
                />
                <span className="mt-4 text-gray-500">Add new item</span>
              </div>
            </div>
          </Carousel>
        </div>

        <TextAreaEdit
          name="caption"
          className="caption"
          value={data.caption}
          setValue={update}
        />
      </div>
    </div>
  );
};
