import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IProduct } from "../models/Product";

export const BASE_PRODUCT_URL = `${API_URL}/v1/content/products`;
export const GET_ALL_PRODUCT_URL = `${API_URL}/v1/content/products/get-all`;
export const CREATE_PRODUCT_URL = `${API_URL}/v1/content/products`;

export const listProduct = async (search = "", direction = "asc") => {
	const URL = `${BASE_PRODUCT_URL}/list?search=${search}&direction=${direction}`;
	const {
		data: { value },
	} = await axios.get<{ value: PaginatedValue<IProduct> }>(URL);

	return value;
};

export const createProduct = async (payload: IProduct) => {
	const {
		data: { value },
	} = await axios.post<{ value: IProduct }>(CREATE_PRODUCT_URL, payload);

	return value;
};

export const viewProduct = async (id: number | string) => {
	const {
		data: { value },
	} = await axios.get<{ value: IProduct }>(`${BASE_PRODUCT_URL}/${id}/view`);

	return value;
};

export const updateProduct = async (payload: IProduct) => {
	const {
		data: { value },
	} = await axios.post<{ value: IProduct }>(
		`${BASE_PRODUCT_URL}/${payload.id}/update`,
		payload
	);

	return value;
};

export const deleteProduct = async (payload: IProduct) => {
	const {
		data: { value },
	} = await axios.post<{ value: IProduct }>(
		`${BASE_PRODUCT_URL}/${payload.id}/delete`,
		payload
	);

	return value;
};

// unpaginated categories
export const getAllProduct = async () => {
	const {
		data: { value },
	} = await axios.get<{ value: Array<IProduct> }>(GET_ALL_PRODUCT_URL);

	return value;
};
