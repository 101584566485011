import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { PageTitle } from "../../../_start/layout/core";
import { PermissionsPage } from "./pages/permissions/PermissionsPage";
import { UpdatePermission } from "./pages/permissions/UpdatePermission";
import { RolesPage } from "./pages/roles/RolesPage";
import { UpdateRole } from "./pages/roles/UpdateRole";

export const RoleAndPermissionPage = () => {
  return (
    <Switch>
      {/* begin::ROLE ROUTES */}
      <Route exact={true} path="/role-and-permission/roles">
        <>
          <PageTitle>Rollen</PageTitle>
          <RolesPage />
        </>
      </Route>
      <Route exact={true} path="/role-and-permission/roles/:role_id/update">
        <>
          <PageTitle>Rollen</PageTitle>
          <UpdateRole />
        </>
      </Route>
      {/* end::ROLE ROUTES */}

      {/* begin::PERMISSION ROUTES */}
      <Route exact={true} path="/role-and-permission/permissions">
        <>
          <PageTitle>Rechten</PageTitle>
          <PermissionsPage />
        </>
      </Route>
      <Route
        exact={true}
        path="/role-and-permission/permissions/:permission_id/update"
      >
        <>
          <PageTitle>Rechten</PageTitle>
          <UpdatePermission />
        </>
      </Route>
      {/* end::PERMISSION ROUTES */}

      <Redirect
        from="/role-and-permission"
        exact={true}
        to="/role-and-permission/roles"
      />
      <Redirect to="/role-and-permission/roles" />
    </Switch>
  );
};
