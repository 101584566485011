import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { MediaLibraryPage } from './pages/MediaLibraryPage';
import { SvgLibraryPage } from './pages/SvgLibraryPage';

export const MediaManagementPage = () => {
  return (
    <Switch>
      {/* begin::Routes */}
      <Route exact={true} path="/media-management/media-library">
        <>
          <PageTitle>Media Library</PageTitle>
          <MediaLibraryPage />
        </>
      </Route>
      {/* end::Routes */}

      {/* begin::SVG */}
      <Route exact={true} path="/media-management/svg-library">
        <>
          <PageTitle>SVG</PageTitle>
          <SvgLibraryPage />
        </>
      </Route>
      {/* end::SVG */}

      <Redirect
        from="/media-management"
        exact={true}
        to="/media-management/media-libray"
      />

      <Redirect to="/media-management/media-libray" />
    </Switch>
  );
};
