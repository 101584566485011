import { IProduct } from '../../content-management/models/Product';
import { defaultMember, IMember } from '../../user-management/models/Member';
import { defaultCustomer, ICustomer } from './Customer';
import {
  defaultCustomerAskPriceQuote,
  ICustomerAskPriceQuote,
} from './CustomerAskPriceQuote';
import { defaultInvoice, IInvoice } from './Invoice';
import {
  IPackage,
  defaultPackage,
} from '../../content-management/models/Package';

export enum QuotationStatusType {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  CONCEPT = 'CONCEPT',
  REMINDED = 'REMINDED',
  CLOSED = 'CLOSED',
  ADDED = 'ADDED',
  OPEN = 'OPEN',
  PAUSED = 'PAUSED',
  EXPIRED = 'EXPIRED',
}

export interface IQuotation {
  id: number;
  customer_ask_price_quote_id: number;

  customer_id: number;
  customer: ICustomer;
  customer_ask_price_quote: ICustomerAskPriceQuote;

  invoice_id: number;
  invoice: IInvoice;

  package_id: number;
  package: IPackage;

  products: Array<IProduct>;

  user_id: number | null;
  user: null | IMember;

  status: QuotationStatusType;

  date: string;

  created_by: string;
}

export const defaultQuotation: IQuotation = {
  id: 0,
  customer_ask_price_quote_id: 0,

  customer_id: 0,
  customer: defaultCustomer,
  customer_ask_price_quote: defaultCustomerAskPriceQuote,

  invoice_id: 1200000,
  invoice: defaultInvoice,

  package_id: 0,
  package: defaultPackage,

  user_id: null,
  user: defaultMember,

  products: [],

  date: '',

  status: QuotationStatusType.NEW,

  created_by: '',
};
