import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_start/partials';
import { StartDashboardWrapper } from '../pages/dashboards/start-dashboard/StartDashboardWrapper';
import { QuotationManagementWrapper } from '../modules/quotation-management/QuotationManagementWrapper';
import { UserManagementWrapper } from '../modules/user-management/UserManagementWrapper';
import { PostManagementWrapper } from '../modules/post-management/PostManagementWrapper';
import { RoleAndPermissionWrapper } from '../modules/role-and-permission/RoleAndPermissionWrapper';
import { EmployeeManagementWrapper } from '../modules/employee-management/EmployeeManagementWrapper';
import { PageManagementWrapper } from '../modules/page-management/PageManagementWrapper';
import { ContentManagementWrapper } from '../modules/content-management/ContentManagementWrapper';
import { SettingsWrapper } from '../modules/settings/SettingsWrapper';
import { StorageTest } from '../pages/_testing/StorageTest';
import { LayoutManagementWrapper } from '../modules/layout-management/LayoutManagementWrapper';
import { InquiryManagementWrapper } from '../modules/inquiry-management/InquiryManagementWrapper';
import { MediaManagementWrapper } from '../modules/media-management/MediaManagementWrapper';
import { NoticeManagementWrapper } from '../modules/notice-management/NoticeManagementWrapper';
import { ClientManagementWrapper } from '../modules/client-management/ClientManagementWrapper';
import { RedirectLinkManagementWrapper } from '../modules/redirect-link-management/RedirectLinkManagementWrapper';
import { GoogleMyBusinessWrapper } from '../pages/gmb/GoogleMyBusinessWrapper';

export function PrivateRoutes() {
  //   const BuilderPageWrapper = lazy(
  //     () => import('../pages/layout-builder/BuilderPageWrapper')
  //   );
  const ProfilePageWrapper = lazy(
    () => import('../modules/profile/ProfilePageWrapper')
  );
  //   const ChagePage = lazy(() => import('../modules/apps/chat/ChatPageWrapper'));
  //   const ShopPageWrapper = lazy(
  //     () => import('../modules/apps/shop/ShopPageWrapper')
  //   );
  //   const GeneralPageWrapper = lazy(
  //     () => import('../modules/general/GeneralPageWrapper')
  //   );
  //   const DocsPageWrapper = lazy(() => import('../modules/docs/DocsPageWrapper'));

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path="/dashboard" component={StartDashboardWrapper} />

        <Route path="/post-management" component={PostManagementWrapper} />

        <Route path="/page-management" component={PageManagementWrapper} />

        <Route path="/settings" component={SettingsWrapper} />

        <Route
          path="/quotation-management"
          component={QuotationManagementWrapper}
        />

        <Route
          path="/content-management"
          component={ContentManagementWrapper}
        />

        <Route path="/user-management" component={UserManagementWrapper} />

        <Route
          path="/role-and-permission"
          component={RoleAndPermissionWrapper}
        />

        <Route
          path="/employee-management"
          component={EmployeeManagementWrapper}
        />

        <Route path="/layout-management" component={LayoutManagementWrapper} />

        <Route
          path="/inquiry-management"
          component={InquiryManagementWrapper}
        />

        <Route path="/media-management" component={MediaManagementWrapper} />

        <Route path="/notice-management" component={NoticeManagementWrapper} />

        <Route path="/client-management" component={ClientManagementWrapper} />

        <Route
          path={`/redirect-link-management`}
          component={RedirectLinkManagementWrapper}
        />

        <Route path={`/google-reviews`} component={GoogleMyBusinessWrapper} />

        <Route path="/test-storage" component={StorageTest} />

        <Route path="/profile" component={ProfilePageWrapper} />

        <Redirect from="/auth" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
        <Redirect to="error/404" />
      </Switch>
    </Suspense>
  );
}
