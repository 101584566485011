import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export interface IconTitle {
  id: number;
  title: string;
  icon: string;
  icon_url?: string;
}

export type HomeHeroWideThreeIconsElement = IElement & {
  title: string;
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  image_preview: string;
  image_preview_alt: string;
  icon_titles: Array<IconTitle>;
};

export const defaultIcon = `<svg class="icon-24" data-src="assets/icons/icon-heart.svg" focusable="false" aria-hidden="true" data-cache="604800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 27" data-id="svg-loader_125"><path d="M15.08 27h-.16c-.5 0-.98-.2-1.34-.55L2.67 15.53C.95 13.81 0 11.53 0 9.1s.95-4.72 2.67-6.43C4.39.95 6.67 0 9.1 0c2.19 0 4.25.76 5.9 2.17 3.57-3.04 8.96-2.88 12.33.5C29.05 4.39 30 6.67 30 9.1s-.95 4.71-2.67 6.43L16.41 26.44c-.36.36-.84.55-1.34.55ZM9.1 3.79c-1.42 0-2.75.55-3.76 1.56-1 1-1.56 2.34-1.56 3.76s.55 2.75 1.56 3.76L15 22.52l9.65-9.65c1-1 1.56-2.34 1.56-3.76s-.55-2.75-1.56-3.76c-1-1-2.34-1.56-3.76-1.56s-2.75.55-3.76 1.56l-.54.54c-.85.85-2.34.85-3.2 0l-.54-.54c-1-1-2.34-1.56-3.76-1.56ZM4 4.01Z"></path></svg>`;

export const defaultIconTitle: IconTitle = {
  id: 0,
  title: 'Gratis iOS App',
  icon: defaultIcon,
  icon_url: '',
};

export const defaultHomeHeroWideIconsElement: HomeHeroWideThreeIconsElement = {
  id: 0,
  name: ElementType.HOME_HERO_WIDE_THREE_ICONS,
  title: 'Flexibel schakelen',
  heading: 'Telefonie afgestemd op jouw wensen',
  description:
    'Flexibel schakelen. Wel verbonden, niet gebonden. Geen langlopende contracten, geen kleine lettertjes en per dag kunnen opzeggen!',
  button_title: 'Ja dat wil ik!',
  button_url: '',
  button_url_is_external: false,
  image_preview: '',
  image_preview_alt: '',
  icon_titles: [defaultIconTitle, defaultIconTitle, defaultIconTitle],
};
