/* eslint-disable jsx-a11y/anchor-is-valid */
import { PropsWithChildren } from 'react';
import { KTSVG } from '../../../_start/helpers';

type HeaderCellProps = {
  isSortable?: boolean;
  attribute: string;
  sort: string;
  handleSort: (attribute: string) => void;
};

const HeaderCell = ({
  isSortable = true,
  attribute,
  sort,
  handleSort,
  children,
}: PropsWithChildren<HeaderCellProps>) => {
  return (
    <th>
      <a
        href="#"
        aria-label="header-sort"
        onClick={() => isSortable && handleSort(attribute)}
        className="d-flex align-items-center"
      >
        {children}

        {sort === attribute ? (
          <KTSVG
            className={`svg-icon-sm svg-icon-primary`}
            path="/media/icons/duotone/Navigation/Up-2.svg"
          />
        ) : null}

        {sort === `-${attribute}` ? (
          <KTSVG
            className={`svg-icon-sm svg-icon-primary`}
            path="/media/icons/duotone/Navigation/Down-2.svg"
          />
        ) : null}
      </a>
    </th>
  );
};

export default HeaderCell;
