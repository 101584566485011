import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../../_start/helpers';
import { IMember } from '../../user-management/models/Member';
import { IQuotation } from '../models/Quotation';

type Props = {
  headerTitle?: string;
  bodyMessage?: string;
  item: IQuotation;
  advisors: IMember[];
  handleSetAdvisor: (
    event: React.ChangeEvent<HTMLSelectElement>,
    item: IQuotation
  ) => void;
  handleUpdate: (item: any) => void;
};

export const QuotationsAssignToModal: React.FC<Props> = ({
  headerTitle = 'Assign To',
  bodyMessage = `Change Quotation's Advisor to:`,
  item,
  handleUpdate,
  children,
  advisors,
  handleSetAdvisor,
}) => {
  const [currentAdvisor, setCurrentAdvisor] = useState(item.user_id ?? 'null');

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    item: IQuotation
  ) => {
    setCurrentAdvisor(parseInt(event.target.value));
    handleSetAdvisor(event, item);
  };

  useEffect(() => {
    setCurrentAdvisor(item.user_id ?? 'null');
  }, [item]);
  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id="custom_update_advisor_kt_modal_1"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{headerTitle}</h5>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTSVG
                path="/media/icons/duotone/Navigation/Close.svg"
                className="svg-icon svg-icon-2x"
              />
            </div>
          </div>
          <div className="modal-body">
            {children ? (
              children
            ) : (
              <>
                <p>{bodyMessage}</p>
                {/* <p className="block">{item}</p> */}
                {/* begin::Form row */}
                <div className="row mb-8">
                  <label className="col-lg-3 col-form-label">Advisor</label>
                  <div className="col-lg-9">
                    <select
                      className="form-select form-select-lg form-select-solid"
                      data-control="select2"
                      data-placeholder="Select advisor..."
                      value={currentAdvisor}
                      onChange={(e) => handleChange(e, item)}
                    >
                      <option value={'null'}>NA</option>
                      {advisors.map((item, idx) => (
                        <option value={item.id} key={idx}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* end::Form row */}
              </>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            {/* <button
              onClick={() => handleUpdate(item)}
              type="button"
              className="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Confirm
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
