/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { KTSVG } from "../../../../../../_start/helpers";
import { TextEditor } from "../../../../../components/TextEditor";
import { ButtonTooltip } from "../../../components/ButtonTooltip";
import { ButtonTooltipOld } from "../../../components/ButtonTooltipOld";
import { InlineEdit } from "../../../components/InlineEdit";
import { TextAreaEdit } from "../../../components/TextAreaEdit";
import {
  IProcess,
  ProcessHighlight,
  ProcessHighlightItemTypes,
} from "./ProcessHighlight";

type ProcessProps = {
  index: number;
  process: IProcess;
  processHighlight: ProcessHighlight;
  updateProcess: (idx: number, process: IProcess) => void;
  removeListItem: (itemIndex: number) => void;
  moveListItem: (dragIndex: number, hoverIndex: number) => void;
};

export const Process = ({
  index,
  process,
  processHighlight,
  updateProcess,
  removeListItem,
  moveListItem,
}: ProcessProps) => {
  const [data, setData] = useState(process);

  const update = (fieldsToUpdate: Partial<IProcess>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
    updateProcess(index, updatedData);
  };

  useEffect(() => {
    setData(process);
  }, [process]);

  const ref = useRef<HTMLLIElement>(null);
  const removeItemRef = useRef<HTMLSpanElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.remove("d-none");
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLLIElement>) => {
    removeItemRef.current?.classList.add("d-none");
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: `${ProcessHighlightItemTypes.PROCESS}-${processHighlight.id}`,
    item: () => ({ id: data.id, index: index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ isOver, canDrop, handlerId }, drop] = useDrop({
    accept: `${ProcessHighlightItemTypes.PROCESS}-${processHighlight.id}`,
    drop: (item: { id: number; index: number }) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveListItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  drop(drag(ref));

  const opacity = isDragging ? 0.3 : 1;

  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ opacity }}
      className={`list-item ${canDrop ? "can-drop-highlight" : "bg-white"} ${
        isOver ? "is-over-highlight" : ""
      }`}
    >
      {/* begin::Delete Item */}
      {processHighlight.processes.length > 1 && (
        <span
          ref={removeItemRef}
          onClick={() => removeListItem(index)}
          className="position-absolute top-0 end-0 d-none mt-n3 me-n3 opacity-75-hover"
        >
          <KTSVG
            path="/media/icons/duotone/Interface/Minus-Square.svg"
            className="svg-icon-danger svg-icon-1"
          />
        </span>
      )}
      {/* end::Delete Item */}

      {/* begin::Label */}
      <div className="item-labels">
        <ButtonTooltipOld name={`label`} value={data.label} setValue={update}>
          <a
            className="labels-item back-purple1"
            style={{
              color: "white",
            }}
          >
            <span className="labels-item__title">{data.label}</span>
          </a>
        </ButtonTooltipOld>
      </div>
      {/* end::Label */}

      {/* begin::Step */}
      <span className="item-step">{`Stap ${index + 1}`}</span>
      {/* end::Step */}

      {/* begin::Title */}
      <TextAreaEdit
        name="title"
        value={data.title}
        setValue={update}
        className="item-title"
      />
      {/* end::Title */}

      {/* begin::Description */}
      <div className="item-description">
        <TextEditor
          content={data.description}
          updateContent={(description) => update({ description })}
        />
      </div>
      {/* end::Description */}

      {/* begin::Action*/}
      <div className="item-action">
        <ButtonTooltip
          name={`button_url`}
          value={data.button_url}
          setValue={update}
          isCheckboxChecked={data.button_url_is_external}
          checkboxChanged={(v) => update({ button_url_is_external: v })}
        >
          <a>
            <InlineEdit
              className="action-text"
              name="button_title"
              value={data.button_title ?? ""}
              setValue={update}
              activeVerticalPadding={"py-0"}
              dynamicWidth
              style={{
                maxWidth: "22rem",
              }}
            />
          </a>
        </ButtonTooltip>
      </div>
      {/* end::Action*/}
    </li>
  );
};
