import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { ManageRedirectLinkPage } from './pages/ManageRedirectLinkPage';
import { RedirectLinkPage } from './pages/RedirectLinksPage';

export const RedirectLinkManagementPage = () => {
  return (
    <Switch>
      <Route exact={true} path="/redirect-link-management/links">
        <>
          <PageTitle>Redirect Link</PageTitle>
          <RedirectLinkPage />
        </>
      </Route>
      <Route exact={true} path="/redirect-link-management/links/manage-links">
        <>
          <PageTitle>Redirect Link</PageTitle>
          <ManageRedirectLinkPage />
        </>
      </Route>
      <Route
        exact={true}
        path="/redirect-link-management/links/:redirect_link_id/update"
      >
        <>
          <PageTitle>Redirect Link</PageTitle>
          {/* <UpdateCustomerPage /> */}
        </>
      </Route>

      <Redirect
        from="/redirect-link-management"
        exact={true}
        to="/redirect-link-management/links"
      />
      <Redirect to="/redirect-link-management/links" />
    </Switch>
  );
};
