import { ElementType, IElement } from "../../../../models/Element";
import { ElementHeaderType } from "../../../utils";

export interface IReviews {
  id: number;
  title: string;
  description: string;
  rating: number;
  author: string;
}

export const defaultReview1: IReviews = {
  id: 0,
  title: "Alles is gratis?",
  description:
    "Maecenas faucibus mollis inter dum. Etiam porta sem malesuada magna mollis euismod vivamus.",
  rating: 4,
  author: "Han, werkloos in leeuwarden",
};

export type HomeCustomerReviews = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  total_reviews: number;
  average_rating: number;
  image_preview: string;
  image_preview_alt: string;
  reviews: IReviews[];
};

export const defaultHomeCustomerReviews: HomeCustomerReviews = {
  id: 0,
  name: ElementType.HOME_CUSTOMER_REVIEWS,
  heading: "Klanten over Verbonden",
  total_reviews: 80,
  average_rating: 4,
  image_preview: "",
  image_preview_alt: "",
  reviews: [defaultReview1, defaultReview1],
};
