import { formatDistanceToNowStrict } from 'date-fns';
import { IMember } from '../../user-management/models/Member';
import { IQuotationActivityLog } from '../models/QuotationActivityLog';

type UserLogProps = {
  log: IQuotationActivityLog;
};

export const UserLog = ({ log }: UserLogProps) => {
  const renderLogMessage = (log: IQuotationActivityLog) => {
    if (log.old === undefined) {
        return `Send quote`
    }

    if (log.attributes.status === log.old.status) {
    	return `Assigned advisor from ${log.old["user.name"] ?? "NA"} to ${
    		log.attributes["user.name"] ?? "NA"
    	}`;
    } else {
    	return `Changed status from ${log.old.status} to ${log.attributes.status}`;
    }
    // return ``;
  };
  return (
    <div className="mt-3">
      <div
        className="d-flex align-items-center justify-content-between"
        id="modal-headline"
      >
        <span className="fs-4">{(log.attributes.causer as IMember).name}</span>
        <span className="text-gray-500 fs-6">
          {formatDistanceToNowStrict(new Date(log.attributes.updated_at), {
            addSuffix: true,
          })}
        </span>
      </div>
      <div className="text-left">
        <p className="fs-6 text-gray-500">{renderLogMessage(log)}</p>
      </div>
    </div>
  );
};
