import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import { KTSVG } from '../../../../../../_start/helpers';
import { IEmployee } from '../../../../employee-management/models/Employee';

interface Props {
  employee: IEmployee;
  index: number;
  updateEmployee: (employee: IEmployee, index: number) => void;
}

export const TeamEmployeesItemWrapper: React.FC<Props> = ({
  employee,
  index,
  updateEmployee,
  children,
}) => {
  const allEmployees = useSelector(
    (state: RootState) => state.editContent.allEmployees
  );

  const [employees, setEmployees] = useState<Array<IEmployee>>([]);

  useEffect(() => {
    if (allEmployees) {
      setEmployees(allEmployees);
    }
  }, [allEmployees]);

  const [active, setActive] = useState(false);

  const sectionRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (active) {
      sectionRef.current?.focus();
    }
  }, [active]);

  const onBlur = (event: React.FocusEvent<HTMLLIElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setActive(false);
    }
  };

  const handleSelect = (item: IEmployee) => {
    updateEmployee(item, index);
    setActive(false);
  };

  const handleLocalSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value.toLowerCase();

    const filtered_items = allEmployees.filter((employee) => {
      const employeeName = employee.name.toLowerCase();
      return employeeName.indexOf(keyword) > -1;
    });

    setEmployees(filtered_items);
  };

  const handleActivate = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();

    let dataValue = (event.target as HTMLElement).getAttribute('data-value');

    if (dataValue !== 'child-li') {
      setActive(true);
    }
  };
  return (
    <div data-value="parent-div" onClick={handleActivate} className="employee">
      {/* begin::Select Case Detail with Search */}
      {active && (
        <section
          ref={sectionRef}
          onBlur={onBlur}
          tabIndex={1}
          className="position-absolute start-0 end-0 z-index-2 other-case-list"
          style={{
            // marginTop: `${-17}rem`,
            top: '50%',
            transform: 'translate(0, -50%)',
          }}
        >
          <div className="d-flex flex-column align-items-center">
            {/* begin::Case Page List*/}
            <div
              className="rounded"
              style={{
                background: 'white',
              }}
            >
              {/* begin::Search */}
              <div className="position-relative px-3 pt-3">
                <span
                  className="position-absolute"
                  style={{
                    top: '60%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotone/General/Search.svg"
                    className="ps-1 svg-icon-1 svg-icon-primary"
                  />
                </span>
                <input
                  onChange={handleLocalSearch}
                  className="ps-10 form-control form-control-solid"
                  type="text"
                  placeholder="Search"
                />
              </div>
              {/* end::Search */}

              <div className="separator mt-2"></div>

              {/* begin::OtherCases */}
              <ul
                className="my-1 me-2 p-1 list-unstyled overflow-auto select-other-case"
                style={{
                  maxHeight: '128px',
                }}
              >
                {employees.map((employeeItem, idx) => (
                  <li
                    key={idx}
                    data-value="child-li"
                    onClick={() => handleSelect(employeeItem)}
                    className={`mb-1 px-2 py-1 ${
                      employeeItem.id === employee.id ? 'bg-success' : ''
                    }`}
                  >
                    {employeeItem.name}
                  </li>
                ))}
                {employees.length === 0 && (
                  <div className="mb-1 px-2 py-1 text-center text-gray-500">
                    No data available
                  </div>
                )}
              </ul>
              {/* end::OtherCases */}

              <div className="separator mb-2"></div>
            </div>
            {/* end::Case Page List*/}
            {/* begin::ArrowDown Element*/}
            <div
              style={{
                width: '0',
                height: '0',
                borderLeft: '12px solid transparent',
                borderRight: '12px solid transparent',
                borderTop: '12px solid white',
              }}
            ></div>
            {/* end::ArrowDown Element*/}
          </div>
        </section>
      )}
      {/* end::Select Case Detail with Search */}

      {children}
    </div>
  );
};
