export interface IVoipGrid {
	quotation_id: number;
	name: string;
	kvk_number: string;
	anonymize_after: string;
	phone_number: string;
	proposition: string;
	blocked_call_permissions: string;
	email: string;
	country: string;
	language: string;
	address_country: string;
	address_street: string;
	address_number: string;
	address_complement: string;
	address_zipcode: string;
	address_city: string;
	admin_user_first_name: string;
	admin_user_preposition: string;
	admin_user_last_name: string;
	admin_user_email_address: string;
	billing_iban: string;
	billing_account_holder: string;
	billing_vat_number: string;
	billing_email_address: string;
	billing_pay_available: string;
	billing_invoice_characteristic: string;
}

export const defaultVoipGrid: IVoipGrid = {
	quotation_id: 0,
	name: "",
	kvk_number: "",
	anonymize_after: "3",
	phone_number: "",
	proposition: "fup_500",
	blocked_call_permissions: "AMUSEMENT",
	email: "",
	country: "nl",
	language: "nl",
	address_country: "nl",
	address_street: "",
	address_number: "",
	address_complement: "",
	address_zipcode: "",
	address_city: "",
	admin_user_first_name: "Henk",
	admin_user_preposition: "de",
	admin_user_last_name: "Tester",
	admin_user_email_address: "dwiff.baconguis@gmail.com",
	billing_iban: "",
	billing_account_holder: "",
	billing_vat_number: "",
	billing_email_address: "",
	billing_pay_available: "",
	billing_invoice_characteristic: "",
};
