import { IPost } from "../../post-management/models/Post";
import { defaultDepartment, IDepartment } from "./Department";

export interface IEmployee {
	id: number;
	name: string;
	profile_image: string;
	gif: string;
	department_id: number;
	department: IDepartment;
	posts: Array<IPost>;
}

export const defaultEmployee: IEmployee = {
	id: 0,
	name: "",
	profile_image: "",
	gif: "",
	department_id: 0,
	department: defaultDepartment,
	posts: [],
};

export const defaultAuthorPlaceholder: IEmployee = {
	...defaultEmployee,
	name: "John Doe",
	department: { ...defaultDepartment, name: "Department" },
};
