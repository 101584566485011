import React from 'react';
import { ButtonTooltip } from '../../../../components/ButtonTooltip';
import { InlineEdit } from '../../../../components/InlineEdit';
import {
  ButtonTitle,
  HomeHeroWideButtonsElement,
} from './HomeHeroWideButtonsElement';

interface Props {
  data: HomeHeroWideButtonsElement;
  update: (to: Partial<HomeHeroWideButtonsElement>) => void;
}

export const HomeHeroWideThreeIconsItems: React.FC<Props> = ({
  data,
  update,
}) => {
  // const updateData = (to: Partial<HomeHeroWideButtonsElement>, idx: number) => {
  //   const titles = [...data.button_titles];
  //   titles[idx] = { ...titles[idx], ...to };
  //   update({ ...data, button_titles: titles });
  // };
  const updateButton = (to: Partial<ButtonTitle>, index: number) => {
    const button_titles = [...data.button_titles];
    button_titles[index] = { ...button_titles[index], ...to };
    update({ button_titles });
  };
  return (
    <div className="hero__usps">
      {data.button_titles.map((title, idx) => (
        <div className="hero__usp">
          {/* begin::Action Button */}
          <div>
            <ButtonTooltip
              name={`url`}
              value={title.url}
              setValue={(val) => updateButton(val, idx)}
              isCheckboxChecked={title.url_is_external}
              checkboxChanged={(v) => updateButton({ url_is_external: v }, idx)}
            >
              <div className="btn__holder">
                <svg
                  className="icon"
                  data-src="assets/icons/icon-arrow.svg"
                  focusable="false"
                  aria-hidden="true"
                  data-cache="604800"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 18"
                  xmlSpace="preserve"
                  data-id="svg-loader_37"
                  style={{ width: '20px', height: '20px' }}
                >
                  <path d="M1.8 8.4c.4.4 1.1.4 1.6 0l4.4-4.5v13c.1.6.6 1.1 1.2 1.1s1.1-.5 1.1-1.1v-13l4.4 4.5c.4.4 1.1.4 1.6 0 .4-.4.4-1.2 0-1.6L9.8.3C9.7.2 9.6.1 9.4.1c-.2-.1-.6-.1-.8 0-.2 0-.3.1-.4.2L1.8 6.7c-.4.5-.4 1.2 0 1.7z"></path>
                </svg>
                <InlineEdit
                  name="title"
                  className="px-1 text-white text-center text-primary-color"
                  value={title.title}
                  setValue={(val) => updateButton(val, idx)}
                  activeVerticalPadding={'py-1'}
                  dynamicWidth
                  style={{
                    maxWidth: '180px',
                  }}
                />
              </div>
            </ButtonTooltip>
            {/* end::Action Button */}
          </div>
        </div>
      ))}
    </div>
  );
};
