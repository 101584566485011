/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { ElementWrapper } from "../../../components/ElementWrapper";
import { IElement } from "../../../models/Element";

import { FAQ2, getAllFAQs } from "./FAQ2";
import { FAQ2Content } from "./FAQ2Content";
import { FAQ2List } from "./FAQ2List";

interface FAQProps {
  index: number;
  data: FAQ2;
  updateData: (body: IElement, index: number) => void;
}

export const FAQ2FC: React.FC<FAQProps> = ({ index, data, updateData }) => {
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const fetchedData = await getAllFAQs();
    const updatedData: FAQ2 = { ...data, item_list: fetchedData };
    updateData(updatedData, index);
  };

  const update = (fieldsToUpdate: Partial<FAQ2>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);
  };
  return (
    <ElementWrapper elementIndex={index}>
      <div className="specializations-faq body-my-8">
        <div className="specializations-grid">
          <div className="grid__inner">
            <FAQ2Content data={data} update={update} />
            <FAQ2List data={data} update={update} />
          </div>
        </div>
      </div>
    </ElementWrapper>
  );
};
