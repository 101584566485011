/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";

import { QuestionItem } from "./FAQ2";

interface FAQListItemProps {
  index: number;
  data: QuestionItem;
  remove: (index: number) => void;
  update: (fieldsToUpdate: Partial<QuestionItem>, index: number) => void;
}

export const FAQ2ListItem: React.FC<FAQListItemProps> = ({
  index,
  data,
  update,
  remove,
}) => {
  const [active, setActive] = useState(false);
  // const updateData = (toUpdate: Partial<QuestionItem>) => {
  //   update(toUpdate, index);
  // };

  const removeButtonRef = useRef<HTMLButtonElement>(null);

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.remove("d-none");
    // addItemRef.current?.classList.remove('d-none');

    if (event.currentTarget === event.target) {
      event.stopPropagation();
    }

    event.currentTarget.style.cursor = "pointer";
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    removeButtonRef.current?.classList.add("d-none");
    // addItemRef.current?.classList.add('d-none');
    event.currentTarget.style.cursor = "auto";
  };
  return (
    <div
      className="accordion__item"
      id={`faq_accordion_${index}`}
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
      data-element="accordion"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <a
        className={`accordion__toggle ${active && "accordion__active"}`}
        onClick={() => setActive(!active)}
      >
        {/* begin::Remove Button */}
        {/* <RemoveElementButton
          index={index}
          remove={remove}
          buttonRef={removeButtonRef}
        /> */}
        {/* end::Body Remove Button */}
        <div className="accordion__text">{data.title}</div>

        <span className="accordion__holder u-flex u-align-center u-justify-center">
          <svg
            className="accordion__icon icon-14 rotate-180"
            data-src="assets/icons/icon-angle.svg"
            aria-hidden="true"
            focusable="false"
            data-cache="disabled"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            xmlSpace="preserve"
            data-id="svg-loader_36"
          >
            <path d="M16 11.5c0 .3-.1.5-.3.7-.4.4-1 .4-1.4 0L8 5.9l-6.3 6.3c-.4.4-1 .4-1.4 0s-.4-1 0-1.4l7-7c.4-.4 1-.4 1.4 0l7 7c.2.2.3.4.3.7z"></path>
          </svg>
        </span>
      </a>
      <div
        className="accordion__inner"
        style={{ display: !active ? "none" : "block" }}
      >
        <p>
          {data.title_description && (
            <div className="accordion__heading mb-8">
              {data.title_description}
            </div>
          )}
        </p>

        <div className="accordion__description mb-8">{data.description}</div>

        {data.url && data.slug && (
          <div className="btn btn-success rounded-pill">
            <div className="px-1 text-white text-center">{data.slug}</div>
          </div>
        )}
      </div>
    </div>
  );
};
