import React from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { ButtonTooltip } from '../../../../components/ButtonTooltip';
import { HeaderSelectWrapper } from '../../../../components/HeaderSelectWrapper';
import { InlineEdit } from '../../../../components/InlineEdit';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import { ElementHeaderType } from '../../../utils';
import { HomeImageLeftDescriptionElement } from './HomeImageLeftDescriptionElement';

interface HomeImageLeftDescriptionContentProps {
  data: HomeImageLeftDescriptionElement;
  update: (fieldsToUpdate: Partial<HomeImageLeftDescriptionElement>) => void;
}

export const HomeImageLeftDescriptionContent: React.FC<
  HomeImageLeftDescriptionContentProps
> = ({ data, update }) => {
  return (
    <div className="showcase__content">
      {/*begin::Heading*/}
      <HeaderSelectWrapper
        name={`heading_type`}
        value={data.heading_type ?? ElementHeaderType.H2}
        setValue={update}
      >
        <TextAreaEdit
          name="heading"
          className="header-title_input"
          value={data.heading}
          setValue={update}
        />
      </HeaderSelectWrapper>
      {/*end::Heading*/}

      {/*begin::Description*/}
      <div className="description_input prose">
        <TextEditor
          content={data.description}
          updateContent={(description) => update({ description })}
        />
      </div>
      {/*end::Description*/}

      {/* begin::Action Button */}
      <div className="content-button">
        <ButtonTooltip
          name={`button_url`}
          value={data.button_url}
          setValue={update}
          isCheckboxChecked={data.button_url_is_external}
          checkboxChanged={(v) => update({ button_url_is_external: v })}
        >
          <button className="btn rounded-pill content-button__inner">
            <InlineEdit
              name="button_title"
              className="px-1 text-white text-center text-primary-color"
              value={data.button_title}
              setValue={update}
              activeVerticalPadding={'py-1'}
              dynamicWidth
              style={{
                maxWidth: '180px',
              }}
            />
          </button>
        </ButtonTooltip>
        {/* end::Action Button */}
      </div>
    </div>
  );
};
