export enum RedirectLinkEvents {
  CREATE_REDIRECT_LINK_REQUESTED = '[Request Create Redirect Link] Action',
  CREATE_REDIRECT_LINK_LOADED = '[Load Created Redirect Link] API',
  CREATE_REDIRECT_LINK_FAILED = '[Create Redirect Link Failed] API',

  MANAGE_REDIRECT_LINK_REQUESTED = '[Request Manage Redirect Link] Action',
  MANAGE_REDIRECT_LINK_LOADED = '[Load Managed Redirect Link] API',
  MANAGE_REDIRECT_LINK_FAILED = '[Manage Redirect Link Failed] API',

  VIEW_REDIRECT_LINK_REQUESTED = '[Request View Redirect Link] Action',
  VIEW_REDIRECT_LINK_LOADED = '[Load Viewed Redirect Link] API',
  VIEW_REDIRECT_LINK_FAILED = '[View Redirect Link Failed] API',

  UPDATE_REDIRECT_LINK_REQUESTED = '[Request Update Redirect Link] Action',
  UPDATE_REDIRECT_LINK_LOADED = '[Load Updated Redirect Link] API',
  UPDATE_REDIRECT_LINK_FAILED = '[Update Redirect Link Failed] API',

  DELETE_REDIRECT_LINK_REQUESTED = '[Request Delete Redirect Link] Action',
  DELETE_REDIRECT_LINK_LOADED = '[Load Deleted Redirect Link] API',
  DELETE_REDIRECT_LINK_FAILED = '[Delete Redirect Link Failed] API',

  LIST_REDIRECT_LINK_REQUESTED = '[Request List Redirect Link] Action',
  LIST_REDIRECT_LINK_LOADED = '[Load List Redirect Link] API',
  LIST_REDIRECT_LINK_FAILED = '[List Redirect Link Failed] API',

  RESET_CREATE_REDIRECT_LINK = '[Reset Create Redirect Link] Action',

  SEARCH_REDIRECT_LINK_REQUESTED = '[Request Search Redirect Link] Action',
  SEARCH_REDIRECT_LINK_LOADED = '[Load Search Redirect Link] API',
  SEARCH_REDIRECT_LINK_FAILED = '[Search Redirect Link Failed] API',
}
