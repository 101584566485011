export interface IBankAccount {
	id: string;
	customer_id: number;
	iban: string;
	account_holder: string;
}

export const defaultBankAccount: IBankAccount = {
	id: "",
	customer_id: 0,
	iban: "",
	account_holder: "",
};

export interface ICustomer {
	id: number;

	firstname: string;
	lastname: string | null;
	email: string;
	phone_number: string;
	address: string;
	house_number: string;
	postal_code:string;
	city: string;

	company_name: string;
	company_email: string;
	chamber_of_commerce_number: string;

	payment_type: string;

	remarks: string;
	signature: string;

	bank_account?: IBankAccount
}

export const defaultCustomer: ICustomer = {
	id: 0,

	firstname: "",
	lastname: "",
	email: "",
	phone_number: "",
	address: "",
	house_number: "",
	postal_code: "",
	city: "",
	
	company_name: "",
	company_email: "",
	chamber_of_commerce_number: "",

	payment_type: "",

	remarks: "",
	signature: "",
};
