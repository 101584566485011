export default function setupAxios(axios: any, store: any) {
	axios.interceptors.request.use(
		(config: any) => {
			const {
				auth: { accessToken },
			} = store.getState();

			if (accessToken) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			}

			return config;
		},
		(err: any) => Promise.reject(err)
	);

	axios.interceptors.response.use(
		(response: any) => {
			return response;
		},
		(error: any) => {
			if (401 === error.response.status) {
				window.location.replace("/logout");
			} else {
				return Promise.reject(error);
			}
		}
	);
}
