import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export type HomeShowcaseBoxHighlightRight = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  image_preview: string;
  image_preview_alt: string;
};

export const defaultHomeShowcaseBoxHighlightRight: HomeShowcaseBoxHighlightRight =
  {
    id: 0,
    name: ElementType.HOME_SHOWCASE_BOX_HIGHLIGHT_RIGHT,
    heading: 'VoIP telefonie zonder gedoe',
    description:
      'Verbonden is hard op weg om één van de grootste telecomproviders te worden als het gaat om VoIP telefonie. Onze visie is om klanten telefonie te geven zonder gedoe.',
    button_title: 'Vertel meer!',
    button_url: '',
    button_url_is_external: false,
    image_preview: '',
    image_preview_alt: '',
  };
