/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../setup';
import {
  defaultPackageDialog,
  IPackage,
} from '../../../../content-management/models/Package';
import { ButtonTooltipOld } from '../../../components/ButtonTooltipOld';
import { ElementWrapper } from '../../../components/ElementWrapper';
import { InlineEdit } from '../../../components/InlineEdit';
import { StaticWidthInlineEdit } from '../../../components/StaticWidthInlineEdit';
import { IElement } from '../../../models/Element';
import { FormPackage } from './FormPackage';
import { SelectPackageWrapper } from './SelectPackageWrapper';

import immutabilityHelper from 'immutability-helper';
import { setFeaturedPackages } from '../../../redux/EditContentActions';
import { PackageDialog } from './PackageDialog';
import FormPackageItem from './FormPackageItem';

type FormPackageFCProps = {
  index: number;
  data: FormPackage;
  updateData: (element: IElement, index: number) => void;
};

export const FormPackageFC = ({
  index,
  data,
  updateData,
}: FormPackageFCProps) => {
  const dispatch = useDispatch();

  const update = (fieldsToUpdate: Partial<FormPackage>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    updateData(updatedData, index);

    dispatch(setFeaturedPackages(updatedData.featured_packages));
  };

  const allPackages = useSelector(
    (state: RootState) => state.editContent.allPackages
  );

  const featuredPackages = useSelector(
    (state: RootState) => state.editContent.featuredPackages
  );

  const [selectedPackagesMap, setSelectedPackagesMap] = useState<
    Map<number, IPackage>
  >(new Map());

  useEffect(() => {
    if (allPackages.length) {
      const iterable: Array<[number, IPackage]> = featuredPackages.map(
        (element) => [element.id, element]
      );

      setSelectedPackagesMap(new Map(iterable));
    }
  }, [allPackages, featuredPackages]);

  const addPackage = (selected: IPackage) => {
    const items = immutabilityHelper(data.featured_packages, {
      $push: [{ ...selected, dialog: defaultPackageDialog }],
    });

    update({ featured_packages: items });
  };

  const removePackage = (itemIndex: number) => {
    let items = immutabilityHelper(data.featured_packages, {
      $splice: [[itemIndex, 1]],
    });

    update({ featured_packages: items });
  };

  const updatePackage = (itemIndex: number, selected: IPackage) => {
    let items = [...data.featured_packages];

    items[itemIndex] = selected;

    update({ featured_packages: items });
  };

  return (
    <ElementWrapper elementIndex={index}>
      <div className="form-package">
        <div className="form-package__heading m40b">
          <StaticWidthInlineEdit
            className="w-100 heading h-large s-bold t-fancy m15b t-sm-center-down"
            name={`heading`}
            value={data.heading}
            setValue={update}
          />
          <StaticWidthInlineEdit
            className="w-100"
            name={`label`}
            value={data.label}
            setValue={update}
          />
        </div>

        <div className="form-package__packages m40b">
          {data.featured_packages.map((element, idx) => (
            <SelectPackageWrapper
              key={idx}
              actionType={`update`}
              itemIndex={idx}
              allModels={allPackages}
              addSelected={addPackage}
              updateSelected={updatePackage}
              selectedMap={selectedPackagesMap}
            >
              <div className="featured-item position-relative">
                {/* begin::Dialog Item */}
                <PackageDialog
                  itemIndex={idx}
                  element={element}
                  updatePackage={updatePackage}
                />
                {/* end::Dialog Item */}

                <FormPackageItem
                  formPackage={element}
                  formPackageIndex={idx}
                  updatePackage={updatePackage}
                  removePackage={removePackage}
                 />
              </div>
            </SelectPackageWrapper>
          ))}
          <SelectPackageWrapper
            actionType={`add`}
            itemIndex={0}
            allModels={allPackages}
            addSelected={addPackage}
            updateSelected={updatePackage}
            selectedMap={selectedPackagesMap}
          >
            <div className="add-item"></div>
          </SelectPackageWrapper>
        </div>

        <div className="form-package__action">
          <ButtonTooltipOld
            name={`button_url`}
            className="heading-action"
            value={data.button_url}
            setValue={update}
          >
            <a>
              <InlineEdit
                className="action-text"
                name="button_title"
                value={data.button_title}
                setValue={update}
                activeVerticalPadding={'py-0'}
                dynamicWidth
                style={{
                  maxWidth: '22rem',
                }}
              />
            </a>
          </ButtonTooltipOld>
        </div>
      </div>
    </ElementWrapper>
  );
};
