/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { failureToast, successToast } from "../../../../support/utils";
import { updateRole, viewRole } from "../../redux/RoleCRUD";
import { IPermission } from "../permissions/Permission";

import { defaultRole, IRole } from "./Role";

export const UpdateRole = () => {
  const history = useHistory();

  const { role_id } = useParams<{ role_id: string }>();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IRole>(defaultRole);

  const updateData = (fieldsToUpdate: Partial<IRole>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleFetch = async (id: number | string) => {
    setLoading(true);
    try {
      const result = await viewRole(id);
      setData({
        ...data,
        ...result,
      });
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch(role_id);
  }, [role_id]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      const permissions: Array<number> = [];
      data.permissions?.forEach((permission) => {
        permissions.push((permission as IPermission).id);
      });
      await updateRole({ ...data, permissions });
      successToast("Role has been updated.");
      setLoading(false);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update Role</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Name</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  readOnly
                  placeholder="Enter name"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.name}
                  onChange={(e) => updateData({ name: e.target.value })}
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.description}
                  onChange={(e) => updateData({ description: e.target.value })}
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to="/role-and-permission/roles"
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
