import { defaultCategory, ICategory } from './Category';

export enum ProductPriceType {
  ONCE = 'once',
  MONTHLY = 'monthly',
  BOTH = 'both',
}

export interface IProduct {
  id: number;
  name: string;
  description: string;
  price_type: ProductPriceType;
  show: number;
  discount_code: string;
  volume_discount: boolean;
  quantity: number | string;
  one_time_payment: string;
  monthly_payment: string;

  category_id: number;
  category: ICategory;
  is_bulk_purchase: boolean;
}

export const defaultProduct: IProduct = {
  id: 0,
  name: '',
  description: '',
  price_type: ProductPriceType.ONCE,
  show: 1,
  discount_code: '',
  volume_discount: false,
  quantity: 0,
  one_time_payment: '',
  monthly_payment: '',

  category_id: 0,
  category: defaultCategory,
  is_bulk_purchase: true,
};
