import axios from 'axios';
import { API_URL } from '../../../support/api';
import { PaginatedValue } from '../../../support/utils';
import { ICookieBanner } from '../models/CookieBanner';

export const BASE_COOKIE_BANNER_URL = `${API_URL}/v1/layout/cookie-banner`;
export const GET_ALL_COOKIE_BANNER_URL = `${API_URL}/v1/layout/cookie-banner/get-all`;
export const CREATE_COOKIE_BANNER_URL = `${API_URL}/v1/layout/cookie-banner`;

export const listCookieBanner = async (search = '', direction = 'asc') => {
  const URL = `${BASE_COOKIE_BANNER_URL}/list?search=${search}&direction=${direction}`;
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<ICookieBanner> }>(URL);

  return value;
};

export const createCookieBanner = async (payload: ICookieBanner) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICookieBanner }>(
    CREATE_COOKIE_BANNER_URL,
    payload
  );

  return value;
};

export const viewCookieBanner = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: ICookieBanner }>(
    `${BASE_COOKIE_BANNER_URL}/${id}/view`
  );

  return value;
};

export const updateCookieBanner = async (payload: ICookieBanner) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICookieBanner }>(
    `${BASE_COOKIE_BANNER_URL}/${payload.id}/update`,
    payload
  );

  return value;
};

export const deleteCookieBanner = async (payload: ICookieBanner) => {
  const {
    data: { value },
  } = await axios.post<{ value: ICookieBanner }>(
    `${BASE_COOKIE_BANNER_URL}/${payload.id}/delete`,
    payload
  );

  return value;
};

// unpaginated categories
export const getAllCookieBanner = async () => {
  const {
    data: { value },
  } = await axios.get<{ value: Array<ICookieBanner> }>(
    GET_ALL_COOKIE_BANNER_URL
  );

  return value;
};
