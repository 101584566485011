import React from 'react';
import { HomeThreeIconsHeadingsAndDescriptionItem } from './HomeThreeIconsHeadingsAndDescriptionItem';
import {
  HeadingsAndDescriptionsItem,
  HomeThreeIconsHeadingsAndDescriptionsElement,
} from './HomeThreeIconsHeadingsAndDescriptionsElement';

interface Props {
  data: HomeThreeIconsHeadingsAndDescriptionsElement;
  update: (
    fieldsToUpdate: Partial<HomeThreeIconsHeadingsAndDescriptionsElement>
  ) => void;
}

export const HomeThreeIconsHeadingsAndDescriptionContent: React.FC<Props> = ({
  data,
  update,
}) => {
  const updateItem = (
    toUpdate: Partial<HeadingsAndDescriptionsItem>,
    idx: number
  ) => {
    const item_list = [...data.item_list];
    item_list[idx] = { ...item_list[idx], ...toUpdate };
    update({ ...data, item_list });
  };
  return (
    <div className="usp">
      {data.item_list.map((item, idx) => (
        <HomeThreeIconsHeadingsAndDescriptionItem
          key={idx}
          index={idx}
          data={data}
          item={item}
          update={updateItem}
        />
      ))}
    </div>
  );
};
