import React from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../../../../_start/helpers";
import { HomeCustomerReviews } from "./HomeCustomerReviews";

interface Props {
  data: HomeCustomerReviews;
  update: (to: Partial<HomeCustomerReviews>) => void;
}

const arrow_prev = `<svg class="flickity-button-icon" viewBox="0 0 100 100" aria-hidden="true"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"></path></svg>`;
const arrow_next = `<svg class="flickity-button-icon" viewBox="0 0 100 100" aria-hidden="true"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg>`;

export const HomeCustomerReviewsList: React.FC<Props> = ({ data, update }) => {
  return (
    <div className="reviews__slider">
      <div className="viewport">
        <div className="slider">
          <div className="slide">
            <div className="rating">
              <div className="rating__stars">
                {[...Array(5)].map((v, i) => (
                  <KTSVG
                    key={i}
                    path={toAbsoluteUrl("/assets/icons/icon-star-solid.svg")}
                    svgClassName="icon"
                  />
                ))}
              </div>
            </div>

            <div className="description">
              {"Review Messages will show here....."}
            </div>
            <div className="author">{" Author of the Review "}</div>
          </div>
        </div>
      </div>
      <div className="button__prev">
        <KTSVG path={arrow_prev} svgClassName="icon" />
      </div>
      <div className="button__next">
        <KTSVG path={arrow_next} svgClassName="icon" />
      </div>
      <div className="page-dots">
        {data.reviews.map((v, i) => (
          <div key={i} className="dot"></div>
        ))}
      </div>
    </div>
  );
};
