import { ElementType, IElement } from '../../../models/Element';

export interface ColumnContent {
  heading: string;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
}

export const defaultColumnContent: ColumnContent = {
  heading: 'Dagelijk opzegbaar.',
  description: 'Onze diensten kan je altijd per dag aanvragen maar ook stopzetten.',
  button_title: 'Meer informatie',
  button_url: '',
  button_url_is_external: false,
};

export type FourColumnContent = IElement & {
  contents: ColumnContent[];
};

export const defaultFourColumnContent: FourColumnContent = {
  id: 0,
  name: ElementType.FOUR_COLUMN_CONTENT,
  contents: [],
};
