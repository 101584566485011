import { ElementType, IElement } from '../../../models/Element';

export type FormContactWithRemark = IElement & {
  title: string;

  heading_primary: string;

  name_label: string;
  name_placeholder: string;

  email_label: string;
  email_placeholder: string;

  subject_label: string;
  subject_placeholder: string;

  heading_secondary: string;

  message_label: string;
  message_placeholder: string;

  button_title: string;

  success_message?: string;
};

export const defaultFormContactWithRemark: FormContactWithRemark = {
  id: 0,
  name: ElementType.FORM_CONTACT_WITH_REMARK,

  title: 'Of gebruik ons formulier',

  heading_primary:'Contactgegevens',

  name_label: 'Voornaam',
  name_placeholder: 'Enter name',

  email_label: 'Emailadres',
  email_placeholder: 'Enter email',

  subject_label: 'Onderwerp',
  subject_placeholder: 'Enter subject',

  heading_secondary: 'Opmerking',

  message_label: 'Vragen, opmerking of bijzonderheden?',
  message_placeholder: 'Enter message',

  button_title: 'Verstuur',
};
