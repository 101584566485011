import axios from "axios";
import { API_URL } from "../../../support/api";
import { PaginatedValue } from "../../../support/utils";
import { IRole } from "../pages/roles/Role";

export const listRoles = async (search = "") => {
  const {
    data: { value },
  } = await axios.get<{ value: PaginatedValue<IRole> }>(
    `${API_URL}/v1/role-and-permission/roles?search=${search}`
  );

  return value;
};

export const viewRole = async (id: number | string) => {
  const {
    data: { value },
  } = await axios.get<{ value: IRole }>(
    `${API_URL}/v1/role-and-permission/roles/${id}/view`
  );

  return value;
};

export const updateRole = async (payload: IRole) => {
  const {
    data: { value },
  } = await axios.post<{ value: IRole }>(
    `${API_URL}/v1/role-and-permission/roles/${payload.id}/update`,
    payload
  );

  return value;
};
