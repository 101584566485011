import { Redirect, Route, Switch } from 'react-router-dom';
import { PageTitle } from '../../../_start/layout/core';
import { ClientsPage } from './pages/client/ClientsPage';
import { CreateClientPage } from './pages/client/CreateClientPage';
import { UpdateClientPage } from './pages/client/UpdateClientPage';
import { CreatePartnerPage } from './pages/partner/CreatePartnerPage';
import { PartnersPage } from './pages/partner/PartnersPage';
import { UpdatePartnerPage } from './pages/partner/UpdatePartnerPage';

export const ClientManagementPage = () => {
  return (
    <Switch>
      <Route exact={true} path="/client-management/clients">
        <>
          <PageTitle>Customers</PageTitle>
          <ClientsPage />
        </>
      </Route>
      <Route exact={true} path="/client-management/clients/create">
        <>
          <PageTitle>Customers</PageTitle>
          <CreateClientPage />
        </>
      </Route>
      <Route exact={true} path="/client-management/clients/:id/update">
        <>
          <PageTitle>Customers</PageTitle>
          <UpdateClientPage />
        </>
      </Route>

      <Route exact={true} path="/client-management/partners">
        <>
          <PageTitle>Partners</PageTitle>
          <PartnersPage />
        </>
      </Route>
      <Route exact={true} path="/client-management/partners/create">
        <>
          <PageTitle>Partners</PageTitle>
          <CreatePartnerPage />
        </>
      </Route>
      <Route exact={true} path="/client-management/partners/:id/update">
        <>
          <PageTitle>Partners</PageTitle>
          <UpdatePartnerPage />
        </>
      </Route>

      <Redirect
        from="/client-management"
        exact={true}
        to="/client-management/customers"
      />
      <Redirect to="/client-management/customers" />
    </Switch>
  );
};
