import React from 'react';
import { toAbsoluteUrl } from '../../../../../../../_start/helpers';

import { HomeImageTwoRightDescriptionFloatingWindowElement } from './HomeImageTwoRightDescriptionFloatingWindowElement';
import { HomeImageTwoRightDescriptionFloatingWindowFloatingComponent } from './HomeImageTwoRightDescriptionFloatingWindowFloatingComponent';

interface Props {
  data: HomeImageTwoRightDescriptionFloatingWindowElement;
  update: (
    fieldsToUpdate: Partial<HomeImageTwoRightDescriptionFloatingWindowElement>
  ) => void;
}

export const HomeImageTwoRightDescriptionFloatingWindowFigure: React.FC<
  Props
> = ({ data, update }) => {
  return (
    <div className="showcase__figure">
      {/* begin::Floating Text */}
      <HomeImageTwoRightDescriptionFloatingWindowFloatingComponent
        data={data}
        update={update}
      />
      {/* end::Floating Text */}
      {/* begin::Content Image Preview */}
      <div className="content-image">
        {data.image_preview ? (
          <div className="overlay">
            <div className="overlay-wrapper">
              <img
                alt="dummy"
                src={data.image_preview}
                // style={{
                //   width: "100%",
                //   height: "100%",
                //   objectFit: "cover",
                // }}
              />
            </div>
            <div className="overlay-layer bg-dark bg-opacity-10 flex-column">
              <div>
                <button
                  name="image_preview"
                  className="btn btn-primary btn-shadow"
                  onClick={() =>
                    update({
                      image_preview: '',
                      image_preview_alt: '',
                    })
                  }
                >
                  Remove
                </button>
                <label
                  className="btn btn-light-primary btn-shadow ms-2"
                  data-bs-toggle="modal"
                  data-bs-target={`#${data.name}-${data.id}`}
                >
                  Change
                </label>
              </div>

              <input
                placeholder="Alt attribute"
                className="w-50 mt-2 form-control form-control-lg form-control-solid"
                type="text"
                value={data.image_preview_alt ?? ''}
                onChange={(event) =>
                  update({
                    image_preview_alt: event.target.value,
                  })
                }
              />
            </div>
          </div>
        ) : (
          <>
            <label
              data-bs-toggle="modal"
              data-bs-target={`#${data.name}-${data.id}`}
            >
              <img src={toAbsoluteUrl('/assets/images/606x627.png')} alt="" />
            </label>
          </>
        )}
      </div>
      {/* end::Content Image Preview */}
    </div>
  );
};
