import { ElementType, IElement } from '../../../../models/Element';
import { ElementHeaderType } from '../../../utils';

export type HomeImageLeftDescriptionBlueElement = IElement & {
  heading: string;
  heading_type?: ElementHeaderType;
  description: string;
  button_title: string;
  button_url: string;
  button_url_is_external: boolean;
  image_preview: string;
  image_preview_alt: string;
};

export const defaultHomeImageLeftDescriptionBlueElement: HomeImageLeftDescriptionBlueElement =
  {
    id: 0,
    name: ElementType.HOME_IMAGE_LEFT_DESCRIPTION_BLUE,
    heading: 'De vrijheid om te doen wat jij wil.',
    description:
      'Productprijzen worden automatisch berekend op basis van de gewenste huurperiode daarnaast kunnen producten online verlengd worden.',
    button_title: 'Vertel meer!',
    button_url: '',
    button_url_is_external: false,
    image_preview: '',
    image_preview_alt: '',
  };
