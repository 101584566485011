/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ActionsDropdown } from '../../../../components/ActionsDropdown';
import { DeleteModal } from '../../../../components/modals/DeleteModal';
import { DuplicateModal } from '../../../../components/modals/DuplicateModal';
import HeaderCell from '../../../../components/table/HeaderCell';
import { POST_URL, WEB_URL } from '../../../../support/api';
import { failureToast, successToast } from '../../../../support/utils';
import { IPost } from '../../models/Post';

export type PostsTableProps = {
  data: Array<IPost>;
  sort: string;
  handleSort: (attr: string) => void;
  selected?: IPost;
  setSelected: (item: IPost) => void;
  handleDelete: (item: IPost) => void;
  setIsPending: (value: boolean) => void;
  handleFetchList: () => void;
};

export const duplicate = async (url: string) => {
  const {
    data: { value },
  } = await axios.post<{ value: IPost }>(url);

  return value;
};

const PostsTable = ({
  data,
  sort,
  handleSort,
  selected,
  setSelected,
  handleDelete,
  setIsPending,
  handleFetchList,
}: PostsTableProps) => {
  const handleDuplicate = async (item: IPost) => {
    setIsPending(true);
    try {
      const URL = `${POST_URL}/${item.id}/duplicate`;
      await duplicate(URL);
      successToast('Item has been duplicated.');
      setIsPending(false);
      handleFetchList();
    } catch (error) {
      failureToast(error);
      setIsPending(false);
    }
  };

  return (
    <>
      {/* begin::Modal */}
      <DuplicateModal item={selected} handleDuplicate={handleDuplicate}>
        <div>
          <p>{`Are you sure you want to duplicate this item?`}</p>
          <p className="block fw-bolder">{selected?.title}</p>
        </div>
      </DuplicateModal>

      <DeleteModal item={selected} handleDelete={handleDelete}>
        <div>
          <p>{`Are you sure you want to delete this item? This action is irreversible.`}</p>
          <p className="block fw-bolder">{selected?.title}</p>
        </div>
      </DeleteModal>
      {/* end::Modal */}

      <table className="table table-row-gray-300 gy-7">
        <thead className="table-row-dashed">
          <tr className="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
            <HeaderCell attribute="title" sort={sort} handleSort={handleSort}>
              Title
            </HeaderCell>
            <HeaderCell attribute="status" sort={sort} handleSort={handleSort}>
              Status
            </HeaderCell>
            <HeaderCell
              attribute="publish_date"
              sort={sort}
              handleSort={handleSort}
            >
              Publish Date
            </HeaderCell>
            {/* <HeaderCell
              attribute="last_edit"
              sort={sort}
              handleSort={handleSort}
            >
              Last Edit
            </HeaderCell> */}
            <HeaderCell
              isSortable={false}
              attribute="category"
              sort={sort}
              handleSort={handleSort}
            >
              Category
            </HeaderCell>
            <th className="text-end"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((el, idx) => (
            <tr key={idx} className="align-middle">
              <td>{el.title}</td>
              <td className="text-uppercase">{el.status}</td>
              <td>{format(new Date(el.publish_date), 'yyyy-MM-dd HH:mm')}</td>
              {/* <td>{format(new Date(el.last_edit), 'yyyy-MM-dd HH:mm')}</td> */}
              <td>{el.post_category?.name}</td>
              <td className="text-end">
                {/* begin::Dropdown */}
                <ActionsDropdown>
                  <div className="menu-item px-3">
                    <Link
                      to={`posts/${el.id}/update`}
                      className="menu-link px-3"
                    >
                      Update
                    </Link>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      className="menu-link px-3"
                      href={`${WEB_URL}/actueel/${el.post_category.slug}/${el.slug}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Preview
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      onClick={() => setSelected(el)}
                      href="#"
                      className="menu-link px-3 text-info"
                      data-bs-toggle="modal"
                      data-bs-target="#custom_duplicate_kt_modal_1"
                    >
                      Duplicate
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a
                      onClick={() => setSelected(el)}
                      href="#"
                      className="menu-link px-3 text-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#custom_delete_kt_modal_1"
                    >
                      Delete
                    </a>
                  </div>
                  <div className="separator mt-3 opacity-75"></div>

                  <div className="menu-item px-3">
                    <div className="menu-content px-3 py-3">
                      <Link
                        to={`posts/${el.id}/edit-content`}
                        className="btn btn-success fw-bold btn-sm px-4"
                      >
                        Edit Content
                      </Link>
                    </div>
                  </div>
                </ActionsDropdown>
                {/* end::Dropdown */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PostsTable;
