/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select, { MultiValue } from "react-select";
import { failureToast, successToast } from "../../../../support/utils";
import { IFAQ } from "../../models/FAQ";
import {
  defaultFAQCategory,
  IFAQCategory,
  UpdateFAQCategoryPayload,
} from "../../models/FAQCategory";
import {
  updateFAQCategory,
  viewFAQCategory,
} from "../../redux/FAQCategoryCRUD";
import { getAllFAQ } from "../../redux/FAQCRUD";

type UpdateFAQCategoryProps = {
  cancelUrl?: string;
};

export const UpdateFAQCategory = ({
  cancelUrl = `/page-management/faq-categories`,
}: UpdateFAQCategoryProps) => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<IFAQCategory>(defaultFAQCategory);

  const [initialized, setInitialized] = useState(false);

  const [faqs, setFaqs] = useState<Array<IFAQ>>([]);
  const [selectedFAQs, setSelectedFAQs] = useState<MultiValue<IFAQ>>([]);

  const updateData = (fieldsToUpdate: Partial<IFAQCategory>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    try {
      const payload: UpdateFAQCategoryPayload = {
        title: data.title,
        description: data.description,
        faqs: selectedFAQs.map((faq) => faq.id),
      };
      await updateFAQCategory(id, payload);
      successToast("FAQ has been updated.");
      setData(defaultFAQCategory);
      history.goBack();
      setLoading(false);
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  const handleInitialization = async (id: string) => {
    setLoading(true);

    try {
      const [faqs, data] = await Promise.all([
        getAllFAQ(),
        viewFAQCategory(id),
      ]);

      setFaqs(faqs);
      setData(data);

      setSelectedFAQs(data.faqs!);

      setLoading(false);
    } catch (e) {
      failureToast(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleInitialization(id);
    }
  }, [id]);

  useEffect(() => {
    handleInitialization(id);
    setInitialized(true);
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Update FAQ</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.title}
                  onChange={(e) =>
                    updateData({
                      title: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Description</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <textarea
                  rows={2}
                  placeholder="Enter description"
                  className="form-control form-control-lg form-control-solid"
                  value={data.description}
                  onChange={(e) =>
                    updateData({
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">FAQs</label>
            <div className="col-lg-9">
              <Select
                placeholder={`Select faqs...`}
                value={selectedFAQs}
                onChange={setSelectedFAQs}
                getOptionValue={(model: IFAQ) => model.id.toString()}
                getOptionLabel={(model: IFAQ) => model.title}
                options={faqs}
                isSearchable
                isMulti
                isClearable
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={cancelUrl}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
