import React from 'react';
import { TextEditor } from '../../../../../../components/TextEditor';
import { TextAreaEdit } from '../../../../components/TextAreaEdit';
import {
  HeadingsAndDescriptionsItem,
  HomeThreeIconsHeadingsAndDescriptionsNarrowElement,
} from './HomeThreeIconsHeadingsAndDescriptionsNarrowElement';
import { HomeThreeIconsHeadingsAndDescriptionsNarrowIcon } from './HomeThreeIconsHeadingsAndDescriptionsNarrowIcon';

interface Props {
  data: HomeThreeIconsHeadingsAndDescriptionsNarrowElement;
  item: HeadingsAndDescriptionsItem;
  index: number;
  update: (toUpdate: Partial<HeadingsAndDescriptionsItem>, idx: number) => void;
}

export const HomeThreeIconsHeadingsAndDescriptionNarrowItem: React.FC<
  Props
> = ({ index, data, item, update }) => {
  const updateValue = (to: Partial<HeadingsAndDescriptionsItem>) => {
    update(to, index);
  };
  return (
    <div className="usp__item">
      <HomeThreeIconsHeadingsAndDescriptionsNarrowIcon
        idx={index}
        data={data}
        item={item}
        updateData={update}
      />
      {/*begin::Heading*/}
      <TextAreaEdit
        name="heading"
        className="heading"
        value={item.heading}
        setValue={updateValue}
      />
      {/*end::Heading*/}

      {/*begin::Description*/}
      <div className="description">
        <TextEditor
          content={item.description}
          updateContent={(description) => updateValue({ description })}
        />
      </div>
      {/*end::Description*/}
    </div>
  );
};
