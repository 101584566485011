/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { failureToast, successToast } from '../../../support/utils';
import { defaultNotice, INotice } from '../models/Notice';
import { createNotice } from '../redux/NoticesCRUD';
import { TextEditor } from '../../../components/TextEditor';
import { FormSvgField } from '../../media-management/components/svg/FormSvgField';
import { listSvg } from '../../media-management/redux/SvgLibraryCRUD';
import { setSvgLibrary } from '../../edit-content/redux/EditContentActions';
import { useDispatch } from 'react-redux';

export const CreateNotice = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<INotice>(defaultNotice);

  const updateData = (fieldsToUpdate: Partial<INotice>) => {
    const updatedData = { ...data, ...fieldsToUpdate };
    setData(updatedData);
  };

  const handleInitialize = async () => {
    const [paginatedSvg] = await Promise.all([listSvg()]);

    // media library
    dispatch(setSvgLibrary(paginatedSvg));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    try {
      await createNotice(data);
      successToast('Notice has been created.');
      setLoading(false);
      setData(defaultNotice);
      history.goBack();
    } catch (error) {
      failureToast(error);
      setLoading(false);
    }
  };

  // removing unnecessary re-render during initialization
  // and calling the same api/s
  const [initialized, setInitialized] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleActiveNotice = () => {
    setIsChecked(!isChecked);

    updateData({ is_active: !isChecked });
  };

  useEffect(() => {
    if (!initialized) {
      handleInitialize();
      setInitialized(true);
    }
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Create Notice</h3>
      </div>
      {/* begin::Form */}
      <form onSubmit={handleSubmit} className="form d-flex flex-center">
        <div className="card-body mw-800px py-20">
          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Set as default</label>
            <div className="col-lg-9">
              <select
                className="form-select form-select-lg form-select-solid text-capitalize"
                data-control="select2"
                data-placeholder="Select State..."
                value={isChecked ? 'YES' : 'NO'}
                onChange={handleActiveNotice}
              >
                <option value={'YES'}>YES</option>
                <option value={'NO'}>NO</option>
              </select>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Title</label>
            <div className="col-lg-9">
              <div className="spinner spinner-sm spinner-primary spinner-right">
                <input
                  placeholder="Enter title"
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={data.title}
                  onChange={(e) => updateData({ title: e.target.value })}
                  required
                />
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-20">
            <label className="col-lg-3 col-form-label">Icon</label>
            <div className="col-lg-9">
              <FormSvgField
                name={`icon`}
                src={data.icon}
                update={updateData}
                modalId={`logo_modal`}
              />
              <div className="form-text">
                The icon for the notice that will be used on components that
                require notice selection.
              </div>
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row mb-8">
            <label className="col-lg-3 col-form-label">Message</label>
            <div className="col-lg-9">
              <TextEditor
                content={data.message}
                updateContent={(message) => updateData({ message })}
              />
            </div>
          </div>
          {/* end::Form row */}

          {/* begin::Form row */}
          <div className="row">
            <label className="col-lg-3 col-form-label"></label>
            <div className="col-lg-9">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary fw-bolder px-6 py-3 me-3"
              >
                {!loading && <span className="indicator-label">Save</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Please wait...{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
              <Link
                className="btn btn-color-gray-600 btn-active-light-primary fw-bolder px-6 py-3"
                to={`/notice-management/notices`}
              >
                Cancel
              </Link>
            </div>
          </div>
          {/* end::Form row */}
        </div>
      </form>
      {/* end::Form */}
    </div>
  );
};
